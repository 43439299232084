import { ReactiveVar } from '@apollo/client';

import {
  IJobTitlesInstitution,
  IJobTitlesInstitutionCache,
  jobTitlesInstitutionReactiveVar,
} from '../../cache/learner/jobTitlesInstitutionReactiveVar';
import { IFeatures } from '../../contexts/reducer/featureFlagsReducer';

export interface IJobTitlesInstitutionReactiveVarHook {
  operations: {
    setJobTitlesInstitutionData: (
      data: IJobTitlesInstitution[],
    ) => void,
    isDemo: ( jobTitleId: string, features: IFeatures ) => boolean,
    jobTitlesInstitutionDataCache: IJobTitlesInstitutionCache,
  }
}

const useJobTitlesInstitutionReactiveVarHook = (
  jobTitlesInstitutionProp: ReactiveVar<IJobTitlesInstitutionCache>,
): IJobTitlesInstitutionReactiveVarHook => {
  const jobTitlesInstitutionDataCache = jobTitlesInstitutionProp();
  const setJobTitlesInstitutionData = (
    data: IJobTitlesInstitution[],
  ): void => {
    jobTitlesInstitutionReactiveVar({
      data,
    });
  };
  const isDemo = (
    jobTitleId: string,
    features: IFeatures,
  ): boolean => features.occupationsLimitedEnabled === true && (
    jobTitlesInstitutionDataCache.data.findIndex((
      jobTitleInstitution,
    ) => jobTitleInstitution.jobTitleId === jobTitleId ) < 0 );

  return {
    operations: {
      setJobTitlesInstitutionData,
      jobTitlesInstitutionDataCache,
      isDemo,
    },
  };
};

export default useJobTitlesInstitutionReactiveVarHook;
