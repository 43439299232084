import './ChangePasswordFormConfig';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  Collapse,
  Alert,
  AlertColor,
  useTheme,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  ChangePasswordFormRootStyles,
  TextFieldCustomStyles,
  ChangePasswordButtonStyles,
  TextStyles,
  PatternPassIcon,
  PatternNotPassIcon,
  TextTitleStyles,
} from './styles/ChangePasswordFormStyles';
import { changePassword } from '../../../services/firebase.auth';
import { IPasswordValidation, passwordValidation } from '../../../utils/passwordValidationUtil';
import FormBuilderComponent from '../../generics/FormBuilderComponent/FormBuilderComponent';
import { styleConfigs } from '../../resources/styleConfigs';
import GridWrapper from '../../wrappers/GridWrapper';

interface IFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const initialValues: IFormValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

interface IChangePasswordFormProps {
  closeModal: VoidFunction;
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({ closeModal }: IChangePasswordFormProps ) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>( false );
  const [showPassword, setShowPassword] = useState<boolean>( false );
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>( false );
  const [passwordPatternValidate, setPasswordPatternValidate] = useState<IPasswordValidation>({
    uppercase: false,
    number: false,
    length: false,
  });
  const [serverError, setServerError] = useState( '' );
  const [openAlert, setOpenAlert] = useState( false );
  const [severity, setSeverity] = useState<AlertColor>( 'error' );
  const { t } = useTranslation( 'ChangePasswordForm' );
  const theme = useTheme();

  const updatePassword = async ( password: string ): Promise<void> => {
    try {
      await changePassword( password );
      formik.resetForm();
      setOpenAlert( true );
      setSeverity( 'success' );
      setServerError( t( 'PASSWORD_CHANGED_SUCCESSFULLY' ));
      setTimeout(() => {
        closeModal();
      }, 6000 );
    } catch ( error ) {
      setOpenAlert( true );
      setSeverity( 'error' );
      if ( error instanceof SyntaxError ) {
        setServerError( error.message );
      }
    }
  };

  const login = async ( password: string ): Promise<void> => {
    try {
      await updatePassword( password );
    } catch ( err ) {
      setOpenAlert( true );
      setSeverity( 'error' );
      if ( err instanceof SyntaxError ) {
        if ( err.message
          && ( err.message.toString().includes( 'wrong-password' )
            || err.message.toString().includes( 'user-not-found' )
          )
        ) {
          setServerError( t( 'INVALID_USER_PASSWORD' ));

          return;
        }
        setServerError( err.message );
      }
    }
  };

  const handleSubmit = async ( values: IFormValues ): Promise<void> => {
    setOpenAlert( false );
    login( values.password );
  };

  const formik = FormBuilderComponent(
    initialValues,
    Yup.object({
      currentPassword: Yup.string().required( t( 'CURRENT_PASSWORD_REQUIRED' )),
      password: Yup.string(),
      confirmPassword: Yup.string().oneOf([Yup.ref( 'password' ), null], t( 'PASSWORD_DONT_MATCH' )),
    }),
    handleSubmit,
  );

  const passwordChange = ( event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ): void =>
    setPasswordPatternValidate( passwordValidation( event?.target?.value ));

  return (
    <ChangePasswordFormRootStyles
      data-testid="changepassword-form"
      onSubmit={formik.handleSubmit}
    >
      <Collapse in={openAlert}>
        <Alert
          severity={severity}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert( false );
              }}
            >
              {severity !== 'success'
                && (
                  <CloseIcon fontSize="inherit" />
                )}
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          {serverError}
        </Alert>
      </Collapse>
      {severity !== 'success'
        && (
          <>
            <TextFieldCustomStyles
              type={showCurrentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowCurrentPassword( !showCurrentPassword )}
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t( 'LABEL_CURRENT_PASSWORD' )}
              placeholder={t( 'LABEL_CURRENT_PASSWORD' )}
              autoFocus
              name="currentPassword"
              onChange={formik.handleChange}
              error={!!formik.errors.currentPassword || false}
              helperText={formik.errors.currentPassword}
              required
              color="secondary"
            />
            <TextFieldCustomStyles
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword( !showPassword )}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t( 'LABEL_NEW_PASSWORD' )}
              placeholder={t( 'LABEL_NEW_PASSWORD' )}
              name="password"
              onChange={( event ) => {
                formik.handleChange( event );
                passwordChange( event );
              }}
              error={( !passwordPatternValidate.uppercase
                || !passwordPatternValidate.number
                || !passwordPatternValidate.length ) && formik.values.password !== ''}
              required
              color="secondary"
            />
            <TextFieldCustomStyles
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowConfirmPassword( !showConfirmPassword )}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t( 'LABEL_CONFIRM_NEW_PASSWORD' )}
              placeholder={t( 'LABEL_CONFIRM_NEW_PASSWORD' )}
              name="confirmPassword"
              onChange={formik.handleChange}
              error={!!formik.errors.confirmPassword && formik.values.confirmPassword !== ''}
              helperText={formik.errors.confirmPassword === t( 'PASSWORD_DONT_MATCH' ) ? formik.errors.confirmPassword
                : ''}
              required
              color="secondary"
            />
            <GridWrapper
              marginBottom={styleConfigs.marginPadding.custom3}
            >
              <TextTitleStyles>
                {t( 'CONSTRAINT_CONTAIN' )}
              </TextTitleStyles>
              <TextStyles
                theme={theme}
                iserror={passwordPatternValidate.uppercase ? 0 : 1}
              >
                {t( 'CONSTRAINT_UPPERCASE' )}
                {passwordPatternValidate.uppercase ? <PatternPassIcon data-testid="uppercase-pass" /> : <PatternNotPassIcon data-testid="uppercase-notpass" />}
              </TextStyles>
              <TextStyles
                theme={theme}
                iserror={passwordPatternValidate.number ? 0 : 1}
              >
                {t( 'CONSTRAINT_NUMBER' )}
                {passwordPatternValidate.number ? <PatternPassIcon data-testid="number-pass" /> : <PatternNotPassIcon data-testid="number-notpass" />}
              </TextStyles>
              <TextStyles
                theme={theme}
                iserror={passwordPatternValidate.length ? 0 : 1}
              >
                {t( 'CONSTRAINT_LENGTH' )}
                {passwordPatternValidate.length ? <PatternPassIcon data-testid="length-pass" /> : <PatternNotPassIcon data-testid="length-notpass" />}
              </TextStyles>
            </GridWrapper>
            <ChangePasswordButtonStyles
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!passwordPatternValidate.uppercase
                || !passwordPatternValidate.number
                || !passwordPatternValidate.length
                || !!formik.errors.currentPassword
                || !!formik.errors.confirmPassword
                || formik.values.confirmPassword === ''
                || formik.values.currentPassword === ''}
            >
              {t( 'UPDATE_PASSWORD' )}
            </ChangePasswordButtonStyles>
          </>
        )}
    </ChangePasswordFormRootStyles>
  );
};

export default ChangePasswordForm;
