import { gql, MutationTuple, useMutation } from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';

export const NEW_SIGNUP_GOOGLE = gql`
mutation Mutation($loginUser: SignInGoogleInput!) {
  signInWithGoogle(loginUser: $loginUser) {
    jwtToken
  }
}`;

interface ISignupGoogleParams {
  loginUser: {
    localId: string,
    email: string,
    emailVerified: boolean,
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
  }
}

interface ISignupGoogleResponse {
  signInWithGoogle: {
    jwtToken: string
  }
}

export const useMutationSignupGoogle = ():
MutationTuple<ISignupGoogleResponse, ISignupGoogleParams> =>
  useMutation<ISignupGoogleResponse, ISignupGoogleParams>(
    NEW_SIGNUP_GOOGLE,
    {
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
