import { makeVar } from '@apollo/client';

import { IRelatedOccupations } from '../../operations/query/students/useGetRelatedOccupations';

export interface IRelatedOccupationsCache {
  error: '';
  data: IRelatedOccupations[];
}

export const emptyRecommendedOccupationCache: IRelatedOccupationsCache = {
  error: '',
  data: [],
};

export const RelatedOccupationsReactiveVar = makeVar<IRelatedOccupationsCache>(
  emptyRecommendedOccupationCache,
);
