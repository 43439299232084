import './AccountPopoverConfig';
import { Icon } from '@iconify/react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box, Divider, MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import {
  useRef, useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import AccountBrief from './AccountPopOverComponents/AccountBrief';
import AccountInfo from './AccountPopOverComponents/AccountInfo';
import { InitialsStyled, ListItemIconStyle, MIconButtonStyled } from './styles/AccountPopoverStyles';
import { userMajors } from '../../cache/cache';
import { invalidMajor } from '../../cache/learner/invalidMajorReactiveVar';
import MenuPopover from '../../components/template-minimal/MenuPopoverComponent/MenuPopover';
import SvgIconStyle from '../../components/template-minimal/SvgIconStyle';
import icons from '../../constants/icons';
import { INVALID_MAJOR_ID } from '../../constants/invalidMajorId';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';
import userAuth from '../../hooks/userAuth';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { MixpanelComponentsNames } from '../../mixpanel/MixpanelEvents/mixpanelComponentNames';
import { MixpanelEventsEnum } from '../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { useLazyGetStudentMajors } from '../../operations/query/students/useGetStudentMajors';
import { PATH_DASHBOARD_STUDENT } from '../../routes/paths';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { popInvalidMajors } from '../../utils/popInvalidMajor';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';

type IAccountPopoverProps = {
  readonly openChangePasswordDialog: VoidFunction;
}

interface IMenuOptions {
  label: string;
  icon: JSX.Element;
  linkTo: string;
}

const getIcon = ( icon: string ): JSX.Element => (
  <SvgIconStyle src={icon} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  profile: getIcon( icons.PROFILE_ICON ),
  password: getIcon( icons.PASSWORD_ICON ),
  logout: getIcon( icons.LOGOUT_ICON ),
};

export const MENU_OPTIONS: IMenuOptions[] = [
  { label: 'PROFILE', icon: ICONS.profile, linkTo: PATH_DASHBOARD_STUDENT.profile.profile },
  { label: 'CHANGE_PASSWORD', icon: ICONS.password, linkTo: '#' },
  { label: 'LOGOUT', icon: ICONS.logout, linkTo: '#' },
];

export default function AccountPopover( props: IAccountPopoverProps ): JSX.Element {
  const { openChangePasswordDialog } = props;
  const { logout, auth } = userAuth();
  const navigate = useNavigate();
  const anchorRef = useRef( null );
  const [open, setOpen] = useState( false );
  const { pathname, search } = useLocation();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const studentInitials = `${auth?.student?.name.replace( /\s/g, '' )[0]}${auth?.student?.lastName.replace( /\s/g, '' )[0]}`;
  const { t } = useTranslation( 'LearnerAccountPopover' );
  const { currentUser } = getAuth();
  const [fromKeyboard, setFromKeyboard] = useState( 0 );
  const { features } = useContext( FeatureFlagsContext );
  const smScreen: boolean = useMediaQuery( useTheme().breakpoints.down( 'sm' ));

  const handleOptions = (): IMenuOptions[] => {
    const initialOptions = currentUser?.providerData?.find(({ providerId }) => providerId === 'password' )
      ? MENU_OPTIONS
      : MENU_OPTIONS.filter(({ label }) => label !== 'CHANGE_PASSWORD' );

    return auth?.redirectLogoutUrl ?? features.embededInIframe ? initialOptions.filter(( item ) => item.label !== 'LOGOUT' ) : initialOptions;
  };

  const handleOpen = (): void => {
    setFromKeyboard( 0 );
    setOpen( true );
    Mixpanel.track({
      action: MixpanelEventsEnum.PROFILE_POPOVER,
      data: {
        screen: getMixpanelName( pathname.concat( search )),
        component: MixpanelComponentsNames.ACCOUNT_POPOVER,
        action: 'click',
      },
    }, isLearner );
  };

  const handleClose = async ( label?: string ): Promise<void> => {
    if ( label ) {
      const screenName = pathname.concat( search );

      if ( label === 'CHANGE_PASSWORD' ) {
        openChangePasswordDialog();
        Mixpanel.track({
          action: MixpanelEventsEnum.CHANGE_PASSWORD,
          data: {
            screen: getMixpanelName( screenName ),
            component: MixpanelComponentsNames.ACCOUNT_POPOVER,
            action: 'click',
          },
        }, isLearner );
      }
      if ( label === 'PROFILE' ) {
        Mixpanel.track({
          action: MixpanelEventsEnum.GO_TO_PROFILE,
          data: {
            screen: getMixpanelName( screenName ),
            component: MixpanelComponentsNames.ACCOUNT_POPOVER,
            action: 'click',
          },
        }, isLearner );
      }
      if ( label === 'LOGOUT' ) {
        await logout();
        navigate( '/' );
        Mixpanel.track({
          action: MixpanelEventsEnum.LOGOUT,
          data: {
            screen: getMixpanelName( screenName ),
            component: MixpanelComponentsNames.ACCOUNT_POPOVER,
            action: 'click',
          },
        }, isLearner );
      }
    }
    setOpen( false );
  };

  const handleKeyDownButton = ( e: React.KeyboardEvent<HTMLButtonElement> ):void => {
    const keyPressed = e.key;

    switch ( keyPressed ) {
      case 'ArrowRight':
        setFromKeyboard( 1 );
        break;

      case 'ArrowLeft':
        setFromKeyboard( 1 );
        break;

      case 'End':
        setFromKeyboard( 1 );
        break;

      case 'Home':
        setFromKeyboard( 1 );
        break;

      case 'Tab':
        setFromKeyboard( 1 );
        break;

      default:
        setFromKeyboard( 0 );

        break;
    }
  };

  const [getData, { data: majorResponse }] = useLazyGetStudentMajors( true );

  useEffect(() => {
    if ( majorResponse?.getStudentMajors ) {
      const invalidMajors = majorResponse.getStudentMajors.filter(( major ) =>
        major.tblMajor?.id === INVALID_MAJOR_ID ).length > 0
      || !majorResponse.getStudentMajors[0]?.tblMajor;

      invalidMajor( invalidMajors );

      const filteredMajors = popInvalidMajors( majorResponse.getStudentMajors );

      const majors = filteredMajors.map(( item ) => ({
        name: item.tblMajor?.name ?? '',
      }));

      userMajors( majors );
    }
  }, [majorResponse]);

  useEffect(() => {
    getData({
      variables: {
        studentId: auth?.student?.id ?? '',
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MIconButtonStyled
        data-testid="pop_button"
        ref={anchorRef}
        onClick={handleOpen}
        onKeyDown={handleKeyDownButton}
        open={open ? 1 : 0}
        islearner={isLearner ? 1 : 0}
        fromkeyboard={fromKeyboard}
      >
        <InitialsStyled>
          {studentInitials}
        </InitialsStyled>
        {!smScreen && (
          <>
            <AccountBrief />
            <ExpandMore />
          </>
        )}
      </MIconButtonStyled>

      <MenuPopover
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        width={330}
        id="learner-MenuPopover"
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <AccountInfo />
        </Box>
        {handleOptions().map(( option ) => (
          <div key={option.label}>
            <Divider variant="middle" />
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={( event: any ) => {
                if ( option.linkTo === '#' ) {
                  event.preventDefault();
                }
                handleClose( option.label );
              }}
              sx={{ typography: 'body2', py: 2.5, px: 2.5 }}
            >
              <Box
                component={Icon}
                sx={{
                  width: 24,
                  height: 24,
                  mr: 2,
                }}
              />
              <ListItemIconStyle>
                {option.icon}
              </ListItemIconStyle>
              {t( option.label )}
            </MenuItem>
          </div>
        ))}
      </MenuPopover>
    </>
  );
}
