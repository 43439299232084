const locale = {
  GUEST_DISPLAYNAME: 'Guest',
  LOCATION: 'Institution',
  DEFAULT_LOCATION: 'Ontario, Canada',
  INFO_POPOVER_STUDENT_LOCATION: 'University',
  INFO_POPOVER_PROFILE: 'Purdue University',
  ADMIN_PROFILE: 'Admin',
  NO_MAJORS_LENGTH: 'No areas of interest info',
  ADD_MAJOR: 'Add your first area of interest',
};

export default locale;
