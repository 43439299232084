import { styled } from '@mui/material/styles';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import GridWrapper from '../../../wrappers/GridWrapper';

export const LogoContainerStyled = styled( GridWrapper )({
  width: styleConfigs.dimensions.width145,
  borderRadius: styleConfigs.borders.radius10,
  cursor: 'pointer',
  '&:focus-visible': {
    border: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
  },
});
