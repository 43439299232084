import environmentVariables from '../../appVariables';
import { FeaturesEnum } from '../../constants/enums/FeaturesEnum';
import { IFeatureWithValue } from '../../operations/query/public/features/useGetFeaturesWithValues';
import { getCustomFavicon } from '../../utils/getCustomFavicon';
import { getCustomRedirectUrl } from '../../utils/getCustomRedirectUrl';
import { getProvidireToHideNumberOfCourses } from '../../utils/hideCoursesNumberUtils';
import { LanguagesEnum, getDefaultLanguageFromFeature } from '../../utils/languageUtils';

export interface IFeatures {
  sso?: boolean;
  registerUser?: boolean;
  customLogo: boolean | string;
  customFavicon: string | undefined;
  learner?: {
    hideLearnerHelp: boolean;
    defaultStudentInstitution: boolean;
    hideProfileEditButton: boolean;
    hideLearningPathwaysScreen: boolean;
    showHobbies: boolean;
    institutionWizardQuestion: boolean;
    typeOfUserWizardQuestion: boolean;
    employabilityStatusWizardQuestion: boolean;
    defaultLevelOfStudy: boolean;
    learningPathShowInOccupations?: boolean;
    academicProgramEnabled: boolean;
    showCourseRecommendationsMessage: boolean;
    showFooter?: boolean;
    showInfoSkillsSection?: boolean;
    showInfoLearningPathSection?: boolean;
    showInfoWizard?: boolean;
    showCareerDiscovery?: boolean;
    showBadgeSkillValidated?: boolean;
  };
  advisor?: {
    hideCareerTrendsScreen?: boolean;
    hideStudentList?: boolean;
    hideSkillInsights?: boolean;
    hideStudentOverview?: boolean;
    hideStudentAdvising?: boolean;
    hideContactUs?: boolean;
    hideFAQ?: boolean;
  }
  defaultLanguage: LanguagesEnum;
  embededLoginLiderly: string | undefined;
  embededInIframe?: boolean;
  defaultCountry: boolean;
  specialzedMajors?: boolean;
  occupationsLimitedEnabled?: boolean;
  samlGriky: boolean;
  samlUVA: boolean;
  iframeAndPlatform: boolean;
  qsInstance?:boolean;
  showLPProvidersCoursesNumber?:string;
  customRedirectUrl?: string;
  whiteLabel?: boolean;
  showOffice365?: boolean;
}

export interface IFeatureFlagsReducer {
  features: IFeatures
  setFeatures: ( initialValue: string[]) => void
  updateFeatures?: ( features: IFeatureWithValue[]) => void
}

export interface IIFeatureFlagsReducerAction {
  type: string;
  initialValue: string[];
  features?: IFeatureWithValue[];
}

export const featuresFlagReducerState: IFeatureFlagsReducer = {
  features: {
    defaultLanguage: 'en',
    embededLoginLiderly: undefined,
    customLogo: false,
    customFavicon: undefined,
    embededInIframe: environmentVariables?.embededInIframe === 'true',
    samlGriky: environmentVariables?.reactAppSamlGriky === 'true',
    samlUVA: environmentVariables?.reactAppSamlUVA === 'true',
    defaultCountry: false,
    iframeAndPlatform: environmentVariables?.iframeAndPlatform === 'true',
    showLPProvidersCoursesNumber: '',
    customRedirectUrl: '',
    showOffice365: false,
  },
  setFeatures: () => null,
};

const featureFlagReducer = (
  state: IFeatureFlagsReducer,
  action: IIFeatureFlagsReducerAction,
): IFeatureFlagsReducer => {
  const defaultState = state || featuresFlagReducerState;
  const embededInIframe = environmentVariables?.embededInIframe === 'true';
  const iframeAndPlatform = environmentVariables?.iframeAndPlatform === 'true';

  const findFeature = ( feature: string ): boolean =>
    !!action.initialValue.find(( availableFeature ) => availableFeature === feature );

  const defaultLanguage = getDefaultLanguageFromFeature( action.initialValue );

  const listOfProviderToHidCoursesNumbers = () :string => {
    const showLPProvidersCoursesNumber = action.initialValue.find(( availableFeature ) =>
      availableFeature.includes( FeaturesEnum.showLPProvidersCoursesNumber ));

    return getProvidireToHideNumberOfCourses( showLPProvidersCoursesNumber ?? '' );
  };

  const embededLogin = (): string | undefined => {
    const embedLogin = action.initialValue.find(( availableFeature ) => availableFeature.includes( 'EMBED_LOGIN' ));

    return embedLogin ? embedLogin.split( `${FeaturesEnum.embededLoginLiderly}-` )[1] : undefined;
  };

  const hasFeature = ( features: string[], feature: string ): boolean =>
    features.some(( item ) => item.includes( feature ));

  const featureValue = ( features: string[], feature: string ): string | undefined =>
    features.find(( item ) => item.includes( feature ));

  switch ( action.type ) {
    case 'addFeatures':

      return {
        ...defaultState,
        features: {
          sso: findFeature( FeaturesEnum.sso ),
          registerUser: findFeature( FeaturesEnum.registerUser ),
          customLogo: hasFeature(
            action.initialValue,
            FeaturesEnum.customLogo,
          ),
          customFavicon: hasFeature(
            action.initialValue,
            FeaturesEnum.customFavicon,
          ) ? getCustomFavicon( featureValue(
              action.initialValue,
              FeaturesEnum.customFavicon,
            )) : undefined,
          learner: {
            hideLearnerHelp: findFeature( FeaturesEnum.hideLearnerHelp ),
            defaultStudentInstitution: hasFeature(
              action.initialValue,
              FeaturesEnum.defaultStudentInstitution,
            ),
            hideProfileEditButton: findFeature( FeaturesEnum.learnerHideProfileEditButton ),
            hideLearningPathwaysScreen: findFeature( FeaturesEnum.hideLearningPathWaysScreen ),
            showHobbies: findFeature( FeaturesEnum.showHobbies ),
            institutionWizardQuestion: findFeature( FeaturesEnum.institutionWizardQuestion ),
            typeOfUserWizardQuestion: findFeature( FeaturesEnum.typeOfUserWizardQuestion ),
            defaultLevelOfStudy: hasFeature(
              action.initialValue,
              FeaturesEnum.defaultLevelOfStudy,
            ),
            employabilityStatusWizardQuestion: findFeature( FeaturesEnum.showEmployabilityStatus ),
            learningPathShowInOccupations: findFeature( FeaturesEnum.learningPathShowInOccupations ),
            academicProgramEnabled: findFeature( FeaturesEnum.academicProgramEnabled ),
            showCourseRecommendationsMessage: findFeature( FeaturesEnum.showCourseRecommendationsMessage ),
            showFooter: findFeature( FeaturesEnum.showFooter ),
            showInfoSkillsSection: findFeature( FeaturesEnum.showInfoSkillsSection ),
            showInfoLearningPathSection: findFeature( FeaturesEnum.showInfoLearningPathSection ),
            showInfoWizard: findFeature( FeaturesEnum.showInfoWizard ),
            showCareerDiscovery: findFeature( FeaturesEnum.showCareerDiscovery ),
            showBadgeSkillValidated: findFeature( FeaturesEnum.showBadgeSkillValidated ),
          },
          advisor: {
            hideCareerTrendsScreen: findFeature( FeaturesEnum.hideCareerTrendsScreen ),
            hideStudentList: findFeature( FeaturesEnum.hideAdvisorStudentList ),
            hideSkillInsights: findFeature( FeaturesEnum.hideAdvisorSkillInsights ),
            hideStudentAdvising: findFeature( FeaturesEnum.hideAdvisorStudentAdvising ),
            hideStudentOverview: findFeature( FeaturesEnum.hideAdvisorStudentOverview ),
            hideContactUs: findFeature( FeaturesEnum.hideAdvisorContactUs ),
            hideFAQ: findFeature( FeaturesEnum.hideAdvisorFAQ ),
          },
          defaultLanguage,
          embededLoginLiderly: embededLogin(),
          embededInIframe: !embededInIframe ? findFeature( FeaturesEnum.embededInIframe ) : embededInIframe,
          defaultCountry: hasFeature(
            action.initialValue,
            FeaturesEnum.defaultCountry,
          ),
          specialzedMajors: findFeature( FeaturesEnum.specializedMajors ),
          occupationsLimitedEnabled: findFeature( FeaturesEnum.occupationsLimitedEnabled ),
          samlGriky: findFeature( FeaturesEnum.samlGriky ),
          samlUVA: findFeature( FeaturesEnum.samlUVA ),
          iframeAndPlatform: !iframeAndPlatform ? findFeature( FeaturesEnum.iframeAndPlatform ) : iframeAndPlatform,
          qsInstance: findFeature( FeaturesEnum.qsInstance ),
          showLPProvidersCoursesNumber: listOfProviderToHidCoursesNumbers(),
          customRedirectUrl: hasFeature(
            action.initialValue,
            FeaturesEnum.customRedirectUrl,
          ) ? getCustomRedirectUrl( featureValue(
              action.initialValue,
              FeaturesEnum.customRedirectUrl,
            )) : '',
          whiteLabel: findFeature( FeaturesEnum.whiteLabel ),
          showOffice365: hasFeature(
            action.initialValue,
            FeaturesEnum.showOffice365,
          ),
        },
      };

    case 'updateFeatures': {
      const featuresMapWithValues = action?.features?.reduce(( map, feature ) => {
        const normalizedKey = feature.name
          .toLowerCase()
          .replace( /_/g, '' )
          .split( '-' )[0];

        // eslint-disable-next-line no-param-reassign
        map[normalizedKey] = feature.value;

        return map;
      }, {} as Record<string, string> );

      const updatedFeatures = { ...defaultState.features };

      // TODO: Refactor
      if ( featuresMapWithValues?.customlogo ) {
        updatedFeatures.customLogo = featuresMapWithValues.customlogo;
      }

      return {
        ...defaultState,
        features: updatedFeatures,
      };
    }

    default:
      return state;
  }
};

export default featureFlagReducer;
