import {
  gql,
  useMutation,
  MutationTuple,
} from '@apollo/client';

export const WRITE_LOG = gql`
    mutation WriteLog($project: String!, $source: String!, $func: String!, $error: String!, $level: String) {
        writeLog(project: $project, source: $source, func: $func, error: $error, level: $level) {
            success
        }
    }
`;

export interface IWriteLogParams {
  project: string;
  source: string;
  func: string;
  error: string;
  level?: string;
}

export interface IWriteLogReturn {
  success: boolean;
}

export interface IWriteLogResponse {
  writeLog: IWriteLogReturn | undefined;
}

export const useWriteLog = ():
MutationTuple<IWriteLogResponse, IWriteLogParams> =>
  useMutation<IWriteLogResponse, IWriteLogParams>(
    WRITE_LOG,
  );
