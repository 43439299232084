const locale = {
  TITLE: 'Select a platform',
  SUBTITLE: 'We’ve detected that your account has two roles associated with it. Please select the platform you wish to use.',
  LEARNER_USER: 'Learner',
  ADVISOR_USER: 'Advisor',
  DISABLED_BUTTON: 'Select role',
  ENABLED_BUTTON: 'Log in',
  CANCEL: 'Cancel',
  TITLE_ERROR: 'Access error',
  DESCRIPTION_ERROR: 'No module is currently enabled. Please contact your 1Mentor account manager for assistance',
};

export default locale;
