import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../learnerPalette';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function SvgIcon( theme: Theme ): unknown {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.redViolet ?? palette.light.text.ebonyClay;

  return {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          width: 20,
          height: 20,
          fontSize: 'inherit',
          fill: colorPalette,
        },
        fontSizeLarge: {
          width: 32,
          height: 32,
          fontSize: 'inherit',
          fill: colorPalette,
        },
        fontSizeMedium: {
          fill: colorPalette,
        },
      },
    },
  };
}
