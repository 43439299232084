import { gql, MutationTuple, useMutation } from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';

export const NEW_SIGNIN = gql`
mutation Mutation($email: NonEmptyString!, $password: NonEmptyString!, $remember: Boolean) {
  jwtToken : signIn(email: $email, password: $password , remember: $remember)
}
`;

interface ISignInParams {
  email: string;
  password: string;
  remember: boolean;
}

interface ISignInResponse {
  jwtToken?: string
}

export const useMutationSignIn = ():
MutationTuple<ISignInResponse, ISignInParams> =>
  useMutation<ISignInResponse, ISignInParams>(
    NEW_SIGNIN,
    {
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
