import '../ComponentNotificationConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogStyled,
  DialogContainerStyled,
  DialogHeaderStyled,
  DialogBackButton,
  DialogContentStyled,
  DialogPaperStyled,
  DialogEmptyStateStyled,
  DialogScrollTopStyled,
  DialogFabStyled,
} from './styles/MobileListComponentStyles';
import EmptyStateComponent from '../../../components/generics/EmptyStateComponent/EmptyStateComponent';
import { styleConfigs } from '../../../components/resources/styleConfigs';
import DividerWrapper from '../../../components/wrappers/DividerWrapper';
import ScrollbarWrapper from '../../../components/wrappers/ScrollbarWrapper';
import StackWrapper from '../../../components/wrappers/StackWrapper';
import TypographyWrapper from '../../../components/wrappers/TypographyWrapper';
import icons from '../../../constants/icons';
import { IStudentNotification } from '../../../operations/mutations/student/useMutationUpdateStudentSkillStatus/useMutationUpdateStudentSkillStatus';
import palette from '../../../theme/learnerPalette';
import CardNotificationComponent from '../CardNotificationComponent/CardNotificationComponent';

interface INotifications {
  newNotifications: IStudentNotification[];
  beforeNotifications: IStudentNotification[];
}

interface IMobileListContainerProps {
  data?: INotifications;
  open: boolean;
  onClose?: () => Promise<void>;
}

const ScrollTop = ( props: { children: React.ReactElement }): JSX.Element => {
  const { children } = props;
  const handleClick = ( event: React.MouseEvent<HTMLDivElement> ): void => {
    const anchor = ( event.target as HTMLDivElement ).ownerDocument?.querySelector( '#back-to-top-anchor' );

    if ( anchor ) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <DialogScrollTopStyled onClick={handleClick}>
      {children}
    </DialogScrollTopStyled>
  );
};

const MobileListComponent: React.FC<IMobileListContainerProps> = ( props: IMobileListContainerProps ) => {
  const { data, open, onClose } = props;
  const { t } = useTranslation( 'ComponentNotification' );

  return (
    <DialogStyled
      fullScreen
      open={open}
      PaperComponent={DialogPaperStyled}
    >
      <DialogContainerStyled>
        <DialogHeaderStyled>
          <StackWrapper
            direction="row"
            gap={styleConfigs.marginPadding.custom2}
          >
            <StackWrapper
              height={styleConfigs.dimensions.percent100}
              direction="column"
              justifyContent="flex-start"
            >
              <DialogBackButton
                data-testid="arrow-back-button"
                onClick={onClose}
              >
                <ArrowBackIcon />
              </DialogBackButton>
            </StackWrapper>
            <StackWrapper>
              <TypographyWrapper variant="subtitle1">
                {t( 'NOTIFICATION_TEXT_TITLE' )}
              </TypographyWrapper>
              <TypographyWrapper variant="subtitle2" color={palette.light.text.mistBlue}>
                {t( 'NOTIFICATION_TEXT_UNREAD_NOTIFICATION', { unread: data?.newNotifications.length.toString() })}
              </TypographyWrapper>
            </StackWrapper>
          </StackWrapper>
          <StackWrapper
            mr={styleConfigs.marginPadding.custom2}
          >
            <DoneAllIcon color="secondary" />
          </StackWrapper>
        </DialogHeaderStyled>
      </DialogContainerStyled>
      <DividerWrapper />
      { !data || ( !data.newNotifications.length && !data.beforeNotifications.length ) ? (
        <DialogEmptyStateStyled>
          <EmptyStateComponent
            iconSrc={icons.EMPTY_STATE_NOTIFICATIONS}
            widthIcon={styleConfigs.dimensions.width120}
            heightIcon={styleConfigs.dimensions.height120}
            title={t( 'NOTIFICATION_EMPTY_STATE_TITLE' )}
            fontSizeTitle={styleConfigs.fonts.fontSize18}
            gapIconTitle={styleConfigs.marginPadding.empty}
            subtitle={t( 'NOTIFICATION_EMPTY_STATE_SUBTITLE' )}
            fontSizeSubtitle={styleConfigs.fonts.fontSize12}
            paddingTopSubtitle={styleConfigs.marginPadding.empty}
          />
        </DialogEmptyStateStyled>
      ) : (
        <>
          <ScrollbarWrapper>
            <div id="back-to-top-anchor" />
            { data.newNotifications.map(( notification: IStudentNotification ) => (
              <CardNotificationComponent
                key={notification.id.toString()}
                notificationDetails={notification}
                isMobile
              />
            )) }
            { data.beforeNotifications.map(( notification: IStudentNotification ) => (
              <CardNotificationComponent
                key={notification.id.toString()}
                notificationDetails={notification}
                beforeNotification
                isMobile
              />
            )) }
          </ScrollbarWrapper>
          <DialogContentStyled>
            <ScrollTop>
              <DialogFabStyled
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUp />
              </DialogFabStyled>
            </ScrollTop>
          </DialogContentStyled>
        </>
      ) }
    </DialogStyled>
  );
};

export default MobileListComponent;
