import '../ComponentNotificationConfig';
import { useReactiveVar } from '@apollo/client';
import bellOutline from '@iconify/icons-eva/bell-outline';
import { Icon } from '@iconify/react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router';

import {
  GridScrollStyled, MIconButtonStyle, NewNotifications,
} from './NotificationComponentStyles';
import { emptyNotifications, renderNotification } from '../../../cache/learner/homeReactiveVars';
import EmptyStateComponent from '../../../components/generics/EmptyStateComponent/EmptyStateComponent';
import { styleConfigs } from '../../../components/resources/styleConfigs';
import MenuPopover from '../../../components/template-minimal/MenuPopoverComponent/MenuPopover';
import DividerWrapper from '../../../components/wrappers/DividerWrapper';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import StackWrapper from '../../../components/wrappers/StackWrapper';
import TypographyWrapper from '../../../components/wrappers/TypographyWrapper';
import icons from '../../../constants/icons';
import userAuth from '../../../hooks/userAuth';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { MixpanelComponentsNames } from '../../../mixpanel/MixpanelEvents/mixpanelComponentNames';
import { MixpanelEventsEnum } from '../../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { useMutationDeleteNotifications } from '../../../operations/mutations/student/useDeleteStudentNotification/useDeleteStudentNotification';
import palette from '../../../theme/learnerPalette';
import { getMixpanelName } from '../../../utils/getMixpanelName';
import { ROLE_ENUM } from '../../../views/Auth/Login/interfaces/IGoogleUser';
import CardNotificationComponent from '../CardNotificationComponent/CardNotificationComponent';
import MobileListComponent from '../MobileListComponent/MobileListComponent';

const NotificationComponent: React.FC = () => {
  const { auth } = userAuth();
  const theme = useTheme();
  const { t } = useTranslation( 'ComponentNotification' );
  const anchorRef = useRef( null );
  const [open, setOpen] = useState<boolean>( false );
  const [fromKeyboard, setFromKeyboard] = useState( 0 );
  const [deleteNotifications] = useMutationDeleteNotifications();
  const allNotifications = useReactiveVar( renderNotification );
  const isSmallScreen = useMediaQuery( theme.breakpoints.down( 'sm' ));
  const { pathname, search } = useLocation();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;

  const handleChangeOpen = async (): Promise<void> => {
    setFromKeyboard( 0 );
    setOpen( !open );
    const updateNotificationWhenClose = !!open;
    const notificationsIdsToUpdate = allNotifications.newNotifications.map(({ id }) => id );

    if ( updateNotificationWhenClose && !!notificationsIdsToUpdate.length ) {
      renderNotification( emptyNotifications );
      await deleteNotifications({
        variables: {
          studentId: auth?.student?.id ?? '',
        },
      });
      Mixpanel.track({
        action: MixpanelEventsEnum.CLEAR_NOTIFICATIONS,
        data: {
          screen: getMixpanelName( pathname.concat( search )),
          component: MixpanelComponentsNames.NOTIFICATION_COMPONENT,
          action: 'click',
        },
      }, isLearner );
    } else {
      Mixpanel.track({
        action: MixpanelEventsEnum.VIEW_NOTIFICATIONS,
        data: {
          screen: getMixpanelName( pathname.concat( search )),
          component: MixpanelComponentsNames.NOTIFICATION_COMPONENT,
          action: 'click',
        },
      }, isLearner );
    }
  };

  const handleKeyDownButton = ( e: React.KeyboardEvent<HTMLButtonElement> ): void => {
    const keyPressed = e.key;

    switch ( keyPressed ) {
      case 'ArrowRight':
        setFromKeyboard( 1 );
        break;

      case 'ArrowLeft':
        setFromKeyboard( 1 );
        break;

      case 'End':
        setFromKeyboard( 1 );
        break;

      case 'Home':
        setFromKeyboard( 1 );
        break;

      case 'Tab':
        setFromKeyboard( 1 );
        break;

      default:
        setFromKeyboard( 0 );

        break;
    }
  };

  return (
    <>
      <MIconButtonStyle
        aria-label="Notifications"
        ref={anchorRef}
        theme={theme}
        fromkeyboard={fromKeyboard}
        onClick={handleChangeOpen}
        onKeyDown={handleKeyDownButton}
      >
        {!!allNotifications.newNotifications?.length && (
          <NewNotifications>
            <TypographyWrapper variant="button">
              {allNotifications.newNotifications.length}
            </TypographyWrapper>
          </NewNotifications>
        )}
        <Icon
          icon={bellOutline}
          color={palette.light.icons.ebonyClay}
        />
      </MIconButtonStyle>
      { isSmallScreen ? (
        <MobileListComponent
          open={open}
          onClose={handleChangeOpen}
          data={allNotifications}
        />
      ) : (
        <MenuPopover
          open={open}
          onClose={handleChangeOpen}
          anchorEl={anchorRef.current}
          sx={{ borderRadius: `${styleConfigs.borders.border16}px` }}
          width={styleConfigs.dimensions.width360}
        >
          <GridWrapper
            container
            direction="row"
            alignItems="center"
            padding={`${styleConfigs.marginPadding.custom20}px 
            ${styleConfigs.marginPadding.custom16}px`}
          >
            <GridWrapper item sm={10}>
              <TypographyWrapper variant="h6">
                { t( 'NOTIFICATION_TEXT_TITLE' ) }
              </TypographyWrapper>
              <TypographyWrapper variant="subtitle2" color={palette.light.text.mistBlue}>
                {t( 'NOTIFICATION_TEXT_UNREAD_NOTIFICATION', { unread: allNotifications.newNotifications.length.toString() })}
              </TypographyWrapper>
            </GridWrapper>
            <GridWrapper
              item
              sm={2}
              justifyContent="center"
              alignItems="flex-start"
            >
              <StackWrapper
                direction="column"
                alignItems="flex-end"
              >
                <DoneAllIcon color="secondary" />
              </StackWrapper>
            </GridWrapper>
          </GridWrapper>
          <DividerWrapper />
          {( !allNotifications.newNotifications.length && !allNotifications.beforeNotifications.length ) ? (
            <GridWrapper
              height={styleConfigs.dimensions.height560}
            >
              <EmptyStateComponent
                iconSrc={icons.EMPTY_STATE_NOTIFICATIONS}
                widthIcon={styleConfigs.dimensions.width80}
                heightIcon={styleConfigs.dimensions.height80}
                title={t( 'NOTIFICATION_EMPTY_STATE_TITLE' )}
                fontSizeTitle={styleConfigs.fonts.fontSize18}
                gapIconTitle={styleConfigs.marginPadding.empty}
                subtitle={t( 'NOTIFICATION_EMPTY_STATE_SUBTITLE' )}
                fontSizeSubtitle={styleConfigs.fonts.fontSize14}
              />
            </GridWrapper>
          ) : (
            <GridScrollStyled>
              {allNotifications.newNotifications.map(( notification ) => (
                <CardNotificationComponent
                  key={notification.id.toString()}
                  notificationDetails={notification}
                />
              ))}
              {allNotifications.beforeNotifications.map(( notification ) => (
                <CardNotificationComponent
                  key={notification.id.toString()}
                  notificationDetails={notification}
                  beforeNotification
                />
              ))}
            </GridScrollStyled>
          )}
        </MenuPopover>
      )}
    </>
  );
};

export default NotificationComponent;
