import { gql, MutationTuple, useMutation } from '@apollo/client';

export const UPDATE_COURSES_LEARNING_PATHWAYS_STATUS = gql`
mutation UpdateCoursesLearningPathwayStatus($pathwayCourseId: NonEmptyString!, $pathwayId: NonEmptyString!, $studentId: NonEmptyString!, $completed: Boolean! ) {
    updateCoursesLearningPathwayStatus(pathwayCourseId: $pathwayCourseId, pathwayId: $pathwayId, studentId: $studentId, completed: $completed )
}
`;

interface IUpdateCoursesLearningPathwayStatusParams {
  pathwayId: string;
  studentId: string;
  pathwayCourseId: string;
  completed: boolean;
}

interface IUpdateCoursesLearningPathwayStatusResponse {
  updateCoursesLearningPathwayStatus: boolean;
}

export const useUpdateCoursesLearningPathwayStatus = ():
MutationTuple<IUpdateCoursesLearningPathwayStatusResponse, IUpdateCoursesLearningPathwayStatusParams> =>
  useMutation<IUpdateCoursesLearningPathwayStatusResponse, IUpdateCoursesLearningPathwayStatusParams>(
    UPDATE_COURSES_LEARNING_PATHWAYS_STATUS,
  );
