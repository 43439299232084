const locale = {
  PASSWORD_CHANGED_SUCCESSFULLY: 'Contraseña cambiada con éxito',
  INVALID_USER_PASSWORD: 'Usuario o contraseña inválidos',
  CURRENT_PASSWORD_REQUIRED: 'Se requiere contraseña actual',
  PASSWORD_DONT_MATCH: 'Las contraseñas no coinciden',
  LABEL_CURRENT_PASSWORD: 'Contraseña actual',
  LABEL_NEW_PASSWORD: 'Nueva contraseña',
  LABEL_CONFIRM_NEW_PASSWORD: 'Confirmar nueva contraseña',
  CONSTRAINT_CONTAIN: 'La contraseña debe contener:',
  CONSTRAINT_UPPERCASE: 'Al menos 1 letra mayúscula',
  CONSTRAINT_NUMBER: 'Al menos 1 número',
  CONSTRAINT_LENGTH: 'Entre 8 y 30 caracteres',
  UPDATE_PASSWORD: 'Actualizar contraseña',
};

export default locale;
