import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../resources/styleConfigs';

export const RootStyle = styled( 'div' )((
  { show = 0 }: { show: number},
) => ({
  display: show > 0 ? 'flex' : 'none',
  flexDirection: 'column',
  rowGap: styleConfigs.marginPadding.custom20,
  position: 'absolute',
  top: styleConfigs.marginPadding.custom30,
  right: styleConfigs.marginPadding.custom30,
  zIndex: 10001,
}));
