import { Skeleton, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface IProps {
  sizeSkeleton?: number
  variant?: Variant
}

const SkeletonComponent: React.FC<IProps> = ( props ) => {
  const { sizeSkeleton, variant } = props;
  const range = Array.from( Array( sizeSkeleton ?? 3 ).keys());

  return (
    <>
      {
        range.map(( index: number ) => (
          <Typography
            data-testid="typography"
            key={index}
            component="div"
            variant={variant ?? 'body1'}
          >
            <Skeleton
              data-testid="skeleton"
              animation="wave"
            />
          </Typography>
        ))
      }
    </>
  );
};

export default SkeletonComponent;
