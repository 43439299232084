import { gql, MutationTuple, useMutation } from '@apollo/client';

export const UPDATE_STUDENT_COURSE_RECOMMENDATION = gql`
mutation UpdateStudentCourseRecommendations($studentId: String!) {
  updateStudentCourseRecommendations(studentId: $studentId)
}`;

interface IUpdateStudentCourseRecommendationParams {
  studentId: string;
}
interface IUpdateStudentCourseRecommendationResponse {
  updateStudentCourseRecommendations?: boolean;
}

export const useMutationUpdateStudentCourseRecommendation = ():
MutationTuple<IUpdateStudentCourseRecommendationResponse, IUpdateStudentCourseRecommendationParams> =>
  useMutation<IUpdateStudentCourseRecommendationResponse, IUpdateStudentCourseRecommendationParams>(
    UPDATE_STUDENT_COURSE_RECOMMENDATION,
  );
