import {
  gql, QueryResult, useQuery,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_INSTITUTION_PROVIDER = gql`
query GetInstitutionsCourseProviders ($InstitutionId: String!) {
  getInstitutionCourseProviders (institutionId: $InstitutionId) {
        id
        institutionId
        providerId
  }
}
`;

export interface IInsitutionCourseProviders {
  id: number,
  institutionId: string,
  providerId: string,
}

export interface IInsitutionCourseProviderParams {
  InstitutionId: string
}

export interface IGetInsitutionCourseProvidersResponse {
  getInstitutionCourseProviders: IInsitutionCourseProviders[]
}

export const useQueryGetInstitutionCourseProviders = ( InstitutionId: string, fromCache?: boolean ):
QueryResult<IGetInsitutionCourseProvidersResponse, IInsitutionCourseProviderParams> =>
  useQuery<IGetInsitutionCourseProvidersResponse, IInsitutionCourseProviderParams>(
    GET_INSTITUTION_PROVIDER, {
      variables: { InstitutionId },
      ...getPolices( fromCache ),
    },
  );
