export const fonts = {
  empty: 0,
  sizeText: 14,
  sizeMedium: 16,
  sizeTitle: 18,
  sizeCardTitle: 20,
  sizeCustom10: 10,
  sizeCustom11: 11,
  sizeCustom12: 12,
  sizeCustom15: 15,
  sizeCustom16: 16,
  sizeCustom17: 17,
  sizeCustom18: 18,
  sizeCustom19: 19,
  sizeCustom32: 32,
  sizeCustom22: 22,
  sizeCustom24: 24,
  sizeCustom28: 28,
  sizeCustom74: 74,
  sizeCustom42: 42,
  sizeCustom100: 100,
  sizeCustom150: 150,
  weight50: 50,
  weight300: 300,
  weight400: 400,
  regular: 500,
  weight500: 500,
  weight540: 540,
  weight550: 550,
  weight600: 600,
  bold: 700,
  weight700: 700,
  weight800: 800,
  weightNormal: 'normal',
  italic: 'italic',
  customFontResponsive: '13px',
  customFontNodeResponsive: '12px',
  lineHeight240: '240px',
  lineHeight220: '220px',
  lineHeight160: '160px',
  lineHeight126: '126px',
  lineHeight85: '85px',
  lineHeight80: '80px',
  lineHeight70: '70px',
  lineHeight64: '64px',
  lineHeight60: '60px',
  lineHeight58: '58px',
  lineHeight56: '56px',
  lineHeight52: '52px',
  lineHeight51p25: '51.25px',
  lineHeight51: '51px',
  lineHeight47: '47px',
  lineHeight46: '46px',
  lineHeight45: '45px',
  lineHeight42: '42px',
  lineHeight40: '40px',
  lineHeight38: '38px',
  lineHeight37: '37px',
  lineHeight36: '36px',
  lineHeight35: '35px',
  lineHeight34: '34px',
  lineHeight32: '32px',
  lineHeight30: '30px',
  lineHeight29: '29px',
  lineHeight28p7: '28.7px',
  lineHeight28: '28px',
  lineHeight26p4: '26.4px',
  lineHeight26: '26px',
  lineHeight25: '25px',
  lineHeight24: '24px',
  lineHeight22: '22px',
  lineHeight21: '21px',
  lineHeight20: '20px',
  lineHeight19: '19px',
  lineHeight19p2: '19.2px',
  lineHeight18: '18px',
  lineHeight17: '17px',
  lineHeight16: '16px',
  lineHeight15: '15px',
  lineHeight14: '14px',
  lineHeight12: '12px',
  lineHeight10: '10px',
  lineHeight8: '8px',
  lineHeight0p8: 0.8,
  lineHeight1p5: 1.5,
  lineHeight1p1: 1.1,
  lineHeightCustom1: 1,
  fontSize200: '200px',
  fontSize150: '150px',
  fontSize105: '105px',
  fontSize85: '85px',
  fontSize70: '70px',
  fontSize64: '64px',
  fontSize60: '60px',
  fontSize55: '55px',
  fontSize54: '54px',
  fontSize52: '52px',
  fontSize50: '50px',
  fontSize48: '48px',
  fontSize46: '46px',
  fontSize45: '45px',
  fontSize42: '42px',
  fontSize40: '40px',
  fontSize38: '38px',
  fontSize36: '36px',
  fontSize35: '35px',
  fontSize32: '32px',
  fontSize30: '30px',
  fontSize28: '28px',
  fontSize26: '26px',
  fontSize25: '25px',
  fontSize24: '24px',
  fontSize22: '22px',
  fontSize20: '20px',
  fontSize18: '18px',
  fontSize17: '17px',
  fontSize16: '16px',
  fontSize15: '15px',
  fontSize14: '14px',
  fontSize13: '13px',
  fontSize12: '12px',
  fontSize10: '10px',
  fontSize11: '11px',
  fontSize8: '8px',
  fontSizeEmpty: '0px',
  styleNormal: 'normal',
  fontFamily: '"HelveticaNeue", sans-serif',
  fontFamilyRoboto: 'Roboto',
  fontPercentage: '120px !important',
  fontPercentageMobile: '68px !important',
  fontPercentageModal: '70px !important',
  letterSpacing20: '20px',
  letterSpacing1: '1.1px',
  letterSpacing2: '2px',
  fontPercentageSymbol: '42px',
  fontSize07em: '0.7em',
  fontSize08em: '0.8em',
  fontSize09em: '0.9em',
  fontSize12em: '1.2em',
  fontSize1p6em: '1.6em',
  fontSize225em: '2.25em',
  fontSize27em: '2.7em',
  fontSize3em: '3em',
  fontSize37em: '3.7em',
  fontSize0p7rem: '0.7rem',
  fontSize0875rem: '0.875rem',
  fontSize0p9rem: '0.9rem',
  fontSize1rem: '1rem',
  fontSize1125rem: '1.125rem',
  fontSize125rem: '1.25rem',
  fontSize1375rem: '1.375rem',
  fontSize15rem: '1.5rem',
  fontSize17rem: '1.7rem',
  fontSize175rem: '1.75rem',
  fontSize1p8rem: '1.8rem',
  fontSize19rem: '1.9rem',
  fontSize3rem: '3rem',
  iconFontSize: '37px',
  fontSize101: 101,
  threeHalfVmin: '3.5vmin',
};
