export const getProvidireToHideNumberOfCourses = (
  feature: string,
): string => {
  const listOfProviders: string = feature.split( '-' )[1];

  return listOfProviders;
};

export const showNumberOfCourses = (
  listOfProviders: string,
  provider: string,
): boolean => !listOfProviders.includes( provider );
