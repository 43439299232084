import { Icon } from '@iconify/react';
import i18next from 'i18next';

import en from './i18n/en';
import es from './i18n/es';
import { INavItemProps } from '../../components/template-minimal/NavItem/NavItem';
import SvgIconStyle from '../../components/template-minimal/SvgIconStyle';
import icons from '../../constants/icons';
import { IFeatures } from '../../contexts/reducer/featureFlagsReducer';
import { PATH_DASHBOARD_STUDENT } from '../../routes/paths';

i18next.addResourceBundle( 'en', 'LearnerSidebar', en );
i18next.addResourceBundle( 'es', 'LearnerSidebar', es );

const getHomeIcon = (): JSX.Element => (
  <Icon
    width={24}
    height={24}
    icon="clarity:home-line"
  />
);

const getIcon = ( icon: string ): JSX.Element => (
  <SvgIconStyle
    src={icon}
    sx={{
      width: '100%',
      height: '100%',
    }}
  />
);

const ICONS = {
  home: getHomeIcon(),
  skills: getIcon( icons.STUDENT_SKILLS_ICON ),
  occupations: getIcon( icons.STUDENT_OCCUPATION ),
  learningPaths: getIcon( icons.LEARNING_PATHS_ICON ),
  help: getIcon( icons.HELP_ICON ),
};

const sidebarConfig = (
  features: IFeatures,
): ISideBarPathConfig[] => {
  const home = { title: 'HOME', path: PATH_DASHBOARD_STUDENT.admin.pageHome, icon: ICONS.home };
  const skills = { title: 'SKILLS', path: PATH_DASHBOARD_STUDENT.admin.skills, icon: ICONS.skills };
  const occupations = { title: 'OCCUPATIONS', path: PATH_DASHBOARD_STUDENT.admin.occupations, icon: ICONS.occupations };
  const learningPathways = { title: 'LEARNING_PATHS', path: PATH_DASHBOARD_STUDENT.admin.LearningPaths, icon: ICONS.learningPaths };
  const helps = { title: 'HELP', path: PATH_DASHBOARD_STUDENT.admin.help, icon: ICONS.help };
  const paths: ISideBarPathConfig[] = [
    {
      items: [home, skills, occupations, helps],
    },
  ];

  if ( !features.learner?.hideLearningPathwaysScreen ) {
    paths[0].items.splice( 3, 0, learningPathways );
  }

  return paths;
};

export interface ISideBarPathConfig {
  items: INavItemProps[];
}

export default sidebarConfig;
