import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../../components/resources/styleConfigs';
import GridWrapper from '../../../../components/wrappers/GridWrapper';
import TypographyWrapper from '../../../../components/wrappers/TypographyWrapper';
import palette from '../../../../theme/learnerPalette';

export const GridNotification = styled( GridWrapper )((
  { backgroundcolor }: { backgroundcolor?: string},
) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  backgroundcolor,
  paddingRight: styleConfigs.marginPadding.custom3,
  borderBottom: `${styleConfigs.borders.borderLine} ${palette.light.borders.white}`,
}));

export const IconBackground = styled( 'div' )(() => ({
  width: styleConfigs.dimensions.width40,
  height: styleConfigs.dimensions.height40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.light.background.springWood,
  borderRadius: styleConfigs.borders.borderRound,
}));

export const TextContainerStyled = styled( TypographyWrapper )(() => ({
  fontSize: styleConfigs.fonts.fontSize14,
  lineHeight: styleConfigs.fonts.lineHeight22,
  color: palette.light.text.mistBlue,
}));

export const SkillTextStyled = styled( TypographyWrapper )(() => ({
  fontWeight: styleConfigs.fonts.bold,
  fontSize: 'inherit',
  color: palette.light.text.prussianBlue,
}));

export const ItalicTextStyled = styled( TypographyWrapper )(() => ({
  fontSize: 'inherit',
  fontStyle: styleConfigs.fonts.italic,
}));

export const OccupationTextStyled = styled( TypographyWrapper )(() => ({
  fontSize: 'inherit',
  fontWeight: styleConfigs.fonts.bold,
}));
