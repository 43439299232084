import { Drawer, DrawerProps } from '@mui/material';

interface IProps {
  children?: React.ReactNode,
}

const DrawerWrapper: React.FC<IProps & DrawerProps> = ({ children, ...props }) => (
  <Drawer {...props}>
    { children }
  </Drawer>
);

export default DrawerWrapper;
