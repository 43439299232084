// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AES, enc, mode, pad, lib,
} from 'crypto-js';

const keyString = process.env.REACT_APP_ENCRYPT_KEY ?? '';
const key = enc?.Hex.parse( keyString );

export const encryptor = ( text: string ): string | undefined => {
  if ( !text ) {
    return undefined;
  }

  const iv = lib.WordArray.random( 16 );

  const encrypted = AES.encrypt( text, key, {
    iv,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  const encryptedText = encrypted.ciphertext.toString( enc.Base64 );
  const ivText = iv.toString( enc.Base64 );

  return `${ivText}:${encryptedText}`;
};

export const decryptor = ( text?: string ): string | undefined => {
  if ( !text ) {
    return undefined;
  }

  const parts = text.split( ':' );

  const iv = enc.Base64.parse( parts[0]);
  const encryptedText = parts[1];

  try {
    const cipherParams = lib.CipherParams.create({
      ciphertext: enc.Base64.parse( encryptedText ),
    });
    const decrypted = AES.decrypt( cipherParams, key, {
      iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });

    return decrypted.toString( enc.Utf8 );
  } catch ( error: unknown ) {
    return undefined;
  }
};
