import ADVISOR_ROLE_ICON from '../assets/icons/advisor/advisor_role_icon.svg';
import ADVISOR_CLEAN_FILTER_ICON from '../assets/icons/advisor/clean_filter_icon.svg';
import ALERT_RED_ICON from '../assets/icons/alert/ic_alert.svg';
import ARROW_DOWN from '../assets/icons/arrow/arrow-down.svg';
import CHECK_ICON_ALT from '../assets/icons/check/check_icon_alt.svg';
import CHECK_ICON_FILLED from '../assets/icons/check/checked_icon_filled.svg';
import CHECKMARK from '../assets/icons/check/checkmark.svg';
import DOUBLE_CHECK from '../assets/icons/check/double_check.svg';
import GREEN_CHECK_CIRCLE from '../assets/icons/check/green_check_circle.svg';
import DRAG_AND_DROP_ICON from '../assets/icons/dragAndDrop/drag_and_drop_icon.svg';
import ALERT_TRIANGLE from '../assets/icons/emptyState/alert_triangle.svg';
import BLACK_LENS from '../assets/icons/emptyState/black_lens.svg';
import BULB from '../assets/icons/emptyState/bulb.svg';
import CLOUD_NO_DATA_RECOMMENDED_OCCUPATIONS from '../assets/icons/emptyState/cloud-no-data-recommended-occupations.svg';
import CONVERSATION_CHAT from '../assets/icons/emptyState/conversation_chat.svg';
import EARTH_SEARCH_PAGE404 from '../assets/icons/emptyState/earthSearchPage404.svg';
import EMPTY_STATE_MAJORS from '../assets/icons/emptyState/empty-state-majors.svg';
import EMPTY_STATE_COURSE_ADVISOR from '../assets/icons/emptyState/empty_state_courses_advisor.svg';
import EMPTY_STATE_EXPLORE_SKILLS_ADVISOR from '../assets/icons/emptyState/empty_state_explore_skills_advisor.svg';
import EMPTY_STATE_RECOMMENDATION from '../assets/icons/emptyState/empty_state_recommendation.svg';
import EMPTY_STATE_SELECT_OCCUPATION from '../assets/icons/emptyState/empty_state_select_occupation.svg';
import HOUR_GLASS_ICON from '../assets/icons/emptyState/hourglass_icon.svg';
import CIRCLE_NONE from '../assets/icons/emptyState/ic_remove.svg';
import LIGHT_BULB from '../assets/icons/emptyState/light_bulb_shine.svg';
import NO_COURSES_EMPTY from '../assets/icons/emptyState/noCoursesEmptyState.svg';
import NO_DATA_CLOUD_ICON from '../assets/icons/emptyState/noDataCloud.svg';
import NO_DATA_CLOUD_ICON_BIG from '../assets/icons/emptyState/noDataCloudIconBig.svg';
import EMPTY_STATE_NOTIFICATIONS from '../assets/icons/emptyState/noNotifications.svg';
import EMPTY_STATE_NO_SKILLS_IN_KANBAN from '../assets/icons/emptyState/noSkills.svg';
import RED_ICON_SKILL_SET_REQUIRED from '../assets/icons/emptyState/red-icon-skill-set-required.svg';
import SEARCH_FILES from '../assets/icons/emptyState/search_files.svg';
import ERROR_ICON_CARD from '../assets/icons/errorState/error_icon_card.svg';
import ERROR_ICON_LARGE from '../assets/icons/errorState/error_icon_carousel.svg';
import EXPAND_ARROWS from '../assets/icons/expand/expand_icon_black.svg';
import EXPAND_ICON from '../assets/icons/expand/expand_icon_grey.svg';
import FAVICON from '../assets/icons/favicon/favicon.svg';
import FAVICON_ADVISOR from '../assets/icons/favicon/faviconAdvisor.svg';
import FAVICON_LEARNER from '../assets/icons/favicon/faviconLearner.svg';
import FILTER_ICON from '../assets/icons/filter/filter_icon.svg';
import WORLD from '../assets/icons/filter/world.svg';
import HELP_ICON from '../assets/icons/help/help_icon.svg';
import INFO_ICON from '../assets/icons/info/info_icon.svg';
import LEARNER_ROLE_ICON from '../assets/icons/learner/learner_role_icon.svg';
import CIRCULAR_LOADER_DARK from '../assets/icons/loader/circular_loader_dark.png';
import CIRCULAR_LOADER_LIGHT from '../assets/icons/loader/circular_loader_light.png';
import ADVISOR_LOGO from '../assets/icons/logo/advisor_logo.svg';
import BLACK_LOGO from '../assets/icons/logo/BlackLogo.svg';
import GOOGLE_ICON from '../assets/icons/logo/googleLogo.png';
import LEARNER_LOGO from '../assets/icons/logo/learner_logo.svg';
import LEARNER_LOGO_HORIZONTAL from '../assets/icons/logo/learner_logo_horizontal.svg';
import LEARNER_WHITE_LOGO from '../assets/icons/logo/learner_white_logo.svg';
import LOGIN_UVA from '../assets/icons/logo/login_uva.svg';
import LIGHT_BULB_NOTIFICATION from '../assets/icons/notification/light_bulb.svg';
import THUMBS_UP_NOTIFICATION from '../assets/icons/notification/like_shine.svg';
import PERFORMANCE_INCREASE_ICON from '../assets/icons/notification/performance-increase.svg';
import PERFORMANCE_DOWN_ICON_NEW from '../assets/icons/notification/performance_down_icon_new.svg';
import AI_STARS from '../assets/icons/other/ai_stars.svg';
import ARTWORK from '../assets/icons/other/artwork.svg';
import DEFAULT_UNIVERSITY_AVATAR from '../assets/icons/other/DefaultUniversityAvatar.svg';
import FILL_POSITION_ICON from '../assets/icons/other/fillPosition_icon.svg';
import INSTITUTION_DEFAULT from '../assets/icons/other/ic_banking.svg';
import CAREER_TRENDS from '../assets/icons/other/ic_career_trends.svg';
import CONTACT_ICON from '../assets/icons/other/ic_contact.svg';
import COURSES_ICON from '../assets/icons/other/ic_courses.svg';
import STUDENT_OCCUPATION from '../assets/icons/other/ic_employers.svg';
import FAQ_ICON from '../assets/icons/other/ic_faq.svg';
import INSIGHTS_ICON from '../assets/icons/other/ic_insights.svg';
import LOVE_STAR_ICON from '../assets/icons/other/ic_love_it_star.svg';
import MORE_HORIZONTAL_ICON from '../assets/icons/other/ic_more_hoz.svg';
import PENCIL_RULER_ICON from '../assets/icons/other/ic_pencil_ruler.svg';
import EMPTY_STATE_CAREER_TRENDS from '../assets/icons/other/ic_school.svg';
import STUDENT_SKILLS_ICON from '../assets/icons/other/ic_skills.svg';
import STUDENT_OVERVIEW_ICON from '../assets/icons/other/ic_student_overview.svg';
import ICON_CV_UPLOADED from '../assets/icons/other/icon_cv_uploaded.svg';
import INTERSECT from '../assets/icons/other/intersect.svg';
import JOB_ICON from '../assets/icons/other/job_icon.svg';
import JOB_ICON_CHECK from '../assets/icons/other/job_responsibility_bag_check.svg';
import LEARNING_ICON from '../assets/icons/other/learning_icon.svg';
import LOGOUT_ICON from '../assets/icons/other/logout_icon.svg';
import MICROSOFT365_ICON from '../assets/icons/other/microsoft_365.svg';
import MONEY from '../assets/icons/other/money.svg';
import MOVE_SKILL_ICON from '../assets/icons/other/move-skill.svg';
import PASSWORD_ICON from '../assets/icons/other/password_icon.svg';
import PENCIL_BLACK from '../assets/icons/other/pencil_black.svg';
import PROFILE_ICON from '../assets/icons/other/profile_icon.svg';
import PSYCHOLOGY from '../assets/icons/other/psychology.svg';
import READING_ICON from '../assets/icons/other/reading_icon.svg';
import REQUIRE_SKILL_ICON from '../assets/icons/other/requireSkill_icon.svg';
import ROCKET_CREATE_PATH from '../assets/icons/other/rocket_create_path.svg';
import SEARCH_STAR_ICON from '../assets/icons/other/search_star.svg';
import SINGLE_LAPTOP_BLACK from '../assets/icons/other/single_laptop_black.svg';
import SINGLE_LAPTOP_SCREEN_SM from '../assets/icons/other/single_laptop_screen_sm.svg';
import SKILL_MODAL_STATUS from '../assets/icons/other/skill_modal_status.svg';
import STAR_BADGE from '../assets/icons/other/star_badge.svg';
import STUDY_BOOK_BLACK from '../assets/icons/other/study_book_black.svg';
import STUDY_BOOK_SCREEN_SM from '../assets/icons/other/study_book_screen_sm.svg';
import THUMBS_UP_BLACK from '../assets/icons/other/thumbs_up_black.svg';
import THUMBS_UP_SCREEN_SM from '../assets/icons/other/thumbs_up_screen_sm.svg';
import DEFAULT_PROVIDER from '../assets/icons/provider/default.svg';
import LEARNING_PATHS_ICON from '../assets/icons/sidebar/learning_paths_icon.svg';
import ICON_TRASH from '../assets/icons/trash/iconTrash.svg';
import REMOVED_SKILL_TRASH from '../assets/icons/trash/removed_skill_trash.svg';
import TRASH_BLACK from '../assets/icons/trash/trash_black.svg';
import TRASH_ICON_GREY from '../assets/icons/trash/trash_icon_grey.svg';
import TRASH_ICON_PINK from '../assets/icons/trash/trash_icon_pink.svg';
import WHITE_TRASH_ICON from '../assets/icons/trash/white_trash.svg';

export default {
  ADVISOR_ROLE_ICON,
  ADVISOR_CLEAN_FILTER_ICON,
  ALERT_RED_ICON,
  ARROW_DOWN,
  BULB,
  CHECK_ICON_ALT,
  CHECK_ICON_FILLED,
  CHECKMARK,
  DOUBLE_CHECK,
  CLOUD_NO_DATA_RECOMMENDED_OCCUPATIONS,
  EARTH_SEARCH_PAGE404,
  EMPTY_STATE_COURSE_ADVISOR,
  EMPTY_STATE_EXPLORE_SKILLS_ADVISOR,
  EMPTY_STATE_RECOMMENDATION,
  EMPTY_STATE_SELECT_OCCUPATION,
  ERROR_ICON_LARGE,
  ERROR_ICON_CARD,
  NO_COURSES_EMPTY,
  NO_DATA_CLOUD_ICON,
  NO_DATA_CLOUD_ICON_BIG,
  EMPTY_STATE_NOTIFICATIONS,
  EMPTY_STATE_NO_SKILLS_IN_KANBAN,
  EMPTY_STATE_MAJORS,
  RED_ICON_SKILL_SET_REQUIRED,
  EXPAND_ARROWS,
  EXPAND_ICON,
  FAVICON,
  EMPTY_STATE_CAREER_TRENDS,
  FAVICON_ADVISOR,
  FAVICON_LEARNER,
  FILTER_ICON,
  HELP_ICON,
  INFO_ICON,
  MICROSOFT365_ICON,
  ADVISOR_LOGO,
  GOOGLE_ICON,
  LEARNER_LOGO,
  LEARNER_WHITE_LOGO,
  PERFORMANCE_INCREASE_ICON,
  DEFAULT_UNIVERSITY_AVATAR,
  CONTACT_ICON,
  COURSES_ICON,
  STUDENT_OCCUPATION,
  FAQ_ICON,
  INSIGHTS_ICON,
  CAREER_TRENDS,
  LOVE_STAR_ICON,
  MORE_HORIZONTAL_ICON,
  PENCIL_RULER_ICON,
  STUDENT_SKILLS_ICON,
  STUDENT_OVERVIEW_ICON,
  ICON_CV_UPLOADED,
  JOB_ICON,
  LOGOUT_ICON,
  MOVE_SKILL_ICON,
  PASSWORD_ICON,
  PROFILE_ICON,
  READING_ICON,
  SEARCH_STAR_ICON,
  SINGLE_LAPTOP_BLACK,
  SINGLE_LAPTOP_SCREEN_SM,
  SKILL_MODAL_STATUS,
  STUDY_BOOK_BLACK,
  STUDY_BOOK_SCREEN_SM,
  THUMBS_UP_BLACK,
  THUMBS_UP_SCREEN_SM,
  DEFAULT_PROVIDER,
  ICON_TRASH,
  REMOVED_SKILL_TRASH,
  TRASH_ICON_GREY,
  TRASH_ICON_PINK,
  WHITE_TRASH_ICON,
  FILL_POSITION_ICON,
  LEARNING_ICON,
  REQUIRE_SKILL_ICON,
  BLACK_LOGO,
  PERFORMANCE_DOWN_ICON_NEW,
  BLACK_LENS,
  WORLD,
  HOUR_GLASS_ICON,
  CONVERSATION_CHAT,
  LEARNING_PATHS_ICON,
  LIGHT_BULB,
  CIRCLE_NONE,
  ALERT_TRIANGLE,
  SEARCH_FILES,
  PENCIL_BLACK,
  TRASH_BLACK,
  CIRCULAR_LOADER_LIGHT,
  CIRCULAR_LOADER_DARK,
  ROCKET_CREATE_PATH,
  INSTITUTION_DEFAULT,
  LEARNER_ROLE_ICON,
  LOGIN_UVA,
  GREEN_CHECK_CIRCLE,
  LEARNER_LOGO_HORIZONTAL,
  DRAG_AND_DROP_ICON,
  JOB_ICON_CHECK,
  INTERSECT,
  AI_STARS,
  PSYCHOLOGY,
  ARTWORK,
  MONEY,
  STAR_BADGE,
  LIGHT_BULB_NOTIFICATION,
  THUMBS_UP_NOTIFICATION,
};
