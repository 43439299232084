import {
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { getPolices } from '../../../../utils/gqlPolicesUtil';
import { GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID_ATTRIBUTES } from '../../jobs/useGetTopJobsWithMatchSkillSet';

const GET_STUDENT_SKILLS_RECOMMENDED_BY_INTEREST_ATTRIBUTES = `
  skill
  skillId
  type
  percentage
  translatedType
`;

export const GET_RECOMMENDED_OCCUPATIONS = gql`
query GetRecommendationsByMajor($studentId: String!) {
  getRecommendationsByMajor(studentId: $studentId) {
    percentageOfMajorMatch {
      id
      match
      name
    }
    studentMajor {
      tblMajor {
        id
        name
      }
    }
  }
  getJobRecommendationsByStudentId(studentId: $studentId) {
    ${GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID_ATTRIBUTES}
  }
  getStudentSkillsRecommendedByTypeAndInterests(studentId: $studentId) {
    ${GET_STUDENT_SKILLS_RECOMMENDED_BY_INTEREST_ATTRIBUTES}
  }
}
`;

export const GET_RECOMMENDED_OCCUPATIONS_LIGHT = gql`
query GetRecommendationsByMajor($studentId: String!) {
  getRecommendationsByMajor(studentId: $studentId) {
    percentageOfMajorMatch {
      id
      match
      name
    }
    studentMajor {
      tblMajor {
        id
        name
      }
    }
  }
}
`;

export interface IRecommendedSkill {
  percentage: number;
  skillId: string;
  skill: string;
  type: string;
  translatedType?: string;
}

export interface IRecommendedOccupationBySkillSet {
  id: string;
  match: number;
  job: string;
}

export interface IRecommendedOccupations {
  id: string;
  match: number;
  name: string;
}

export interface IStudentMajor {
  id: string,
  name: string,
}

export interface IGetRecommendedOccupationResponse {
  getRecommendationsByMajor: {
    percentageOfMajorMatch: IRecommendedOccupations[],
    studentMajor: {
      tblMajor?: IStudentMajor
    }
  }[],
  getJobRecommendationsByStudentId: IRecommendedOccupationBySkillSet[],
  getStudentSkillsRecommendedByTypeAndInterests: IRecommendedSkill[],
}

export interface IGetRecommendedOccupationLightResponse {
  getRecommendationsByMajor: {
    percentageOfMajorMatch: IRecommendedOccupations[],
    studentMajor: {
      tblMajor?: IStudentMajor
    }
  }[],
}

export interface IGetRecommendedOccupationParams {
  studentId: string;
}

export const useLazyGetJobRecommendedOccupations = ( fromCache?: boolean ):
QueryTuple<IGetRecommendedOccupationResponse, IGetRecommendedOccupationParams> =>
  useLazyQuery<IGetRecommendedOccupationResponse, IGetRecommendedOccupationParams>(
    GET_RECOMMENDED_OCCUPATIONS,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetJobRecommendedOccupations = ( studentId: string, fromCache?: boolean ):
QueryResult<IGetRecommendedOccupationResponse, IGetRecommendedOccupationParams> =>
  useQuery<IGetRecommendedOccupationResponse, IGetRecommendedOccupationParams>(
    GET_RECOMMENDED_OCCUPATIONS, {
      variables: { studentId },
      ...getPolices( fromCache ),
    },
  );

export const useLazyQueryGetJobRecommendedOccupationsLight = ( fromCache?: boolean ):
QueryTuple<IGetRecommendedOccupationLightResponse, IGetRecommendedOccupationParams> =>
  useLazyQuery<IGetRecommendedOccupationLightResponse, IGetRecommendedOccupationParams>(
    GET_RECOMMENDED_OCCUPATIONS_LIGHT, {
      ...getPolices( fromCache ),
    },
  );

export const useQueryGetJobRecommendedOccupationsLight = ( studentId: string, fromCache?: boolean ):
QueryResult<IGetRecommendedOccupationLightResponse, IGetRecommendedOccupationParams> =>
  useQuery<IGetRecommendedOccupationLightResponse, IGetRecommendedOccupationParams>(
    GET_RECOMMENDED_OCCUPATIONS_LIGHT, {
      variables: { studentId },
      ...getPolices( fromCache ),
    },
  );
