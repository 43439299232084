import { useMediaQuery, useTheme } from '@mui/material';
import { ComponentType, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { DialogStyled } from './styles/ModalViewStyles';
import { ModalContext } from '../../../contexts/ModalContext';
import { PATH_WIZARD } from '../../../routes/paths';
import ModalDialogAnimated from '../ModalDialogAnimated/ModalDialogAnimated';

const ModalView: React.FC = () => {
  const { pathname } = useLocation();
  const isSmScreen: boolean = useMediaQuery( useTheme().breakpoints.down( 'sm' ));
  const { modalClose, isOpen, contentData } = useContext( ModalContext );
  const { disableCloseOnClickBackdrop, disableCloseOnPressScape } = contentData;
  const ActiveComponent: ComponentType<any> = contentData?.component;

  const handleClose = ( event: unknown, reason: 'escapeKeyDown' |'backdropClick' | undefined ): void => {
    if ( reason === 'escapeKeyDown' && disableCloseOnPressScape ) { return; }
    if ( reason === 'backdropClick' && disableCloseOnClickBackdrop ) { return; }
    modalClose();
  };

  return isSmScreen ? (
    <ModalDialogAnimated
      data-testid="modal-view"
      isOpen={isOpen}
      onClose={() => handleClose( null, undefined )}
      backdropClick={disableCloseOnClickBackdrop}
      escapeKeyClick={disableCloseOnPressScape}
      canBeSwipped={pathname !== PATH_WIZARD.steps.information}
      mobileWidth={contentData.mobileWidth}
      mobileHeight={contentData.mobileHeight}
      mobileRoundedCorner={contentData.mobileRoundedCorner}
      mobileMarginInline={contentData.mobileMarginInline}
    >
      <ActiveComponent directProps={contentData?.directProps} />
    </ModalDialogAnimated>
  ) : (
    <DialogStyled
      data-testid="modal-view"
      open={isOpen}
      onClose={handleClose}
      keepMounted
      maxWidth="xl"
      desktopwidth={contentData.desktopWidth}
      desktopheight={contentData.desktopHeight}
    >
      <ActiveComponent directProps={contentData?.directProps} />
    </DialogStyled>
  );
};

export default ModalView;
