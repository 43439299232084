import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';

import userAuth from '../hooks/userAuth';
import { IGetInstitutionCustomTexts, useQueryGetInstitutionCustomTexts } from '../operations/query/institutions/useGetInstitutionCustomTexts';

interface IInstitutionCustomTexts {
  loading: boolean;
  customTexts: IGetInstitutionCustomTexts[];
  institutionId: string | null;
  setInstitutionId: ( id: string | null ) => void;
}

interface IInstitutionCustomText {
  loading: boolean;
  description: string;
}

interface IInstitutionCustomTextsProviderProps {
  children: ReactNode;
}

export enum TypeCustomTexts {
  FOOTER = 'FOOTER',
  COURSE_RECOMMENDATIONS_MESSAGE = 'COURSE_RECOMMENDATIONS_MESSAGE',
  SKILLS_SECTION = 'SKILLS_SECTION',
  LEARNING_PATH_SECTION = 'LEARNING_PATH_SECTION',
  WIZARD_STEP_1 = 'WIZARD_STEP_1',
  WIZARD_STEP_2 = 'WIZARD_STEP_2',
  WIZARD_STEP_3 = 'WIZARD_STEP_3',
  LP_COURSES_NUMBER_PROVIDER_RIIPEN_TEXT = 'LP_COURSES_NUMBER_PROVIDER_RIIPEN_TEXT',
  LP_COURSES_NUMBER_PROVIDER_GOOGLE_TEXT = 'LP_COURSES_NUMBER_PROVIDER_GOOGLE_TEXT',
  STUDENT_TYPE_INPUT_LABEL = 'STUDENT_TYPE_INPUT_LABEL',
}

const institutionCustomTextInitialState: IInstitutionCustomTexts = {
  loading: false,
  customTexts: [],
  institutionId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInstitutionId: () => {},
};

export const InstitutionCustomTextsContext = createContext<IInstitutionCustomTexts>(
  institutionCustomTextInitialState,
);

export const InstitutionCustomTextsProvider: React.FC<IInstitutionCustomTextsProviderProps> = (
  { children },
) => {
  const { auth } = userAuth();
  const [institutionId, setInstitutionId] = useState<string | null>( null );

  const { data, loading } = useQueryGetInstitutionCustomTexts(
    {
      institutionId: institutionId ?? auth?.studentInstitution?.institutionId ?? '',
      languageId: auth?.preferredLanguage,
    },
    {
      skip: !institutionId && !auth?.studentInstitution?.institutionId,
    },
  );

  const [customTexts, setCustomTexts] = useState<IGetInstitutionCustomTexts[]>([]);

  useEffect(() => {
    if ( data?.getInstitutionCustomTexts ) {
      setCustomTexts( data.getInstitutionCustomTexts );
    }
  }, [data]);

  const value = useMemo(() => ({
    loading,
    customTexts,
    institutionId,
    setInstitutionId,
  }), [loading, customTexts, institutionId]);

  return (
    <InstitutionCustomTextsContext.Provider value={value}>
      {children}
    </InstitutionCustomTextsContext.Provider>
  );
};

export const useCustomTexts = (): IInstitutionCustomTexts => useContext( InstitutionCustomTextsContext );

export const useCustomText = ( key: TypeCustomTexts ): IInstitutionCustomText => {
  const { loading, customTexts } = useContext( InstitutionCustomTextsContext );

  const description = loading ? '' : customTexts.find(( customText ) => customText.textKey === key )?.textValue ?? '';

  return { loading, description };
};
