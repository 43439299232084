import {
  ReactNode, createContext, useMemo, useReducer,
} from 'react';

import { modalCloseAction, modalOpenAction } from './actions/modalActions';
import modalReducer, { IModalContentData, IModalReducer, modalReducerState } from './reducer/modalReducer';

type ModalProviderProps = {
  children: ReactNode;
};

const ModalContext = createContext<IModalReducer>( modalReducerState );

const ModalProvider = ({ children }: ModalProviderProps ): JSX.Element => {
  const [state, dispatch] = useReducer( modalReducer, modalReducerState );

  const modalOpen = ( modalData: IModalContentData ): void => {
    dispatch( modalOpenAction( modalData ));
  };

  const modalClose = (): void => {
    dispatch( modalCloseAction());
  };

  const modalContextValue = useMemo(() => ({
    modalOpen,
    modalClose,
    isOpen: state.isOpen,
    contentData: state.contentData,
  }), [state.isOpen, state.contentData]);

  return (
    <ModalContext.Provider
      value={modalContextValue}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
