const locale = {
  PROFILE: 'Perfil',
  CHANGE_PASSWORD: 'Cambiar Contraseña',
  LOGOUT: 'Salir',
  HOME: 'Recomendaciones',
  SKILLS: 'Habilidades',
  OCCUPATIONS: 'Objetivos Profesionales',
  LEARNING_PATHS: 'Rutas de Aprendizaje',
  HELP: 'Ayuda',
  PERSONAL: 'Información Personal',
  PREFERENCES: 'Preferencias',
  BACK_TO_DASHBOARD: 'Regresar al Inicio',
};

export default locale;
