export const PlatformAltTexts = {
  login: {
    leftContent: {
      slogan1: {
        title: 'Skill match',
        description: `Explore different career pathways that align with your skills and interests`,
      },
      slogan2: {
        title: 'Skill tracker',
        description: `Discover the skills employers value most to set yourself apart from the competition`,
      },
      slogan3: {
        title: 'Recommended occupations',
        description: `Find courses and programs to develop the skills that will maximize your career success`,
      },
    },
  },
  sidebar: {
    title: 'Company logo',
    description: `One Mentor's logo - click to go home`,
  },
  profile: {
    title: 'Name initials',
    description: 'None',
  },
  home: {
    pathTracker: {
      title: 'Graphic: your occupation objectives',
      description: 'Objective occupations and match percentage according to your interests',
    },
    recommendedCourses: {
      title: 'Course and program name',
      description: `Link to the $provider website to take the complete C# Unity game development`,
    },
  },
  skills: {
    explore: {
      emptyState: {
        title: 'Empty state',
        description: 'Please select a skill on the dropdown button above to start exploring',
      },
      rankingLists: {
        title: `Ranking of [skills / occupations]`,
        description: 'This is a list of $top related to the selected skill above. They are ordered from most to least important',
      },
    },
  },
  occupations: {
    explore: {
      emptyState: {
        title: 'Empty state',
        description: 'Please select a skill on the dropdown button above to start exploring',
      },
      skillMatch: {
        title: 'Skill match for $occupation',
        description: `Percentage of human and specialized skills you have for the selected occupations above`,
      },
      skillSetRequired: {
        title: 'Graphic: Skill set required',
        description: 'List of skills for your chosen occupation. It is divided between skills you need to learn and skills you already have',
      },
    },
  },
  studentsOverview: {
    title: 'Graphic: upskilling objectives',
    description: 'It allows you to check the skills of different majors and find out the percentage of skills students are learning and the percentage of skills students still have to learn',
  },
  studentAdvising: {
    studentProfile: {
      title: 'Empty state',
      description: 'Type a student’s name in the search bar above to view the student’s profile',
    },
    exploreJobs: {
      title: 'Empty state',
      description: 'Select an occupation on the dropdown button above to start exploring',
    },
    exploreSkills: {
      title: 'Empty state',
      description: 'Select a skill on the dropdown button above to start exploring',
    },
  },
};
