const locale = {
  TO_LEARN: 'BRECHAS',
  LEARNING: 'OBJETIVOS DE APRENDIZAJE',
  COMPLETED: 'COMPLETADO',
  OOPS: '¡Ups!',
  YOU_ALREADY_HAVE_THIS_SKILL: 'Ya tienes esta habilidad',
  YOU_ADDED: 'Añadiste',
  YOU_MOVED: 'Moviste',
  YOU_DELETED: 'Eliminaste',
  ADD_SKILL_NOTIFICATION: 'Se ha añadido {{skill}}',
  DRAG_SKILL_NOTIFICATION: 'Se ha movido {{skill}}',
  REMOVED_SKILL_NOTIFICATION: 'Se ha eliminado {{skill}}',
  TO_LEARN_SKILL_TRACKER: 'a la columna "Por aprender" en tu rastreador de habilidades',
  TO_LEARNING_SKILL_TRACKER: 'a la columna "Aprendiendo" en tu rastreador de habilidades',
  FROM_LEARN_SKILL_TRACKER: 'de la columna "Por aprender" en tu rastreador de habilidades',
  FROM_LEARNING_SKILL_TRACKER: 'de la columna "Aprendiendo" en tu rastreador de habilidades',
  CONGRATULATIONS: '¡Esta actualización de habilidades ha aumentado tu compatibilidad con {{numberOccupations}} ocupaciones!',
  LOOKOUT_NOTIFICATION: '¡Esta acción ha disminuido tu compatibilidad con {{numberOccupations}} ocupaciones!',
  SOMETHING_WENT_WRONG: 'Algo salió mal. Por favor, inténtalo de nuevo.',
  SOMETHING_WENT_WRONG_PLAIN: 'Algo salió mal',
  ERROR: 'Error',
};

export default locale;
