import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';

export const ChangePasswordFormRootStyles = styled( 'form' )(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TextFieldCustomStyles = styled( TextField )(() => ({
  marginBottom: styleConfigs.marginPadding.custom24,
  '&  > .MuiOutlinedInput-root': {
    '& > .MuiOutlinedInput-input': {
      fontWeight: styleConfigs.fonts.bold,
      color: palette.light.text.ebonyClay,
    },
  },
  '& > .MuiInputLabel-root': {
    fontWeight: styleConfigs.fonts.bold,
    color: palette.light.text.amethyst,
  },
}));

export const TextTitleStyles = styled( Typography )((
  { theme }: {theme: Theme},
) => ({
  fontWeight: styleConfigs.fonts.weight600,
  fontSize: styleConfigs.fonts.fontSize16,
  color: palette.light.text.ebonyClay,
  marginBottom: styleConfigs.marginPadding.custom12,
  [theme.breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize14,
  },
}));

export const TextStyles = styled( Typography )((
  { theme, iserror }: { theme: Theme, iserror: number },
) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize16,
  color: iserror
    ? palette.light.text.coralRed
    : palette.light.text.greenTeal,
  marginBottom: styleConfigs.marginPadding.custom2,
  [theme.breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize14,
  },
}));

export const ChangePasswordButtonStyles = styled( Button )(() => ({
  height: styleConfigs.dimensions.height48,
  fontSize: styleConfigs.fonts.fontSize15,
  borderRadius: styleConfigs.borders.radius8,
  boxShadow: `${styleConfigs.marginPadding.empty} ${styleConfigs.marginPadding.custom8}px 
  ${styleConfigs.marginPadding.custom16}px ${palette.light.boxShadows.daySkyBlueA80}`,
  '&:hover': {
    boxShadow: `${styleConfigs.marginPadding.empty} ${styleConfigs.marginPadding.custom8}px 
  ${styleConfigs.marginPadding.custom16}px ${palette.light.boxShadows.daySkyBlueA80}`,
  },
}));

export const PatternPassIcon = styled( CheckCircleIcon )(() => ({
  color: palette.light.icons.greenTeal,
  marginLeft: styleConfigs.marginPadding.custom5,
}));

export const PatternNotPassIcon = styled( ErrorIcon )(() => ({
  color: palette.light.icons.coralRed,
  marginLeft: styleConfigs.marginPadding.custom5,
}));
