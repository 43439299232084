import {
  gql, QueryResult, useQuery,
} from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const GET_FEATURES = gql`
    query GetFeaturesWithValue {
      getFeaturesWithValue {
        name
        value
      }
    }
`;

export interface IFeatureWithValue {
  name: string;
  value: string;
}

export interface IGetFeatureWithValues {
  getFeaturesWithValue: IFeatureWithValue[];
}

export const useQueryGetFeaturesWithValue = ( fromCache?: boolean ):
QueryResult<IGetFeatureWithValues, Record<string, unknown>> =>
  useQuery<IGetFeatureWithValues, Record<string, unknown>>(
    GET_FEATURES, {
      ...getPolices( fromCache ),
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
