const locale = {
  SUCCESS_NOTIFICATION: 'Success',
  DANGER_NOTIFICATION: 'Danger',
  CONGRATULATION_NOTIFICATION: 'Congratulations!',
  WARNING_NOTIFICATION: 'Warning!',
  LOKOUT_NOTIFICATION: 'Heads-up!',
  LOOKOUT_NOTIFICATION_DESCRIPTION_1_OCCUPATION: 'Your match for {{numberOccupations}} occupation has been updated!',
  LOOKOUT_NOTIFICATION_DESCRIPTION: 'Your match for {{numberOccupations}} occupations has been updated!',
  REMOVED_NOTIFICATION: 'Removed!',
  CONGRATULATIONS_1_OCCUPATION: 'This skill update has increased your match for {{numberOccupations}} occupation!',
  CONGRATULATIONS: 'This skill update has increased your match for {{numberOccupations}} occupations!',
  YOU_ADDED: 'You added',
  YOU_MOVED: 'You moved',
  TO_LEARNING_SKILL_TRACKER: 'to the column Upskilling Objectives in your skill tracker',
  TO_LEARN_SKILL_TRACKER: 'to the column Gaps in your skill tracker',
  ADD_SKILL_NOTIFICATION: '{{skill}} has been added',
  DRAG_SKILL_NOTIFICATION: '{{skill}} has been moved',
  REMOVED_SKILL_NOTIFICATION: '{{skill}} has been removed',
  FROM_LEARNING_SKILL_TRACKER: 'from the column Upskilling Objectives in your skill tracker',
  YOU_DELETED: 'You deleted',
  FROM_LEARN_SKILL_TRACKER: 'from the column Gaps in your skill tracker',
  CV_UPLOAD_ERROR: 'Error uploading File',
  CV_VALID_FORMAT: 'Only PDF format is accepted',
  CV_ERROR_452: 'Bucket does not exists',
  CV_ERROR_512: 'Text cleaning operation went wrong. Please try again later',
  CV_ERROR_513: 'Text translation operation went wrong. Please try again later',
  CV_ERROR_454: 'Skill extraction went wrong. Please try again later',
  CV_MAX_SIZE: 'Maximum PDF size of {{size}} MB exceeded',
  CV_ERROR_002: 'Error uploading file to google',
  COMPLETE_LEARNING_PATH_DESCRIPTION: 'You will see your achievements in section',
  LP_COMPLETE_STATUS: 'Completed!',
  COMPLETE_LP_TITLE: 'Awesome job!',
  REACTIVATE_LP_DESCRIPTION: 'This path is back in your active routes. Let\'s keep progressing and achieving together!',
  REACTIVATE_LP_TITLE: 'Learning Path reactivated',
  OCCUPATION_SELECTED: 'Occupation selected',

};

export default locale;
