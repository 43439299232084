import {
  alpha, ThemeProvider, createTheme, useTheme, Theme,
} from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import useSettings from '../../hooks/useSettings';
import componentsOverrides from '../../theme/overrides';

type ThemePrimaryColorProps = {
  readonly children: ReactNode;
};

export default function ThemePrimaryColor({ children }: ThemePrimaryColorProps ): JSX.Element {
  const defaultTheme: Theme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(() => ({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: setColor,
    },
    customShadows: {
      ...defaultTheme.customShadows,
      primary: `0 8px 16px 0 ${alpha( setColor.main, 0.24 )}`,
    },
  }),
  [setColor, defaultTheme]);

  const theme = createTheme( themeOptions );

  theme.components = componentsOverrides( theme );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
