export enum MixpanelComponentsNames {
  KANBAN_BOARD = 'Kanban Board',
  SKILL_SET_REQUIRED = 'Skill set required',
  OCCUPATION_MODAL = 'Occupation modal',
  ADD_SKILL_EXPLORE = 'Add skill explore',
  REMOVE_SKILL_EXPLORE = 'Remove skill explore',
  SKILL_MODAL = 'Skill modal',
  RECOMMENDED_SKILLS_ROULETTE = 'Recommended skills roulette',
  TOP_SKILLS_BY_SKILL = 'Top skills by skill',
  EXPLORE_SKILL = 'Explore skill',
  ADD_CV_COMPONENT= 'Add cv component',
  FORM_LEARNER_MAJORS= 'Form learner majors',
  FORM_PERSONAL_INFORMATION= 'Form personal information',
  FRAME_INFO= 'Frame info',
  COURSES_TO_LEARN= 'Courses to learn',
  PATH_OCCUPATION_TRACKER= 'Path occupation tracker',
  RECOMMENDED_SKILLS= 'Recommended skills',
  ROULETTE_OCCUPATION_RECOMMENDATION_ITEM= 'Roulette occupation recommendation item',
  PREFERENCES_VIEW= 'Preferences view',
  PROFILE_SKILLS= 'Profile skills',
  PROFILE_VIEW= 'Profile view',
  WIZARD_OCCUPATION_STEP= 'Wizard occupation step',
  WIZARD_SKILL_STEP= 'Wizard skill step',
  OCCUPATION_MY_OBJECTIVES = 'Occupations My Objectives',
  OCCUPATIONS_EXPLORE_SCREEN = 'Occupations Explore Screen',
  OCCUPATION_EXPLORE_BY_MAJORS = 'Occupations Explore By Majors',
  OCCUPATION_REPLACE_OCCUPATION_MODAL = 'Replace Occupation Modal',
  OCCUPATION_REMOVE_OCCUPATION_MODAL = 'Remove Occupation Modal',
  USE_REGISTRATIONS_HOOK = 'Use registrations hook',
  SIGN_UP_FORM = 'Sign up form',
  LOGIN_FORM = 'Login form',
  FORGOT_PASSWORD_FORM = 'Forgot password form',
  WIZARD_INFORMATION_STEP = 'Wizard information step',
  REGISTER_FORM = 'Register form',
  PRIVACY_POLICY_MODAL = 'Privacy policy modal',
  NAV_SECTION_STUDENT = 'Nav section student',
  NOTIFICATION_COMPONENT = 'Notification component',
  ACCOUNT_POPOVER = 'Account popover',
  ADVISOR_ACCOUNT_POPOVER = 'Advisor account popover',
  SKILL_JOB_LEARN_COURSE = 'Skill job learn course',
  FILTERS_LABOR_MARKET = 'Filters labor market',
  FILTERS_SKILL_TYPE = 'Filters skill type',
  MOVE_SKILL_MENU = 'Move skill menu',
  INFORMATION_DROPDOWN = 'Information dropdown',
  MIXPANEL_TIME_FUNCTION = 'Mixpanel time function',
  LEGAL_COMPONENT = 'Legal component',
  FAQ_LIST = 'FAQ list',
  MULTIPLE_ITEMS_CARROUSEL = 'Multiple items carrousel',
  CARD_COURSE = 'Card course',
  SKILL_EXPLORE_VIEW = 'Skill explore',
  MAJOR_LIST = 'Major list',
  TOP_OCCUPATIONS = 'Top occupations',
  TOP_RECOMMENDED_SKILLS_FOR_STUDENTS= 'Top recommended skills for students',
  CARD_TOP_SKILLS = 'Card top skills',
  SKILL_CARD = 'Skill card',
  SEARCHBAR = 'Searchbar',
  OCCUPATION_OBJECTIVES = 'Occupation objectives',
  STUDENT_PROGRESS_TAB_CONTENT = 'Student progress tab content',
  TOP_LIST_BY_OCCUPATION = 'Top list by occupation',
  IKIGAI_HOME = 'Ikigai home',
  IKIGAI_PASSION = 'Ikigai passion',
  IKIGAI_MISSION = 'Ikigai mission',
  IKIGAI_VOCATION = 'Ikigai vocation',
  IKIGAI_PROFESSION = 'Ikigai profession',
}
