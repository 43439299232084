import { ReactNode } from 'react';

import {
  ArrowBackContainerStyled, ArrowBackIconStyled, CloseContainerStyled, CloseIconStyled, DialogTitleStyled,
  StackTitleStatusStyled, TitleStyled,
} from './styles/DefaultModalHeaderStyles';
import SkillTrackerStatusSpan from '../../../SkillTrackerStatusSpan/SkillTrackerStatusSpan';
import { skillStatusType } from '../../NewModalLayoutComponent';

interface IDefaultModalHeaderProps {
  title?: string | ReactNode;
  onBackButton?: VoidFunction;
  skillStatus?: skillStatusType;
  modalClose?: VoidFunction;
  paddingInline?: number;
  paddingBlockStart?: number;
}

const DefaultModalHeader: React.FC<IDefaultModalHeaderProps> = ( props ) => {
  const {
    title = '',
    onBackButton,
    skillStatus,
    modalClose,
    paddingInline,
    paddingBlockStart,
  } = props;

  return (
    <DialogTitleStyled
      paddingInline={paddingInline}
      paddingBlockStart={paddingBlockStart}
    >
      {onBackButton ? (
        <ArrowBackContainerStyled
          data-testid="back-button"
          onClick={onBackButton}
        >
          <ArrowBackIconStyled />
        </ArrowBackContainerStyled>
      ) : null}
      <StackTitleStatusStyled>
        <TitleStyled>
          {title}
        </TitleStyled>
        {skillStatus && (
          <SkillTrackerStatusSpan status={skillStatus} />
        )}
      </StackTitleStatusStyled>
      {title && modalClose && (
        <CloseContainerStyled
          aria-label="close"
          onClick={modalClose}
        >
          <CloseIconStyled />
        </CloseContainerStyled>
      )}
    </DialogTitleStyled>
  );
};

export default DefaultModalHeader;
