const locale = {
  SKILL_INSIGHTS: 'Análisis de Brechas',
  STUDENT_OVERVIEW: 'Resumen por Área',
  STUDENT_ADVISING: 'Mentoría Profesional',
  COURSE_RECOMMENDATIONS: 'Recomendaciones de Cursos',
  CAREER_TRENDS: 'Tendencias Profesionales',
  CONTACT_US: 'Contáctanos',
  FAQ: 'Preguntas Frecuentes',
  GENERAL: 'General',
  SUPPORT: 'Soporte',
};

export default locale;
