import { kanbanJobTitleId, kanbanSkillType } from '../../cache/cache';
import { jobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import { RecommendedOccupationsByMajorReactiveVar } from '../../cache/learner/recommendedOccupationsByMajorReactiveVar';
import { RecommendedOccupationsReactiveVar } from '../../cache/learner/recommendedOccupationsReactiveVar';
import { recommendedSkillsVar } from '../../cache/learner/recommendedSkillsReactiveVar';
import { removeAllCookies } from '../../utils/cookiesUtil';
import useJobPreferencesDataHook from '../jobPreferencesDataHook/useJobPreferencesDataHook';
import useKanbanDataHook from '../kanbanDataHook/useKanbanDataHook';
import useRecommendedOccupationsByMajorHook from '../recommendedOccupationsByMajorHook/recommendedOccupationsByMajorHook';
import RecommendedOccupationHook from '../RecommendedOccupationsHook/recommendedOccupationsHook';
import useRecommendedSkillsHook from '../recommendedSkillsHook/useRecommendedSkillsHook';
import SkillForCoursesRecommendationHook from '../skillForCoursesRecommendationHook/skillForCoursesRecommendationHook';

interface ISetDataFromLocalStorage {
  operations: {
    setDataFromLocal: () => boolean
    deleteLocalStorage: () => void
    deleteSessionStorageData: () => void
  }
}

const useSetDataFromLocalStorage = (): ISetDataFromLocalStorage => {
  const { operations } = useJobPreferencesDataHook( jobPreferencesVar, kanbanDataVar );
  const { setFromLocalRecommendedSkills } = useRecommendedSkillsHook( recommendedSkillsVar );
  const { setFromLocalRecommendedOccupations } = RecommendedOccupationHook( RecommendedOccupationsReactiveVar );
  const { setFromLocalSkillForCoursesRecommendations } = SkillForCoursesRecommendationHook();
  const { setFromLocalRecommendedOccupationsByMajor } = useRecommendedOccupationsByMajorHook(
    RecommendedOccupationsByMajorReactiveVar,
  );
  const { operations: kanbanOps } = useKanbanDataHook( kanbanDataVar, kanbanSkillType, kanbanJobTitleId );

  const setDataFromLocal = (): boolean =>
    operations.setFromLocalJobPreferences()
    && setFromLocalRecommendedSkills()
    && setFromLocalRecommendedOccupations()
    && setFromLocalRecommendedOccupationsByMajor()
    && kanbanOps.setFromLocalKanbanDataOrganized()
    && setFromLocalSkillForCoursesRecommendations();

  const deleteLocalStorage = ():void => {
    localStorage.removeItem( 'persist:root' );
    removeAllCookies();
    sessionStorage.clear();
  };

  const deleteSessionStorageData = ():void => {
    sessionStorage.removeItem( 'jobPreferences' );
    sessionStorage.removeItem( 'recommendedSkills' );
    sessionStorage.removeItem( 'recommendedOccupations' );
    sessionStorage.removeItem( 'skillForCoursesRecommendations' );
    sessionStorage.removeItem( 'recommendedOccupationsByMajor' );
    sessionStorage.removeItem( 'kanbanData' );
  };

  return {
    operations: {
      setDataFromLocal,
      deleteLocalStorage,
      deleteSessionStorageData,
    },
  };
};

export default useSetDataFromLocalStorage;
