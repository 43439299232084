import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../../../theme/learnerPalette';
import { styleConfigs } from '../../../../../resources/styleConfigs';
import GridWrapper from '../../../../../wrappers/GridWrapper';
import IconButtonWrapper from '../../../../../wrappers/IconButtonWrapper';
import TypographyWrapper from '../../../../../wrappers/TypographyWrapper';

interface IDialogTitleStyledProps {
  paddingInline?: number;
  paddingBlockStart?: number;
}

export const DialogTitleStyled = styled( GridWrapper )<IDialogTitleStyledProps>(
  ({ paddingInline, paddingBlockStart }) => ({
    width: styleConfigs.dimensions.percent100,
    height: styleConfigs.dimensions.percent100,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.light.background.white,
    paddingInline: paddingInline || styleConfigs.marginPadding.custom35,
    paddingBlock: paddingBlockStart || styleConfigs.marginPadding.custom20,
    [useTheme().breakpoints.down( 'sm' )]: {
      paddingInlineEnd: styleConfigs.marginPadding.custom12,
    },
  }),
);

export const ArrowBackContainerStyled = styled( IconButtonWrapper )(() => ({
  padding: styleConfigs.marginPadding.empty,
}));

export const ArrowBackIconStyled = styled( ArrowBackIcon )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPallete = wlPalette?.text?.redViolet ?? palette.light.text.redViolet;

  return ({
    fontSize: styleConfigs.dimensions.width31,
    color: palette.light.text.prussianBlue,
    '&:hover': {
      backgroundColor: alpha( colorPallete, 0.1 ),
      borderRadius: styleConfigs.borders.radius50,
    },
    [useTheme().breakpoints.down( 'sm' )]: {
      fontSize: styleConfigs.dimensions.width26,
    },
  });
});

export const StackTitleStatusStyled = styled( GridWrapper )(() => ({
  width: styleConfigs.dimensions.percent100,
  display: 'flex',
  flexFlow: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: styleConfigs.marginPadding.custom20,
  rowGap: styleConfigs.marginPadding.custom10,
  [useTheme().breakpoints.down( 'sm' )]: {
    rowGap: styleConfigs.marginPadding.custom17,
  },
}));

export const TitleStyled = styled( TypographyWrapper )(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  fontWeight: styleConfigs.fonts.bold,
  fontSize: styleConfigs.fonts.fontSize32,
  lineHeight: styleConfigs.fonts.lineHeight34,
  marginLeft: styleConfigs.marginPadding.custom5,
  margin: styleConfigs.marginPadding.empty,
  paddingBottom: styleConfigs.marginPadding.custom3,
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize24,
    lineHeight: styleConfigs.fonts.lineHeight26,
    paddingBottom: styleConfigs.marginPadding.custom2,
  },
}));

export const CloseContainerStyled = styled( IconButtonWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPallete = wlPalette?.text?.redViolet ?? palette.light.text.redViolet;

  return ({
    marginInlineStart: styleConfigs.marginPadding.custom25,
    padding: styleConfigs.marginPadding.custom3,
    ':hover': {
      background: alpha( colorPallete, 0.1 ),
    },
    [useTheme().breakpoints.down( 'sm' )]: {
      marginInlineStart: styleConfigs.marginPadding.custom21,
    },
  });
});

export const CloseIconStyled = styled( CloseIcon )(() => ({
  fontSize: styleConfigs.dimensions.width31,
  color: palette.light.icons.ebonyClay,
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.dimensions.width26,
  },
}));
