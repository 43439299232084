import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../resources/styleConfigs';
import DialogWrapper from '../../../wrappers/DialogWrapper';

export const DivBackgroundStyled = styled( 'div' )(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: styleConfigs.marginPadding.empty,
  left: styleConfigs.marginPadding.empty,
  bottom: styleConfigs.marginPadding.empty,
  right: styleConfigs.marginPadding.empty,
}));

export const DivModalStyled = styled( DialogWrapper )((
  {
    mobilewidth, mobileheight, roundercorner, mobileMargin,
  }:{ mobilewidth?: string | number, mobileheight?: string, roundercorner?: number, mobileMargin?: string | number},
) => ({
  transition: useTheme().transitions.create( 'width', {
    duration: useTheme().transitions.duration.complex,
  }),
  '& .MuiDialog-container': {
    paddingInline: mobileMargin,
  },
  '& .MuiDialog-paper': {
    width: mobilewidth,
    maxHeight: mobileheight,
    height: mobileheight,
    margin: styleConfigs.marginPadding.empty,
    borderRadius: !roundercorner && `${styleConfigs.borders.empty} !important`,
  },
}));
