import './useNotificationsHookConfig';
import { useReactiveVar } from '@apollo/client';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { cloneDeep, uniqueId } from 'lodash';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { renderNotification } from '../../cache/learner/homeReactiveVars';
import { jobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import IconComponent from '../../components/generics/IconComponent/IconComponent';
import { CustomSkillStyled } from '../../components/generics/NotificationComponent/styles/NotificationComponentStyles';
import SvgIconStyle from '../../components/template-minimal/SvgIconStyle';
import TypographyWrapper from '../../components/wrappers/TypographyWrapper';
import icons from '../../constants/icons';
import { NotificationContext } from '../../contexts/NotificationsContext';
import { IStudentNotification, NotificationType } from '../../operations/mutations/student/useMutationUpdateStudentSkillStatus/useMutationUpdateStudentSkillStatus';
import palette from '../../theme/learnerPalette';
import useJobPreferencesDataHook from '../jobPreferencesDataHook/useJobPreferencesDataHook';

export enum AddNotificationActionsEnum {
  ADD = 'ADD',
  DELETE = 'DELETE',
  MODIFY = 'MODIFY'
}

export enum LearningPathNotificationActionsEnum {
  COMPLETE = 'COMPLETE',
  REACTIVATE = 'REACTIVATE',
}

export interface IDatabaseNotification {
  id: number,
  date: string,
  skillName: string,
  type: NotificationType,
}

interface IAddSkillNotification {
  action: AddNotificationActionsEnum.ADD,
  data: {
    skillId: string,
    skillName: string,
    toStatus: string,
    fromStatus?: string,
    databaseNotification?: IDatabaseNotification,
  }
}

interface IDeleteSkillNotification {
  action: AddNotificationActionsEnum.DELETE
  data: {
    skillId: string,
    skillName: string,
    fromStatus?: string,
    databaseNotification?: IDatabaseNotification,
  }
}

interface IModifySkillNotification {
  action: AddNotificationActionsEnum.MODIFY,
  data: {
    skillId: string,
    skillName: string,
    fromStatus: string,
    toStatus: string,
    databaseNotification?: IDatabaseNotification
  }
}

export interface INotificationsHook {
  operations: {
    addNotification: ( type: string, description: string | ReactNode, customTitle?: string ) => void
    addSkillNotification: ( action: ISkillNotification ) => void
    addNotificationToVar: ( notification: IStudentNotification ) => void
    errorCVNotification: ( errorMessage: string ) => void
    addLearningPathNotification: ( action: LearningPathNotificationActionsEnum ) => void
  }
}

type ISkillNotification = IAddSkillNotification | IDeleteSkillNotification | IModifySkillNotification

const useNotificationsHook = (): INotificationsHook => {
  const { notificationOpen } = useContext( NotificationContext );
  const { t } = useTranslation( 'useNotificationsHook' );
  const { operations } = useJobPreferencesDataHook( jobPreferencesVar, kanbanDataVar );
  const allNotifications = useReactiveVar( renderNotification );

  const addNotificationToVar = ( notification: IStudentNotification ): void => {
    const copyOfNotification = cloneDeep( allNotifications );

    copyOfNotification.newNotifications.unshift( notification );
    renderNotification( copyOfNotification );
  };

  const handleOccupationsNumber = (
    action: ISkillNotification, forOneOccupation: string, forOccupations: string,
  ): string => ( operations.getOccupationChanges( action.data.skillId ) === '1' ? forOneOccupation : forOccupations );

  const addSkillNotification = ( action: ISkillNotification ): void => {
    switch ( action.action ) {
      case AddNotificationActionsEnum.ADD:
        if ( action.data.toStatus === 'completed' ) {
          addNotification(
            'congratulation',
            t( handleOccupationsNumber( action, 'CONGRATULATIONS_1_OCCUPATION', 'CONGRATULATIONS' ),
              { numberOccupations: operations.getOccupationChanges( action.data.skillId ) }),
          );
        } else if ( action.data.fromStatus === 'completed' ) {
          addNotification(
            'lookout',
            t( handleOccupationsNumber( action, 'LOOKOUT_NOTIFICATION_DESCRIPTION_1_OCCUPATION', 'LOOKOUT_NOTIFICATION_DESCRIPTION' ),
              { numberOccupations: operations.getOccupationChanges( action.data.skillId ) }),
          );
        } else {
          addNotification(
            'success',
            <TypographyWrapper>
              {t( 'YOU_ADDED' )}
              <CustomSkillStyled>{action.data.skillName}</CustomSkillStyled>
              {action.data.toStatus === 'learning' ? t( 'TO_LEARNING_SKILL_TRACKER' ) : t( 'TO_LEARN_SKILL_TRACKER' )}
            </TypographyWrapper>,
            t( 'ADD_SKILL_NOTIFICATION', { skill: action.data.skillName }),
          );
        }
        if ( action.data.databaseNotification ) {
          addNotificationToVar({
            id: action.data.databaseNotification.id,
            type: action.data.databaseNotification.type,
            skillName: action.data.databaseNotification.skillName,
            date: action.data.databaseNotification.date,
            occupationName: '',
            skillStatus: action.data.toStatus,
          });
        }
        break;

      case AddNotificationActionsEnum.DELETE:
        if ( action.data.fromStatus === 'completed' ) {
          addNotification(
            'lookout',
            t( handleOccupationsNumber( action, 'LOOKOUT_NOTIFICATION_DESCRIPTION_1_OCCUPATION', 'LOOKOUT_NOTIFICATION_DESCRIPTION' ),
              { numberOccupations: operations.getOccupationChanges( action.data.skillId ) }),
          );
        } else {
          addNotification(
            'removed',
            <TypographyWrapper>
              {t( 'YOU_DELETED' )}
              <CustomSkillStyled>{action.data.skillName}</CustomSkillStyled>
              {action.data.fromStatus === 'learning' ? t( 'FROM_LEARNING_SKILL_TRACKER' ) : t( 'FROM_LEARN_SKILL_TRACKER' )}
            </TypographyWrapper>,
            t( 'REMOVED_SKILL_NOTIFICATION', { skill: action.data.skillName }),
          );
          break;
        }
        if ( action.data.databaseNotification ) {
          addNotificationToVar({
            id: action.data.databaseNotification.id,
            type: action.data.databaseNotification.type,
            skillName: action.data.databaseNotification.skillName,
            date: action.data.databaseNotification.date,
            occupationName: '',
            skillStatus: '',
          });
        }
        break;

      case AddNotificationActionsEnum.MODIFY:
        if ( action.data.fromStatus === 'completed' ) {
          addNotification(
            'lookout',
            t( handleOccupationsNumber( action, 'LOOKOUT_NOTIFICATION_DESCRIPTION_1_OCCUPATION', 'LOOKOUT_NOTIFICATION_DESCRIPTION' ),
              { numberOccupations: operations.getOccupationChanges( action.data.skillId ) }),
          );
          if ( action.data.databaseNotification ) {
            addNotificationToVar({
              id: action.data.databaseNotification.id,
              type: action.data.databaseNotification.type,
              skillName: action.data.databaseNotification.skillName,
              date: action.data.databaseNotification.date,
              occupationName: '',
              skillStatus: action.data.toStatus,
            });
          }
          break;
        }
        if ( action.data.toStatus === 'completed' ) {
          addNotification(
            'congratulation',
            t( handleOccupationsNumber( action, 'CONGRATULATIONS_1_OCCUPATION', 'CONGRATULATIONS' ),
              { numberOccupations: operations.getOccupationChanges( action.data.skillId ) }),
          );
          if ( action.data.databaseNotification ) {
            addNotificationToVar({
              id: action.data.databaseNotification.id,
              type: action.data.databaseNotification.type,
              skillName: action.data.databaseNotification.skillName,
              date: action.data.databaseNotification.date,
              occupationName: '',
              skillStatus: action.data.toStatus,
            });
          }
          break;
        }
        addNotification(
          'success',
          <TypographyWrapper>
            {t( 'YOU_MOVED' )}
            <CustomSkillStyled>{action.data.skillName}</CustomSkillStyled>
            {action.data.toStatus === 'learning' ? t( 'TO_LEARNING_SKILL_TRACKER' ) : t( 'TO_LEARN_SKILL_TRACKER' )}
          </TypographyWrapper>,
          t( 'DRAG_SKILL_NOTIFICATION', { skill: action.data.skillName }),
        );
        break;

      default:
        addNotification( 'danger', 'Unsupported Action' );
    }
  };

  const addNotification = ( type: string, description: string | ReactNode, customTitle?: string ): void => {
    let title: string;
    let leftIcon: JSX.Element;

    switch ( type ) {
      case 'success':
        title = t( 'SUCCESS_NOTIFICATION' );
        leftIcon = <CheckOutlinedIcon sx={{ color: palette.light.icons.greenTeal }} />;
        break;

      case 'danger':
        title = t( 'DANGER_NOTIFICATION' );
        leftIcon = <ErrorIcon sx={{ color: palette.light.icons.coralRed }} />;
        break;

      case 'congratulation':
        title = t( 'CONGRATULATION_NOTIFICATION' );
        leftIcon = (
          <IconComponent
            src={icons.PERFORMANCE_INCREASE_ICON}
          />
        );
        break;

      case 'thumbsUp':
        title = t( 'CONGRATULATION_NOTIFICATION' );
        leftIcon = (
          <SvgIconStyle
            src={icons.THUMBS_UP_NOTIFICATION}
            sx={{ backgroundColor: palette.light.icons.greenTeal }}
          />
        );
        break;

      case 'occupationSelected':
        title = t( 'OCCUPATION_SELECTED' );
        leftIcon = (
          <IconComponent
            src={icons.JOB_ICON_CHECK}
          />
        );
        break;

      case 'warning':
        title = t( 'WARNING_NOTIFICATION' );
        leftIcon = <ErrorIcon sx={{ color: palette.light.icons.coralRed }} />;
        break;

      case 'lookout':
        title = t( 'LOKOUT_NOTIFICATION' );
        leftIcon = (
          <IconComponent
            src={icons.PERFORMANCE_DOWN_ICON_NEW}
            sx={{ display: 'flex' }}
          />
        );
        break;

      case 'bulb':
        title = t( 'LOKOUT_NOTIFICATION' );
        leftIcon = (
          <IconComponent
            src={icons.LIGHT_BULB_NOTIFICATION}
            sx={{ display: 'flex' }}
          />
        );
        break;

      case 'removed':
        title = t( 'REMOVED_NOTIFICATION' );
        leftIcon = (
          <IconComponent
            src={icons.REMOVED_SKILL_TRASH}
            sx={{ display: 'flex', marginTop: '2px' }}
          />
        );
        break;

      default:
        title = t( 'SUCCESS_NOTIFICATION' );
        leftIcon = <CheckOutlinedIcon sx={{ color: palette.light.icons.greenTeal }} />;
        break;
    }

    notificationOpen({
      listNotifications: [{
        id: uniqueId( 'notification_' ),
        title: customTitle || title,
        description,
        leftIcon,
        autoClose: true,
      }],
    });
  };

  const addLearningPathNotification = ( action: LearningPathNotificationActionsEnum ): void => {
    switch ( action ) {
      case LearningPathNotificationActionsEnum.COMPLETE:
        addNotification(
          'thumbsUp',
          <TypographyWrapper>
            {t( 'COMPLETE_LEARNING_PATH_DESCRIPTION' )}
            <CustomSkillStyled>{t( 'LP_COMPLETE_STATUS' )}</CustomSkillStyled>
          </TypographyWrapper>,
          t( 'COMPLETE_LP_TITLE' ),
        );
        break;

      case LearningPathNotificationActionsEnum.REACTIVATE:
        addNotification(
          'bulb',
          <TypographyWrapper>
            {t( 'REACTIVATE_LP_DESCRIPTION' )}
          </TypographyWrapper>,
          t( 'REACTIVATE_LP_TITLE' ),
        );
        break;

      default:
        addNotification( 'danger', 'Unsupported Action' );
    }
  };

  const errorCVNotification = ( errorMessage: string ): void => {
    const message = errorMessage.toString().split( ':' )[0];

    switch ( message ) {
      case 'Error code 001:':
        addNotification( 'warning', t( 'CV_MAX_SIZE', { size: '5' }));
        break;

      case 'Error code 002:':
        addNotification( 'warning', t( 'CV_MAX_SIZE', { size: '5' }));
        break;

      case 'Error code 452':
        addNotification( 'warning', t( 'CV_ERROR_452' ));
        break;

      case 'Error code 453':
        addNotification( 'warning', t( 'CV_VALID_FORMAT' ));
        break;

      case 'Error code 454':
        addNotification( 'warning', t( 'CV_ERROR_454' ));
        break;

      case 'Error code 512':
        addNotification( 'warning', t( 'CV_ERROR_512' ));
        break;

      case 'Error code 513':
        addNotification( 'warning', t( 'CV_ERROR_513' ));
        break;

      default:
        addNotification( 'warning', t( 'CV_UPLOAD_ERROR' ));
        break;
    }
  };

  return {
    operations: {
      addNotification,
      addSkillNotification,
      addNotificationToVar,
      errorCVNotification,
      addLearningPathNotification,
    },
  };
};

export default useNotificationsHook;
