import { formatPathName } from './formatString';

export const getMixpanelName = ( screen?: string ): string => {
  const screenName = formatPathName( screen ?? '' );

  const queryString = [
    { name: 'login', value: 'Login' },
    { name: 'on-boarding', value: 'Onboarding' },
    { name: 'forgot', value: 'Forgot' },
    { name: 'register', value: 'Register' },

    // new
    { name: 'wizard-information', value: 'Wizard - Step 1' },
    { name: 'wizard-occupations', value: 'Wizard - Step 2' },
    { name: 'wizard-skills', value: 'Wizard - Step 3' },
    // old
    { name: 'wizard-step1', value: 'Wizard - Step1' },
    { name: 'wizard-step2', value: 'Wizard - Step2' },
    { name: 'wizard-summary', value: 'Wizard - Step2 - Summary' },
    { name: 'wizard-step3', value: 'Wizard - Step3' },
    { name: 'wizard-step4', value: 'Wizard - Step4' },
    { name: 'wizard-step5', value: 'Wizard - Step5' },

    { name: 'profile', value: 'Profile - Personal Information' },
    { name: 'profile-links', value: 'Profile - Link Your Information' },
    { name: 'profile-skills', value: 'Profile - Skills' },
    { name: 'home', value: 'Home' },
    { name: 'skills?activetab=0', value: 'Skills - My Objectives' },
    { name: 'skills?activetab=1', value: 'Skills - Explore' },
    { name: 'occupations?activetab=0', value: 'Occupations - My Objectives' },
    { name: 'occupations?activetab=1', value: 'Occupations - Explore' },
    { name: 'occupations?activetab=2', value: 'Occupations - Explore by Area of Interest' },
    { name: 'help', value: 'Help' },
    { name: 'help?activetab=0', value: 'Help - Contact us' },
    { name: 'help?activetab=1', value: 'Help - FAQ' },
    { name: 'help?activetab=2', value: 'Help - Legal' },
    { name: 'insights', value: 'Skill Insights' },
    { name: 'student-overview', value: 'Student Overview' },
    { name: 'student-advising?activetab=0', value: 'Student Advising - Student Profile' },
    { name: 'student-advising?activetab=1', value: 'Student Advising - Explore Jobs' },
    { name: 'student-advising?activetab=2', value: 'Student Advising - Explore Skills' },
    { name: 'contact', value: 'Contact us' },
    { name: 'faq', value: 'FAQ' },
  ];

  let result = '';

  queryString.forEach(( item ) => {
    if ( screenName.includes( item.name )) {
      result = item.value;
    }
  });

  return result;
};
