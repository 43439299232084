import axios from 'axios';

import environmentVariables from '../appVariables';
import { setCookie } from '../utils/cookiesUtil';
import { tokenDecode } from '../utils/jwtUtils';
import { ILoginUserSuccessResponse } from '../views/Auth/Login/interfaces/IGoogleUser';

export const refreshTokenService = async (
  jwtToken: string, refreshToken: string,
) : Promise<ILoginUserSuccessResponse> => {
  let token = jwtToken;
  const uri = environmentVariables?.environment !== 'development'
    ? `${environmentVariables?.reactAppApiGraphql}/graphql`
    : `${environmentVariables?.reactAppApiGraphqlDev}/graphql`;
  let auth = tokenDecode( jwtToken );

  const { data } = await axios.post( uri, {
    operationName: 'refreshToken',
    query: 'mutation refreshToken($refreshToken: String!) {\n  refreshToken(refreshToken: $refreshToken) {\n    jwtToken\n  }\n}\n',
    variables: {
      refreshToken,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if ( !data?.data?.refreshToken?.jwtToken ) {
    return auth;
  }

  token = data?.data?.refreshToken?.jwtToken;
  auth = tokenDecode( token );
  setCookie( 'token', token, auth?.expiresIn );

  return auth;
};

export const refreshAuthTokenService = async ( jwtToken: string, refresh: string ) : Promise<string|null> => {
  const token = jwtToken;
  const uri = environmentVariables?.environment !== 'development'
    ? `${environmentVariables?.reactAppApiGraphql}/graphql`
    : `${environmentVariables?.reactAppApiGraphqlDev}/graphql`;

  const { data } = await axios.post( uri, {
    operationName: 'refreshToken',
    query: 'mutation refreshToken($refreshToken: String!) {\n  refreshToken(refreshToken: $refreshToken) {\n    jwtToken\n  }\n}\n',
    variables: {
      refreshToken: refresh,
    },
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const tokenRefresed = data?.data?.refreshToken?.jwtToken;

  if ( !tokenRefresed ) {
    return null;
  }
  setCookie( 'token', tokenRefresed );

  return tokenRefresed;
};
