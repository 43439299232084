import { Dialog, Paper, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import { styleConfigs } from '../../../../components/resources/styleConfigs';
import StackWrapper from '../../../../components/wrappers/StackWrapper';
import palette from '../../../../theme/learnerPalette';

export const DialogPaperStyled = withStyles(() => ({
  root: {
    margin: styleConfigs.marginPadding.fullNotificationList,
  },
}))( Paper );

export const DialogStyled = styled( Dialog )({
  margin: styleConfigs.marginPadding.empty,
  padding: styleConfigs.marginPadding.empty,
});

export const DialogContainerStyled = styled( StackWrapper )({
  position: 'sticky',
  flexDirection: 'column',
  padding: styleConfigs.marginPadding.rem1,
});

export const DialogHeaderStyled = styled( StackWrapper )({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DialogBackButton = styled( 'div' )({
  justifyContent: 'flex-end',
});

export const DialogContentStyled = styled( 'div' )({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: styleConfigs.marginPadding.rem1,
});

export const DialogEmptyStateStyled = styled( StackWrapper )({
  height: styleConfigs.dimensions.percent70,
  justifyContent: 'center',
});

export const DialogScrollTopStyled = styled( 'div' )({
  display: 'flex',
  flexDirection: 'row',
  width: styleConfigs.marginPadding.percentage100,
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const DialogFabStyled = styled( Fab )({
  background: palette.light.background.redViolet,
});
