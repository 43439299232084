import ExpandMore from '@mui/icons-material/ExpandMore';
import { alpha, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import { MIconButton } from '../../../components/template-minimal/@material-extend';
import BoxWrapper from '../../../components/wrappers/BoxWrapper';
import useGetPaletteFromWhiteLabel from '../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../theme/learnerPalette';

const focusStyle = ( fromkeyboard : number ): any => {
  if ( fromkeyboard !== 0 ) {
    return {
      border: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
    };
  }

  return {
    border: styleConfigs.borders.empty,
  };
};

export const MIconButtonStyled = styled( MIconButton )((
  {
    open, islearner, fromkeyboard,
  }: { open: number, islearner: number, fromkeyboard : number},
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const learnerColorPalette = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;
  const advisorColorPalette = wlPalette?.background?.yaleBlue ?? palette.light.background.yaleBlue;

  return ({
    width: styleConfigs.dimensions.width244,
    height: styleConfigs.dimensions.auto,
    color: palette.light.text.black,
    padding: `${styleConfigs.marginPadding.custom8}px ${styleConfigs.marginPadding.custom10}px`,
    borderRadius: styleConfigs.borders.radius12,
    '&:hover': {
      background: islearner
        ? alpha( learnerColorPalette, 0.1 )
        : alpha( advisorColorPalette, 0.1 ),
      border: styleConfigs.borders.unset,
    },
    ...( open && {
      '&:before': {
        content: '\'\'',
        width: styleConfigs.dimensions.percent100,
        height: styleConfigs.dimensions.percent100,
        background: islearner
          ? alpha( learnerColorPalette, 0.1 )
          : alpha( advisorColorPalette, 0.1 ),
        border: `${styleConfigs.borders.borderLine} ${palette.light.borders.platinum}`,
        borderRadius: styleConfigs.borders.radius12,
        position: 'absolute',
      },
    }),
    '&:after': {
      content: '\'\'',
      width: styleConfigs.dimensions.percent100,
      height: styleConfigs.dimensions.percent100,
      border: `${styleConfigs.borders.borderLine} ${palette.light.borders.platinum}`,
      borderRadius: styleConfigs.borders.radius12,
      position: 'absolute',
    },
    '&:focus': focusStyle( fromkeyboard ),
    [useTheme().breakpoints.down( 'sm' )]: {
      width: styleConfigs.dimensions.auto,
      padding: styleConfigs.marginPadding.empty,
      '&:after': {
        border: styleConfigs.borders.unset,
      },
    },
  });
});

export const ListItemIconStyle = styled( 'div' )({
  width: styleConfigs.dimensions.width24,
  height: styleConfigs.dimensions.height24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: `${styleConfigs.marginPadding.auto} ${styleConfigs.marginPadding.custom15}px`,
});

export const InitialsStyled = styled( BoxWrapper )((
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColor = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;

  return {
    width: styleConfigs.dimensions.width40,
    height: styleConfigs.dimensions.height40,
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
    fontWeight: styleConfigs.fonts.bold,
    fontSize: styleConfigs.fonts.fontSize18,
    letterSpacing: styleConfigs.marginPadding.custom1,
    color: palette.light.text.white,
    background: backgroundColor,
    borderRadius: styleConfigs.borders.radius8,
    [useTheme().breakpoints.down( 'sm' )]: {
      width: styleConfigs.dimensions.width37,
      height: styleConfigs.dimensions.height36,
      fontSize: styleConfigs.fonts.fontSize16,
      borderRadius: styleConfigs.borders.radius6,
    },
  };
});

export const ExpandMoreStyled = styled( ExpandMore )(() => ({
  fill: palette.light.text.ebonyClay,
}));
