import { ReactNode } from 'react';

import notificationActionTypes from '../actionTypes/notificationActionsTypes';

export interface INotification {
  id: number | string;
  title: string;
  description: string | ReactNode;
  leftIcon?: ReactNode;
  autoClose?: boolean;
  autoHideDuration?: number;
}

export interface INotificationData {
  listNotifications: INotification[],
}

export interface INotificationReducer {
  contentData: INotificationData
  notificationOpen: ( notificationData: INotificationData ) => void
  setShowNotification: ( showNotification: boolean ) => void
  deleteNotification: ( notificationId: string | number ) => void
  showNotification: boolean
}

export const notificationReducerState: INotificationReducer = {
  contentData: {
    listNotifications: [],
  },
  notificationOpen: () => null,
  setShowNotification: () => null,
  deleteNotification: () => null,
  showNotification: false,
};

const notificationReducer = (
  state: INotificationReducer,
  action: { type: string, payload?: any },
): INotificationReducer => {
  const defaultState = state || notificationReducerState;

  switch ( action.type ) {
    case notificationActionTypes.NOTIFICATION_SET_DATA:
      return {
        ...defaultState,
        contentData: {
          listNotifications: [...defaultState.contentData.listNotifications, ...action.payload.listNotifications],
        },
        showNotification: true,
      };

    case notificationActionTypes.NOTIFICATION_SHOW:
      return {
        ...defaultState,
        showNotification: action.payload,
      };

    case notificationActionTypes.NOTIFICATION_DELETE:
      return {

        ...defaultState,
        contentData: {
          listNotifications: defaultState.contentData.listNotifications.filter(({ id }) => id !== action.payload ),
        },
        showNotification: true,

      };

    default:
      return defaultState;
  }
};

export default notificationReducer;
