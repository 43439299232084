import { styled } from '@mui/material/styles';

import { fonts } from '../../../components/resources/fonts';
import { marginPadding } from '../../../components/resources/marginPadding';
import palette from '../../../theme/learnerPalette';

export const ProfileWrapper = styled( 'div' )(() => ({
  textAlign: 'left',
  width: '150px',
  paddingLeft: marginPadding.custom10,
}));

export const ProfileName = styled( 'div' )(() => ({
  fontFamily: fonts.fontFamily,
  fontWeight: fonts.weight600,
  color: 'black',
  fontSize: fonts.fontSize15,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ProfileType = styled( 'p' )(({ theme }) => ({
  fontFamily: fonts.fontFamily,
  fontWeight: fonts.weight400,
  color: palette.light.text.mistBlue,
  fontSize: fonts.fontSize15,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down( 'sm' )]: {
    fontSize: fonts.fontSize12,
  },
}));
