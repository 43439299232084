import {
  gql,
  useMutation,
  MutationTuple,
} from '@apollo/client';

export const CREATE_STUDENT_KANBAN = gql`
mutation CreateOrUpdateStudentSkill($studentId: String!, $skillId: String!, $skillStatus: String!, $fromStatus: String, $jobTitleId: String) {
  createOrUpdateStudentSkill(studentId: $studentId, skillId: $skillId, skillStatus: $skillStatus, fromStatus: $fromStatus, jobTitleId: $jobTitleId) {
    created
    studentNotification {
      id
      type
      date
    }
  }
}
`;

export interface ICreateOrUpdateStudentSkillParams {
  skillStatus: string;
  skillId: string;
  studentId: string;
  fromStatus?: string;
  jobTitleId?: string;
}

export interface ICommonMutationReturn {
  created: boolean;
  studentNotification: {
    id: number;
    type: string;
    date: string;
  }
}

export interface ICreateOrUpdateStudentSkillResponse {
  createOrUpdateStudentSkill: ICommonMutationReturn | undefined;
}

export const useMutationCreateOrUpdateStudentSkill = ():
MutationTuple<ICreateOrUpdateStudentSkillResponse, ICreateOrUpdateStudentSkillParams> =>
  useMutation<ICreateOrUpdateStudentSkillResponse, ICreateOrUpdateStudentSkillParams>(
    CREATE_STUDENT_KANBAN,
  );
