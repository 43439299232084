import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ReactNode } from 'react';

interface IProps {
  children: JSX.Element[] | ReactNode,
}

const DialogWrapper: React.FC<IProps & DialogProps > = ({ children, ...props }) => (
  <Dialog {...props}>
    { children }
  </Dialog>
);

export default DialogWrapper;
