import './useLoadableRoutesConfig';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CircularLoaderComponent from '../../components/generics/CircularLoaderComponent/CircularLoaderComponent';
import BoxWrapper from '../../components/wrappers/BoxWrapper';

interface ILoadableRoutes {
  children: any;
}

const LoadableRoutes = ( props: ILoadableRoutes ): JSX.Element => {
  const { children } = props;
  const { t } = useTranslation( 'useLoadableRoutes' );
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={pathname.includes( 'wizard' ) || pathname.includes( 'auth' ) ? ( <BoxWrapper /> ) : (
        <CircularLoaderComponent
          isLoading
          background="white"
          text={t( 'LOADER_TEXT' )}
          textColor="prussianBlue"
        />
      )}
    >
      {children}
    </Suspense>
  );
};

export default LoadableRoutes;
