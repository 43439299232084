export const marginPadding = {
  auto: 'auto',
  unset: 'unset',
  extraSmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  extraLarge: 48,
  rem0p7: '0.7rem',
  rem1: '1rem',
  empty: 0,
  custom1: 1,
  custom1p5: 1.5,
  custom2: 2,
  custom2p5: 2.5,
  custom3: 3,
  custom4: 4,
  custom5: 5,
  custom6: 6,
  custom7: 7,
  custom7p5: 7.5,
  custom8: 8,
  custom9: 9,
  custom10: 10,
  custom11: 11,
  custom11p5: 11.5,
  custom12: 12,
  custom13: 13,
  custom14: 14,
  custom15: 15,
  custom15p53: 15.53,
  custom16: 16,
  custom17: 17,
  custom18: 18,
  custom19: 19,
  custom20: 20,
  custom21: 21,
  custom22: 22,
  custom23: 23,
  custom24: 24,
  custom25: 25,
  custom26: 26,
  custom27: 27,
  custom28: 28,
  custom29: 29,
  custom30: 30,
  custom31: 31,
  custom32: 32,
  custom33: 33,
  custom34: 34,
  custom35: 35,
  custom36: 36,
  custom37: 37,
  custom38: 38,
  custom39: 39,
  custom40: 40,
  custom41: 41,
  custom42: 42,
  custom43: 43,
  custom44: 44,
  custom45: 45,
  custom46: 46,
  custom47: 47,
  custom48: 48,
  custom50: 50,
  custom49: 49,
  custom51: 51,
  custom52: 52,
  custom53: 53,
  custom55: 55,
  custom56: 56,
  custom57: 57,
  custom58: 58,
  custom60: 60,
  custom62: 62,
  custom64: 64,
  custom65: 65,
  custom66: 66,
  custom67: 67,
  custom68: 68,
  custom69: 69,
  custom70: 70,
  custom71: 71,
  custom75: 75,
  custom76: 76,
  custom77: 77,
  custom78: 78,
  custom79: 79,
  custom80: 80,
  custom81: 81,
  custom85: 85,
  custom88: 88,
  custom89: 89,
  custom90: 90,
  custom91: 91,
  custom92: 92,
  custom93: 93,
  custom94: 94,
  custom96: 96,
  custom100: 100,
  custom101: 101,
  custom102: 102,
  custom105: 105,
  custom110: 110,
  custom112: 112,
  custom116: 116,
  custom120: 120,
  custom121: 121,
  custom123: 123,
  custom125: 125,
  custom128: 128,
  custom130: 130,
  custom131: 131,
  custom137: 137,
  custom140: 140,
  custom144: 144,
  custom150: 150,
  custom151: 151,
  custom152: 152,
  custom154: 154,
  custom170: 170,
  custom180: 180,
  custom190: 190,
  custom197: 197,
  custom200: 200,
  custom210: 210,
  custom221: 221,
  custom238: 238,
  custom240: 240,
  custom248: 248,
  custom250: 250,
  custom270: 270,
  custom280: 280,
  custom294: 294,
  custom299: 299,
  custom344: 344,
  custom700: 700,
  custom902: 902,
  custom0px: '0px',
  custom1px: '1px',
  custom2px: '2px',
  custom3px: '3px',
  custom4px: '4px',
  custom5px: '5px',
  custom8px: '8px',
  custom10px: '10px',
  custom12px: '12px',
  custom11px: '11px',
  custom15px: '15px',
  custom16px: '16px',
  custom17px: '17px',
  custom20px: '20px',
  custom21px: '21px',
  custom24px: '24px',
  custom26px: '26px',
  custom28px: '28px',
  custom32px: '32px',
  custom40px: '40px',
  custom44px: '44px',
  custom46px: '46px',
  custom100px: '100px',
  marginCustom: '1rem',
  percentage3: '3%',
  percentage4: '4%',
  percentage5: '5%',
  percentage10: '10%',
  percentage12: '12%',
  percentage15: '15%',
  percentage18: '18%',
  percentage22p527: '22.527%',
  percentage22p6: '22.6%',
  percentage23: '23%',
  percentage25: '25%',
  percentage25p2: '25.2%',
  percentage31p5: '31.5%',
  percentage34p203: '34.203%',
  percentage34p204: '34.204%',
  percentage34p5: '34.5%',
  percentage35: '35%',
  percentage40: '40%',
  percentage45: '45%',
  percentage45p2: '45.2%',
  percentage46p8: '46.8%',
  percentage47p22: '47.22%',
  percentage47p42: '47.42%',
  percentage50: '50%',
  percentage55: '55%',
  percentage60: '60%',
  percentage90: '90%',
  percentage100: '100%',
  percentage80: '80%',
  percentage85: '85%',
  negativePercentage6p8: '-6.8%',
  negativePercentage4p5: '-4.5%',
  negativePercentage15: '-15%',
  negativePercentage60: '-60%',
  negativePercentage50: '-50%',
  negativePercentage90: '-90%',
  relative35: '35px',
  relative30: '30px',
  relative45: '45px',
  drawerWidth: 280,
  appBarMobile: 64,
  appBarDesktop: 92,
  skillCardPadding: '1px 8px',
  mainSkillCardPadding: '16px 12px 16px 25px',
  skillCardPaddingSecondary: '10px 15px',
  commonCardPadding: '15px 25px',
  commonCardPaddingSm: '15px 10px',
  dataLabelsOffset10: -10,
  lineHeight28: '28px',
  marginTitle: '30px 0px',
  learnerHomeCard: '24px 32px',
  emptyStateComponentButton: '6px 16px',
  customNegative2: -2,
  customNegative3: -3,
  customNegative4: -4,
  customNegative5: -5,
  customNegative7: -7,
  customNegative8: -8,
  customNegative9: -9,
  customNegative10: -10,
  customNegative11: -11,
  customNegative12: -12,
  customNegative15: -15,
  customNegative16: -16,
  customNegative20: -20,
  customNegative30: -30,
  customNegative49: -49,
  customNegative55: -55,
  customNegative78: -78,
  customNegative90: -90,
  customNegative105: -105,
  customNegative144: -144,
  custom4Px: '4px',
  custom12Px: '12px',
  custom100Px: '100px',
  backgroundPaddingBox: 'padding-box',
  quarterRem: '0.25rem',
  halfRem: '0.5rem',
  twoRem: '2rem',
  wizardConnector: {
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  loginBoxShadowFirst: '0 0 2px',
  loginBoxShadowSecond: '0 16px 32px -4px',
  paddingCourseCardsMobile: '24px 0 0 24px',
  chipSelectorPadding: '0 16px 15px',
  negative11: '-11px',
  topListDivRoot: '0px 25px',
  topListContainer: '0px 0px 24px',
  topListItemFirst: '16px 0px 24px',
  roleSelectorPadding: '28px 48px 28px 48px',
  topListItem: '5px 0px',
  fullNotificationList: '0 !important',
  mobileDrawer: '32px !important',
  boxShadowItemColumnBoard: '0px 0px 9px',
  boxShadowAddOccupationModal: '0px 20px 40px -4px',
  floatingErrorWizard: '12px 16px',
  custom200px: '200px',
  custom400px: '400px',
};
