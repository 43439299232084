import './SidebarConfigurationConfig';
import { TFunction } from 'i18next';

import { ISidebarItemConfig } from '../../../components/template-minimal/NavSectionComponent/NavSection';
import SvgIconStyle from '../../../components/template-minimal/SvgIconStyle';
import icons from '../../../constants/icons';
import { IFeatures } from '../../../contexts/reducer/featureFlagsReducer';
import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = ( icon: string ): JSX.Element => (
  <SvgIconStyle src={icon} sx={{ width: '100%', height: '100%' }} />
);

const SidebarConfiguration = (
  features: IFeatures,
  t: TFunction<'SidebarConfiguration', undefined>,
): ISidebarItemConfig[] => {
  const ICONS = {
    insights: getIcon( icons.INSIGHTS_ICON ),
    studentOverview: getIcon( icons.STUDENT_OVERVIEW_ICON ),
    student: getIcon( icons.PROFILE_ICON ),
    contact: getIcon( icons.CONTACT_ICON ),
    faq: getIcon( icons.FAQ_ICON ),
    courses: getIcon( icons.COURSES_ICON ),
    careerTrends: getIcon( icons.CAREER_TRENDS ),
  };

  const inSightsItem = {
    title: t( 'SKILL_INSIGHTS' ),
    path: PATH_DASHBOARD.admin.pageInsights,
    icon: ICONS.insights,
  };
  const studentOverViewItem = {
    title: t( 'STUDENT_OVERVIEW' ),
    path: PATH_DASHBOARD.admin.pageStudentOverview,
    icon: ICONS.studentOverview,
  };
  const studentAdvisingItem = {
    title: t( 'STUDENT_ADVISING' ),
    path: PATH_DASHBOARD.admin.pageStudentAdvising,
    icon: ICONS.student,
  };
  const careerTrensItem = {
    title: t( 'CAREER_TRENDS' ),
    path: PATH_DASHBOARD.admin.pageCareerTrends,
    icon: ICONS.careerTrends,
  };

  const contactUsItem = {
    title: t( 'CONTACT_US' ),
    path: PATH_DASHBOARD.admin.contact,
    icon: ICONS.contact,
  };
  const faqItem = {
    title: t( 'FAQ' ),
    path: PATH_DASHBOARD.admin.faq,
    icon: ICONS.faq,
  };

  const navBarItems: ISidebarItemConfig[] = [
    {
      subheader: t( 'GENERAL' ),
      items: [],
    },
    {
      subheader: t( 'SUPPORT' ),
      items: [],
    },
  ];

  if ( !features.advisor?.hideContactUs ) {
    navBarItems[1].items.push( contactUsItem );
  }

  if ( !features.advisor?.hideFAQ ) {
    navBarItems[1].items.push( faqItem );
  }

  if ( !features.advisor?.hideSkillInsights ) {
    navBarItems[0].items.push( inSightsItem );
  }
  if ( !features.advisor?.hideStudentOverview ) {
    navBarItems[0].items.push( studentOverViewItem );
  }
  if ( !features.advisor?.hideStudentAdvising ) {
    navBarItems[0].items.push( studentAdvisingItem );
  }
  if ( !features.advisor?.hideCareerTrendsScreen ) {
    navBarItems[0].items.push( careerTrensItem );
  }

  return navBarItems.filter(( item ) => item.items.length > 0 );
};

export default SidebarConfiguration;
