import { BoxProps } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BoxStyled, StackWrapperStyled,
} from './styles/NavSectionStudentStyles';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import BoxWrapper from '../../wrappers/BoxWrapper';
import NavItem, { INavItemProps } from '../NavItem/NavItem';

interface INavSectionProps extends BoxProps {
  navConfig: {
    items: INavItemProps[];
  }[];
}

const NavSectionStudent = (
  { navConfig, ...other }: INavSectionProps,
): JSX.Element => {
  const { t } = useTranslation( 'LearnerSidebar' );
  const { features } = useContext( FeatureFlagsContext );
  const { isCollapse } = useCollapseDrawer();

  const filterNavConfig = ( isHelpHidden?: boolean ): { items: INavItemProps[] }[] => {
    if ( isHelpHidden ) {
      return navConfig.map(( item ) => ({
        ...item,
        items: item.items.filter(( innerItem ) => innerItem.title !== 'HELP' ),
      }));
    }

    return navConfig;
  };

  return (
    <BoxStyled
      {...other}
    >
      {filterNavConfig( features.learner?.hideLearnerHelp ).map(( list, i ) => {
        const { items } = list;

        return (
          <StackWrapperStyled
            key={i.toString()}
            flexDirection="column"
            justifyContent="space-between"
          >
            <BoxWrapper>
              {items.map(( item: INavItemProps ) => (
                <NavItem
                  key={item.title}
                  title={t( item.title )}
                  path={item.path}
                  icon={item.icon}
                  info={item.info}
                  isVisible={!isCollapse}
                >
                  {item.children}
                </NavItem>
              ))}
            </BoxWrapper>
          </StackWrapperStyled>
        );
      })}
    </BoxStyled>
  );
};

export default NavSectionStudent;
