import { ReactiveVar } from '@apollo/client';

import { ILearningPathsByObjectivesCache, learningPathsByObjectivesVar } from '../../cache/learner/learningPathsByObjectivesReactiveVar';
import { ILearningPathwaysByObjectives } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';

export interface ILearningPathsByObjectivesHook {
  operations: {
    setLearningPathsByObjectives: ( learningPathsByObjectives: ILearningPathwaysByObjectives[]) => void;
    deleteLearningPathsByObjectives: ( learningId: string ) => void;
    learningPathsByObjectivesData: ILearningPathsByObjectivesCache;
  }
}

export const useLearningPathsByObjectivesHook = (
  learningPathsByObjectivesVarProp: ReactiveVar<ILearningPathsByObjectivesCache>,
): ILearningPathsByObjectivesHook => {
  const learningPathsByObjectivesData = learningPathsByObjectivesVarProp();

  const setLearningPathsByObjectives = ( learningPathsByObjectives: ILearningPathwaysByObjectives[]): void => {
    learningPathsByObjectivesVar(
      {
        error: undefined,
        data: learningPathsByObjectives,
      },
    );
  };

  const deleteLearningPathsByObjectives = ( learningId: string ): void => {
    const tempData = learningPathsByObjectivesData.data.filter(( learningPath ) => learningPath.id !== learningId );

    setLearningPathsByObjectives( tempData );
  };

  return {
    operations: {
      learningPathsByObjectivesData,
      setLearningPathsByObjectives,
      deleteLearningPathsByObjectives,
    },
  };
};
