import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import IconButtonWrapper from '../../../wrappers/IconButtonWrapper';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';

export const RootStyle = styled( Card )((
  { show = 0, theme }: { show: number, theme?: any},
) => ({
  width: styleConfigs.dimensions.width430,
  display: show > 0 ? 'flex' : 'none',
  flexDirection: 'row',
  padding: styleConfigs.marginPadding.custom16,
  columnGap: styleConfigs.marginPadding.custom16,
  [theme.breakpoints.down( 'md' )]: {
    width: styleConfigs.dimensions.width323,
  },
}));

export const BodyStyle = styled( 'div' )(() => ({
  height: styleConfigs.dimensions.percent100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
}));

export const ContainerIconStyle = styled( 'div' )(() => ({
  height: styleConfigs.dimensions.percent100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 0.1,
}));

export const IconButtonStyle = styled( IconButtonWrapper )(() => ({
  color: palette.light.icons.ebonyClay,
  margin: styleConfigs.marginPadding.empty,
  padding: styleConfigs.marginPadding.empty,
}));

export const CustomSkillStyled = styled( TypographyWrapper )(() => ({
  display: 'inline',
  fontWeight: styleConfigs.fonts.bold,
  color: palette.light.text.prussianBlue,
  margin: `${styleConfigs.marginPadding.empty} ${styleConfigs.marginPadding.custom5}px`,
}));
