import { gql, MutationTuple, useMutation } from '@apollo/client';

export const DELETE_STUDENT_LEARNING_PATHWAY = gql`
mutation Mutation($studentId: String!, $pathwayId: String!) {
  deleteStudentLearningPathway(studentId: $studentId, pathwayId: $pathwayId)
}
`;

interface IDeleteStudentLearningPathwayParams {
  studentId: string;
  pathwayId: string;
}

interface IDeleteStudentLearningPathwayResponse {
  deleteStudentLearningPathway: boolean;
}

export const useMutationDeleteStudentLearningPathway = ():
MutationTuple<IDeleteStudentLearningPathwayResponse, IDeleteStudentLearningPathwayParams> =>
  useMutation<IDeleteStudentLearningPathwayResponse, IDeleteStudentLearningPathwayParams>(
    DELETE_STUDENT_LEARNING_PATHWAY,
  );
