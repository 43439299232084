import { ReactiveVar } from '@apollo/client';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as _ from 'lodash';

import { notificationsVar } from '../../cache/cache';
import {
  emptyRecommendedOccupationCache,
  IRecommendedOccupationsCache,
  IRecommendedOccupationsData, RecommendedOccupationsReactiveVar,
} from '../../cache/learner/recommendedOccupationsReactiveVar';
import { decryptor, encryptor } from '../../utils/decryptor';

interface IRecommendedOccupationHook {
  clearRecommendedOccupationsError: ()=> void;
  setRecommendedOccupationsError : ( errorMessage: string ) => void;
  setRecommendedOccupations : ( recommendedOccupations: IRecommendedOccupationsData[]) => void;
  setFromLocalRecommendedOccupations: () => boolean;
  updateRecommendedOccupations: ( id: string ) => void;
}

const RecommendedOccupationHook = (
  recommendedOccupationVarProp: ReactiveVar<IRecommendedOccupationsCache>,
): IRecommendedOccupationHook => {
  const {
    data: recommendedOccupationData,
    error: recommededOccupationError,
  } = recommendedOccupationVarProp();
  const clearRecommendedOccupationsError = (): void => {
    RecommendedOccupationsReactiveVar( emptyRecommendedOccupationCache );
  };

  const setRecommendedOccupationsError = ( errorMessage: string ):void => {
    RecommendedOccupationsReactiveVar({ ...emptyRecommendedOccupationCache, error: errorMessage });
  };

  const setRecommendedOccupations = ( recommendedOccupations: IRecommendedOccupationsData[]):void => {
    const sortedData = recommendedOccupations.slice().sort(
      ( occupation, occupation2 ) => occupation2.match - occupation.match,
    );

    const encryptedData = encryptor( JSON.stringify( sortedData ));

    RecommendedOccupationsReactiveVar(
      {
        error: '',
        data: sortedData,
      },
    );

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'recommendedOccupations', encryptedData );
  };

  const setFromLocalRecommendedOccupations = ():boolean => {
    const dataLocal = sessionStorage.getItem( 'recommendedOccupations' );

    const decryptedData = decryptor( dataLocal ?? '' );

    if ( !decryptedData ) {
      return false;
    }
    RecommendedOccupationsReactiveVar(
      {
        error: '',
        data: JSON.parse( decryptedData ),
      },
    );

    return true;
  };

  const updateRecommendedOccupations = ( id: string ):void => {
    if ( recommededOccupationError ) {
      notificationsVar([
        {
          id: 'updateRecommendedOccupations',
          title: recommededOccupationError,
          description: recommededOccupationError,
          autoClose: true,
          leftIcon: <WarningAmberRoundedIcon color="error" />,
        },
      ]);
    } else if ( recommendedOccupationData ) {
      setRecommendedOccupations(
        _.cloneDeep( recommendedOccupationData.filter(( occupation ) => occupation.jobTitleId !== id )),
      );
    }
  };

  return {
    clearRecommendedOccupationsError,
    setRecommendedOccupationsError,
    setRecommendedOccupations,
    setFromLocalRecommendedOccupations,
    updateRecommendedOccupations,
  };
};

export default RecommendedOccupationHook;
