import {
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { publicGraphQLURL } from '../../../config';
import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_FEATURE_COMPLETE_ATTRIBS = `
    getFeatureNameAndValue(feature: $feature) {        
        feature
        active
        featureValue
    }
`;

export const GET_FEATURE_COMPLETE = gql`
    query GetFeatureComplete($feature: String!) {
        ${GET_FEATURE_COMPLETE_ATTRIBS}
    }
`;

interface IGetFeatureCompleteParams {
  feature: string,
}

export interface IGetFeatureComplete {
  getFeatureNameAndValue: {
    feature: string,
    active: boolean,
    featureValue: string,
  },
}
export const useLazyFeatureComplete = ( fromCache?: boolean ):
QueryTuple<IGetFeatureComplete, IGetFeatureCompleteParams> =>
  useLazyQuery<IGetFeatureComplete, IGetFeatureCompleteParams>(
    GET_FEATURE_COMPLETE, { ...getPolices( fromCache ), context: { uri: publicGraphQLURL } },
  );
export const useQueryFeatureComplete = (
  feature: string, fromCache?: boolean,
): QueryResult<IGetFeatureComplete, IGetFeatureCompleteParams> =>
  useQuery<IGetFeatureComplete, IGetFeatureCompleteParams>(
    GET_FEATURE_COMPLETE, {
      ...getPolices( fromCache ),
      context: {
        uri: publicGraphQLURL,
      },
      variables: {
        feature,
      },
    },
  );
