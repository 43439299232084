import { gql, MutationTuple, useMutation } from '@apollo/client';

export const DELETE_COURSE_IN_STUDENT_LEARNING_PATHWAY = gql`
mutation DeleteStudentCourseInLearningPathway($pathwayCourseId: NonEmptyString!) {
  deleteStudentCourseInLearningPathway(pathwayCourseId: $pathwayCourseId)
}
`;

interface ICourseInStudentLearningPathwayInterface {
  pathwayCourseId: string;
}

interface ICourseInStudentLearningPathwayResponse {
  deleteStudentCourseInLearningPathway: boolean;
}

export const useMutationDeleteCourseInStudentLearningPath = ():
MutationTuple<ICourseInStudentLearningPathwayResponse, ICourseInStudentLearningPathwayInterface> =>
  useMutation<ICourseInStudentLearningPathwayResponse, ICourseInStudentLearningPathwayInterface>(
    DELETE_COURSE_IN_STUDENT_LEARNING_PATHWAY,
  );
