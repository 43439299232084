import { subscribeConnectionReactiveVar } from '../../cache/common/subscribeConnectionReactiveVar';

interface ISubscribeConnectionHook {
  setSubscribeConnection: ( connected: boolean ) => void;
}

const SubscribeConnectionHook = (): ISubscribeConnectionHook => ({
  setSubscribeConnection: ( connected: boolean ) => subscribeConnectionReactiveVar( connected ),
});

export default SubscribeConnectionHook;
