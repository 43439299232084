import { useReactiveVar } from '@apollo/client';
import { Backdrop, CircularProgress } from '@mui/material';

import { loader } from '../../../cache/cache';

const Z_INDEX_DIFF = 4000;

export const LoaderComponent: React.FC = () => {
  const currentLoader = useReactiveVar( loader );

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: ( theme ) => theme.zIndex.drawer + Z_INDEX_DIFF,
      }}
      open={Boolean( currentLoader.length )}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
