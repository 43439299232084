import {
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const GET_FEATURES = gql`
    query Query {
        features
    }
`;

export interface IGetFeatureFlags {
  features: string[];
}

export const useLazyGetFeatureFlags = ( fromCache?: boolean ):
QueryTuple<IGetFeatureFlags, any> =>
  useLazyQuery<IGetFeatureFlags, any>(
    GET_FEATURES, { ...getPolices( fromCache ), context: { URL: publicGraphQLURL } },

  );

export const useQueryGetFeatureFlags = ( fromCache?: boolean ):
QueryResult<IGetFeatureFlags, any> =>
  useQuery<IGetFeatureFlags, any>(
    GET_FEATURES, {
      ...getPolices( fromCache ),
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
