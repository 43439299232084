import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { useEffect } from 'react';

import AccountPopover from './AccountPopover';
import {
  AppBarStyled, ButtonToolbarStyled, GridContainerStyled, ToolbarStyled,
} from './styles/DashboardNavbarStyles';
import TranslationComponent from '../../components/generics/TranslationComponent/TranslationComponent';
import useCourseInstitutionProvidersHook from '../../hooks/courseInstitutionProvidersHook/useCourseInstitutionProvidersHook';
import userAuth from '../../hooks/userAuth';
import { useQueryGetInstitutionCourseProviders } from '../../operations/query/institutions/useGetInstitutionsCourseProvider';

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
  openChangePasswordDialog: VoidFunction;
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({ onOpenSidebar, openChangePasswordDialog }) => {
  const { auth } = userAuth();
  const { setCourseInstitutionProviders } = useCourseInstitutionProvidersHook();
  const { data: courseProvidresData } = useQueryGetInstitutionCourseProviders( auth.advisor?.institutionId ?? '', true );

  useEffect(() => {
    setCourseInstitutionProviders( courseProvidresData?.getInstitutionCourseProviders ?? []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseProvidresData]);

  return (
    <AppBarStyled>
      <ToolbarStyled>
        <ButtonToolbarStyled
          aria-label="Menu button"
          onClick={onOpenSidebar}
        >
          <Icon
            icon={menu2Fill}
          />
        </ButtonToolbarStyled>
        <GridContainerStyled>
          <TranslationComponent />
          <AccountPopover
            openChangePasswordDialog={openChangePasswordDialog}
          />
        </GridContainerStyled>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default DashboardNavbar;
