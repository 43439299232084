import { makeVar } from '@apollo/client';

export interface ICourseProviders {
  id: number;
  institutionId: string;
  providerId: string;
}

export interface ICourseProvidersCache {
  error: string;
  data: ICourseProviders[];
}

export const emptyCourseProvidersCache: ICourseProvidersCache = {
  error: '',
  data: [],
};

export const coursesProvidersReactiveVars = makeVar<ICourseProvidersCache>(
  emptyCourseProvidersCache,
);
