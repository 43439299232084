import { makeVar } from '@apollo/client';

export interface IFormikMajorValues {
  id: string | null;
  name: string | null;
  major: { id: string; name: string; }
}

export interface IFormikValues {
  majors: IFormikMajorValues[];
}

export interface INewStudentMajor {
  studentId: string | undefined;
  majorId: string;
}

export const majorListWizard = makeVar<string[]>([]);
export const newStudentMajorForms = makeVar<INewStudentMajor[]>([]);
export const newFormsMajor = makeVar<IFormikValues>({ majors: [] });
export const successSaveNewMajors = makeVar<boolean>( false );
export const isValidMajorForm = makeVar<boolean>( true );
export const userIdActive = makeVar<string>( '' );
