import { makeVar } from '@apollo/client';

export interface IRecommendedOccupationsData {
  name: string
  match: number
  jobTitleId: string
}
export interface IRecommendedOccupationsCache {
  error: string;
  data: IRecommendedOccupationsData[] | undefined;
}

export const emptyRecommendedOccupationCache: IRecommendedOccupationsCache = {
  error: '',
  data: undefined,
};

export const RecommendedOccupationsReactiveVar = makeVar<IRecommendedOccupationsCache>(
  emptyRecommendedOccupationCache,
);
