export interface IPasswordValidation {
  uppercase: boolean;
  number: boolean;
  length: boolean;
}

export const passwordValidation = ( text?: string ): IPasswordValidation => ({
  uppercase: text ? !!/^(?=.*[A-Z]).*$/.exec( text ) : false,
  number: text ? !!/^(?=.*\d).*$/.exec( text ) : false,
  length: text ? ( text?.length >= 8 && text?.length < 31 ) : false,
});
