import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import TypographyWrapper from '../../../components/wrappers/TypographyWrapper';
import palette from '../../../theme/learnerPalette';

export const ProfileContainer = styled( GridWrapper )(() => ({
  display: 'flex',
}));

export const AvatarStyled = styled( Avatar )(() => ({
  width: styleConfigs.dimensions.width100,
  height: styleConfigs.dimensions.height100,
  border: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.platinum}`,
  borderRadius: styleConfigs.borders.radius10,
}));

export const ProfileDetails = styled( GridWrapper )(() => ({
  paddingLeft: styleConfigs.marginPadding.custom16,
}));

export const ProfileName = styled( TypographyWrapper )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.bold,
  fontSize: styleConfigs.fonts.fontSize1rem,
  color: palette.light.text.black,
}));

export const ProfileMail = styled( TypographyWrapper )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize0p9rem,
  color: palette.light.text.mistBlue,
  marginTop: styleConfigs.marginPadding.customNegative2,
}));

export const LocationHeader = styled( TypographyWrapper )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize0p7rem,
  color: palette.light.text.mistBlue,
  marginTop: styleConfigs.marginPadding.custom10,
}));

export const ProfileLocation = styled( TypographyWrapper )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize0p9rem,
  color: palette.light.text.black,
}));
