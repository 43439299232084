import Grid, { GridProps } from '@mui/material/Grid';

interface IProps {
  children: React.ReactNode,
}

const GridWrapper: React.FC<IProps & GridProps> = ({ children, ...props }) => (
  <Grid {...props}>
    { children }
  </Grid>
);

export default GridWrapper;
