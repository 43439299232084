import { ApolloError, makeVar } from '@apollo/client';

export interface IJobTitleSkillSetVar {
  skillId: string;
  skillName: string;
  skillType: string;
  skillTypeTranslated?: string;
  jobTitleId: string;
  skillKanbanStatus?: string;
  validatedSkill?: boolean;
}

export interface IRelatedOccupations {
  jobTitleId: string;
  match: number;
  name: string;
}

export interface ISkillMatch {
  jobTitleId: string
  jobTitleName: string
  match: number
  specializedPercentage: number
  humanPercentage: number
  specializedAcquired: number
  specializedTotal: number
  humanAcquired: number
  humanTotal: number
}

export interface IJobPreferencesVar {
  jobTitleId: string;
  jobTitleName: string;
  jobTitleSkillSet: IJobTitleSkillSetVar[];
  skillMatch?: ISkillMatch;
  match?: number;
}

export interface IJobPreferencesCache {
  error: ApolloError | undefined;
  data: IJobPreferencesVar[]
}

export const emptyJobPreferencesCache: IJobPreferencesCache = {
  error: undefined,
  data: [],
};

export const jobPreferencesVar = makeVar<IJobPreferencesCache>( emptyJobPreferencesCache );
