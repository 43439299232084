import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import BoxWrapper from '../../../components/wrappers/BoxWrapper';
import LinkWrapper from '../../../components/wrappers/LinkWrapper';
import useGetPaletteFromWhiteLabel from '../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../theme/learnerPalette';

export const ProfileContainer = styled( 'div' )(() => ({
  display: 'flex',
}));

export const ProfileDetails = styled( 'div' )(() => ({
  width: styleConfigs.dimensions.percent100,
}));

export const ProfileName = styled( 'div' )(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.bold,
  fontSize: styleConfigs.fonts.fontSize16,
  color: palette.light.text.black,
}));

export const ProfileMail = styled( 'p' )(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize15,
  color: palette.light.text.mistBlue,
  marginTop: styleConfigs.marginPadding.customNegative2,
}));

export const LocationHeader = styled( 'p' )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize07em,
  color: palette.light.text.mistBlue,
  marginTop: styleConfigs.marginPadding.custom3,
}));

export const ProfileLocation = styled( 'p' )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize15,
  color: palette.light.text.black,
}));

export const EmptyMajorsLinkStyled = styled( LinkWrapper )(() => ({
  fontSize: styleConfigs.fonts.sizeCustom12,
  color: palette.light.text.yaleBlue,
}));

export const BoxAvatar = styled( BoxWrapper )((
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColor = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;

  return {
    width: styleConfigs.dimensions.width108,
    height: styleConfigs.dimensions.height110,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: backgroundColor,
    borderRadius: styleConfigs.borders.extraSmall,
  };
});
