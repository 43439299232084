// scroll bar
import 'simplebar/src/simplebar.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './i18n';

import { OverlayProvider } from '@react-aria/overlays';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

// contexts
import App from './App';
import { initializeVariables } from './appVariables';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { IkigaiProvider } from './contexts/IkigaiContext';
import { ModalProvider } from './contexts/ModalContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { SettingsProvider } from './contexts/SettingsContext';

initializeVariables();

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ModalProvider>
        <NotificationsProvider>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <OverlayProvider>
                  <IkigaiProvider>
                    <App />
                  </IkigaiProvider>
                </OverlayProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </NotificationsProvider>
      </ModalProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById( 'app' ),
);
