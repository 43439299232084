import {
  MutationTuple,
  gql,
  useMutation,
} from '@apollo/client';

export const DELETE_STUDENT_SKILLS = gql`
  mutation DeleteStudentSkills($studentId: String!, $skillIdList: [String]!, $jobTitleId: String) {
    deleteStudentSkills: deleteStudentSkills(studentId: $studentId, skillIdList: $skillIdList, jobTitleId: $jobTitleId) {
      count
      studentNotification {
        id
        type
        date
      }
    }
  }
`;

interface IDeleteStudentSkillsParams {
  studentId: string,
  skillIdList: string[];
  jobTitleId?: string;
}

interface IDeleteStudentSkillsResponse {
  deleteStudentSkills: {
    count: boolean;
    studentNotification: {
      id: number,
      type: string,
      date: string,
    }
  };
}

export const useMutationDeleteStudentSkills = ():
MutationTuple<IDeleteStudentSkillsResponse, IDeleteStudentSkillsParams> =>
  useMutation<IDeleteStudentSkillsResponse, IDeleteStudentSkillsParams>(
    DELETE_STUDENT_SKILLS,
  );
