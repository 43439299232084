import notificationActionsTypes from '../actionTypes/notificationActionsTypes';
import { INotificationData } from '../reducer/notificationReducer';

export const notificationOpenAction = (
  notificationData: INotificationData,
): { type: string, payload: INotificationData } => ({
  type: notificationActionsTypes.NOTIFICATION_SET_DATA,
  payload: {
    listNotifications: notificationData.listNotifications,
  },
});

export const showNotificationAction = (
  showNotification: boolean,
): { type: string, payload: boolean } => ({
  type: notificationActionsTypes.NOTIFICATION_SHOW,
  payload: showNotification,
});

export const deleteNotificationAction = (
  notificationId: string | number,
): {type: string, payload: string | number} => ({
  type: notificationActionsTypes.NOTIFICATION_DELETE,
  payload: notificationId,
});
