import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import { Icon } from '@iconify/react';
import { Box, Collapse, List } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { NavItemStyle, NavItemIconStyle } from './styles/NavItemStyles';
import userAuth from '../../../hooks/userAuth';
import palette from '../../../theme/learnerPalette';
import { ROLE_ENUM } from '../../../views/Auth/Login/interfaces/IGoogleUser';
import ListItemTextComponent from '../../generics/ListItemTextComponent/ListItemTextComponent';
import { styleConfigs } from '../../resources/styleConfigs';

export interface INavItemProps {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title2: string;
    path2: string;
  }[];
  isVisible?: boolean;
}

interface IFocusStyle{
  border: string;
}

const NavItem: React.FC<INavItemProps> = ( props: INavItemProps ) => {
  const {
    title, path, icon, info, children, isVisible,
  } = props;
  const theme = useTheme();
  const { auth } = userAuth();
  const { pathname } = useLocation();
  const isActiveRoot = path
    ? !!matchPath({ path, end: true }, pathname )
    : false;
  const [open, setOpen] = useState( isActiveRoot );
  const [fromKeyboard, setFromKeyboard] = useState( 0 );
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;

  const KEY_ACTION_MAPS: Record<string, number> = {
    ArrowRight: 1,
    ArrowLeft: 1,
    End: 1,
    Home: 1,
    Tab: 1,
  };

  const handleKeyDown = ( e: React.KeyboardEvent<HTMLInputElement> ): void => {
    const pressedKey = e.key;

    setFromKeyboard( KEY_ACTION_MAPS[pressedKey] ?? 0 );
  };

  const focusStyle = ( fromKeyboardParam: number ): IFocusStyle => {
    if ( fromKeyboardParam !== 0 ) {
      return {
        border: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
      };
    }

    return {
      border: `${styleConfigs.borders.empty}`,
    };
  };

  const handleOpen = (): void => {
    setFromKeyboard( 1 );
    setOpen( !open );
  };

  const activeSubStyled = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
    '&:focus ': focusStyle( fromKeyboard ),
  };

  const LearnerStyled = {
    color: 'secondary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha( theme.palette.secondary.main, theme.palette.action.selectedOpacity ),
    '&:before': { display: 'block' },
    '&:focus ': focusStyle( 0 ),
  };

  const AdvisorStyled = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha( theme.palette.primary.main, theme.palette.action.selectedOpacity ),
    '&:before': { display: 'block' },
    '&:focus ': focusStyle( 0 ),
  };

  return children ? (
    <>
      <NavItemStyle
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        sx={{
          ...(( isActiveRoot && isLearner ) && LearnerStyled ),
          ...(( isActiveRoot && !isLearner ) && AdvisorStyled ),
        }}
      >
        <NavItemIconStyle>{icon}</NavItemIconStyle>
        {isVisible && (
          <>
            <ListItemTextComponent primary={title} />
            {info}
            <Box
              component={Icon}
              icon={open ? arrowIosUpwardFill : arrowIosDownwardFill}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          </>
        )}
      </NavItemStyle>
      {
        isVisible && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map(( item2, index ) => {
                const { path2 } = item2;
                const { title2 } = item2;
                const isActiveSub = path2
                  ? !!matchPath({ path, end: true }, pathname )
                  : false;

                return (
                  <NavItemStyle
                    key={item2.title2.concat( index.toString())}
                    component={RouterLink}
                    to={path2}
                    sx={{
                      ...( isActiveSub && activeSubStyled ),
                    }}
                  >
                    <NavItemIconStyle />
                    <ListItemTextComponent primary={title2} />
                  </NavItemStyle>
                );
              })}
            </List>
          </Collapse>
        )
      }
    </>
  ) : (
    <NavItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(( isActiveRoot && isLearner ) && LearnerStyled ),
        ...(( isActiveRoot && !isLearner ) && AdvisorStyled ),
      }}
    >
      <NavItemIconStyle>{icon}</NavItemIconStyle>
      {isVisible && (
        <>
          <ListItemTextComponent primary={title} />
          {info}
        </>
      )}
    </NavItemStyle>
  );
};

export default NavItem;
