import { ApolloError, makeVar } from '@apollo/client';

import { ILearningPathsByInstitution } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';

export interface ILearningPathsByInstitutionCache {
  error: ApolloError | undefined;
  data: ILearningPathsByInstitution[];
}

export const emptyLearningPathsByInstitutionCache: ILearningPathsByInstitutionCache = {
  error: undefined,
  data: [],
};
export const learningPathsByInstitutionVar = makeVar<ILearningPathsByInstitutionCache>(
  emptyLearningPathsByInstitutionCache,
);
