import { makeVar } from '@apollo/client';

export interface IRecommendedOccupationsByMajors {
  majorId: string,
  majorName: string,
  match: {
    name : string,
    match: number,
    jobTitleId: string
  }[]
}

export interface IRecommendedOccupationsByMajorCache {
  error: string;
  data: IRecommendedOccupationsByMajors[] | undefined;
}

export const emptyRecommendedOccupationsByMajorCache: IRecommendedOccupationsByMajorCache = {
  error: '',
  data: undefined,
};

export const RecommendedOccupationsByMajorReactiveVar = makeVar<IRecommendedOccupationsByMajorCache>(
  emptyRecommendedOccupationsByMajorCache,
);
