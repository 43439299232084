interface IGradientThreeColors{
  position?: string,
  color1?: string,
  opacity1?: string,
  color2?: string,
  opacity2?: string,
  color3?: string,
  opacity3?: string,
}
interface IGradientTwoColors{
  position?: string,
  color1?: string,
  opacity1?: string,
  color2?: string,
  opacity2?: string,
}

const gradientThreeColors: ( Props : IGradientThreeColors ) => string = ({
  position, color1, opacity1, color2, opacity2, color3, opacity3,
}) => `linear-gradient(${position}, ${color1} ${opacity1}, ${color2} ${opacity2}, ${color3} ${opacity3})`;

const gradientTwoColors: ( Props : IGradientTwoColors ) => string = ({
  position, color1, opacity1, color2, opacity2,
}) => {
  const color1Opacity = opacity1 ? `${color1} ${opacity1}` : color1;
  const color2Opacity = opacity2 ? `${color2} ${opacity2}` : color2;

  return `linear-gradient(${position}, ${color1Opacity}, ${color2Opacity})`;
};

const PRIMARY = {
  frenchRose: '#F25A8C',
  frenchRoseA29: '#f25a8c29',
  redViolet: '#D82B9A',
  purplishPink: '#E055AE',
  prussianBlue: '#172638',
  royalFuchsia: '#d82b9a7a',
  cadetGrey: '#919eab3d',
  moonBlueA29: '#10479929',
  lightBlueTranslucent: '#dff0f94d',
  lightBlue: '#5fb2e03d',
  lightBlue05: '#DFF0F9',
  daySkyBlueA80: '#94cbff80',
  moonBlue: '#104799b3',
  coolGrey: '#919eabcc',
  whiteSmoke: '#f4f6f8',
  vividTangerine: '#FFA48D',
  antiqueWhite: '#FFE7D9',
  brightCeruleanA14: '#18a2dc14',
  cascade: '#919eab52',
  transparent: 'transparent',
  curiousBlue: '#18A2DC',
  morningGlory: '#A2D9F1',
  ocean: '#10719A',
  brightCeruleanA29: '#18A2DC29',
  black: '#000000',
  astronautBlue: '#094058',
  ballBlue: '#18a2dc1f',
  whiteA80: '#ffffff80',
  aquaGreen: '#34D399',
  pinkLemonade: '#e02881',
  blueDianne: '#164656',
  deepPink: '#FC248D',
  purpleHeart: '#6D28D9',
  greenPeas: '#68d75b',
  pumpkinOrange: '#f96d21',
  mistBlueA40: '#63738140',
  gableGreen: '#172638bf',
  goldenYellow: '#FFE700',
  balticSea: '#212b363d',
  watermelon: '#fa4447',
  bluishCyan: '#17A2DC',
  none: 'none',

  // News
  darkBlue: '#172738',
  chartSkillTrackerSelect: '#F0F2F4',
  topListColorPrevious: '#406CAD',
  tooltipRankingList: '#454F5B',
  blackSkeleton: '#D9D9D9',
  floatingButton: '#00000040',
  frenchRoseVariant: '#F57BA3',
  greenPathCourses: '#45963B',
  skillTagGreen: '#B1EBAA80',
  carrouselButton: '#7b7b7b3d',
  orangeUva: '#E47404',
  blueUva: '#232D4B',
  skinUva: '#F9DCBF',
  firstGenAIButton: '#8A2BE2',
  secondGenAIButton: '#5761ED',
  thirdGenAIButton: '#00BFFF',
  circleVerticalIkigai: '#B0B9C0',
  intersectCircleBackgroundIkigai: '#FECC01',
  intersectCircleBorderIkigai: '#F7A70D',
};
const SECONDARY = {
  pictonBlue: '#5FB2E0',
  yaleBlue: '#104799',

};
const INFO = {
  paleSkyBlue: '#D0F2FF',
  malibu: '#74CAFF',
  azure: '#1890FF',
};
const SUCCESS = {
  algaeGreen: '#81DE77',
  freshGreen: '#62D655',
  greenTeal: '#10B981',
};
const WARNING = {
  pastelOrange: '#FE9A52',
  blazeOrange: '#FD6F08',
  waxFlower: '#FFBF92',
};
const ERROR = {
  bittersweet: '#FF6D68',
  coralRed: '#FF4842',
};
const GREY = {
  white: '#FFFFFF',
  alabaster: '#F9FAFB',
  springWood: '#F4F6F8',
  platinum: '#DFE3E8',
  jungleMist: '#C4CDD5',
  amethyst: '#919EAB',
  mistBlue: '#637381',
  ebonyClay: '#212B36',
  mirage: '#161C24',
  buttonGreyDisabled: '#919EAB1A',
  listSelected: '#0000000A',
};

const ICONS = {
  redViolet: PRIMARY.redViolet,
  purplishPink: PRIMARY.purplishPink,
  prussianBlue: PRIMARY.prussianBlue,
  moonBlue: PRIMARY.moonBlue,
  freshGreen: SUCCESS.freshGreen,
  coralRed: ERROR.coralRed,
  coolGrey: PRIMARY.coolGrey,
  white: GREY.white,
  jungleMist: GREY.jungleMist,
  mistBlue: GREY.mistBlue,
  ebonyClay: GREY.ebonyClay,
  curiousBlue: PRIMARY.curiousBlue,
  amethyst: GREY.amethyst,
  greenTeal: SUCCESS.greenTeal,
};
const BORDERS = {
  frenchRose: PRIMARY.frenchRose,
  redViolet: PRIMARY.redViolet,
  royalFuchsia: PRIMARY.royalFuchsia,
  cadetGrey: PRIMARY.cadetGrey,
  vividTangerine: PRIMARY.vividTangerine,
  coralRed: ERROR.coralRed,
  cascade: PRIMARY.cascade,
  transparent: PRIMARY.transparent,
  yaleBlue: SECONDARY.yaleBlue,
  white: GREY.white,
  platinum: GREY.platinum,
  curiousBlue: PRIMARY.curiousBlue,
  black: PRIMARY.black,
  lightBlue: PRIMARY.lightBlue,
  prussianBlue: PRIMARY.prussianBlue,
  amethyst: GREY.amethyst,
  frenchRoseVariant: PRIMARY.frenchRoseVariant,
  jungleMist: GREY.jungleMist,
  tooltipRankingList: PRIMARY.tooltipRankingList,
  greenPathCourses: PRIMARY.greenPathCourses,
  pictonBlue: SECONDARY.pictonBlue,
  orangeUva: PRIMARY.orangeUva,
  mistBlue: GREY.mistBlue,
  intersectCircleBorderIkigai: PRIMARY.intersectCircleBorderIkigai,
  firstGenAIButton: PRIMARY.firstGenAIButton,
  secondGenAIButton: PRIMARY.secondGenAIButton,
  thirdGenAIButton: PRIMARY.thirdGenAIButton,
  none: PRIMARY.none,
};
const BOXSHADOWS = {
  prussianBlue: PRIMARY.prussianBlue,
  cadetGrey: PRIMARY.cadetGrey,
  daySkyBlueA80: PRIMARY.daySkyBlueA80,
  mistBlueA40: PRIMARY.mistBlueA40,
  floatingButton: PRIMARY.floatingButton,
  carrouselButton: PRIMARY.carrouselButton,
};
const LINEARPROGRESS = {
  pictonBlue: SECONDARY.pictonBlue,
};
const CIRCULARPROGRESS = {
  jungleMist: GREY.jungleMist,
  redViolet: PRIMARY.redViolet,
  prussianBlue: PRIMARY.prussianBlue,
  yaleBlue: SECONDARY.yaleBlue,
};
const CHECKBOXS = {
  purplishPink: PRIMARY.purplishPink,
};
const CHARTS = {
  // #region DO NOT DELETED
  blue: ['blue'],
  green: ['green'],
  red: ['red'],
  violet: ['violet'],
  yellow: ['yellow'],
  // #endregion
  frenchRose: PRIMARY.frenchRose,
  freshGreen: SUCCESS.freshGreen,
  blazeOrange: WARNING.blazeOrange,
  coralRed: ERROR.coralRed,
  pictonBlue: SECONDARY.pictonBlue,
  springWood: GREY.springWood,
  curiousBlue: PRIMARY.curiousBlue,
  black: PRIMARY.black,
  deepPink: PRIMARY.deepPink,
  purpleHeart: PRIMARY.purpleHeart,
  pumpkinOrange: PRIMARY.pumpkinOrange,
  greenPeas: PRIMARY.greenPeas,
  balticSea: PRIMARY.balticSea,
  watermelon: PRIMARY.watermelon,
  transparent: PRIMARY.transparent,
  mistBlue: GREY.mistBlue,
  yaleBlue: SECONDARY.yaleBlue,
  amethyst: GREY.amethyst,
  chartSkillTrackerSelect: PRIMARY.chartSkillTrackerSelect,
  redViolet: PRIMARY.redViolet,
};
const GRADIENTS = {
  // #region DO NOT DELETED
  white: gradientTwoColors({ position: '90deg', color1: 'white', color2: 'white' }),
  primary: gradientThreeColors({ color1: '#fff' }),
  info: gradientThreeColors({ color1: '#fff' }),
  success: gradientThreeColors({ color2: '#fff' }),
  warning: gradientThreeColors({ color3: '#fff' }),
  error: gradientThreeColors({ color3: '#fff' }),
  // #endregion
  chatGPT: gradientThreeColors({
    position: '113.7deg',
    color1: PRIMARY.frenchRose,
    opacity1: '0%',
    color2: SECONDARY.yaleBlue,
    opacity2: '52.46%',
    color3: SECONDARY.pictonBlue,
    opacity3: '94.14%',
  }),
  leftContentLogin: gradientTwoColors({
    position: '135deg',
    color1: SECONDARY.yaleBlue,
    opacity1: '-20%',
    color2: PRIMARY.frenchRose,
    opacity2: '100%',
  }),
  genAIButton: gradientThreeColors({
    position: '90deg',
    color1: PRIMARY.firstGenAIButton,
    opacity1: '0%',
    color2: PRIMARY.secondGenAIButton,
    opacity2: '50%',
    color3: PRIMARY.thirdGenAIButton,
    opacity3: '100%',
  }),
};
const COMMON = {
  icons: { ...ICONS },
  borders: { ...BORDERS },
  boxShadows: { ...BOXSHADOWS },
  linearProgress: { ...LINEARPROGRESS },
  circularProgress: { ...CIRCULARPROGRESS },
  checkboxs: { ...CHECKBOXS },
  chart: CHARTS,
  gradients: GRADIENTS,
};

const palette = {
  light: {
    ...COMMON,
    text: {
      // #region DO NOT DELETED
      primary: PRIMARY.prussianBlue,
      secondary: PRIMARY.frenchRose,
      // #endregion
      frenchRose: PRIMARY.frenchRose,
      redViolet: PRIMARY.redViolet,
      prussianBlue: PRIMARY.prussianBlue,
      moonBlue: PRIMARY.moonBlue,
      coralRed: ERROR.coralRed,
      coolGrey: PRIMARY.coolGrey,
      pictonBlue: SECONDARY.pictonBlue,
      yaleBlue: SECONDARY.yaleBlue,
      white: GREY.white,
      jungleMist: GREY.jungleMist,
      amethyst: GREY.amethyst,
      mistBlue: GREY.mistBlue,
      ebonyClay: GREY.ebonyClay,
      curiousBlue: PRIMARY.curiousBlue,
      ocean: PRIMARY.ocean,
      black: PRIMARY.black,
      astronautBlue: PRIMARY.astronautBlue,
      whiteA80: PRIMARY.whiteA80,
      blueDianne: PRIMARY.blueDianne,
      greenPeas: PRIMARY.greenPeas,
      greenTeal: SUCCESS.greenTeal,
      ballBlue: PRIMARY.ballBlue,
      darkBlue: PRIMARY.darkBlue,
      topListColorPrevious: PRIMARY.topListColorPrevious,
      tooltipRankingList: PRIMARY.tooltipRankingList,
      frenchRoseVariant: PRIMARY.frenchRoseVariant,
      orangeUva: PRIMARY.orangeUva,
      blueUva: PRIMARY.blueUva,
      greenPathCourses: PRIMARY.greenPathCourses,
    },
    background: {
      // #region DO NOT DELETED
      paper: '#fff',
      // #endregion
      frenchRose: PRIMARY.frenchRose,
      frenchRoseA29: PRIMARY.frenchRoseA29,
      redViolet: PRIMARY.redViolet,
      prussianBlue: PRIMARY.prussianBlue,
      cadetGrey: PRIMARY.cadetGrey,
      moonBlueA29: PRIMARY.moonBlueA29,
      lightBlue: PRIMARY.lightBlue,
      lightBlueTranslucent: PRIMARY.lightBlueTranslucent,
      whiteSmoke: PRIMARY.whiteSmoke,
      antiqueWhite: PRIMARY.antiqueWhite,
      brightCeruleanA14: PRIMARY.brightCeruleanA14,
      freshGreen: SUCCESS.freshGreen,
      transparent: PRIMARY.transparent,
      pictonBlue: SECONDARY.pictonBlue,
      yaleBlue: SECONDARY.yaleBlue,
      paleSkyBlue: INFO.paleSkyBlue,
      white: GREY.white,
      alabaster: GREY.alabaster,
      springWood: GREY.springWood,
      platinum: GREY.platinum,
      jungleMist: GREY.jungleMist,
      amethyst: GREY.amethyst,
      mirage: GREY.mirage,
      curiousBlue: PRIMARY.curiousBlue,
      morningGlory: PRIMARY.morningGlory,
      ocean: PRIMARY.ocean,
      brightCeruleanA29: PRIMARY.brightCeruleanA29,
      ballBlue: PRIMARY.ballBlue,
      aquaGreen: PRIMARY.aquaGreen,
      pinkLemonade: PRIMARY.pinkLemonade,
      gableGreen: PRIMARY.gableGreen,
      mistBlue: GREY.mistBlue,
      coralRed: ERROR.coralRed,
      waxFlower: WARNING.waxFlower,
      watermelon: PRIMARY.watermelon,
      bluishCyan: PRIMARY.bluishCyan,
      purplishPink: PRIMARY.purplishPink,
      ebonyClay: GREY.ebonyClay,
      black: PRIMARY.black,
      blackSkeleton: PRIMARY.blackSkeleton,
      tooltipRankingList: PRIMARY.tooltipRankingList,
      greenPathCourses: PRIMARY.greenPathCourses,
      skillTagGreen: PRIMARY.skillTagGreen,
      blueUva: PRIMARY.blueUva,
      skinUva: PRIMARY.skinUva,
      orangeUva: PRIMARY.orangeUva,
      circleVerticalIkigai: PRIMARY.circleVerticalIkigai,
      lightBlue05: PRIMARY.lightBlue05,
      buttonGrayDisabled: GREY.buttonGreyDisabled,
      intersectCircleBackgroundIkigai: PRIMARY.intersectCircleBackgroundIkigai,
      none: PRIMARY.none,
      listSelected: GREY.listSelected,
    },
    action: {
      // #region DO NOT DELETED
      active: PRIMARY.frenchRose,
      // #endregion
      mistBlue: GREY.mistBlue,
    },
  },
  dark: {
    ...COMMON,
    text: {
      // #region DO NOT DELETED
      primary: '#000',
      // #endregion
      amethyst: GREY.amethyst,
      mistBlue: GREY.mistBlue,
    },
    background: {
      // #region DO NOT DELETED
      paper: '#fff',
      // #endregion
      ebonyClay: GREY.ebonyClay,
      mirage: GREY.mirage,
    },
    action: {
      // #region DO NOT DELETED
      active: '#fff',
      // #endregion
      amethyst: GREY.amethyst,
    },
  },
};

export default palette;

export const MATERIAL_PRIMARY = {
  lighter: PRIMARY.frenchRoseA29,
  light: SECONDARY.pictonBlue,
  main: PRIMARY.redViolet,
  dark: '#406CAD',
  darker: '#7091C2',
};
