import { ApolloError, ReactiveVar } from '@apollo/client';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { notificationsVar, occupationPreferenceId } from '../../cache/cache';
import {
  emptyJobPreferencesCache, IJobPreferencesCache, IJobPreferencesVar, IJobTitleSkillSetVar, jobPreferencesVar,
} from '../../cache/learner/jobPreferencesReactiveVar';
import {
  IKanbanCache, IKanbanVar, ISkillKanban, StudentSkillStatus,
} from '../../cache/learner/kanbanReactiveVar';
import { RecommendedOccupationsByMajorReactiveVar } from '../../cache/learner/recommendedOccupationsByMajorReactiveVar';
import { RecommendedOccupationsReactiveVar } from '../../cache/learner/recommendedOccupationsReactiveVar';
import { RelatedOccupationsReactiveVar } from '../../cache/learner/relatedOccupationsReactiveVar';
import { SkillStatusEnum } from '../../constants/enums/SkillStatusEnum';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { MixpanelEventsEnum } from '../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import {
  IJobPreferencesQueryHook,
  IJobTitleSkillSet,
} from '../../operations/query/students/useGetLearnerEntireProfile/useGetLearnerEntireProfile';
import {
  getJobRelatedOccupations, IRelatedOccupations, useLazyGetJobRelatedOccupations,
} from '../../operations/query/students/useGetRelatedOccupations';
import { decryptor, encryptor } from '../../utils/decryptor';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import RecommendedOccupationsByMajorHook from '../recommendedOccupationsByMajorHook/recommendedOccupationsByMajorHook';
import RecommendedOccupationHook from '../RecommendedOccupationsHook/recommendedOccupationsHook';
import useLogger from '../useLogger/useLogger';
import userAuth from '../userAuth';
import useStudentRefetch from '../useStudentRefetch';

interface IUseJobPreferencesDataHook {
  operations: {
    setJobPreferences: ( jobPreferences: IJobPreferencesQueryHook[]) => void;
    setJobPreferencesError: ( errorMessage: ApolloError | undefined ) => void;
    clearJobPreferencesError: () => void;
    getSkillSetByJobTitle: ( jobTitleId: string ) => IJobTitleSkillSetVar[];
    getOccupationChanges: ( skillId: string ) => string;
    filterJobPreferencesById: ( filterId: string ) => IJobPreferencesVar | undefined;
    setJobPreferencesRelatedOccupations: ( relatedOccupations: IRelatedOccupations[]) => void;
    setFromLocalJobPreferences: () => boolean;
    addSkillAndRecalculateMatch: ( skillId: string, added?: boolean ) => void;
    addJobPreference: (
      jobPreference: IJobPreferencesVar,
      mixPanelData: { component: string; replaced: boolean; recommendedBy: string },
    ) => void;
    deleteJobPreference: ( jobTitleId: string, component: string ) => void;
    filterJobPreferencesByCurrentId: (
      currentJobTitleId: string,
      skillsKanban: IKanbanVar,
    ) => IJobPreferencesVar | undefined;
    calculateJobPreferenceMatch: ( jobPreferenceId: string ) => number;
    replaceJobPreference: (
      replaceId: string,
      jobPreferenceToAdd: IJobPreferencesVar,
      mixPanelData: { component: string; replaced: boolean; recommendedBy: string },
    ) => Promise<void>;
    occupationsThatContainSkill: ({ skillName }: {skillName: string}) => string,
    getNumberOfOccupationsAffected: ( skillName: string ) => number,
  };
}

enum SkillTypeEnum {
  SPECIALIZED = 'specialized',
  HUMAN = 'human',
}

interface ISkillsCounter {
  specializedAcquired: number;
  specializedTotal: number;
  humanAcquired: number;
  humanTotal: number;
}

const useJobPreferencesDataHook = (
  jobPreferencesVarProp: ReactiveVar<IJobPreferencesCache>,
  kanbanVarProp: ReactiveVar<IKanbanCache>,
): IUseJobPreferencesDataHook => {
  const jobPreferencesData = jobPreferencesVarProp();
  const kanbanData = kanbanVarProp();
  const { auth } = userAuth();
  const { pathname, search } = useLocation();
  const { operations } = useLogger();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const { t } = useTranslation( 'useJobPreferencesDataHookConfig' );
  const { updateRecommendedOccupations } = RecommendedOccupationHook( RecommendedOccupationsReactiveVar );
  const { updateRecommendedOccupationsByMajor } = RecommendedOccupationsByMajorHook(
    RecommendedOccupationsByMajorReactiveVar,
  );
  const { refetchPathways } = useStudentRefetch();

  const [
    getRelatedOccupations,
    {
      data: relatedOccupationsData,
      error: relatedOccupationsError,
    },
  ] = useLazyGetJobRelatedOccupations( false );

  const getOccupationChanges = ( skillId: string ): string => {
    const occupationsAffected = jobPreferencesData.data.reduce(
      ( count, jobPreference ) =>
        count + ( jobPreference.jobTitleSkillSet.some(( skill ) => skill.skillId === skillId ) ? 1 : 0 ),
      0,
    );

    return occupationsAffected.toString();
  };

  const getSkillSetByJobTitle = ( jobTitleId: string ): IJobTitleSkillSetVar[] => {
    const matchingJobPreference = jobPreferencesData.data.find(
      ( jobPreference ) => jobPreference.jobTitleId === jobTitleId,
    );

    const skillSet = matchingJobPreference?.jobTitleSkillSet || [];

    return skillSet;
  };

  const clearJobPreferencesError = (): void => {
    jobPreferencesVar( emptyJobPreferencesCache );
  };

  const setJobPreferencesError = ( errorMessage: ApolloError | undefined ): void => {
    const copyOfData = _.cloneDeep( jobPreferencesData );

    copyOfData.error = errorMessage;
    jobPreferencesVar( copyOfData );
  };

  const setJobPreferences = ( jobPreferences: IJobPreferencesQueryHook[]): void => {
    if ( jobPreferences ) {
      const data: IJobPreferencesVar[] = jobPreferences?.map(( jobPreference: IJobPreferencesQueryHook ) => ({
        jobTitleId: jobPreference.jobTitleId,
        jobTitleName: jobPreference.jobTitleName,
        jobTitleSkillSet: jobPreference.jobTitleSkillSet.map(( skillSet: IJobTitleSkillSet ) => ({
          skillId: skillSet.skill,
          skillName: skillSet.skillName,
          skillType: skillSet.skillType,
          skillTypeTranslated: skillSet.skillTypeTranslated,
          jobTitleId: skillSet.jobTitle,
          validatedSkill: skillSet.validatedSkill,
        })) || [],
      }));

      const encryptedData = encryptor( JSON.stringify( data ));

      jobPreferencesVar({ error: jobPreferencesData.error, data });

      if ( !encryptedData ) {
        return;
      }

      sessionStorage.setItem( 'jobPreferences', encryptedData );
    }
  };

  const setJobPreferencesRelatedOccupations = ( relatedOccupations: IRelatedOccupations[]): void => {
    const updatedData = jobPreferencesData.data.map(( jobPreference ) => {
      const relatedOccupationData = relatedOccupations
        .find(( relatedOccupation ) => relatedOccupation.jobTitleId === jobPreference.jobTitleId )
        ?.data.slice()
        .sort(( a, b ) => b.match - a.match ) ?? [];

      return {
        ...jobPreference,
        relatedOccupations: relatedOccupationData,
      };
    });

    jobPreferencesVar({ error: jobPreferencesData.error, data: updatedData });
  };

  const setJobPreferencesOrganized = ( data: IJobPreferencesVar[]): void => {
    jobPreferencesVar({ error: jobPreferencesData.error, data });

    const encryptedData = encryptor( JSON.stringify( data ));

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'jobPreferences', encryptedData );
  };

  const addSkillAndRecalculateMatch = ( skillId: string, added?:boolean ): void => {
    const copyOfData = _.cloneDeep( jobPreferencesData.data );

    copyOfData.forEach(( jobPreference ) => {
      const skillToChange = jobPreference.jobTitleSkillSet.find(( skill ) => skill.skillId === skillId );
      const jobPreferenceToChange = copyOfData.find(({ jobTitleId }) => jobTitleId === jobPreference.jobTitleId );
      const skillToModify = jobPreferenceToChange?.jobTitleSkillSet.find(( skill ) => skill.skillId === skillId );

      if ( skillToChange && jobPreferenceToChange?.skillMatch && skillToModify && jobPreference.skillMatch ) {
        if ( skillToModify.skillType === 'human' ) {
          const previousAcquired = jobPreferenceToChange.skillMatch.humanAcquired;
          const newHumanAcquiredValue = added ? previousAcquired + 1 : previousAcquired - 1;
          const newHumanPercentage = calculatePercentage(
            newHumanAcquiredValue, jobPreference.skillMatch.humanTotal,
          );

          const newMatch = calculatePercentage(
            ( newHumanAcquiredValue + jobPreference.skillMatch.specializedAcquired ),
            ( jobPreference.skillMatch.specializedTotal + jobPreference.skillMatch.humanTotal ),
          );

          jobPreferenceToChange.skillMatch.humanAcquired = newHumanAcquiredValue;
          jobPreferenceToChange.skillMatch.humanPercentage = newHumanPercentage;
          jobPreferenceToChange.skillMatch.match = newMatch;

          jobPreferenceToChange.match = newMatch;
        } else if ( skillToModify.skillType === 'specialized' ) {
          const previousSpecialized = jobPreferenceToChange.skillMatch.specializedAcquired;
          const newSpecializedAcquiredValue = added ? previousSpecialized + 1 : previousSpecialized - 1;
          const newSpecializedPercentage = calculatePercentage(
            newSpecializedAcquiredValue, jobPreference.skillMatch.specializedTotal,
          );

          const newMatch = calculatePercentage(
            ( newSpecializedAcquiredValue + jobPreference.skillMatch.humanAcquired ),
            ( jobPreference.skillMatch.specializedTotal + jobPreference.skillMatch.humanTotal
            ),
          );

          jobPreferenceToChange.skillMatch.specializedAcquired = newSpecializedAcquiredValue;
          jobPreferenceToChange.skillMatch.specializedPercentage = newSpecializedPercentage;
          jobPreferenceToChange.match = newMatch;
          jobPreferenceToChange.skillMatch.match = newMatch;
        }

        setJobPreferencesOrganized( copyOfData );
      }
    });
  };

  const countSkills = (
    currentJobSkills: IJobTitleSkillSetVar[],
    kanbanSkills: ISkillKanban[],
  ): ISkillsCounter => {
    const counter: ISkillsCounter = {
      specializedAcquired: 0,
      specializedTotal: 0,
      humanAcquired: 0,
      humanTotal: 0,
    };

    const specializedSkills = currentJobSkills.filter(( jobSkill ) =>
      jobSkill.skillType === SkillTypeEnum.SPECIALIZED );
    const humanSkills = currentJobSkills.filter(( jobSkill ) => jobSkill.skillType !== SkillTypeEnum.SPECIALIZED );

    const specializedKanban = kanbanSkills
      .filter(( kanbanSkill ) => kanbanSkill.type === SkillTypeEnum.SPECIALIZED )
      .map(( kanbanSkill ) => kanbanSkill.skillId );

    const humanKanban = kanbanSkills
      .filter(( kanbanSkill ) => kanbanSkill.type !== SkillTypeEnum.SPECIALIZED )
      .map(( kanbanSkill ) => kanbanSkill.skillId );

    counter.specializedAcquired = specializedSkills.reduce(
      ( accumulator, { skillId }) => accumulator + ( specializedKanban.includes( skillId ) ? 1 : 0 ),
      0,
    );
    counter.specializedTotal = specializedSkills.length;
    counter.humanTotal = humanSkills.length;
    counter.humanAcquired = humanSkills.reduce(
      ( accumulator, { skillId }) => accumulator + ( humanKanban.includes( skillId ) ? 1 : 0 ),
      0,
    );

    return counter;
  };

  const calculatePercentage = ( currentSkills: number, allSkills = 0 ): number => (
    allSkills < 1 ? 0 : Math.round(( currentSkills * 100 ) / allSkills )
  );

  const filterJobPreferencesByCurrentId = ( currentJobTitleId: string ): IJobPreferencesVar | undefined => {
    const currentPreference = jobPreferencesData.data.find(
      ( jobPreference ) => jobPreference.jobTitleId === currentJobTitleId,
    );

    if ( !currentPreference ) {
      return undefined;
    }

    const studentSkills = kanbanData.data.find(( column ) => column.id === StudentSkillStatus.COMPLETED )?.items || [];
    const skillCounter = countSkills( currentPreference?.jobTitleSkillSet, studentSkills );

    const specializedAcquiredPercentage = calculatePercentage(
      skillCounter.specializedAcquired + skillCounter.humanAcquired,
      currentPreference.jobTitleSkillSet.length,
    );

    const specializedPercentage = calculatePercentage(
      skillCounter.specializedAcquired,
      skillCounter.specializedTotal,
    );

    const humanPercentage = calculatePercentage( skillCounter.humanAcquired, skillCounter.humanTotal );

    const jobTitleSkillSet = currentPreference.jobTitleSkillSet.map(( skillSet ) => ({
      ...skillSet,
      studentAcquired: !!studentSkills.find(( studentSkill ) => studentSkill.skillId === skillSet.skillId ),
      validatedSkill: !!studentSkills.find(( studentSkill ) =>
        studentSkill.skillId === skillSet.skillId && studentSkill.validatedSkill ),
    }));

    const skillMatch = {
      jobTitleId: currentPreference?.jobTitleId,
      jobTitleName: currentPreference?.jobTitleName,
      match: specializedAcquiredPercentage,
      specializedPercentage,
      humanPercentage,
      specializedAcquired: skillCounter.specializedAcquired,
      specializedTotal: skillCounter.specializedTotal,
      humanAcquired: skillCounter.humanAcquired,
      humanTotal: skillCounter.humanTotal,
    };

    return {
      jobTitleId: currentPreference?.jobTitleId,
      jobTitleName: currentPreference?.jobTitleName,
      match: specializedAcquiredPercentage,
      jobTitleSkillSet,
      skillMatch,
    };
  };

  const filterJobPreferencesById = ( filterId: string ): IJobPreferencesVar | undefined =>
    jobPreferencesData.data.find(( jobPreference ) => jobPreference.jobTitleId === filterId );

  const setFromLocalJobPreferences = (): boolean => {
    const dataLocal = sessionStorage.getItem( 'jobPreferences' );

    const decryptedData = decryptor( dataLocal ?? '' );

    if ( decryptedData ) {
      jobPreferencesVar({ error: jobPreferencesData.error, data: JSON.parse( decryptedData ) });

      return true;
    }

    return false;
  };

  const addJobPreference = (
    jobPreference: IJobPreferencesVar,
    mixPanelData: { component: string; replaced: boolean; recommendedBy: string },
  ): void => {
    const copyOfData = _.cloneDeep( jobPreferencesData.data );

    copyOfData.push( jobPreference );
    setJobPreferencesOrganized( copyOfData );

    updateRecommendedOccupations( jobPreference.jobTitleId );
    updateRecommendedOccupationsByMajor( jobPreference.jobTitleId );
    const jobTitleIds = copyOfData.map(({ jobTitleId }) => jobTitleId ) || [];

    occupationPreferenceId( jobTitleIds[0] || undefined );
    getRelatedOccupations({
      variables: {
        jobTitleIds,
      },
    });
    refetchPathways?.();

    Mixpanel.track({
      action: MixpanelEventsEnum.ADD_OCCUPATION,
      data: {
        screen: `${getMixpanelName( pathname.concat( search ))}`,
        component: mixPanelData.component,
        action: 'click',
        occupationName: jobPreference.jobTitleName,
        replaceAnOccupation: `${mixPanelData.replaced}`,
        recommendedBy: mixPanelData.recommendedBy,
      },
    }, isLearner );
  };

  const deleteJobPreference = ( jobTitleId: string, component: string ): void => {
    const jobTitleSkills = _.cloneDeep( jobPreferencesData.data )
      .find(( jt ) => jt.jobTitleId === jobTitleId )
      ?.jobTitleSkillSet ?? [];
    const otherJobTitlesSkills = _.cloneDeep( jobPreferencesData.data )
      .filter(( jt ) => jt.jobTitleId !== jobTitleId )
      ?.flatMap(({ jobTitleSkillSet }) => jobTitleSkillSet ) ?? [];
    const skillsToDelete = jobTitleSkills.filter(( skillJobTitle ) =>
      !otherJobTitlesSkills.some(( otherSkillJobTitle ) => otherSkillJobTitle.skillId === skillJobTitle.skillId ));
    const studentSkills = _.cloneDeep( kanbanData.data );
    const newBacklogSkills = studentSkills.map(( studentKanban ) => ({
      ...studentKanban,
      items: studentKanban.id !== SkillStatusEnum.BACKLOG
        ? studentKanban.items : studentKanban.items.filter(( item ) =>
          !skillsToDelete.some(( sd ) => sd.skillId === item.skillId )),
    }));

    kanbanVarProp({
      error: kanbanData.error,
      data: newBacklogSkills,
    });

    const copyOfData = _.cloneDeep( jobPreferencesData.data );
    const indexToRemove = copyOfData.findIndex(( jobPreference ) => jobPreference.jobTitleId === jobTitleId );

    if ( indexToRemove > -1 ) {
      const occupationName = copyOfData[indexToRemove].jobTitleName;

      copyOfData.splice( indexToRemove, 1 );
      setJobPreferencesOrganized( copyOfData );
      const jobTitleIds = copyOfData.map(( data ) => data.jobTitleId );

      occupationPreferenceId( jobTitleIds[0] || undefined );

      getRelatedOccupations({
        variables: {
          jobTitleIds,
        },
      });

      refetchPathways?.();

      Mixpanel.track({
        action: MixpanelEventsEnum.DELETE_OCCUPATION,
        data: {
          screen: getMixpanelName( `${pathname}${search}` ),
          component,
          action: 'click',
          occupationName,
        },
      }, isLearner );
    }
  };

  const replaceJobPreference = async (
    replaceId: string,
    jobPreferenceToAdd: IJobPreferencesVar,
    mixPanelData: { component: string; replaced: boolean; recommendedBy: string },
  ): Promise<void> => {
    const copyOfData = _.cloneDeep( jobPreferencesData.data );
    const indexToRemove = copyOfData.findIndex(( jobPreference ) => jobPreference.jobTitleId === replaceId );

    if ( indexToRemove > -1 ) {
      copyOfData.splice( indexToRemove, 1 );
      copyOfData.push( jobPreferenceToAdd );
      setJobPreferencesOrganized( copyOfData );

      try {
        const { data, error } = await getJobRelatedOccupations( copyOfData.map(({ jobTitleId }) => jobTitleId ));

        if ( !error ) {
          if ( data?.relatedOccupations ) {
            RelatedOccupationsReactiveVar({ error: '', data: data.relatedOccupations });
          }

          occupationPreferenceId( copyOfData[0]?.jobTitleId );

          Mixpanel.track({
            action: MixpanelEventsEnum.ADD_OCCUPATION,
            data: {
              screen: getMixpanelName( `${pathname}${search}` ),
              component: mixPanelData.component,
              action: 'click',
              occupationName: jobPreferenceToAdd.jobTitleName,
              replaceAnOccupation: `${mixPanelData.replaced}`,
              recommendedBy: mixPanelData.recommendedBy,
            },
          }, isLearner );
        } else {
          notificationsVar([
            {
              id: error.name,
              title: error.name,
              description: error.message,
              autoClose: true,
              leftIcon: <WarningAmberRoundedIcon color="error" />,
            },
          ]);
        }
      } catch ( error ) {
        notificationsVar([
          {
            id: 'replaceJobPreference',
            title: t( 'ERROR_TITLE_REPLACE' ),
            description: t( 'ERROR_DESCRIPTION_REPLACE' ),
            autoClose: true,
            leftIcon: <WarningAmberRoundedIcon color="error" />,
          },
        ]);

        await operations.writeLogger(
          {
            project: 'front-end',
            source: 'useJobPreferencesDataHook',
            func: 'replaceJobPreferences',
            error: error as string,
          },
        );
      }
    }
  };

  const calculateJobPreferenceMatch = ( jobPreferenceId: string ): number => {
    const occupation = jobPreferencesData.data.find(( jobPreference ) => jobPreference.jobTitleId === jobPreferenceId );

    if ( !occupation?.jobTitleSkillSet ) {
      return 0;
    }

    const studentSkills = kanbanData.data
      .find(( column ) => column.id === StudentSkillStatus.COMPLETED )
      ?.items.map(({ skillId }) => skillId ) ?? [];

    const totalSkills = occupation.jobTitleSkillSet.length;

    const contains = occupation.jobTitleSkillSet.reduce(
      ( value, currentSkill ) => value + ( studentSkills.includes( currentSkill.skillId ) ? 1 : 0 ),
      0,
    );

    return Math.round(( 100 * contains ) / totalSkills );
  };

  useEffect(() => {
    if ( relatedOccupationsError ) {
      notificationsVar([
        {
          id: relatedOccupationsError.name,
          title: relatedOccupationsError.name,
          description: relatedOccupationsError.message,
          autoClose: true,
          leftIcon: <WarningAmberRoundedIcon color="error" />,
        },
      ]);
    } else {
      const relatedData = relatedOccupationsData?.relatedOccupations;

      if ( relatedData ) {
        RelatedOccupationsReactiveVar({ error: '', data: relatedData });
      }
    }
  }, [relatedOccupationsData, relatedOccupationsError]);

  const occupationsThatContainSkill = ({ skillName }: {skillName: string}) : string => {
    const names: string[] = [];

    jobPreferencesData.data.forEach(( jobPreference ) => {
      jobPreference.jobTitleSkillSet.forEach(( skillSet ) => {
        if ( skillSet.skillName === skillName ) {
          names.push( jobPreference.jobTitleName );
        }
      });
    });

    return names.join( ', ' );
  };

  const getNumberOfOccupationsAffected = ( skillName: string ): number => {
    const occupationsAffected = jobPreferencesData.data.reduce(
      ( count, jobPreference ) =>
        count + ( jobPreference.jobTitleSkillSet.some(( skill ) => skill.skillName === skillName ) ? 1 : 0 ),
      0,
    );

    return occupationsAffected;
  };

  return {
    operations: {
      setJobPreferences,
      setJobPreferencesError,
      clearJobPreferencesError,
      getSkillSetByJobTitle,
      getOccupationChanges,
      filterJobPreferencesById,
      setJobPreferencesRelatedOccupations,
      setFromLocalJobPreferences,
      addJobPreference,
      deleteJobPreference,
      filterJobPreferencesByCurrentId,
      addSkillAndRecalculateMatch,
      calculateJobPreferenceMatch,
      replaceJobPreference,
      occupationsThatContainSkill,
      getNumberOfOccupationsAffected,
    },
  };
};

export default useJobPreferencesDataHook;
