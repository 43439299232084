export const dimensions = {
  empty: 0,
  none: 'none',
  auto: 'auto',
  unset: 'unset',
  inherit: 'inherit',
  percent0: '0%',
  percent10: '10%',
  percent20: '20%',
  percent24: '24%',
  percent25: '25%',
  percent27: '27%',
  percent28: '28%',
  percent30: '30%',
  percent32: '32%',
  percent33: '33%',
  percent40: '40%',
  percent50: '50%',
  percent60: '60%',
  percent61: '61%',
  percent62: '62%',
  percent70: '70%',
  percent75: '75%',
  percent80: '80%',
  percent85: '85%',
  percent92: '92%',
  percent90: '90%',
  percent95: '95%',
  percent99: '99%',
  percent100: '100%',
  width0: 0,
  width2p5: 2.5,
  width3: 3,
  width4: 4,
  width5: 5,
  width6: 6,
  width7: 7,
  width8: 8,
  width9: 9,
  width10: 10,
  width11: 11,
  width12: 12,
  width13: 13,
  width14: 14,
  width15: 15,
  width16: 16,
  width17: 17,
  width18: 18,
  width19: 19,
  width20: 20,
  width21: 21,
  width22: 22,
  width23: 23,
  width24: 24,
  width25: 25,
  width26: 26,
  width27: 27,
  width28: 28,
  width29: 29,
  width30: 30,
  width31: 31,
  width32: 32,
  width33: 33,
  width35: 35,
  width36: 36,
  width37: 37,
  width40: 40,
  width41: 41,
  width42: 42,
  width43: 43,
  width44: 44,
  width45: 45,
  width48: 48,
  width49: 49,
  width50: 50,
  width51: 51,
  width54: 54,
  width55: 55,
  width56: 56,
  width57: 57,
  width58: 58,
  width60: 60,
  width64: 64,
  width65: 65,
  width66: 66,
  width68: 68,
  width70: 70,
  width75: 75,
  width71: 71,
  width80: 80,
  width85: 85,
  width86: 86,
  width87: 87,
  width90: 90,
  width94: 94,
  width95: 95,
  width99: 99,
  width100: 100,
  width102: 102,
  width108: 108,
  width111: 111,
  width112: 112,
  width117: 117,
  width118: 118,
  width120: 120,
  width121: 121,
  width124: 124,
  width129: 129,
  width130: 130,
  width135: 135,
  width136: 136,
  width137: 137,
  width140: 140,
  width142: 142,
  width143: 143,
  width144: 144,
  width145: 145,
  width148: 148,
  width150: 150,
  width152: 152,
  width160: 160,
  width164: 164,
  width165: 165,
  width166: 166,
  width170: 170,
  width175: 175,
  width180: 180,
  width184: 184,
  width185: 185,
  width190: 190,
  width191: 191,
  width200: 200,
  width205: 205,
  width206: 206,
  width211: 211,
  width215: 215,
  width220: 220,
  width225: 225,
  width228: 228,
  width230: 230,
  width232: 232,
  width235: 235,
  width240: 240,
  width244: 244,
  width245: 245,
  width248: 248,
  width250: 250,
  width260: 260,
  width270: 270,
  width275: 275,
  width280: 280,
  width282: 282,
  width284: 284,
  width298: 298,
  width300: 300,
  width303: 303,
  width305: 305,
  width310: 310,
  width312: 312,
  width313: 313,
  width319: 319,
  width320: 320,
  width323: 323,
  width330: 330,
  width335: 335,
  width339: 339,
  width344: 344,
  width350: 350,
  width352: 352,
  width360: 360,
  width364: 364,
  width368: 368,
  width370: 370,
  width380: 380,
  width400: 400,
  width405: 405,
  width425: 425,
  width430: 430,
  width435: 435,
  width440: 440,
  width443: 443,
  width450: 450,
  width457: 457,
  width480: 480,
  width494: 494,
  width500: 500,
  width512: 512,
  width521: 521,
  width528: 528,
  width530: 530,
  width552: 552,
  width544: 544,
  width558: 558,
  width565: 565,
  width600: 600,
  width601: 601,
  width620: 620,
  width630: 630,
  width647: 647,
  width649: 649,
  width652: 652,
  width667: 667,
  width673: 673,
  width700: 700,
  width736: 736,
  width750: 750,
  width752: 752,
  width800: 800,
  width900: 900,
  width1000: 1000,
  width1151: 1151,
  width1200: 1200,
  width1536: 1536,
  width50vw: '50vw',
  width55vw: '55vw',
  width60vw: '60vw',
  width66vw: '66vw',
  width75vw: '75vw',
  width85vw: '85vw',
  width90vw: '90vw',
  width100vw: '100vw',
  height1: 1,
  height2: 2,
  height3: 3,
  height4: 4,
  height5: 5,
  height6: 6,
  height8: 8,
  height9: 9,
  height10: 10,
  height11: 11,
  height12: 12,
  height13: 13,
  height14: 14,
  height15: 15,
  height16: 16,
  height17: 17,
  height18: 18,
  height19: 19,
  height20: 20,
  height21: 21,
  height22: 22,
  height23: 23,
  height24: 24,
  height25: 25,
  height26: 26,
  height27: 27,
  height28: 28,
  height29: 29,
  height30: 30,
  height31: 31,
  height32: 32,
  height34: 34,
  height35: 35,
  height36: 36,
  height37: 37,
  height38: 38,
  height40: 40,
  height42: 42,
  height43: 43,
  height44: 44,
  height45: 45,
  height46: 46,
  height48: 48,
  height50: 50,
  height51: 51,
  height54: 54,
  height55: 55,
  height56: 56,
  height58: 58,
  height60: 60,
  height64: 64,
  height65: 65,
  height66: 66,
  height67: 67,
  height68: 68,
  height69: 69,
  height70: 70,
  height74: 74,
  height75: 75,
  height78: 78,
  height79: 79,
  height80: 80,
  height83: 83,
  height84: 84,
  height85: 85,
  height87: 87,
  height88: 88,
  height90: 90,
  height92: 92,
  height93: 93,
  height95: 95,
  height96: 96,
  height97: 97,
  height98: 98,
  height100: 100,
  height104: 104,
  height105: 105,
  height110: 110,
  height112: 112,
  height115: 115,
  height117: 117,
  height118: 118,
  height120: 120,
  height125: 125,
  height126: 126,
  height127: 127,
  height129: 129,
  height130: 130,
  height131: 131,
  height136: 136,
  height140: 140,
  height141: 141,
  height144: 144,
  height145: 145,
  height150: 150,
  height153: 153,
  height154: 154,
  height155: 155,
  height156: 156,
  height160: 160,
  height165: 165,
  height166: 166,
  height168: 168,
  height170: 170,
  height175: 175,
  height179: 179,
  height180: 180,
  height182: 182,
  height185: 185,
  height188: 188,
  height192: 192,
  height194: 194,
  height200: 200,
  height201: 201,
  height202: 202,
  height204: 204,
  height205: 205,
  height212: 212,
  height219: 219,
  height220: 220,
  height222: 222,
  height227: 227,
  height230: 230,
  height232: 232,
  height234: 234,
  height238: 238,
  height240: 240,
  height242: 242,
  height244: 244,
  height250: 250,
  height254: 254,
  height257: 257,
  height260: 260,
  height264: 264,
  height265: 265,
  height280: 280,
  height282: 282,
  height290: 290,
  height294: 294,
  height300: 300,
  height320: 320,
  height324: 324,
  height335: 335,
  height350: 350,
  height364: 364,
  height326: 326,
  height370: 370,
  height392: 392,
  height400: 400,
  height434: 434,
  height438: 438,
  height450: 450,
  height457: 457,
  height459: 459,
  height460: 460,
  height461: 461,
  height489: 489,
  height500: 500,
  height508: 508,
  height560: 560,
  height640: 640,
  height650: 650,
  height700: 700,
  height748: 748,
  height750: 750,
  height850: 850,
  height1500: 1500,
  height1600: 1600,
  height1700: 1700,
  height1800: 1800,
  height28vh: '28vh',
  height37vh: '37vh',
  height40vh: '40vh',
  height50vh: '50vh',
  height60vh: '60vh',
  height63vh: '63vh',
  height70vh: '70vh',
  height75vh: '75vh',
  height80vh: '80vh',
  height85vh: '85vh',
  height90vh: '90vh',
  height100vh: '100vh',
  height30svh: '30svh',
  height40svh: '40svh',
  height75svh: '75svh',
  height100svh: '100svh',
  height10dvh: '10dvh',
  height30dvh: '30dvh',
  height40dvh: '40dvh',
  height60dvh: '60dvh',
  height70dvh: '70dvh',
  height80dvh: '80dvh',
  height90dvh: '90dvh',
  height100dvh: '100dvh',
  iconSize70p31: 70.31,
  iconSize145: 145,
  listAvatarSize: 24,
  minWidthCard: 250,
  fieldHightLarger: 56,
  selectItemList: 150,
  editIcon: 18,
  visibilityIcon: 25,
  coursesEmptyStateHeight: 300,
  firstListCircle: 44,
  modalActionButtonContainerBoxShadowLeft: '0px 0px 2px',
  modalActionButtonContainerBoxShadowRight: '0px 20px 40px -4px',
  skillModal: 480,
  buttonBorderWidth: 1.5,
  topListRowGap: 10,
  fractionColumn3: '1fr 1fr 1fr',
  fractionColumn2: '1fr 1fr',
  fractionColumn1: '1fr',
  oneRem: '1rem',
  twoRem: '2rem',
  fourRem: '4rem',
  modalHeaderLogoMobile: 37,
  modalHeaderLogo: 57,
  providerLogoW: 75,
  providerLogoH: 15,
  gap2: 2,
  breakpoint1370: 1370,
  breakpoint1260: 1260,
  breakpoint1100: 1100,
};
