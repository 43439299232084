import Cookies from 'js-cookie';
import { browserName } from 'react-device-detect';

import { tokenDecode } from './jwtUtils';
import { cookieExpires, ICookies } from '../config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = ( cookieName: ICookies, value: any, expires?: number ): void => {
  if ( browserName === 'Safari' ) {
    const storageAccess = document.hasStorageAccess();

    storageAccess?.then(
      ( hasAccess ) => {
        if ( hasAccess && cookieName === 'token' ) {
          sessionStorage.setItem( 'token', value );
        }
      },
    );
  }
  if ( cookieName === 'token' || cookieName === 'preferredLanguage' ) {
    Cookies.set( cookieName, value, { expires: expires || cookieExpires, sameSite: 'None', secure: true });
    sessionStorage.setItem( cookieName, value );

    return;
  }

  Cookies.set( cookieName, value, { expires: expires || cookieExpires, sameSite: 'None' });
};

export const removeCookie = ( cookieName: ICookies ): void => {
  Cookies.remove( cookieName );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCookie = ( cookieName: ICookies ): any => {
  if ( cookieName === 'token' ) {
    const sessionToken = sessionStorage.getItem( 'token' );

    if ( sessionToken ) {
      return tokenDecode( sessionToken );
    }
  }

  return Cookies.getJSON( cookieName );
};

export const getJwtToken = (): string => (
  sessionStorage.getItem( 'token' ) ?? Cookies.getJSON( 'token' ));

export const cookieToJson = ( cookie: string ): string => {
  const FORMAT_SPACES = 4;
  const output = cookie
    ? cookie.split( /\s*;\s*/ ).reduce(( accumulator: { [key: string]: string }, value ) => {
      const pairList = value.split( /\s*=\s*/ );

      accumulator[pairList[0]] = pairList.splice( 1 ).join( '=' );

      return accumulator;
    }, {})
    : {};

  return JSON.stringify( output, null, FORMAT_SPACES );
};

export const removeAllCookies = (): void => {
  const cookies = document.cookie.split( ';' );

  while ( cookies.length ) {
    const cookie = cookies.shift();

    if ( cookie ) {
      const [name] = cookie.split( '=' );

      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
};
