import './DashboardProfileNavbarConfig';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ProfileNavbarContainerStyled, ProfileNavbarBackButton, ProfileTabsStyled, StackButtonStyled, ProfileTabStyled,
  DividerStyled,
  ArrowBackIconStyled,
} from './styles/DashboardProfileNavbarStyles';
import { MHidden } from '../../../components/template-minimal/@material-extend';
import { INavItemProps } from '../../../components/template-minimal/NavItem/NavItem';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { PATH_DASHBOARD_STUDENT } from '../../../routes/paths';
import sidebarProfileConfiguration from '../SidebarProfileConfiguration/SidebarProfileConfiguration';

const DashboardProfileNavbar: React.FC = ({ children }) => {
  const [value, setValue] = useState<string>( PATH_DASHBOARD_STUDENT.profile.profile );
  const navigate = useNavigate();
  const { t } = useTranslation( 'DashboardProfileNavbar' );
  const { t: tSidebarProfile } = useTranslation( 'SidebarProfileConfiguration' );
  const [iframeActive, setIframeActive] = useState<boolean>( false );
  const { features } = useContext( FeatureFlagsContext );

  useEffect(() => {
    setValue( window.location.pathname );
  }, []);

  useEffect(() => {
    if ( features?.embededInIframe ) {
      setIframeActive( true );
    }
  }, [features?.embededInIframe]);

  return (
    <ProfileNavbarContainerStyled>
      <MHidden width={iframeActive ? 'unset' : 'lgUp'}>
        <ProfileTabsStyled
          value={value}
          variant="scrollable"
          textColor="secondary"
          indicatorColor="secondary"
          scrollButtons
        >
          {sidebarProfileConfiguration( tSidebarProfile ).map(( list ) => {
            const { items } = list;

            return items.map(( item: INavItemProps ) => (
              <ProfileTabStyled
                key={item.title}
                label={item.title}
                value={item.path}
                onClick={() => {
                  setValue( item.path );
                  navigate( item.path );
                }}
              />
            ));
          })}
        </ProfileTabsStyled>
        <DividerStyled />
      </MHidden>
      {children}
      <StackButtonStyled>
        <MHidden width={iframeActive ? 'unset' : 'lgUp'}>
          <ProfileNavbarBackButton
            variant="contained"
            size="large"
            color="secondary"
            startIcon={<ArrowBackIconStyled />}
            onClick={() => {
              navigate( PATH_DASHBOARD_STUDENT.admin.pageHome );
            }}
          >
            {t( 'BACK_TO_DASHBOARD' )}
          </ProfileNavbarBackButton>
        </MHidden>
      </StackButtonStyled>
    </ProfileNavbarContainerStyled>
  );
};

export default DashboardProfileNavbar;
