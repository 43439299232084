import './AccountInfoConfig';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ProfileWrapper, ProfileName, ProfileType } from './AccountBriefStyles';
import { userMajors } from '../../../cache/cache';
import SkeletonComponent from '../../../components/generics/SkeletonComponent/SkeletonComponent';
import userAuth from '../../../hooks/userAuth';

const AccountBrief: React.FC = () => {
  const { auth } = userAuth();
  const userMajorsStore = useReactiveVar( userMajors );
  const studentFullName = `${auth?.student?.name} ${auth?.student?.lastName}`;
  const { t } = useTranslation( 'LearnerAccountInfo' );

  return (
    <ProfileWrapper>
      <ProfileName>
        { studentFullName || t( 'GUEST_DISPLAYNAME' ) }
      </ProfileName>
      {userMajorsStore ? (
        <ProfileType>
          {userMajorsStore[0]?.name}
        </ProfileType>
      ) : ( <SkeletonComponent sizeSkeleton={1} variant="h5" /> )}
    </ProfileWrapper>
  );
};

export default AccountBrief;
