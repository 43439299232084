import './StudentSkilltrackerFilterEnumConfig';
import { capitalizeFirstLetter } from '../../../../../../utils/formatString';

export type StudentSkillType = 'all' | 'specialized' | 'human';

export const StudentSkilltrackerFiltersEnum = {
  ALL: 'all',
  SPECIALIZED: 'specialized',
  HUMAN: 'human',
};

export const StudentSkilltrackerFilters = ( t: any ): { id: string, name: string }[] => [
  {
    id: capitalizeFirstLetter( StudentSkilltrackerFiltersEnum.ALL ),
    name: t( 'ALL' ),
  },
  {
    id: capitalizeFirstLetter( StudentSkilltrackerFiltersEnum.SPECIALIZED ),
    name: t( 'SPECIALIZED' ),
  },
  {
    id: capitalizeFirstLetter( StudentSkilltrackerFiltersEnum.HUMAN ),
    name: t( 'HUMAN' ),
  },
];

export default StudentSkilltrackerFiltersEnum;
