import { makeVar } from '@apollo/client';

export interface IRecommendedSkills {
  id: string
  name: string
  type: string
  percentage: number
  translatedType?: string
}

export interface IRecommendedSKillsCache {
  error: string
  data: IRecommendedSkills[] | undefined
}

export const emptyRecommendedOccupationsCache = {
  data: undefined,
  error: '',
};

export const recommendedSkillsVar = makeVar<IRecommendedSKillsCache>( emptyRecommendedOccupationsCache );
