import { StylesProvider } from '@mui/styles';
import { useMemo, useReducer } from 'react';
import './App.scss';

import ApolloConfig from './apollo/ApolloConfig';
import ListNotificationComponent from './components/generics/ListNotificationComponent/ListNotificationComponent';
import { LoaderComponent } from './components/generics/LoaderComponent/LoaderComponent';
import ModalView from './components/generics/ModalComponent/ModalView';
import PostMessageSso from './components/generics/PostMessageSSO/PostMessageSso';

import MixpanelOnRouteChange from './components/mixpanel/MixpanelOnRouteChange';
import { ProgressBarStyle } from './components/template-minimal/LoadingScreenComponent/LoadingScreen';
import RtlLayout from './components/template-minimal/RtlLayout';
import ScrollToTop from './components/template-minimal/ScrollToTop';
import ThemePrimaryColor from './components/template-minimal/ThemePrimaryColor';
import { AuthContextProvider } from './contexts/AuthContext/AuthContext';
import { FeatureFlagsContext } from './contexts/FeatureFlagsContext';
import { InstitutionCustomTextsProvider } from './contexts/InstitutionCustomTextContext';
import featureFlagReducer, { featuresFlagReducerState } from './contexts/reducer/featureFlagsReducer';
import { SettingsProvider } from './contexts/SettingsContext';
import StudentDataContextProvider from './contexts/StudentDataContext/StudentDataContext';
import Routes from './routes/Routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

const App: React.FC = () => {
  const [state, dispatch] = useReducer( featureFlagReducer, featuresFlagReducerState );

  const value = useMemo(() => ({
    features: state.features,
    setFeatures: ( initialValue: string[]) => {
      dispatch({ type: 'addFeatures', initialValue });
    },
  }), [state.features]);

  return (
    <ApolloConfig>
      <SettingsProvider>
        <StylesProvider>
          <FeatureFlagsContext.Provider value={value}>
            <AuthContextProvider dispatch={dispatch}>
              <StudentDataContextProvider>
                <InstitutionCustomTextsProvider>
                  <ThemeConfig>
                    <ThemePrimaryColor>
                      <RtlLayout>
                        <GlobalStyles />
                        <ProgressBarStyle />
                        <MixpanelOnRouteChange />
                        <ScrollToTop />
                        <Routes />
                        <ModalView />
                        <LoaderComponent />
                        <ListNotificationComponent />
                        <PostMessageSso />
                      </RtlLayout>
                    </ThemePrimaryColor>
                  </ThemeConfig>
                </InstitutionCustomTextsProvider>
              </StudentDataContextProvider>
            </AuthContextProvider>
          </FeatureFlagsContext.Provider>
        </StylesProvider>
      </SettingsProvider>
    </ApolloConfig>
  );
};

export default App;
