import '../ComponentNotificationConfig';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GridNotification, IconBackground, ItalicTextStyled, OccupationTextStyled, SkillTextStyled, TextContainerStyled,
} from './styles/CardNotificationComponentStyles';
import { jobPreferencesVar } from '../../../cache/learner/jobPreferencesReactiveVar';
import { kanbanDataVar } from '../../../cache/learner/kanbanReactiveVar';
import IconComponent from '../../../components/generics/IconComponent/IconComponent';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import StackWrapper from '../../../components/wrappers/StackWrapper';
import TypographyWrapper from '../../../components/wrappers/TypographyWrapper';
import icons from '../../../constants/icons';
import useJobPreferencesDataHook from '../../../hooks/jobPreferencesDataHook/useJobPreferencesDataHook';
import { NotificationType } from '../../../operations/mutations/student/useMutationUpdateStudentSkillStatus/useMutationUpdateStudentSkillStatus';
import palette from '../../../theme/learnerPalette';

interface ICardNotificationComponent{
  notificationDetails: {
    id: number;
    type: NotificationType;
    skillName: string;
    occupationName: string;
    date: string;
    skillStatus: string;
  }
  beforeNotification?: boolean;
  isMobile?: boolean;
}

const CardNotificationComponent: React.FC<ICardNotificationComponent> = ( props ) => {
  const { beforeNotification, notificationDetails, isMobile } = props;
  const { t } = useTranslation( 'ComponentNotification' );
  const {
    skillName, date, occupationName, type, skillStatus,
  } = notificationDetails;
  const { operations } = useJobPreferencesDataHook( jobPreferencesVar, kanbanDataVar );

  const getNotificationIcon = ( notificationType: NotificationType ): ReactElement => {
    switch ( notificationType ) {
      case 'congratulations':
        return (
          <IconBackground>
            <IconComponent
              src={icons.PERFORMANCE_INCREASE_ICON}
            />
          </IconBackground>
        );

      case 'congratulations_no_job':
        return (
          <IconBackground>
            <IconComponent
              src={icons.PERFORMANCE_INCREASE_ICON}
            />
          </IconBackground>
        );

      default:
        return (
          <IconBackground>
            <IconComponent
              src={icons.PERFORMANCE_DOWN_ICON_NEW}
              sx={{ display: 'flex' }}
            />
          </IconBackground>
        );
    }
  };

  const getNotificationContent = (
    notificationType: NotificationType, skill: string, occupation: string, column: string,
  ): JSX.Element => {
    const occupationNumber = operations.getNumberOfOccupationsAffected( skill );

    switch ( notificationType ) {
      case 'congratulations':

        return (
          <TextContainerStyled>
            {
              occupationNumber > 0 ? (
                <>
                  <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
                  { occupationNumber > 1
                    ? t( 'NOTIFICATION_TEXT_NO_LONGER_PART_TEXT', { column: t( column ?? '' ), occupations: occupationNumber.toString() })
                    : t( 'NOTIFICATION_TEXT_NO_COMPLETED_TEXT_SINGULAR', { column: t( column ?? '' ), occupations: occupationNumber.toString() })}
                </>
              ) : (
                <>
                  <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
                  { t( 'NOTIFICATION_TEXT_ADDED_NO_OCCUPATION', { column: t( column ?? '' ) }) }
                </>
              )
            }
          </TextContainerStyled>
        );

      case 'congratulations_no_job':
        return (
          <TextContainerStyled>
            <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
            { t( 'NOTIFICATION_TEXT_IS_COMPLETED_TEXT' ) }
          </TextContainerStyled>
        );

      case 'lookout':
        return (
          <TextContainerStyled>
            <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
            { t( 'NOTIFICATION_TEXT_IN_COLUMN_TEXT' ) }
            <ItalicTextStyled>{` ${column}. `}</ItalicTextStyled>
            { t( 'NOTIFICATION_TEXT_CHANCE_TEXT' ) }
            <OccupationTextStyled variant="button">{` ${operations.occupationsThatContainSkill({ skillName: skill })} `}</OccupationTextStyled>
            { t( 'NOTIFICATION_TEXT_DECREASE_TEXT' ) }
          </TextContainerStyled>
        );

      case 'lookout_no_job':
        return (
          <TextContainerStyled>
            <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
            { t( 'NOTIFICATION_TEXT_IN_COLUMN_TEXT' ) }
            <ItalicTextStyled>{` ${column}. `}</ItalicTextStyled>
            { t( 'NOTIFICATION_TEXT_EXPLORE_TEXT' ) }
          </TextContainerStyled>
        );

      case 'lookout_removed':
        return (
          <TextContainerStyled>
            {
              occupationNumber > 0 ? (
                <>
                  <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
                  {
                    occupationNumber > 1
                      ? t( 'NOTIFICATION_TEXT_NO_LONGER_PART_TEXT', { column: t( column ?? '' ), occupations: occupationNumber.toString() })
                      : t( 'NOTIFICATION_TEXT_NO_LONGER_PART_TEXT_SINGULAR', { column: t( column ?? '' ), occupations: occupationNumber.toString() })
                  }

                </>
              ) : (
                <>
                  <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
                  { t( 'NOTIFICATION_TEXT_NO_LONGER_PART_NO_JOB_TEXT' ) }
                </>
              )
            }
          </TextContainerStyled>
        );

      case 'lookout_removed_no_job':
        return (
          <TextContainerStyled>
            <SkillTextStyled variant="button">{`${skill} `}</SkillTextStyled>
            { t( 'NOTIFICATION_TEXT_NO_LONGER_PART_NO_JOB_TEXT' ) }
          </TextContainerStyled>
        );

      default:
        return <></>;
    }
  };

  return (
    <GridNotification
      container
      flexWrap={isMobile ? 'nowrap' : 'wrap'}
      backgroundcolor={!beforeNotification
        ? palette.light.background.cadetGrey
        : ''}
      py={1}
      px={isMobile ? 1 : 0}
      gap={isMobile ? 1.5 : 0}
    >
      <GridWrapper
        item
        sm={2}
      >
        <StackWrapper
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          {getNotificationIcon( type )}
        </StackWrapper>
      </GridWrapper>
      <GridWrapper
        item
        sm={10}
        justifyContent="center"
        alignItems="flex-start"
      >
        {getNotificationContent( type, skillName, occupationName, skillStatus )}
        <StackWrapper
          direction="row"
          spacing={0.5}
        >
          <WatchLaterIcon
            fontSize="small"
            color="disabled"
          />
          <TypographyWrapper
            variant="body2"
            color={palette.light.text.amethyst}
          >
            {date}
          </TypographyWrapper>
        </StackWrapper>
      </GridWrapper>
    </GridNotification>
  );
};

export default CardNotificationComponent;
