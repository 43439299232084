import { gql, MutationTuple, useMutation } from '@apollo/client';

export const DELETE_STUDENT_NOTIFICATION = gql`
mutation DeleteStudentReadNotifications($studentId: String!) {
  deleteStudentReadNotifications(studentId: $studentId) {
    count
  }
}`;

interface IDeleteNotificationsParams {
  studentId: string;
}
interface IDeleteNotifications {
  count: number;
}
interface IDeleteNotificationsResponse {
  deleteStudentReadNotifications: IDeleteNotifications;
}

export const useMutationDeleteNotifications = ():
MutationTuple<IDeleteNotificationsResponse, IDeleteNotificationsParams> =>
  useMutation<IDeleteNotificationsResponse, IDeleteNotificationsParams>(
    DELETE_STUDENT_NOTIFICATION,
  );
