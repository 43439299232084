import { ApolloError, makeVar } from '@apollo/client';

import { IStudentLearningPathway } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';

export interface IStudentLearningPathsCache {
  error: ApolloError | undefined;
  data: IStudentLearningPathway[];
}

export const emptyStudentLearningPathsCache: IStudentLearningPathsCache = {
  error: undefined,
  data: [],
};
export const studentLearningPathsVar = makeVar<IStudentLearningPathsCache>( emptyStudentLearningPathsCache );
