import {
  Box,
  BoxProps,
} from '@mui/material';
import React from 'react';

import { ListSubheaderStyle } from './styles/NavSectionStyles';
import NavItem from '../NavItem/NavItem';

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title2: string;
    path2: string;
  }[];
  hidden?: boolean
};

export interface ISidebarItemConfig {
  subheader: string;
  items: NavItemProps[];
}

interface INavSectionProps extends BoxProps {
  readonly navConfig: ISidebarItemConfig[];
  isShow?: boolean | undefined;
}

export default function NavSection(
  { navConfig, isShow = true, ...other }: INavSectionProps,
): JSX.Element {
  const renderItems = ( items: NavItemProps[]): React.ReactNode => {
    const itemsToShow = items.filter(( item ) => !item.hidden );

    return (
      <div>
        {itemsToShow.map(( item: NavItemProps ) => (
          <NavItem
            key={item.title}
            title={item.title}
            path={item.path}
            icon={item.icon}
            info={item.info}
            isVisible={isShow}
          />
        ))}
      </div>
    );
  };

  return (
    <Box {...other}>
      {navConfig.map(( list ) => {
        const { subheader, items } = list;

        return (
          <Box key={subheader}>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {renderItems( items )}
          </Box>
        );
      })}
    </Box>
  );
}
