const locale = {
  TO_LEARN: 'GAPS',
  LEARNING: 'UPSKILLING OBJECTIVES',
  COMPLETED: 'COMPLETED',
  OOPS: 'Oops!',
  YOU_ALREADY_HAVE_THIS_SKILL: 'You already have this skill',
  YOU_ADDED: 'You added',
  YOU_MOVED: 'You moved',
  YOU_DELETED: 'You deleted',
  ADD_SKILL_NOTIFICATION: '{{skill}} has been added',
  DRAG_SKILL_NOTIFICATION: '{{skill}} has been moved',
  REMOVED_SKILL_NOTIFICATION: '{{skill}} has been removed',
  TO_LEARN_SKILL_TRACKER: 'to the column to learn in your skill tracker',
  TO_LEARNING_SKILL_TRACKER: 'to the column learning in your skill tracker',
  FROM_LEARN_SKILL_TRACKER: 'from the column to learn in your skill tracker',
  FROM_LEARNING_SKILL_TRACKER: 'from the column learning in your skill tracker',
  CONGRATULATIONS: 'This skill update has increased your match for {{numberOccupations}} occupations!',
  LOOKOUT_NOTIFICATION: 'This action has decreased your match for {{numberOccupations}} occupations!',
  SOMETHING_WENT_WRONG: 'Something went wrong please try again',
  SOMETHING_WENT_WRONG_PLAIN: 'Something went wrong',
  ERROR: 'Error',
};

export default locale;
