/**
 * For user data received from Google Login
 */
export interface IGoogleUser {
  kind: string;
  // Primary Key
  localId: string;
  // User email for login
  email: string;
  // User name
  displayName?: string;
  // For auth another api's
  idToken: string;
  // If user resgistered
  registered: boolean;
  // For refresh token auth
  refreshToken: string;
  // End token date
  expiresIn: string;
}

export interface IGoogleLoginResponse {
  // Primary Key
  uid: string,
  // User email for login
  email: string;
  stsTokenManager: IGoogleTokenManager;
  // If email was verified
  emailVerified: boolean;
  // Last login int date
  lastLoginAt?: string;
  // Name of user
  displayName?: string;
}

// Google token content
interface IGoogleTokenManager {
  accessToken: string;
  apiKey: string;
  expirationTime: number;
  refreshToken: string;
}

interface IInstitution {
  institutionId: string | undefined;
  institutionName: string | undefined;
  otherInstitutionName: string | undefined;
}

export interface ILoginAdvisor {
  institutionId: string;
  institutionName: string;
}

export interface ILoginUserSuccessResponse {
  refreshToken: string;
  exp?: number;
  expiresIn: number;
  remember: boolean;
  username?: string;
  uid?: string;
  displayName?: string;
  onBoardingCompleted: boolean;
  privacyPolicyAccepted?: boolean;
  email: string;
  student?: {
    id: string;
    email: string;
    name: string;
    lastName: string;
    studentType?: string;
  };
  studentInstitution?: IInstitution;
  advisor?: ILoginAdvisor;
  userRole: string;
  jwtToken?: string;
  features: string[];
  preferredLanguage: string;
  country?: string;
  redirectLogoutUrl?: string;
}

export interface ILoginResponse {
  jwtToken?: string;
}

export const ROLE_ENUM = {
  STUDENT: 'student',
  ADMIN: 'admin',
};

export interface IRememberData {
  email: string
  remember: boolean
}
