import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import baselineMenu from '@iconify/icons-ic/baseline-menu';
import { Icon } from '@iconify/react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';

import AccountPopover from './AccountPopover';
import {
  AppBarStyled,
  ToolbarStyled,
  ButtonToolbarStyled,
  GridContainerStyled,
} from './styles/DashboardNavbarStyles';
import { kanbanSkillType, kanbanJobTitleId } from '../../cache/cache';
import { renderNotification } from '../../cache/learner/homeReactiveVars';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import CircularLoaderComponent from '../../components/generics/CircularLoaderComponent/CircularLoaderComponent';
import TranslationComponent from '../../components/generics/TranslationComponent/TranslationComponent';
import useKanbanDataHook from '../../hooks/kanbanDataHook/useKanbanDataHook';
import userAuth from '../../hooks/userAuth';
import useSetDataFromLocalStorage from '../../hooks/useSetDataFromLocalStorage/useSetDataFromLocalStorage';
import { useLazyGetStudentKanbanSkilltrackerAll } from '../../operations/query/students/useGetStudentKanbanSkillTrackerAll/useGetStudentKanbanSkilltrackerAll';
import { useQueryGetStudentNotification } from '../../operations/query/students/useGetStudentNotification';
import { PATH_DASHBOARD_STUDENT } from '../../routes/paths';
import NotificationComponent from '../components/NotificationComponent/NotificationComponent';

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
  openChangePasswordDialog: VoidFunction;
  iframeActive: boolean;
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({ onOpenSidebar, openChangePasswordDialog, iframeActive }) => {
  const isLearnerProfile = window.location.pathname.includes( PATH_DASHBOARD_STUDENT.profile.profile );
  const { auth } = userAuth();
  const {
    data: studentNotificationData,
    loading: studentNotificationLoading,
  } = useQueryGetStudentNotification( auth?.student?.id ?? '' );
  const { operations: kanbanOps } = useKanbanDataHook( kanbanDataVar, kanbanSkillType, kanbanJobTitleId );
  const { operations: setDataLocalOps } = useSetDataFromLocalStorage();
  const [getKanbanData, { data: dataKanban }] = useLazyGetStudentKanbanSkilltrackerAll( true );
  const smScreen: boolean = useMediaQuery( useTheme().breakpoints.down( 'sm' ));

  const loadKanbanData = (): void => {
    getKanbanData({
      variables: {
        filters: ['specialized', 'human'],
        studentId: auth?.student?.id ?? '',
      },
    });
  };

  useEffect(() => {
    if ( !studentNotificationLoading && studentNotificationData?.getStudentNotification ) {
      renderNotification( studentNotificationData?.getStudentNotification );
    }
  }, [studentNotificationLoading]);

  useEffect(() => {
    if ( auth?.student?.id ) {
      if ( setDataLocalOps.setDataFromLocal()) {
        return;
      }
      loadKanbanData();
    }
  }, []);

  useEffect(() => {
    if ( dataKanban?.getStudentKanbanSkilltrackerAll ) {
      kanbanOps.setKanbanData( dataKanban?.getStudentKanbanSkilltrackerAll );
    }
  }, [dataKanban]);

  return (
    <AppBarStyled
      title="1Mentor"
      iframeactive={iframeActive ? 1 : 0}
    >
      <ToolbarStyled
        learnerprofile={isLearnerProfile ? 1 : 0}
        iframeactive={iframeActive ? 1 : 0}
      >
        {
          !isLearnerProfile && (
            <ButtonToolbarStyled
              title="Menu Icon"
              iframeactive={iframeActive ? 1 : 0}
              onClick={onOpenSidebar}
            >
              <Icon icon={!smScreen ? menu2Fill : baselineMenu} style={{ padding: 'px' }} />
            </ButtonToolbarStyled>
          )
        }
        <GridContainerStyled>
          <TranslationComponent />
          {studentNotificationLoading ? (
            <CircularLoaderComponent
              isLoading
              color="small-dark"
              type="withoutText"
              background="transparent"
            />
          ) : (
            <NotificationComponent />
          )}
          <AccountPopover
            openChangePasswordDialog={openChangePasswordDialog}
          />
        </GridContainerStyled>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default DashboardNavbar;
