const locale = {
  RECOMMENDED_NEW_COURSE: 'Recommend a new course',
  EXPLORE_SKILL: 'Explore skill',
  REMOVE_FROM_TRACKER: 'Remove from tracker',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  LOADER_TEXT: 'Loading',
};

export default locale;
