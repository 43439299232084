import { throttle } from 'lodash';
import {
  ReactNode, createContext, useMemo, useEffect, useRef,
  useState,
} from 'react';

import { kanbanJobTitleId, kanbanSkillType } from '../../cache/cache';
import { jobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import { learningPathsByInstitutionVar } from '../../cache/learner/learningPathsByInstitutionReactiveVar';
import { learningPathsByObjectivesVar } from '../../cache/learner/learningPathsByObjectivesReactiveVar';
import { RecommendedOccupationsByMajorReactiveVar } from '../../cache/learner/recommendedOccupationsByMajorReactiveVar';
import { RecommendedOccupationsReactiveVar } from '../../cache/learner/recommendedOccupationsReactiveVar';
import { recommendedSkillsVar } from '../../cache/learner/recommendedSkillsReactiveVar';
import { RelatedOccupationsReactiveVar } from '../../cache/learner/relatedOccupationsReactiveVar';
import { studentLearningPathsVar } from '../../cache/learner/studentLearningPathsReactiveVar';
import useJobPreferencesDataHook from '../../hooks/jobPreferencesDataHook/useJobPreferencesDataHook';
import useKanbanDataHook from '../../hooks/kanbanDataHook/useKanbanDataHook';
import { useLearningPathsByInstitutionHook } from '../../hooks/learningPathsByInstitutionHook/useLearningPathsByInstitutionHook';
import { useLearningPathsByObjectivesHook } from '../../hooks/learningPathsByObjectivesHook/useLearningPathsByObjectivesHook';
import RecommendedOccupationsByMajorHook from '../../hooks/recommendedOccupationsByMajorHook/recommendedOccupationsByMajorHook';
import RecommendedOccupationHook from '../../hooks/RecommendedOccupationsHook/recommendedOccupationsHook';
import useRecommendedSkillsHook from '../../hooks/recommendedSkillsHook/useRecommendedSkillsHook';
import SkillForCoursesRecommendationHook from '../../hooks/skillForCoursesRecommendationHook/skillForCoursesRecommendationHook';
import useStudentLearningPathsHook from '../../hooks/studentLearningPathsHook/useStudentLearningPathsHook';
import userAuth from '../../hooks/userAuth';
import { useMutationUpdateStudentCourseRecommendation } from '../../operations/mutations/student/useUpdateStudentCourseRecommendations/useUpdateStudentCourseRecommendations';
import { getPathwaysRefetch, getStudentPathwaysRefetch } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';
import {
  getCoursesRecommendationOnRefetch,
  getLearnerProfileOnChangeRefetch,
  IJobPreferencesRefetch,
  IRecommendedByMajorRefetch,
  IRecommendedOccupations,
  IStudentSkillForCoursesRecommendation,
} from '../../operations/query/students/useGetLearnerEntireProfile/useGetLearnerEntireProfile';
import { getJobRelatedOccupations } from '../../operations/query/students/useGetRelatedOccupations';
import { getStudentKanbanSkillTrackerAllRefetch } from '../../operations/query/students/useGetStudentKanbanSkillTrackerAll/useGetStudentKanbanSkilltrackerAll';
import { getStudentSkillSetChangeTriger } from '../../operations/query/students/useWhenStudentSkillSetChangeTrigger/useWhenStudentSkillSetChangeTrigger';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';

interface IStudentDataContextProvider {
  children: ReactNode
}

interface IStudentDataContext {
  refetchStudentData: () => void;
  refetchPathways: () => void;
  refetchStudentPathways: () => void;
  loading: boolean;
}

const StudentDataContextInitialState: IStudentDataContext = {
  refetchStudentData: () => null,
  refetchPathways: () => null,
  refetchStudentPathways: () => null,
  loading: false,
};

const timeConstant = 200;

export const StudentDataContext = createContext<IStudentDataContext>( StudentDataContextInitialState );

const StudentDataContextProvider = ({ children }: IStudentDataContextProvider ): JSX.Element => {
  const { auth } = userAuth();
  const { operations } = useJobPreferencesDataHook( jobPreferencesVar, kanbanDataVar );
  const { setRecommendedOccupationsByMajor } = RecommendedOccupationsByMajorHook(
    RecommendedOccupationsByMajorReactiveVar,
  );
  const { operations: kanbanOps } = useKanbanDataHook( kanbanDataVar, kanbanSkillType, kanbanJobTitleId );
  const { setRecommendedOccupations } = RecommendedOccupationHook( RecommendedOccupationsReactiveVar );
  const { setSkillForCoursesRecommendations } = SkillForCoursesRecommendationHook();
  const { setRecommendedSkills } = useRecommendedSkillsHook( recommendedSkillsVar );

  const studentIdRef = useRef<string | undefined>();
  const userCountryRef = useRef<string | undefined>();
  const userRoleRef = useRef<string | undefined>();

  const { operations: learningPathsByInstitutionOps } = useLearningPathsByInstitutionHook(
    learningPathsByInstitutionVar,
  );
  const { operations: learningPathsByObjectivesOps } = useLearningPathsByObjectivesHook( learningPathsByObjectivesVar );
  const { operations: learningPathsOps } = useStudentLearningPathsHook( studentLearningPathsVar );
  const [refetchLoading, setRefetchLoading] = useState<boolean>( false );
  const [updateStudentCoursesRecommendation] = useMutationUpdateStudentCourseRecommendation();

  useEffect(() => {
    studentIdRef.current = auth?.student?.id;
    userCountryRef.current = auth?.country;
    userRoleRef.current = auth?.userRole;
  }, [auth]);

  const setJobPreferences = ( jobPreferences: IJobPreferencesRefetch[]): void => {
    operations.setJobPreferences(
      jobPreferences.map(( jobPreference ) => ({
        jobTitleId: jobPreference.jobTitleId,
        jobTitleName: jobPreference.jobTitleName,
        match: 0,
        studentId: jobPreference.studentId,
        jobTitleSkillSet: jobPreference.jobTitleSkillSet,
      })),
    );
  };

  const setRecommendedOccupationsByMajorData = ( recommendedOccupationsByMajor: IRecommendedByMajorRefetch[]): void => {
    setRecommendedOccupationsByMajor(
      recommendedOccupationsByMajor.map(( recommendedMajor ) => ({
        majorId: recommendedMajor.studentMajor.tblMajor?.id ?? '',
        majorName: recommendedMajor.studentMajor.tblMajor?.name ?? '',
        match: recommendedMajor.percentageOfMajorMatch.map(( percentage ) => ({
          name: percentage.name,
          match: percentage.match,
          jobTitleId: percentage.id,
        })),
      })),
    );
  };

  const setRecommendedOccupationsData = ( recommendedOccupation: IRecommendedOccupations[]): void => {
    setRecommendedOccupations(
      recommendedOccupation.map(( jobTitle ) => ({
        name: jobTitle.job,
        match: jobTitle.match,
        jobTitleId: jobTitle.id,
      })),
    );
  };

  const setStudentSkillForCoursesRecommendation = (
    skillCoursesRecommendation: IStudentSkillForCoursesRecommendation[] | undefined,
  ): void => {
    if ( !skillCoursesRecommendation ) {
      return;
    }
    setSkillForCoursesRecommendations(
      skillCoursesRecommendation.map(( sc ) => ({
        ...sc,
      })),
    );
  };

  const valueContext = useMemo(() => ({
    refetchStudentData: throttle( async () => {
      if ( studentIdRef.current ) {
        setRefetchLoading( true );
        const { error } = await getStudentSkillSetChangeTriger( studentIdRef.current, false, userCountryRef.current );

        if ( error ) {
          setRefetchLoading( false );

          return;
        }

        const [{
          data: learnerData,
          error: learnerError,
        }, kanbanResult] = await Promise.all([
          getLearnerProfileOnChangeRefetch( studentIdRef.current ),
          getStudentKanbanSkillTrackerAllRefetch(['specialized', 'human'], studentIdRef.current ),
        ]);

        if ( !kanbanResult.error && kanbanResult.data.getStudentKanbanSkilltrackerAll ) {
          kanbanOps.setKanbanData( kanbanResult.data.getStudentKanbanSkilltrackerAll );
        }

        if ( !learnerError ) {
          const {
            jobPreferences,
            recommendationsByMajor, recommendedOccupations,
            recommendedSkills,
          } = learnerData.getLearnerEntireProfile;

          setRecommendedSkills( recommendedSkills );

          const jobTitleIds = jobPreferences.map(({ jobTitleId }) => jobTitleId ) || [];

          setJobPreferences( jobPreferences );
          setRecommendedOccupationsByMajorData( recommendationsByMajor );
          setRecommendedOccupationsData( recommendedOccupations );

          const { data: relatedQueryData, error: relatedError } = await getJobRelatedOccupations( jobTitleIds );

          setRefetchLoading( false );

          if ( relatedError ) {
            setRefetchLoading( false );

            return;
          }

          const relatedData = relatedQueryData?.relatedOccupations;

          if ( relatedData ) {
            RelatedOccupationsReactiveVar({ error: '', data: relatedData });
          }
        }
        setRefetchLoading( false );
        await updateStudentCoursesRecommendation({
          variables: {
            studentId: studentIdRef.current,
          },
        });

        const responseCourse = await getCoursesRecommendationOnRefetch( studentIdRef.current );

        setStudentSkillForCoursesRecommendation(
          responseCourse.data?.getLearnerEntireProfile?.studentSkillForCoursesRecommendation,
        );
      }
    }, timeConstant ),
    refetchPathways: throttle( async () => {
      if ( studentIdRef.current && userRoleRef.current === ROLE_ENUM.STUDENT ) {
        const { data: pathwaysData, error: pathwaysError } = await getPathwaysRefetch();

        if ( pathwaysError ) {
          return;
        }

        if ( pathwaysData?.getPathwaysByInstitutionAndOccupations ) {
          learningPathsByInstitutionOps.setLearningPathsByInstitution(
            pathwaysData?.getPathwaysByInstitutionAndOccupations,
          );
        }
        if ( pathwaysData?.getPathwaysByObjectives ) {
          learningPathsByObjectivesOps.setLearningPathsByObjectives( pathwaysData?.getPathwaysByObjectives );
        }
      }
    }, timeConstant ),
    refetchStudentPathways: throttle( async () => {
      if ( studentIdRef.current && userRoleRef.current === ROLE_ENUM.STUDENT ) {
        const { data: studentPathwaysData, error: studentPathwaysError } = await getStudentPathwaysRefetch();

        if ( studentPathwaysError ) {
          return;
        }

        if ( studentPathwaysData?.getStudentPathways ) {
          learningPathsOps.setStudentLearningPaths( studentPathwaysData.getStudentPathways );
        }
      }
    }, timeConstant ),
    loading: refetchLoading,
  }), [studentIdRef, refetchLoading]);

  return (
    <StudentDataContext.Provider value={valueContext}>
      {children}
    </StudentDataContext.Provider>
  );
};

export default StudentDataContextProvider;
