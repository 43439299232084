import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../resources/styleConfigs';
import BoxWrapper from '../../../wrappers/BoxWrapper';
import StackWrapper from '../../../wrappers/StackWrapper';

export const BoxStyled = styled( BoxWrapper )({
  height: `calc(${styleConfigs.dimensions.percent100} - ${styleConfigs.dimensions.height93}px)`,
  paddingBottom: styleConfigs.marginPadding.custom50,
});

export const StackWrapperStyled = styled( StackWrapper )({
  height: styleConfigs.dimensions.percent100,
});
