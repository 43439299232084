import { Popover, PopoverProps } from '@mui/material';

import { ArrowStyled, DirectionType } from './MenuPopoverStyles';
import { styleConfigs } from '../../resources/styleConfigs';

interface IPosition {
  direction?: DirectionType,
  width?: number;
  arrowPopover?: boolean;
}

const MenuPopover = ({
  children, arrowPopover = true, sx, direction = 'right', width, ...other
}: PopoverProps & IPosition ): JSX.Element => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: direction,
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: direction,
    }}
    PaperProps={{
      sx: {
        ...sx,
        width: width ?? styleConfigs.dimensions.width200,
        alignItems: 'initial',
        overflow: 'inherit',
        mt: 1.5,
        ml: 0.5,
        border: ( theme ) => `${styleConfigs.borders.borderLine} ${theme.palette.grey[500_8]}`,
        boxShadow: ( theme ) => theme.customShadows?.z20,
      },
    }}
    {...other}
  >
    {arrowPopover && (
      <ArrowStyled direction={direction} />
    )}
    {children}
  </Popover>
);

export default MenuPopover;
