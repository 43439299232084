import { InMemoryCache, makeVar } from '@apollo/client';

import { authErrorMessageVar } from './common/authErrorMessageReactiveVar';
import { majorIdVar } from './common/majorIdReactiveVar';
import { platformValidationCodeReactiveVar } from './common/platformValidationCodeReactiveVar';
import { renderNotification } from './learner/homeReactiveVars';
import { jobPreferencesVar } from './learner/jobPreferencesReactiveVar';
import { jobTitlesInstitutionReactiveVar } from './learner/jobTitlesInstitutionReactiveVar';
import { kanbanDataVar } from './learner/kanbanReactiveVar';
import { learningPathsByInstitutionVar } from './learner/learningPathsByInstitutionReactiveVar';
import { learningPathsByObjectivesVar } from './learner/learningPathsByObjectivesReactiveVar';
import { RecommendedOccupationsByMajorReactiveVar } from './learner/recommendedOccupationsByMajorReactiveVar';
import { RecommendedOccupationsReactiveVar } from './learner/recommendedOccupationsReactiveVar';
import { recommendedSkillsVar } from './learner/recommendedSkillsReactiveVar';
import { RelatedOccupationsReactiveVar } from './learner/relatedOccupationsReactiveVar';
import { selectedOptionsSkillVar } from './learner/selectedOptionsSkillVar';
import { SkillForCoursesRecommendationReactiveVar } from './learner/skillForCoursesRecommendationReactiveVar';
import { skillMatchVar } from './learner/skillMatchReactiveVar';
import { studentLearningPathsVar } from './learner/studentLearningPathsReactiveVar';
import {
  isValidMajorForm, majorListWizard, newFormsMajor, newStudentMajorForms, successSaveNewMajors, userIdActive,
} from './wizard/wizardReactiveVars';
import { INotification } from '../contexts/reducer/notificationReducer';
import StudentSkilltrackerFiltersEnum from '../views/Learner/Skills/components/KanbanBoard/interfaces/StudentSkilltrackerFiltersEnum';

export const occupationJobName = makeVar<string>( '' );
export const skillIdVar = makeVar<string | undefined>( undefined );
export const skillTypeVar = makeVar<string | undefined>( undefined );
export const skillTranslatedTypeVar = makeVar<string>( '' );
export const skillNameVar = makeVar<string>( '' );
export const occupationPreferenceId = makeVar<string | undefined>( undefined );
export const studentId = makeVar<number | undefined>( undefined );
export const userMajors = makeVar<undefined | { name: string; }[]>( undefined );
export const kanbanColumnIdVar = makeVar<string | undefined>( undefined );
export const kanbanSkillType = makeVar<string>( StudentSkilltrackerFiltersEnum.ALL );
export const kanbanJobTitleId = makeVar<string>( StudentSkilltrackerFiltersEnum.ALL );
export const loader = makeVar<string[]>([]);
export const notificationsVar = makeVar<INotification[]>([]);
export const userRoleVar = makeVar<string>( '' );
export const occupationFilterIdExplore = makeVar<string | undefined>( undefined );
export const disableSkillSetChange = makeVar<boolean>( false );
export const loadingSSO = makeVar<boolean>( false );
export const loadingWizard = makeVar<boolean>( false );
export const wizardSkillStepSkils = makeVar<string[] | undefined>( undefined );
export const wizardSkillToLearnStepSkills = makeVar<string[] | undefined>( undefined );
export const eventTime = makeVar<number>( Date.now());
export const pathnameEvent = makeVar<string | undefined>( undefined );

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        majorId: {
          read() {
            return majorIdVar();
          },
        },
        skillId: {
          read() {
            return skillIdVar();
          },
        },
        skillType: {
          read() {
            return skillTypeVar();
          },
        },
        skillTranslatedType: {
          read() {
            return skillTranslatedTypeVar();
          },
        },
        occupationJobName: {
          read() {
            return occupationJobName();
          },
        },
        occupationPreferenceId: {
          read() {
            return occupationPreferenceId();
          },
        },
        userMajors: {
          read() {
            return userMajors();
          },
        },
        skillName: {
          read() {
            return skillNameVar();
          },
        },
        kanbanColumnId: {
          read() {
            return kanbanColumnIdVar();
          },
        },
        kanbanSkillType: {
          read() {
            return kanbanSkillType();
          },
        },
        kanbanJobTitleId: {
          read() {
            return kanbanJobTitleId();
          },
        },
        majorListWizard: {
          read() {
            return majorListWizard();
          },
        },
        isValidMajorForm: {
          read() {
            return isValidMajorForm();
          },
        },
        newStudentMajorForms: {
          read() {
            return newStudentMajorForms();
          },
        },
        userIdActive: {
          read() {
            return userIdActive();
          },
        },
        successSaveNewMajors: {
          read() {
            return successSaveNewMajors();
          },
        },
        newFormsMajor: {
          read() {
            return newFormsMajor();
          },
        },
        renderNotification: {
          read() {
            return renderNotification();
          },
        },
        SkillForCoursesRecommendationReactiveVar: {
          read() {
            return SkillForCoursesRecommendationReactiveVar();
          },
        },
        RelatedOccupationsReactiveVar: {
          read() {
            return RelatedOccupationsReactiveVar();
          },
        },
        RecommendedOccupationsReactiveVar: {
          read() {
            RecommendedOccupationsReactiveVar();
          },
        },
        loader: {
          read() {
            return loader();
          },
        },
        RecommendedOccupationsByMajorReactiveVar: {
          read() {
            return RecommendedOccupationsByMajorReactiveVar();
          },
        },
        notificationsVar: {
          read() {
            return notificationsVar();
          },
        },
        jobPreferencesVar: {
          read() {
            return jobPreferencesVar();
          },
        },
        kanbanDataVar: {
          read() {
            return kanbanDataVar();
          },
        },
        recommendedSkillsVar: {
          read() {
            return recommendedSkillsVar();
          },
        },
        skillMatchVar: {
          read() {
            return skillMatchVar();
          },
        },
        userRoleVar: {
          read() {
            return userRoleVar();
          },
        },
        occupationFilterIdExplore: {
          read() {
            return occupationFilterIdExplore();
          },
        },
        disableSkillSetChange: {
          read() {
            return disableSkillSetChange();
          },
        },
        loadingSSO: {
          read() {
            return loadingSSO();
          },
        },
        platformValidationCodeVar: {
          read() {
            return platformValidationCodeReactiveVar();
          },
        },
        loadingWizard: {
          read() {
            return loadingWizard();
          },
        },
        wizardSkillStepSkils: {
          read() {
            return wizardSkillStepSkils();
          },
        },
        wizardSkillToLearnStepSkills: {
          read() {
            return wizardSkillToLearnStepSkills();
          },
        },
        eventTime: {
          read() {
            return eventTime();
          },
        },
        pathnameEvent: {
          read() {
            return pathnameEvent();
          },
        },
        selectedOptionsSkillVar: {
          read() {
            return selectedOptionsSkillVar();
          },
        },
        jobTitlesInstitutionReactiveVar: {
          read() {
            return jobTitlesInstitutionReactiveVar();
          },
        },
        authErrorMessageVar: {
          read() {
            return authErrorMessageVar();
          },
        },
        studentLearningPathsVar: {
          read() {
            return studentLearningPathsVar();
          },
        },
        learningPathsByInstitutionVar: {
          read() {
            return learningPathsByInstitutionVar();
          },
        },
        learningPathsByObjectivesVar: {
          read() {
            return learningPathsByObjectivesVar();
          },
        },
      },
    },
  },
  addTypename: false,
});
