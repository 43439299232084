import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import GridWrapper from '../../../components/wrappers/GridWrapper';

export const LogoContainerStyled = styled( GridWrapper )({
  display: 'flex',
  justifyContent: 'center',
  paddingBlockStart: styleConfigs.marginPadding.custom32,
  paddingBlockEnd: styleConfigs.marginPadding.custom5,
  paddingInline: styleConfigs.marginPadding.custom40,
});
