import { AriaDialogProps, useDialog } from '@react-aria/dialog';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FocusScope } from '@react-aria/focus';
import {
  useOverlay,
  usePreventScroll,
  OverlayContainer,
} from '@react-aria/overlays';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useRef, ReactNode } from 'react';
import { useSwipeable } from 'react-swipeable';

import { DivBackgroundStyled, DivModalStyled } from './styles/ModalDialogAnimatedStyles';

const animatedBackground = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        duration: 0.4,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  },
};

const animatedModal = {
  initial: {
    y: '50vh',
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
  exit: {
    x: '-50vw',
    opacity: 0,
    transition: {
      opacity: {
        duration: 0.4,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  },
};

interface IModalDialogAnimated extends AriaDialogProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  backdropClick: boolean | undefined;
  escapeKeyClick: boolean | undefined;
  canBeSwipped?: boolean;
  mobileWidth?: string | number;
  mobileHeight?: string;
  mobileRoundedCorner?: boolean;
  mobileMarginInline?: string | number;
}

const ModalDialogAnimated: React.FC<IModalDialogAnimated> = ( props ) => {
  const {
    children,
    isOpen,
    onClose,
    backdropClick,
    escapeKeyClick,
    mobileWidth,
    mobileHeight,
    mobileRoundedCorner,
    mobileMarginInline,
    canBeSwipped = true,
  } = props;
  const ref = useRef<HTMLDivElement>( null );
  const { overlayProps, underlayProps } = useOverlay({
    isOpen,
    onClose: escapeKeyClick ? onClose : undefined,
  }, ref );

  usePreventScroll();

  const { dialogProps } = useDialog( props, ref );

  const modalRef = useRef( null );

  const swipeHandler = useSwipeable({
    onSwipedRight: () => {
      if ( canBeSwipped ) {
        onClose();
      }
    },
  });

  return (
    <AnimatePresence>
      {isOpen ? (
        <OverlayContainer>
          <motion.div
            initial={animatedBackground.initial}
            animate={animatedBackground.animate}
            exit={animatedBackground.exit}
          >
            <DivBackgroundStyled
              {...underlayProps}
              onClick={( e ) => {
                if ( e.target === e.currentTarget && backdropClick ) {
                  onClose();
                }
              }}
            >
              <FocusScope contain restoreFocus autoFocus>
                <motion.div
                  initial={animatedModal.initial}
                  animate={animatedModal.animate}
                  exit={animatedModal.exit}
                >
                  <div
                    {...swipeHandler}
                  >
                    <DivModalStyled
                      {...overlayProps}
                      {...dialogProps}
                      ref={modalRef}
                      open={isOpen}
                      mobilewidth={mobileWidth}
                      mobileheight={mobileHeight}
                      roundercorner={mobileRoundedCorner ? 1 : 0}
                      mobileMargin={mobileMarginInline}
                    >
                      {children}
                    </DivModalStyled>
                  </div>
                </motion.div>
              </FocusScope>
            </DivBackgroundStyled>
          </motion.div>
        </OverlayContainer>
      ) : null}
    </AnimatePresence>
  );
};

export default ModalDialogAnimated;
