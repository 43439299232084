import environmentVariables from '../../../../appVariables';

const firebaseConfig = {
  apiKey: environmentVariables?.firebaseApiKey,
  projectId: environmentVariables?.firebaseProject,
  authDomain: environmentVariables?.firebaseAuthDomain,
  storageBucket: environmentVariables?.firebaseStorageBucket,
  messagingSenderId: environmentVariables?.firebaseMessagingSenderId,
  appId: environmentVariables?.firebaseAppId,
};

export default firebaseConfig;
