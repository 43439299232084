import { ReactiveVar } from '@apollo/client';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as _ from 'lodash';

import { notificationsVar } from '../../cache/cache';
import {
  emptyRecommendedOccupationsByMajorCache,
  IRecommendedOccupationsByMajorCache,
  IRecommendedOccupationsByMajors, RecommendedOccupationsByMajorReactiveVar,
} from '../../cache/learner/recommendedOccupationsByMajorReactiveVar';
import { decryptor, encryptor } from '../../utils/decryptor';

interface IRecommendedOccupationsByMajorHook {
  clearRecommendedOccupationsByMajorError: ()=> void;
  setRecommendedOccupationsByMajorError : ( errorMessage: string ) => void;
  setRecommendedOccupationsByMajor : ( RecommendedOccupationsByMajor: IRecommendedOccupationsByMajors[]) => void;
  setFromLocalRecommendedOccupationsByMajor: () => boolean;
  updateRecommendedOccupationsByMajor: ( id: string ) => void;
}

const RecommendedOccupationsByMajorHook = (
  recommendedOccupationsByMajorVarProp: ReactiveVar<IRecommendedOccupationsByMajorCache>,
): IRecommendedOccupationsByMajorHook => {
  const {
    data: recommendedOccupationByMajorData,
    error: recommendedOccupationByMajorError,
  } = recommendedOccupationsByMajorVarProp();

  const clearRecommendedOccupationsByMajorError = (): void => {
    RecommendedOccupationsByMajorReactiveVar( emptyRecommendedOccupationsByMajorCache );
  };
  const setRecommendedOccupationsByMajorError = ( errorMessage: string ):void => {
    RecommendedOccupationsByMajorReactiveVar({ ...emptyRecommendedOccupationsByMajorCache, error: errorMessage });
  };
  const setRecommendedOccupationsByMajor = ( recommendedOccupationsByMajor: IRecommendedOccupationsByMajors[]):void => {
    RecommendedOccupationsByMajorReactiveVar(
      { ...emptyRecommendedOccupationsByMajorCache, data: recommendedOccupationsByMajor },
    );
    const encryptedData = encryptor( JSON.stringify( recommendedOccupationsByMajor ));

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'recommendedOccupationsByMajor', encryptedData );
  };

  const setFromLocalRecommendedOccupationsByMajor = ():boolean => {
    const dataLocal = sessionStorage.getItem( 'recommendedOccupationsByMajor' );
    const decryptedData = decryptor( dataLocal ?? '' );

    if ( !decryptedData ) {
      return false;
    }
    RecommendedOccupationsByMajorReactiveVar(
      { error: '', data: JSON.parse( decryptedData ) },
    );

    return true;
  };

  const updateRecommendedOccupationsByMajor = ( id: string ): void => {
    if ( recommendedOccupationByMajorError ) {
      notificationsVar([
        {
          id: 'updateRecommendedOccupationsByMajor',
          title: recommendedOccupationByMajorError,
          description: recommendedOccupationByMajorError,
          autoClose: true,
          leftIcon: <WarningAmberRoundedIcon color="error" />,
        },
      ]);
    } else if ( recommendedOccupationByMajorData ) {
      setRecommendedOccupationsByMajor( _.cloneDeep( recommendedOccupationByMajorData.map(( major ) => ({
        majorId: major.majorId,
        majorName: major.majorName,
        match: major.match.filter(( occupation ) => occupation.jobTitleId !== id ),
      }))));
    }
  };

  return {
    clearRecommendedOccupationsByMajorError,
    setRecommendedOccupationsByMajorError,
    setRecommendedOccupationsByMajor,
    setFromLocalRecommendedOccupationsByMajor,
    updateRecommendedOccupationsByMajor,
  };
};

export default RecommendedOccupationsByMajorHook;
