import { alpha, styled } from '@mui/material/styles';

import palette from '../../../theme/learnerPalette';
import { styleConfigs } from '../../resources/styleConfigs';

export type DirectionType = 'left' | 'right';

export const ArrowStyled = styled( 'span' )((
  { direction, theme }: { direction: DirectionType, theme?: any},
) => ({
  [theme?.breakpoints.up( 'sm' )]: {
    width: styleConfigs.dimensions.width13,
    height: styleConfigs.dimensions.height13,
    transform: 'rotate(45deg)',
    background: theme?.palette.background.paper,
    borderTop: `${styleConfigs.borders.borderLine} ${alpha( palette.light.borders.amethyst, 0.2 )}`,
    borderLeft: `${styleConfigs.borders.borderLine} ${alpha( palette.light.borders.amethyst, 0.2 )}`,
    borderRadius: `${styleConfigs.marginPadding.custom2}px ${styleConfigs.marginPadding.empty}`,
    position: 'absolute',
    [direction]: styleConfigs.marginPadding.custom20,
    top: styleConfigs.marginPadding.customNegative7,
    zIndex: 1,
  },
}));
