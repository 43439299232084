const locale = {
  TITLE: 'Selecciona una plataforma ',
  SUBTITLE: 'Hemos detectado que tu cuenta tiene dos roles asociados. Por favor, selecciona la plataforma que deseas utilizar.',
  LEARNER_USER: 'Learner',
  ADVISOR_USER: 'Advisor',
  DISABLED_BUTTON: 'Selecciona un rol',
  ENABLED_BUTTON: 'Iniciar sesión',
  CANCEL: 'Cancelar',
  TITLE_ERROR: 'Error de acceso',
  DESCRIPTION_ERROR: 'Actualmente no hay ningún módulo habilitado. Por favor, contacta a tu administrador de cuenta de 1Mentor para asistencia.',
};

export default locale;
