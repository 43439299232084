import {
  MutationTuple,
  gql,
  useMutation,
} from '@apollo/client';

export const UPDATE_STUDENT_SKILL_STATUS = gql`
mutation UpdateStudentSkillStatus($skillStatus: String!, $skillId: String!, $studentId: String!, $fromStatus: String!) {
  updateStudentSkillStatus(skillStatus: $skillStatus, skillId: $skillId, studentId: $studentId, fromStatus: $fromStatus) {
    updated
    studentNotification {
      id
      type
      date
    }
  }
}
`;

export type NotificationType = 'lookout_removed'| 'lookout_removed_no_job'| 'lookout_no_job' |'lookout'|'congratulations_no_job'|'congratulations';

export interface IStudentNotification {
  id: number,
  type: NotificationType,
  skillName: string,
  occupationName: string,
  date: string,
  skillStatus: string,
}

export interface IRenderNotification {
  newNotifications: IStudentNotification[];
  beforeNotifications: IStudentNotification[];
}

export interface IAllStudentNotification {
  allStudentNotification: IRenderNotification;
}

export interface ISingleNotification {
  id: number
  type: string
  date: string
}

export interface IUpdateStudentSkillStatusParams {
  skillStatus: string;
  skillId: string;
  studentId: string;
  fromStatus: string;
}

export interface IKanbanSkillTrackerNotification {
  updated: boolean;
  studentNotification: ISingleNotification;
}

export interface IUpdateStudentSkillStatusResponse {
  updateStudentSkillStatus
  : IKanbanSkillTrackerNotification;
}

export const useMutationUpdateStudentSkillStatus = ():
MutationTuple<IUpdateStudentSkillStatusResponse, IUpdateStudentSkillStatusParams> =>
  useMutation<IUpdateStudentSkillStatusResponse, IUpdateStudentSkillStatusParams>(
    UPDATE_STUDENT_SKILL_STATUS,
  );
