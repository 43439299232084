import {
  createContext, ReactNode, useEffect, useMemo, useState,
} from 'react';

export interface IIkigaiContext {
  passion: string
  mission: string
  profession: string
  vocation: string
  completed: boolean
  setPassion: ( value: string ) => void;
  setMission: ( value: string ) => void;
  setProfession: ( value: string ) => void;
  setVocation: ( value: string ) => void;
  cleanup: () => void;
}

const IkigaiContext = createContext<IIkigaiContext>({
  mission: '',
  passion: '',
  profession: '',
  vocation: '',
  completed: false,
  setPassion: () => null,
  setMission: () => null,
  setProfession: () => null,
  setVocation: () => null,
  cleanup: () => null,
});

const IkigaiProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [passion, setPassion] = useState( '' );
  const [mission, setMission] = useState( '' );
  const [profession, setProfession] = useState( '' );
  const [vocation, setVocation] = useState( '' );
  const [completed, setCompleted] = useState( false );

  useEffect(() => {
    if ( passion && mission && profession && vocation ) {
      setCompleted( true );
    }
  }, [passion, mission, profession, vocation]);

  const cleanup = (): void => {
    setVocation( '' );
    setProfession( '' );
    setMission( '' );
    setPassion( '' );
  };

  const ikigaiData: IIkigaiContext = useMemo(() => ({
    mission,
    passion,
    profession,
    vocation,
    completed,
    setPassion,
    setMission,
    setProfession,
    setVocation,
    cleanup,
  }), [
    mission,
    passion,
    profession,
    vocation,
    completed,
  ]);

  return (
    <IkigaiContext.Provider value={ikigaiData}>
      {children}
    </IkigaiContext.Provider>
  );
};

export { IkigaiContext, IkigaiProvider };
