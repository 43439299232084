import AddIcon from '@mui/icons-material/Add';

import {
  EmptyStateButtonStyled, EmptyStateIconStyled, EmptyStateSubtitleStyled, EmptyStateTitleStyled,
  EmptyStateWrapperStyled, StackEmptyStateStyled,
} from './styles/EmptyStateComponentStyles';
import palette from '../../../theme/learnerPalette';
import { styleConfigs } from '../../resources/styleConfigs';
import BoxWrapper from '../../wrappers/BoxWrapper';
import StackWrapper from '../../wrappers/StackWrapper';

interface IEmptyStateComponentProps {
  iconSrc: string,
  alt?: string,
  ariaLabel?: string,
  widthIcon?: number | string,
  heightIcon?: number | string,
  iconLineWidth?: number,
  iconColor?: string,
  title?: string,
  fontSizeTitle?: string,
  colorTitle?: string,
  marginTopStackIcon?: number,
  marginBottomStackIcon?: number,
  directionIconTitle?: string,
  gapIconTitle?: number,
  subtitle?: string,
  fontSizeSubtitle?: string,
  colorSubtitle?: string,
  paddingTopSubtitle?: string | number,
  maxWidthSubtitle?: string | number,
  startIcon?: boolean,
  callToActionButton?: {
    buttonText: string,
    buttonClick: VoidFunction,
  },
  gapTitlesButton?: number,
}

const EmptyStateComponent: React.FC<IEmptyStateComponentProps> = ( props ) => {
  const {
    iconSrc,
    alt,
    ariaLabel,
    widthIcon = 'auto !important',
    heightIcon = 'auto !important',
    iconLineWidth,
    iconColor = palette.light.text.prussianBlue,
    title,
    fontSizeTitle,
    colorTitle = palette.light.text.prussianBlue,
    marginTopStackIcon,
    marginBottomStackIcon,
    directionIconTitle,
    gapIconTitle = styleConfigs.marginPadding.custom6,
    subtitle,
    fontSizeSubtitle,
    colorSubtitle = palette.light.text.mistBlue,
    paddingTopSubtitle = styleConfigs.marginPadding.empty,
    maxWidthSubtitle = styleConfigs.dimensions.width450,
    startIcon,
    callToActionButton,
    gapTitlesButton,
  } = props;

  return (
    <EmptyStateWrapperStyled
      gaptitlesbutton={gapTitlesButton}
    >
      <StackWrapper>
        <StackEmptyStateStyled
          margintopstackicon={marginTopStackIcon}
          marginbottomstackicon={marginBottomStackIcon}
          directionicontitle={directionIconTitle}
          gapicontitle={gapIconTitle}
        >
          <EmptyStateIconStyled
            src={iconSrc}
            alt={alt}
            aria-label={ariaLabel}
            widthicon={widthIcon}
            heighticon={heightIcon}
            iconlinewidth={iconLineWidth}
            iconcolor={iconColor}
          />
          {title && (
            <EmptyStateTitleStyled
              fontsizetitle={fontSizeTitle}
              colortitle={colorTitle}
            >
              {title}
            </EmptyStateTitleStyled>
          )}
        </StackEmptyStateStyled>
        {subtitle && (
          <EmptyStateSubtitleStyled
            fontsizesubtitle={fontSizeSubtitle}
            colorsubtitle={colorSubtitle}
            paddingtopsubtitle={paddingTopSubtitle}
            maxwidthsubtitle={maxWidthSubtitle}
          >
            { subtitle }
          </EmptyStateSubtitleStyled>
        )}
      </StackWrapper>
      {callToActionButton && (
        <BoxWrapper>
          <EmptyStateButtonStyled
            startIcon={startIcon && <AddIcon />}
            onClick={callToActionButton.buttonClick}
          >
            { callToActionButton.buttonText }
          </EmptyStateButtonStyled>
        </BoxWrapper>
      )}
    </EmptyStateWrapperStyled>
  );
};

export default EmptyStateComponent;
