import CloseIcon from '@mui/icons-material/Close';

import {
  ChangePasswordDialogTitleStyle,
  CloseIconButton,
} from './ChangePasswordDialogStyles';

export interface IDialogTitleProps {
  id: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const ChangePasswordDialogTitle: React.FC<IDialogTitleProps> = ( props: IDialogTitleProps ) => {
  const { children, onClose, ...other } = props;

  return (
    <ChangePasswordDialogTitleStyle {...other}>
      {children}
      {onClose ? (
        <CloseIconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </CloseIconButton>
      ) : null}
    </ChangePasswordDialogTitleStyle>
  );
};

export default ChangePasswordDialogTitle;
