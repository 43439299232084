import { useReactiveVar } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
  PaletteOptions,
} from '@mui/material/styles';
import {
  useMemo, ReactNode, useState, useEffect,
  useContext,
} from 'react';

import adminPalette from './adminPalette';
import breakpoints from './breakpoints';
import learnerPalette from './learnerPalette';
import componentsOverrides from './overrides';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';
import { userRoleVar } from '../cache/cache';
import { FeatureFlagsContext } from '../contexts/FeatureFlagsContext';
import useSettings from '../hooks/useSettings';
import { useLazyFeatureComplete } from '../operations/query/feature/useGetFeatureComplete';

type ThemeConfigProps = {
  children: ReactNode;
};

const ThemeConfig: React.FC<ThemeConfigProps> = ({ children }) => {
  const [getWhiteLabel, { data: featureWhiteLabel }] = useLazyFeatureComplete( true );
  const {
    themeMode, themeDirection,
  } = useSettings();
  const userRole = useReactiveVar( userRoleVar );
  const [palette, setPalette] = useState<PaletteOptions>({ ...adminPalette.light, mode: 'light' });
  const isLight = themeMode === 'light';
  const isStudent = userRole === 'student';
  const { features } = useContext( FeatureFlagsContext );

  useEffect(() => {
    getWhiteLabel({
      variables: {
        feature: 'WHITE_LABEL',
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const whiteLabelObject = useMemo(() => ( featureWhiteLabel?.getFeatureNameAndValue
    ? JSON.parse( featureWhiteLabel?.getFeatureNameAndValue.featureValue )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    : undefined ), [featureWhiteLabel, getWhiteLabel]);

  useEffect(() => {
    if ( isStudent && isLight ) {
      const paletteLight = ( features.whiteLabel && whiteLabelObject )
        ? whiteLabelObject.learnerPaletteWL : learnerPalette;

      setPalette({ ...paletteLight.light, mode: 'light' });

      return;
    }
    if ( isStudent && !isLight ) {
      const paletteDark = ( features.whiteLabel && whiteLabelObject )
        ? whiteLabelObject.learnerPaletteWL : learnerPalette;

      setPalette({ ...paletteDark.dark, mode: 'dark' });

      return;
    }
    if ( !isStudent && isLight ) {
      const paletteLight = ( features.whiteLabel && whiteLabelObject ) ? whiteLabelObject.adminPaletteWL : adminPalette;

      setPalette({ ...paletteLight.light, mode: 'light' });

      return;
    }
    if ( !isStudent && !isLight ) {
      const paletteDark = ( features.whiteLabel && whiteLabelObject ) ? whiteLabelObject.adminPaletteWL : adminPalette;

      setPalette({ ...paletteDark.dark, mode: 'dark' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStudent, isLight, whiteLabelObject]);

  const themeOptions: ThemeOptions = useMemo(() => ({
    palette,
    shape,
    typography,
    breakpoints,
    direction: themeDirection,
    shadows: isLight ? shadows.light : shadows.dark,
    customShadows: isLight ? customShadows.light : customShadows.dark,
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isLight, themeDirection, palette, isStudent, whiteLabelObject]);

  const theme = createTheme( themeOptions );

  theme.components = componentsOverrides( theme );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
