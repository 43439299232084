import { makeVar } from '@apollo/client';

export interface IJobTitlesInstitution {
  institutionId: string;
  jobTitleId: string;
}

export interface IJobTitlesInstitutionCache {
  data: IJobTitlesInstitution[];
}

export const emptyJobTitlesInstitutionCache: IJobTitlesInstitutionCache = {
  data: [],
};

export const jobTitlesInstitutionReactiveVar = makeVar<IJobTitlesInstitutionCache>(
  emptyJobTitlesInstitutionCache,
);
