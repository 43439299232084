import { makeVar } from '@apollo/client';
import React from 'react';

export interface ISkillMatch {
  jobTitleId: string
  jobTitleName: string
  match: number
  specializedPercentage: number
  humanPercentage: number
  specializedAcquired: number
  specializedTotal: number
  humanAcquired: number
  humanTotal: number
}

export interface ISKillMatchCache {
  error: string
  data: ISkillMatch
}

export interface IUseSkillMatchCacheResult {
  skillsMatch: ISkillMatch,
  error: string,
  setSkillsMatch: ( skillsMatch: ISkillMatch ) => void
  setSkillMatchError: ( error: string ) => void
  clearSkillMatchError: () => void
}

export const skillMatchEmptyData: ISkillMatch = {
  jobTitleId: '',
  jobTitleName: '',
  match: 0,
  specializedPercentage: 0,
  humanPercentage: 0,
  specializedAcquired: 0,
  specializedTotal: 0,
  humanAcquired: 0,
  humanTotal: 0,
};

export const skillMatchEmptyCache: ISKillMatchCache = {
  error: '',
  data: skillMatchEmptyData,
};

export const skillMatchVar = makeVar<ISKillMatchCache>( skillMatchEmptyCache );

export const useSkillMatchCache = (): IUseSkillMatchCacheResult => {
  const skillsMatchFromCache = skillMatchVar().data;
  const [error, setError] = React.useState<string>( skillMatchVar().error );

  const setSkillsMatch = ( data: ISkillMatch ): void => {
    skillMatchVar({ data, error: '' });
  };

  const setSkillMatchError = ( newError: string ): void => {
    skillMatchVar({
      data: skillsMatchFromCache,
      error: newError,
    });
    setError( newError );
  };

  const clearSkillMatchError = ( ): void => {
    skillMatchVar({
      data: skillsMatchFromCache,
      error: '',
    });
    setError( '' );
  };

  return {
    skillsMatch: skillsMatchFromCache,
    error,
    setSkillsMatch,
    setSkillMatchError,
    clearSkillMatchError,
  };
};
