import { ReactiveVar } from '@apollo/client';

import { getMixpanelName } from './getMixpanelName';
import { eventTime, pathnameEvent } from '../cache/cache';
import { Mixpanel } from '../mixpanel/mixpanel';
import { MixpanelEventsEnum } from '../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { ILoginUserSuccessResponse, ROLE_ENUM } from '../views/Auth/Login/interfaces/IGoogleUser';

interface IMixpanelTimeFunction {
  changeScreen: ( pathname: string ) => void;
}

export const useMixpanelTimeFunction = (
  eventTimeProp: ReactiveVar<number>,
  pathnameEventProp: ReactiveVar<string | undefined>,
  auth: ILoginUserSuccessResponse,
): IMixpanelTimeFunction => {
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const currentEventTime = eventTimeProp();
  const currentPathname = pathnameEventProp();

  const getPassedTime = ( initialTime: number, endTime: number ): string => {
    const elapsedTime = ( endTime - initialTime ) / 1000;
    const minutes = Math.floor( elapsedTime / 60 );
    const seconds = Math.floor( elapsedTime % 60 );

    return `${minutes} min ${seconds} seg`;
  };

  const changeScreen = ( pathname: string ): void => {
    const passedTime = getPassedTime( currentEventTime, Date.now());

    if ( currentPathname ) {
      Mixpanel.track({
        action: MixpanelEventsEnum.SCREEN_TIME,
        data: {
          screen: currentPathname,
          timeScreen: passedTime,
        },
      }, isLearner );
    }

    eventTime( Date.now());
    pathnameEvent( getMixpanelName( pathname ));
  };

  return {
    changeScreen,
  };
};
