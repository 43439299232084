import './useKanbanDataHookConfig';
import { ApolloError, ReactiveVar, useReactiveVar } from '@apollo/client';
import * as _ from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { IJobTitleSkillSetVar, jobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import {
  IColumnInKanban,
  emptyKanbanDataCache,
  IKanbanCache, IKanbanVar, ISkillKanban, kanbanDataVar, StudentSkillStatus,
} from '../../cache/learner/kanbanReactiveVar';
import { recommendedSkillsVar } from '../../cache/learner/recommendedSkillsReactiveVar';
import { SkillStatusEnum } from '../../constants/enums/SkillStatusEnum';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { MixpanelComponentsNames } from '../../mixpanel/MixpanelEvents/mixpanelComponentNames';
import { MixpanelEventsEnum } from '../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { useMutationCreateOrUpdateStudentSkill } from '../../operations/mutations/student/useCreateOrUpdateStudentSkill/useCreateOrUpdateStudentSkill';
import { useMutationDeleteStudentSkills } from '../../operations/mutations/student/useDeleteStudentSkills/deleteStudentSkills';
import { NotificationType, useMutationUpdateStudentSkillStatus } from '../../operations/mutations/student/useMutationUpdateStudentSkillStatus/useMutationUpdateStudentSkillStatus';
import { IGetStudentKanbanSkilltrackerAll, useLazyGetStudentKanbanSkilltrackerAll } from '../../operations/query/students/useGetStudentKanbanSkillTrackerAll/useGetStudentKanbanSkilltrackerAll';
import { decryptor, encryptor } from '../../utils/decryptor';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { compareValues } from '../../utils/orderArrayObjectsDynamic';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import useNotificationsHook, { AddNotificationActionsEnum } from '../notificationsHook/useNotificationsHook';
import useRecommendedSkillsHook from '../recommendedSkillsHook/useRecommendedSkillsHook';
import userAuth from '../userAuth';
import useStudentRefetch from '../useStudentRefetch';

export interface IDestinationFragEnd {
  droppableId: string;
  index: number;
}

export interface IDragEndParam {
  draggableId: string;
  destination: IDestinationFragEnd;
  source: IDestinationFragEnd;
}

export interface ISkillToAdd {
  id: string,
  name: string,
  type: string,
  translatedType?: string,
}

interface IAddSkillMixpanel {
  recommended: boolean;
  component: string;
}

interface IGetSkillsAcquiredByStudentInAJob {
  studentAcquiredSkills: IJobTitleSkillSetVar[];
  studentMissingSkills: IJobTitleSkillSetVar[];
}

export interface IKanbanDataHook {
  operations: {
    afterDrag: ( result: IDragEndParam ) => void;
    addSkill: (
      skill: ISkillToAdd,
      column: IColumnInKanban,
      mixpanelEvents: IAddSkillMixpanel,
      setLoading?: Dispatch<SetStateAction<boolean>>,
      jobTitleId?: string
    ) => Promise<void>;
    setKanbanData: ( data: IGetStudentKanbanSkilltrackerAll[]) => void;
    clearKanbanData: () => void;
    setKanbanDataError: ( error: ApolloError | undefined ) => void;
    refetchKanbanData: () => void;
    filterKanbanData: ( skills: ISkillKanban[], filterType: string, filterJobTitleId: string ) => ISkillKanban[];
    setKanbanDataOrganized: ( data: IKanbanCache ) => void;
    setFromLocalKanbanDataOrganized: () => boolean;
    deleteSkill: (
      skillId: string,
      skillName: string,
      mixpanelEvents: {component: string},
      jobIdToUpdate?: string,
    ) => Promise<void>;
    hasKanbanDataAfterFilter: ( data: IKanbanCache, filterType: string, filterJobTitleId: string ) => boolean;
    getSkillsAcquiredByStudentInAJob: ( skillsInJob: IJobTitleSkillSetVar[]) => IGetSkillsAcquiredByStudentInAJob;
    kanbanData: IKanbanCache;
    updateSkillStatusFromSkillSet: (
      skillId: string,
      skillName: string,
      skillStatus: string,
      originColumn: string,
    ) => Promise<void>;
  }
}

const simplifyData = ( columnData: IGetStudentKanbanSkilltrackerAll[]): ISkillKanban[] => columnData.map(( skill ) => ({
  skillId: skill.skillId,
  status: skill.status,
  name: skill.tblSkill.name,
  type: skill.tblSkill.type,
  percentageMatch: skill.percentageMatch ?? 0,
  translatedType: skill.tblSkill.translatedType,
  validatedSkill: skill.validatedSkill ?? false,
}));

const useKanbanDataHook = (
  kanbanVarProp: ReactiveVar<IKanbanCache>,
  typeFilterVar: ReactiveVar<string>,
  jobTitleFilterVar: ReactiveVar<string>,
): IKanbanDataHook => {
  const kanbanData = kanbanVarProp();
  const typeFilter = typeFilterVar();
  const jobTitleFilter = jobTitleFilterVar();
  const jobPreferencesCache = useReactiveVar( jobPreferencesVar );
  const [updateStudentSkillStatus] = useMutationUpdateStudentSkillStatus();
  const [createOrUpdateStudentSkill] = useMutationCreateOrUpdateStudentSkill();
  const { pathname, search } = useLocation();
  const { operations: notificationOps } = useNotificationsHook();
  const { auth } = userAuth();
  const [deleteStudentSkillsMutation] = useMutationDeleteStudentSkills();
  const { updateRecommendedSkills } = useRecommendedSkillsHook( recommendedSkillsVar );
  const [getKanbanData, { data: dataKanban, error: errorKanban }] = useLazyGetStudentKanbanSkilltrackerAll( true );
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const { t } = useTranslation( 'useKanbanDataHook' );
  const studentId: string = auth?.student?.id ?? '';
  const { refetchStudentData } = useStudentRefetch();

  useEffect(() => {
    if ( dataKanban ) {
      setKanbanData( dataKanban.getStudentKanbanSkilltrackerAll );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKanban]);

  useEffect(() => {
    if ( errorKanban ) {
      setKanbanDataError( errorKanban );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorKanban]);

  const setLoadingHandler = ( isLoading: boolean, setLoading?: Dispatch<SetStateAction<boolean>> ): void => {
    if ( setLoading ) {
      setLoading( isLoading );
    }
  };

  const addSkill = async (
    skill: ISkillToAdd, column: IColumnInKanban, mixpanelEvents: IAddSkillMixpanel,
    setLoading?: Dispatch<SetStateAction<boolean>>,
    jobTitleId?: string,
  ): Promise<void> => {
    setLoadingHandler( true, setLoading );
    const learnerSkillId: string[] = kanbanData.data.filter(( kanbanColumn ) => kanbanColumn.id === column ).flatMap(
      ( learnerKanban ) => learnerKanban.items,
    ).map(({ skillId }) => skillId );

    if ( learnerSkillId.includes( skill.id )) {
      notificationOps.addNotification( 'danger', t( 'YOU_ALREADY_HAVE_THIS_SKILL' ), t( 'OOPS' ));
      setLoadingHandler( false, setLoading );

      return;
    }

    const columnOrigin = kanbanData.data.find(
      ( col ) => col.items.find(( sk ) => sk.skillId === skill.id ),
    )?.id;
    const columnIndex = kanbanData.data.findIndex(({ id }) => id === column );
    const columnOriginIndex = kanbanData.data.findIndex(({ id }) => id === columnOrigin );
    const newSkill = {
      skillId: skill.id,
      status: column,
      name: skill.name,
      type: skill.type,
      translatedType: skill.translatedType,
      percentageMatch: 0,
      validatedSkill: false,
    };

    const copy = _.cloneDeep( kanbanData );

    if ( copy.data[columnOriginIndex]?.items ) {
      copy.data[columnOriginIndex].items = copy.data[columnOriginIndex].items
        .filter(( item ) => item.skillId !== skill.id );
    }

    copy.data[columnIndex].items.push( newSkill );

    try {
      const response = await createOrUpdateStudentSkill({
        variables: {
          skillStatus: column,
          skillId: skill.id,
          studentId,
          fromStatus: columnOrigin,
          jobTitleId,
        },
      });

      if ( columnOrigin === SkillStatusEnum.COMPLETED || column === SkillStatusEnum.COMPLETED ) {
        refetchStudentData?.();
      }
      const responseNotification = response.data?.createOrUpdateStudentSkill?.studentNotification;

      setLoadingHandler( false, setLoading );
      notificationOps.addSkillNotification(
        {
          action: AddNotificationActionsEnum.ADD,
          data: {
            skillId: skill.id,
            skillName: skill.name,
            toStatus: column,
            fromStatus: columnOrigin,
            databaseNotification: responseNotification ? {
              id: responseNotification.id,
              date: responseNotification.date,
              type: responseNotification.type as NotificationType,
              skillName: skill.name,
            } : undefined,
          },
        },
      );

      setKanbanDataOrganized( copy );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch ( err: any ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
      refetchKanbanData();
      setLoadingHandler( false, setLoading );

      return;
    }

    Mixpanel.track({
      action: MixpanelEventsEnum.ADD_SKILL,
      data: {
        screen: getMixpanelName( pathname.concat( search )),
        component: mixpanelEvents?.component ?? '',
        action: 'click',
        skillName: skill.name,
        skillStatus: column,
        skillType: skill.type,
        recommended: mixpanelEvents?.recommended ?? false,
      },
    }, isLearner );
    updateRecommendedSkills( skill.id );
  };

  const reorderSkill = ( columnData: IKanbanVar, originIndex: number, destinationIndex: number ): ISkillKanban[] => {
    const columnItems = columnData.items;
    const itemSelected = filterKanbanData( columnItems, typeFilter, jobTitleFilter )[originIndex];
    const indexToMove = columnItems.findIndex(({ skillId }) => itemSelected.skillId === skillId );

    if ( indexToMove < 0 ) { return columnItems; }

    const [itemToMove] = columnItems.splice( indexToMove, 1 );

    if ( destinationIndex > 0 ) {
      const itemBelow = filterKanbanData( columnItems, typeFilter, jobTitleFilter )[destinationIndex - 1];
      const indexDestination = columnItems.findIndex(({ skillId }) => itemBelow.skillId === skillId ) + 1;

      columnItems.splice( indexDestination, 0, itemToMove );
    } else {
      columnItems.splice( 0, 0, itemToMove );
    }

    return columnItems;
  };

  const updateSkillStatus = async (
    skillId: string,
    skillName: string, skillStatus: string, originColumn: string, skillType: string,
  ): Promise<void> => {
    try {
      const response = await updateStudentSkillStatus({
        variables: {
          skillStatus,
          skillId,
          studentId,
          fromStatus: originColumn,
        },
      });

      if ( skillStatus === SkillStatusEnum.COMPLETED || originColumn === SkillStatusEnum.COMPLETED ) {
        refetchStudentData();
      }
      const responseNotification = response.data?.updateStudentSkillStatus.studentNotification;

      notificationOps.addSkillNotification({
        action: AddNotificationActionsEnum.MODIFY,
        data: {
          skillId,
          skillName,
          fromStatus: originColumn,
          toStatus: skillStatus,
          databaseNotification: responseNotification ? {
            id: responseNotification.id,
            date: responseNotification.date,
            type: responseNotification.type as NotificationType,
            skillName,
          } : undefined,
        },
      });
    } catch ( err: any ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
      refetchKanbanData();

      return;
    }
    Mixpanel.track({
      action: MixpanelEventsEnum.MODIFY_SKILL,
      data: {
        skillName,
        skillStatus,
        skillType,
        fromStatus: originColumn,
        screen: getMixpanelName( `${pathname}${search}` ),
        component: MixpanelComponentsNames.KANBAN_BOARD,
        action: 'click',
      },
    }, isLearner );
  };

  const changeSkill = (
    dataCopy: IKanbanCache,
    originColumnIndex: number,
    destinationColumnIndex: number,
    result: IDragEndParam,
  ): void => {
    const { source, destination } = result;
    const copyOfData = _.cloneDeep( dataCopy );
    const originSkills = dataCopy.data[originColumnIndex].items;
    const destinationSkills = dataCopy.data[destinationColumnIndex].items;
    const destinationColumn = dataCopy.data[destinationColumnIndex];
    const originColumn = dataCopy.data[originColumnIndex];

    const itemSelected = filterKanbanData( originSkills, typeFilter, jobTitleFilter )[source.index];
    const indexItemMove = originSkills.findIndex(({ skillId }) => itemSelected.skillId === skillId );

    if ( indexItemMove < 0 ) { return; }

    const [itemToMove] = originSkills.splice( indexItemMove, 1 );

    itemToMove.status = destinationColumn.id;

    if ( destination.index > 0 ) {
      const itemBelow = filterKanbanData( destinationSkills, typeFilter, jobTitleFilter )[destination.index - 1];
      const indexDestination = destinationSkills.findIndex(({ skillId }) => itemBelow.skillId === skillId ) + 1;

      destinationSkills.splice( indexDestination, 0, itemToMove );
    } else {
      destinationSkills.splice( 0, 0, itemToMove );
    }

    copyOfData.data[originColumnIndex].items = originSkills;
    copyOfData.data[destinationColumnIndex].items = destinationSkills;

    setKanbanDataOrganized( copyOfData );

    updateSkillStatus(
      itemToMove.skillId, itemToMove.name, destinationColumn.id, originColumn.id, itemToMove.type,
    );
  };

  const moveSkill = ( result: IDragEndParam ): void => {
    if ( !kanbanData.data ) {
      return;
    }
    const { source, destination } = result;
    const originColumnIndex: number = kanbanData.data.findIndex(({ id }) => id === source.droppableId );
    const destinationColumnIndex: number = kanbanData.data.findIndex(({ id }) => id === destination.droppableId );
    const destinationColumn = kanbanData.data[destinationColumnIndex];
    const copyOfData = _.cloneDeep( kanbanData );

    if ( originColumnIndex === destinationColumnIndex ) {
      copyOfData.data[destinationColumnIndex].items = reorderSkill(
        destinationColumn, source.index, destination.index,
      );

      setKanbanDataOrganized( copyOfData );
    } else {
      changeSkill(
        kanbanData,
        originColumnIndex,
        destinationColumnIndex,
        result,
      );
    }
  };

  const afterDrag = ( result: IDragEndParam ): void => {
    const { destination } = result;

    if ( !destination ) {
      return;
    }
    moveSkill( result );
  };

  const clearKanbanData = (): void => {
    setKanbanDataOrganized( emptyKanbanDataCache );
  };

  const setKanbanDataError = ( error: ApolloError | undefined ): void => {
    const copyOfData = _.cloneDeep( kanbanData );

    copyOfData.error = error;
    setKanbanDataOrganized( copyOfData );
  };

  const setKanbanData = ( data: IGetStudentKanbanSkilltrackerAll[]): void => {
    const backlogSkills = data.filter(({ status }) => status === 'backlog' ).sort( compareValues({ key: 'percentageMatch', order: 'desc' }));
    const learningSkills = data.filter(({ status }) => status === 'learning' ).sort( compareValues({ key: 'percentageMatch', order: 'desc' }));
    const completedSkills = data.filter(({ status }) => status === 'completed' ).sort( compareValues({ key: 'percentageMatch', order: 'desc' }));

    const filteredData = [
      {
        id: StudentSkillStatus.TOLEARN,
        items: simplifyData( backlogSkills ),
        title: t( 'TO_LEARN' ),
      },
      {
        id: StudentSkillStatus.LEARNING,
        items: simplifyData( learningSkills ),
        title: t( 'LEARNING' ),
      },
      {
        id: StudentSkillStatus.COMPLETED,
        items: simplifyData( completedSkills ),
        title: t( 'COMPLETED' ),
      },
    ];

    const kanbanVar = {
      error: kanbanData.error,
      data: filteredData,
    };

    kanbanDataVar( kanbanVar );
    const encryptedData = encryptor( JSON.stringify( filteredData ));

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'kanbanData', encryptedData );
  };

  const refetchKanbanData = (): void => {
    getKanbanData({
      variables: {
        filters: ['specialized', 'human'],
        studentId,
      },
    });
  };

  const filterKanbanData = ( skills: ISkillKanban[], filterType: string, filterJobTitleId: string ): ISkillKanban[] => {
    const skillsId: string[] = [];

    jobPreferencesCache.data
      .filter(( jobPreference ) => jobPreference.jobTitleId === filterJobTitleId ).forEach(( jobTitle ) => {
        jobTitle.jobTitleSkillSet.forEach(( skillSet ) => {
          if ( !skillsId.includes( skillSet.skillId )) {
            skillsId.push( skillSet.skillId );
          }
        });
      });
    if ( filterType === 'all' && filterJobTitleId === 'all' ) {
      return skills;
    }
    if ( filterType === 'all' && filterJobTitleId !== 'all' ) {
      return skills.filter(( skill ) => skillsId.includes( skill.skillId ));
    }
    if ( filterType !== 'all' && filterJobTitleId === 'all' ) {
      return skills.filter(({ type }) => type === filterType );
    }

    return skills.filter(({ type }) => type === filterType ).filter(({ skillId }) => skillsId.includes( skillId ));
  };

  const setKanbanDataOrganized = ( data: IKanbanCache ): void => {
    kanbanDataVar( data );
    const encryptedData = encryptor( JSON.stringify( data.data ));

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'kanbanData', encryptedData );
  };

  const setFromLocalKanbanDataOrganized = (): boolean => {
    const dataLocal = sessionStorage.getItem( 'kanbanData' );
    const decryptedData = decryptor( dataLocal ?? '' );

    if ( !decryptedData ) {
      return false;
    }
    kanbanDataVar({ error: kanbanData.error, data: JSON.parse( decryptedData ) });

    return true;
  };

  const deleteSkill = async (
    skillId: string, skillName: string, mixpanelEvents: {component: string}, jobIdToUpdate?: string,
  ): Promise<void> => {
    const copyOfData = _.cloneDeep( kanbanData );

    const columnModify = copyOfData.data.find(
      ( column ) => column.items.find(( skill ) => skill.skillId === skillId ),
    );

    if ( !columnModify ) {
      return;
    }

    const skillToDelete = columnModify.items.find(( skill ) => skill.skillId === skillId );
    const columnToChange: IKanbanVar = {
      id: columnModify.id,
      title: columnModify.title,
      items: columnModify.items.filter(( skill ) => skill.skillId !== skillId ) || [],
    };

    const indexColumn = copyOfData.data.findIndex(( column ) =>
      column.items.find(( skill ) => skill.skillId === skillId ));

    copyOfData.data.splice( indexColumn, 1, columnToChange );

    if ( columnToChange && columnToChange.id ) {
      try {
        const response = await deleteStudentSkillsMutation({
          variables: {
            studentId,
            skillIdList: [skillId],
            jobTitleId: jobIdToUpdate,
          },
        });

        refetchStudentData?.();

        setKanbanDataOrganized( copyOfData );

        const responseNotification = response.data?.deleteStudentSkills.studentNotification;

        notificationOps.addSkillNotification({
          action: AddNotificationActionsEnum.DELETE,
          data: {
            skillId,
            skillName: skillName ?? '',
            fromStatus: columnModify.id,
            databaseNotification: responseNotification ? {
              id: responseNotification.id,
              date: responseNotification.date,
              type: responseNotification.type as NotificationType,
              skillName: skillName ?? '',
            } : undefined,
          },
        });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch ( err: any ) {
        notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
        refetchKanbanData();
      }
    } else {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG_PLAIN' ), t( 'OOPS' ));
    }

    Mixpanel.track({
      action: MixpanelEventsEnum.DELETE_SKILL,
      data: {
        skillName: skillToDelete?.name ?? '',
        skillStatus: skillToDelete?.status ?? '',
        skillType: skillToDelete?.type ?? '',
        screen: getMixpanelName( `${pathname}${search}` ),
        action: 'click',
        component: mixpanelEvents?.component ?? '',
      },
    }, isLearner );

    setKanbanDataOrganized( copyOfData );
  };

  const hasKanbanDataAfterFilter = (
    data: IKanbanCache,
    filterType: string,
    filterJobTitleId: string,
  ) : boolean => {
    const skillsNumber = data.data.reduce(( accumulatedLength, currentData ) =>
      accumulatedLength + filterKanbanData( currentData.items, filterType, filterJobTitleId ).length, 0 );

    return skillsNumber > 0;
  };

  const getSkillsAcquiredByStudentInAJob = (
    skillsInJob: IJobTitleSkillSetVar[],
  ): IGetSkillsAcquiredByStudentInAJob => {
    const studentSkillsAcquired = kanbanData.data.find(( column ) => column.id === 'completed' )?.items ?? [];

    const studentAcquiredSkills: IJobTitleSkillSetVar[] = [];
    const studentMissingSkills: IJobTitleSkillSetVar[] = [];

    skillsInJob.forEach(( skill ) => {
      const itemMatched = studentSkillsAcquired.find(( studentSkill ) => studentSkill.skillId === skill.skillId );

      if ( itemMatched ) {
        studentAcquiredSkills.push( skill );
      } else {
        studentMissingSkills.push( skill );
      }
    });

    return {
      studentAcquiredSkills,
      studentMissingSkills,
    };
  };

  const updateSkillStatusFromSkillSet = async (
    skillId: string,
    skillName: string,
    skillStatus: string,
    originColumn: string,
  ): Promise<void> => {
    try {
      await updateStudentSkillStatus({
        variables: {
          skillStatus,
          skillId,
          studentId,
          fromStatus: originColumn,
        },
      });

      if ( skillStatus === SkillStatusEnum.COMPLETED || originColumn === SkillStatusEnum.COMPLETED ) {
        refetchStudentData();
      }
    } catch ( err: any ) {
      refetchKanbanData();
    }
  };

  return {
    operations: {
      afterDrag,
      addSkill,
      setKanbanData,
      clearKanbanData,
      setKanbanDataError,
      refetchKanbanData,
      filterKanbanData,
      setKanbanDataOrganized,
      setFromLocalKanbanDataOrganized,
      deleteSkill,
      hasKanbanDataAfterFilter,
      getSkillsAcquiredByStudentInAJob,
      kanbanData,
      updateSkillStatusFromSkillSet,
    },
  };
};

export default useKanbanDataHook;
