import './SidebarProfileConfigurationConfig';
import { TFunction } from 'i18next';

import SvgIconStyle from '../../../components/template-minimal/SvgIconStyle';
import icons from '../../../constants/icons';
import { PATH_DASHBOARD_STUDENT } from '../../../routes/paths';

interface IItemAttributes {
  title: string;
  path: string;
  icon: JSX.Element;
}

interface IItemsConfiguration {
  items: IItemAttributes[];
}

const getIcon = ( icon: string ): JSX.Element => (
  <SvgIconStyle src={icon} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  personal: getIcon( icons.PROFILE_ICON ),
  skills: getIcon( icons.PENCIL_RULER_ICON ),
  experience: getIcon( icons.JOB_ICON ),
};

const SidebarProfileConfiguration = ( t: TFunction ): IItemsConfiguration[] => [
  {
    items: [
      { title: t( 'PERSONAL' ), path: PATH_DASHBOARD_STUDENT.profile.profile, icon: ICONS.personal },
      { title: t( 'SKILLS' ), path: PATH_DASHBOARD_STUDENT.profile.skills, icon: ICONS.skills },
    ],
  },
];

export default SidebarProfileConfiguration;
