interface IErrorsSso {
  errorCode: string
  translation: string
}

export const handleErrorSSo = ( error: string ): IErrorsSso => {
  if ( error.includes( 'AUTH-1000' )) {
    return {
      errorCode: 'AUTH-1000',
      translation: 'ERROR_AUTH_GENERIC',
    };
  }
  if ( error.includes( 'AUTH-1001' )) {
    return {
      errorCode: 'AUTH-1001',
      translation: 'ERROR_AUTH_1001',
    };
  }
  if ( error.includes( 'AUTH-1002' )) {
    return {
      errorCode: 'AUTH-1002',
      translation: 'ERROR_AUTH_1002',
    };
  }
  if ( error.includes( 'AUTH-2000' )) {
    return {
      errorCode: 'AUTH-2000',
      translation: 'ERROR_AUTH_GENERIC',
    };
  }
  if ( error.includes( 'AUTH-2001' )) {
    return {
      errorCode: 'AUTH-2001',
      translation: 'ERROR_AUTH_GENERIC',
    };
  }
  if ( error.includes( 'AUTH-3000' )) {
    return {
      errorCode: 'AUTH-3000',
      translation: 'ERROR_AUTH_3000',
    };
  }
  if ( error.includes( 'AUTH-3001' )) {
    return {
      errorCode: 'AUTH-3001',
      translation: 'ERROR_AUTH_3001',
    };
  }

  return {
    errorCode: 'AUTH-0001',
    translation: 'ERROR_AUTH_GENERIC',
  };
};
