import { gql, MutationTuple, useMutation } from '@apollo/client';

export const UPDATE_STUDENT_LEANING_PATH_STATUS = gql`
mutation UpdateStudentLearningPathwayStatus($studentId: String!, $pathwayId: String!, $completed: Boolean!) {
  updateStudentLearningPathwayStatus(studentId: $studentId, pathwayId: $pathwayId, completed: $completed)
}
`;

interface IUpdateStudentLearningPathwayStatusParams {
  studentId: string;
  pathwayId: string;
  completed: boolean;
}

interface IUpdateStudentLearningPathwayStatusResponse {
  updateStudentLearningPathwayStatus: boolean;
}

export const useUpdateStudentLearningPathStatus = ():
MutationTuple<IUpdateStudentLearningPathwayStatusResponse, IUpdateStudentLearningPathwayStatusParams> =>
  useMutation<IUpdateStudentLearningPathwayStatusResponse, IUpdateStudentLearningPathwayStatusParams>(
    UPDATE_STUDENT_LEANING_PATH_STATUS,
  );
