import './SkillTrackerStatusSpanConfig';
import { useTranslation } from 'react-i18next';

import { ContainerStyled, IconStyled } from './styles/SkillTrackerStatusSpanStyles';
import icons from '../../../constants/icons';
import { skillStatusType } from '../ModalComponent/NewModalLayoutComponent';

interface ISkillTrackerStatusSpanProps {
  status: skillStatusType;
}

const SkillTrackerStatusSpan: React.FC<ISkillTrackerStatusSpanProps> = ({
  status,
}: ISkillTrackerStatusSpanProps ) => {
  const { t } = useTranslation( 'SkillTrackerStatusSpan' );
  const text = t( 'SKILL_TRACKER_TEXT' );

  const skillTrackerText = (): string => {
    switch ( status ) {
      case 'backlog':
        return text + ` ${t( 'BACKLOG_LABEL_TEXT' )}`.toLowerCase();

      case 'learning':
        return text + ` ${t( 'LEARNING_LABEL_TEXT' )}`.toLowerCase();

      case 'completed':
        return text + ` ${t( 'COMPLETED_LABEL_TEXT' )}`.toLowerCase();

      default:
        return text + ` ${t( 'BACKLOG_LABEL_TEXT' )}`.toLowerCase();
    }
  };

  return (
    <ContainerStyled>
      <IconStyled
        src={icons.SKILL_MODAL_STATUS}
      />
      {skillTrackerText()}
    </ContainerStyled>
  );
};

export default SkillTrackerStatusSpan;
