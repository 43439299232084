import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../resources/styleConfigs';
import DialogWrapper from '../../../wrappers/DialogWrapper';

export const DialogStyled = styled( DialogWrapper )((
  { desktopwidth, desktopheight }: { desktopwidth?: string | number, desktopheight?: string},
) => ({
  transition: useTheme().transitions.create( 'width', {
    duration: useTheme().transitions.duration.complex,
  }),
  '& .MuiDialog-container': {
    paddingInline: styleConfigs.marginPadding.custom32,
  },
  '& .MuiDialog-paper': {
    width: desktopwidth,
    maxHeight: desktopheight,
    height: desktopheight,
    margin: styleConfigs.marginPadding.empty,
  },
}));
