import { ReactiveVar } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { learningPathsByInstitutionVar } from '../../cache/learner/learningPathsByInstitutionReactiveVar';
import { learningPathsByObjectivesVar } from '../../cache/learner/learningPathsByObjectivesReactiveVar';
import { IStudentLearningPathsCache, studentLearningPathsVar } from '../../cache/learner/studentLearningPathsReactiveVar';
import { useMutationCreateStudentLearningPathway } from '../../operations/mutations/student/useCreateStudentLearningPathway/useCreateStudentLearningPathway';
import { useMutationDeleteCourseInStudentLearningPath } from '../../operations/mutations/student/useDeleteStudentLearningPathway/deleteCourseInStudentLearningPathway';
import { useMutationDeleteStudentLearningPathway } from '../../operations/mutations/student/useDeleteStudentLearningPathway/useDeleteStudentLearningPathway';
import { useUpdateCoursesLearningPathwayStatus } from '../../operations/mutations/student/useUpdateCoursesLearningPathwayStatus/useUpdateCoursesLearningPathwayStatus';
import { useUpdateStudentLearningPathStatus } from '../../operations/mutations/student/useUpdateStudentLearningPathStatus/useUpdateStudentLearningPathStatus';
import { IStudentLearningPathway } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';
import { useLearningPathsByInstitutionHook } from '../learningPathsByInstitutionHook/useLearningPathsByInstitutionHook';
import { useLearningPathsByObjectivesHook } from '../learningPathsByObjectivesHook/useLearningPathsByObjectivesHook';
import useNotificationsHook, { LearningPathNotificationActionsEnum } from '../notificationsHook/useNotificationsHook';
import userAuth from '../userAuth';
import useStudentRefetch from '../useStudentRefetch';

export interface IStudentLearningPathsHook {
  operations: {
    setStudentLearningPaths: ( studentLearningPaths: IStudentLearningPathway[]) => void;
    addStudentLearningPath: ( studentLearningPath: IStudentLearningPathway ) => Promise<void>;
    toggleCompletedLearningPath: ( learningPathId: string ) => Promise<void>;
    deleteStudentLearningPath: ( learningPathId: string ) => Promise<void>;
    toggleCompletedCourseInStudentLearningPath: (
      pathwayId: string, pathwayCourseId: string, completed: boolean ) => Promise<void>;
    deleteCourseInStudentLearningPath: ( learningPathId: string, courseId: string ) => Promise<void>;
    studentLearningPathsData: IStudentLearningPathsCache;
  }
}

const useStudentLearningPathsHook = (
  studentLearningPathsVarProp: ReactiveVar<IStudentLearningPathsCache>,
): IStudentLearningPathsHook => {
  const studentLearningPathsData = studentLearningPathsVarProp();
  const [createStudentLearningPath] = useMutationCreateStudentLearningPathway();
  const [updateStudentLearningPathStatus] = useUpdateStudentLearningPathStatus();
  const [updateCoursesLearningPathwayStatus] = useUpdateCoursesLearningPathwayStatus();
  const [deleteStudentLearningPathway] = useMutationDeleteStudentLearningPathway();
  const [deleteCourseInStudentLearningPathStatus] = useMutationDeleteCourseInStudentLearningPath();
  const { operations: learningPathsByObjectivesOps } = useLearningPathsByObjectivesHook( learningPathsByObjectivesVar );
  const { operations: learningPathsByInstitutionOps } = useLearningPathsByInstitutionHook(
    learningPathsByInstitutionVar,
  );
  const { auth } = userAuth();
  const { operations: notificationOps } = useNotificationsHook();
  const { refetchPathways } = useStudentRefetch();
  const studentId: string = auth?.student?.id ?? '';
  const { t } = useTranslation( 'useStudentLearningPathsHook' );

  const setStudentLearningPaths = ( studentLearningPaths: IStudentLearningPathway[]): void => {
    studentLearningPathsVar({
      error: undefined,
      data: studentLearningPaths,
    });
  };

  const addStudentLearningPath = async ( studentLearningPath: IStudentLearningPathway ): Promise<void> => {
    const newStudentLearningPaths = [...studentLearningPathsData.data, studentLearningPath];

    try {
      await createStudentLearningPath({
        variables: {
          studentId,
          pathwayId: studentLearningPath.pathwayId,
        },
      });
    } catch ( err: unknown ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));

      return;
    }
    learningPathsByObjectivesOps.deleteLearningPathsByObjectives( studentLearningPath.pathwayId );
    learningPathsByInstitutionOps.deleteLearningPathsByInstitution( studentLearningPath.pathwayId );
    setStudentLearningPaths( newStudentLearningPaths );
  };

  const toggleCompletedLearningPath = async ( learningPathId: string ): Promise<void> => {
    const tempData = cloneDeep( studentLearningPathsData.data );
    const learningPathIndex = tempData.findIndex(( learningPath ) => learningPath.pathwayId === learningPathId );

    if ( learningPathIndex >= 0 ) {
      const newStatus = !tempData[learningPathIndex].completed;

      try {
        await updateStudentLearningPathStatus({
          variables: {
            studentId,
            pathwayId: learningPathId,
            completed: newStatus,
          },
        });
      } catch ( err: any ) {
        notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));

        return;
      }

      if ( newStatus ) {
        notificationOps.addLearningPathNotification( LearningPathNotificationActionsEnum.COMPLETE );
      } else {
        notificationOps.addLearningPathNotification( LearningPathNotificationActionsEnum.REACTIVATE );
      }

      tempData[learningPathIndex].completed = newStatus;
      setStudentLearningPaths( tempData );
    }
  };

  const toggleCompletedCourseInStudentLearningPath = async (
    pathwayId: string,
    pathwayCourseId: string,
    completed: boolean,
  ): Promise<void> => {
    try {
      await updateCoursesLearningPathwayStatus({
        variables: {
          pathwayId,
          studentId,
          pathwayCourseId,
          completed: !completed,
        },
      });
    } catch ( err: unknown ) {
      const message = err instanceof SyntaxError ? err.message : t( 'ERROR' );

      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), message );
    }
  };

  const deleteStudentLearningPath = async ( learningPathId: string ): Promise<void> => {
    const tempData = cloneDeep( studentLearningPathsData.data );
    const learningPathIndex = tempData.findIndex(( learningPath ) => learningPath.pathwayId === learningPathId );

    if ( learningPathIndex >= 0 ) {
      try {
        await deleteStudentLearningPathway({
          variables: {
            studentId,
            pathwayId: learningPathId,
          },
        });
      } catch ( err: any ) {
        notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));

        return;
      }
      tempData.splice( learningPathIndex, 1 );
      setStudentLearningPaths( tempData );
      refetchPathways?.();
    }
  };

  const deleteCourseInStudentLearningPath = async (
    learningPathId: string,
    pathwayCourseId: string,
  ): Promise<void> => {
    const tempData = cloneDeep( studentLearningPathsData.data );
    const learningPathIndex = tempData.findIndex(( learningPath ) => learningPath.pathwayId === learningPathId );

    if ( learningPathIndex < 0 ) {
      return;
    }

    const newCourseNumber = tempData[learningPathIndex].coursesNumber - 1;

    try {
      await deleteCourseInStudentLearningPathStatus({
        variables: {
          pathwayCourseId,
        },
      });
    } catch ( err: unknown ) {
      const message = err instanceof SyntaxError ? err.message : t( 'ERROR' );

      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), message );
    }

    tempData[learningPathIndex].coursesNumber = newCourseNumber;
    setStudentLearningPaths( tempData );
  };

  return {
    operations: {
      setStudentLearningPaths,
      addStudentLearningPath,
      toggleCompletedLearningPath,
      deleteStudentLearningPath,
      toggleCompletedCourseInStudentLearningPath,
      deleteCourseInStudentLearningPath,
      studentLearningPathsData,
    },
  };
};

export default useStudentLearningPathsHook;
