/* eslint-disable import/order */
import { ENVIRONMENT } from '../config';

import mixpanel from 'mixpanel-browser';

import { IMixpanelEvents } from './MixpanelEvents/mixpanelEventsInterface';
import environmentVariables from '../appVariables';

const envCheck = ENVIRONMENT === 'production';
// eslint-disable-next-line camelcase
const admin = mixpanel.init( environmentVariables?.mixpanelToken ?? '', envCheck ? { api_host: environmentVariables?.proxyMixpanel } : {}, 'admin' );
// eslint-disable-next-line camelcase
const student = mixpanel.init( environmentVariables?.mixpanelStudentToken ?? '', envCheck ? { api_host: environmentVariables?.proxyMixpanel } : {}, 'student' );

const actions = {
  identify: ( id: string, isStudent?: boolean ): void => {
    if ( envCheck ) {
      if ( !isStudent ) {
        admin.identify( id );
      } else {
        student.identify( id );
      }
    }
  },
  alias: ( id: string, isStudent?: boolean ): void => {
    if ( envCheck ) {
      if ( !isStudent ) {
        admin.alias( id );
      } else {
        student.alias( id );
      }
    }
  },
  track: ( event: IMixpanelEvents, isStudent?: boolean ): void => {
    if ( envCheck ) {
      if ( !isStudent ) {
        admin.track( event.action, event.data );
      } else {
        student.track( event.action, event.data );
      }
    }
  },
  people: {
    set: ( props: any, isStudent?: boolean ): void => {
      if ( envCheck ) {
        if ( !isStudent ) {
          admin.people.set( props );
        } else {
          student.people.set( props );
        }
      }
    },
  },
  reset: (): void => {
    admin?.reset();
    student?.reset();
  },
  timeEvent: ( name: string, isStudent?: boolean ): void => {
    if ( envCheck ) {
      if ( !isStudent ) {
        admin.time_event( ` ${name}` );
      } else {
        student.time_event( ` ${name}` );
      }
    }
  },
};

export const Mixpanel = actions;
