import { makeVar } from '@apollo/client';

import { IRenderNotification } from '../../operations/mutations/student/useMutationUpdateStudentSkillStatus/useMutationUpdateStudentSkillStatus';

export const emptyNotifications: IRenderNotification = {
  newNotifications: [],
  beforeNotifications: [],
};

export const renderNotification = makeVar<IRenderNotification>( emptyNotifications );
