import { Theme } from '@mui/material/styles';

import { styleConfigs } from '../../components/resources/styleConfigs';
import palette from '../learnerPalette';

export default function Tooltip( theme: Theme ): unknown {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 0 : 800],
          color: isLight
            ? palette.light.text.mistBlue
            : theme.palette.grey[0],
          fontSize: styleConfigs.fonts.sizeText,
          filter: `drop-shadow(0px 0px 2px ${palette.light.boxShadows.cadetGrey})
          drop-shadow(0px 12px 24px ${palette.light.boxShadows.cadetGrey})`,
          paddingLeft: styleConfigs.fonts.sizeCustom12,
          paddingRight: styleConfigs.fonts.sizeCustom12,
          paddingTop: styleConfigs.fonts.sizeCustom10,
          paddingBottom: styleConfigs.fonts.sizeText,
        },
        arrow: {
          color: theme.palette.grey[isLight ? 0 : 800],
        },
      },
    },
  };
}
