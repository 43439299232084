import {
  QueryTuple,
  useLazyQuery,
  gql,
  useQuery,
  QueryResult,
  ApolloError,
} from '@apollo/client';

import { client } from '../../../../apollo/ApolloConfig';
import { skillStatusType } from '../../../../components/generics/ModalComponent/ModalLayoutComponent';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const GET_STUDENT_KANBAN_SKILLTRACKER = gql`
  query GetStudentKanbanSkilltrackerAll($filters: [String]!, $studentId: String!) {
    getStudentKanbanSkilltrackerAll(filters: $filters, studentId: $studentId) {
      id
      studentId
      skillId
      status
      percentageMatch
      isFromCv
      validatedSkill
      tblSkill {
        id
        name
        type
        translatedType
      }
    }
  }
`;

export interface IGetStudentKanbanSkilltrackerAllParams {
  filters: string[];
  studentId: string;
}

export interface IGetStudentKanbanSkilltrackerAll {
  id: number;
  studentId: string;
  skillId: string;
  status: skillStatusType;
  percentageMatch?: number;
  isFromCv?: boolean;
  validatedSkill: boolean;
  tblSkill: {
    id: string;
    name: string;
    type: string;
    translatedType?: string;
  }
}

export interface IGetStudentKanbanSkilltrackerAllResponse {
  getStudentKanbanSkilltrackerAll: IGetStudentKanbanSkilltrackerAll[];
}

export interface IGetStudentKanbanSkillTrackerRefetchResponse {
  data: IGetStudentKanbanSkilltrackerAllResponse;
  error: ApolloError | undefined;
}

export const useLazyGetStudentKanbanSkilltrackerAll = ( fromCache?: boolean ):
QueryTuple<IGetStudentKanbanSkilltrackerAllResponse, IGetStudentKanbanSkilltrackerAllParams> =>
  useLazyQuery<IGetStudentKanbanSkilltrackerAllResponse, IGetStudentKanbanSkilltrackerAllParams>(
    GET_STUDENT_KANBAN_SKILLTRACKER,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetStudentKanbanSkilltrackerAll = (
  filters: string[], studentId: string, jobTitleId?: string, fromCache?: boolean,
):
QueryResult<IGetStudentKanbanSkilltrackerAllResponse, IGetStudentKanbanSkilltrackerAllParams> =>
  useQuery<IGetStudentKanbanSkilltrackerAllResponse, IGetStudentKanbanSkilltrackerAllParams>(
    GET_STUDENT_KANBAN_SKILLTRACKER, {
      variables: { filters, studentId },
      ...getPolices( fromCache ),
    },
  );

export const getStudentKanbanSkillTrackerAllRefetch = async (
  filters: string[], studentId: string,
): Promise<IGetStudentKanbanSkillTrackerRefetchResponse> => {
  const data = await client.query({
    query: GET_STUDENT_KANBAN_SKILLTRACKER,
    variables: { filters, studentId },
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data.error,
  };
};
