import {
  ApolloError,
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { client } from '../../../../apollo/ApolloConfig';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const LEARNING_PATHS_INITIAL = gql`
query GetStudentPathwaysInitial {
  getStudentPathways {
    id
    pathwayId
    name
    imageUrl
    completed
    providerId
    providerUrl
    coursesNumber
  }
  getPathwaysByInstitutionAndOccupations {
    majorId
    majorName
    paths {
      id
      name
      providerId
      imageUrl
      description
      providerUrl
      coursesNumber
    }
  }
  getPathwaysByObjectives {
    id
    name
    imageUrl
    description
    providerId
    providerUrl
    coursesNumber
    occupationName
  }
}
`;

export const LEARNING_PATHS_REFETCH = gql`
query GetStudentPathwaysInitial {
  getPathwaysByInstitutionAndOccupations {
    majorId
    majorName
    paths {
      id
      name
      providerId
      imageUrl
      providerUrl
      coursesNumber
    }
  }
  getPathwaysByObjectives {
    id
    name
    imageUrl
    providerUrl
    coursesNumber
    occupationName
  }
}
`;

export const STUDENT_LEARNING_PATHS_REFETCH = gql`
query GetStudentPathwaysInitial {
  getStudentPathways {
    id
    pathwayId
    name
    imageUrl
    providerId
    providerUrl
    coursesNumber
    completed
  }
}
`;

export interface ILearningPathway {
  id: string;
  name: string;
  imageUrl: string;
  providerId?: string;
  providerUrl: string;
  coursesNumber: number;
}

export interface ILearningPathsByInstitution {
  majorId: string;
  majorName: string;
  paths: ILearningPathway[];
}

export interface ILearningPathwaysByObjectives extends ILearningPathway {
  occupationName: string;
}

export interface IStudentLearningPathway extends ILearningPathway {
  completed: boolean;
  pathwayId: string;
}

export interface ILearningPathwaysByObjectiveId extends ILearningPathway {
  occupationId: string;
}

interface IGetStudentPathwaysResponse {
  getStudentPathways: IStudentLearningPathway[];
  getPathwaysByInstitutionAndOccupations: ILearningPathsByInstitution[];
  getPathwaysByObjectives: ILearningPathwaysByObjectives[];
}
interface IGetPathwaysRefetchResponse {
  getPathwaysByInstitutionAndOccupations: ILearningPathsByInstitution[];
  getPathwaysByObjectives: ILearningPathwaysByObjectives[];
}

interface IGetStudentPathwaysRefetchResponse {
  getStudentPathways: IStudentLearningPathway[];
}

interface IGetStudentPathwaysRefetchResponseComplete {
  data: IGetStudentPathwaysRefetchResponse;
  error: ApolloError | undefined;
}

interface IGetPathwaysRefetchResponseComplete {
  data: IGetPathwaysRefetchResponse;
  error: ApolloError | undefined;
}

export const useQueryGetLearningPathsInitial = (
  fromCache?: boolean,
): QueryResult<IGetStudentPathwaysResponse, any> =>
  useQuery<IGetStudentPathwaysResponse, any>(
    LEARNING_PATHS_INITIAL,
    {
      ...getPolices( fromCache ),
    },
  );

export const useLazyGetLearningPathsInitial = (
  fromCache?: boolean,
): QueryTuple<IGetStudentPathwaysResponse, any> =>
  useLazyQuery<IGetStudentPathwaysResponse, any>(
    LEARNING_PATHS_INITIAL,
    { ...getPolices( fromCache ) },
  );

export const getPathwaysRefetch = async (
): Promise<IGetPathwaysRefetchResponseComplete> => {
  const data = await client.query({
    query: LEARNING_PATHS_REFETCH,
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data?.error,
  };
};

export const getStudentPathwaysRefetch = async (
): Promise<IGetStudentPathwaysRefetchResponseComplete> => {
  const data = await client.query({
    query: STUDENT_LEARNING_PATHS_REFETCH,
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data?.error,
  };
};
