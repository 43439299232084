import { gql, MutationTuple, useMutation } from '@apollo/client';

export const CHANGE_PREFERRED_LANGUAGE = gql`
  mutation ChangePreferredLanguage($preferredLanguage: NonEmptyString!) {
    changePreferredLanguage(preferredLanguage: $preferredLanguage) {
      jwtToken
    }
  }
`;

interface IChangePreferredLanguageParams {
  preferredLanguage: string;
}

interface IChangePreferredLanguageResponse {
  changePreferredLanguage: {
    jwtToken: string;
  };
}

export const useMutationChangePreferredLanguage = (): MutationTuple<
IChangePreferredLanguageResponse,
IChangePreferredLanguageParams
> => useMutation<IChangePreferredLanguageResponse, IChangePreferredLanguageParams>( CHANGE_PREFERRED_LANGUAGE );
