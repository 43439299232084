import {
  gql, QueryHookOptions, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_INSTITUTION_CUSTOM_TEXTS = gql`
  query GetInstitutionCustomTexts($institutionId: String!, $languageId: LanguageId!) {
    getInstitutionCustomTexts(institutionId: $institutionId, languageId: $languageId) {
      textKey
      languageId
      textValue
    }
  }
`;

export interface IGetInstitutionCustomTextsParams {
  institutionId: string;
  languageId: string;
}

export interface IGetInstitutionCustomTexts{
  textKey: string;
  languageId: string;
  textValue: string;
}

export interface IGetInstitutionCustomTextsResponse {
  getInstitutionCustomTexts: IGetInstitutionCustomTexts[]
}

export const useLazyGetInstitutionCustomTexts = ( fromCache?: boolean )
: QueryTuple<IGetInstitutionCustomTextsResponse, IGetInstitutionCustomTextsParams> =>
  useLazyQuery<IGetInstitutionCustomTextsResponse, IGetInstitutionCustomTextsParams>(
    GET_INSTITUTION_CUSTOM_TEXTS,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetInstitutionCustomTexts = (
  { institutionId, languageId }: IGetInstitutionCustomTextsParams,
  options?: QueryHookOptions<IGetInstitutionCustomTextsResponse, IGetInstitutionCustomTextsParams>,
):
QueryResult<IGetInstitutionCustomTextsResponse, IGetInstitutionCustomTextsParams> =>
  useQuery<IGetInstitutionCustomTextsResponse, IGetInstitutionCustomTextsParams>(
    GET_INSTITUTION_CUSTOM_TEXTS, {
      variables: { institutionId, languageId },
      ...options,
    },
  );
