import './DashboardStudentLayoutConfig';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { RootStyled, MainStyled } from './styles/DashboardStudentLayoutStyles';
import { useLoadersCache } from '../../../cache/common/loadersReactiveVar';
import ChangePasswordDialog from '../../../components/ChangePasswordDialog/ChangePasswordDialog';
import CircularLoaderComponent from '../../../components/generics/CircularLoaderComponent/CircularLoaderComponent';
import FooterComponent from '../../../components/generics/Footer/FooterComponent';
import Page from '../../../components/template-minimal/Page';
import { OCCUPATIONS_EXPLORE_PATHNAME } from '../../../constants/dashboardStudentLayout';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import StudentLayoutViewDataHook from '../../../hooks/StudentLayoutViewDataHook/StudentLayoutViewDataHook';
import DashboardNavbar from '../DashboardNavbar';
import DashboardSidebar from '../DashboardSidebar';

const DashboardStudentLayout = (): JSX.Element => {
  const { t } = useTranslation( 'DashboardStudentLayout' );
  const [open, setOpen] = useState<boolean>( false );

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>( false );
  const { pathname, search } = useLocation();
  const isFloatingButton: boolean = pathname.concat( search ).includes( OCCUPATIONS_EXPLORE_PATHNAME );
  const [iframeActive, setIframeActive] = useState<boolean>( false );
  const { features } = useContext( FeatureFlagsContext );
  const { loaders } = useLoadersCache();
  const isLoading = Boolean( loaders.length );
  const showFooter = features.learner?.showFooter;

  useEffect(() => {
    if ( features?.embededInIframe ) {
      setIframeActive( true );
    }
  }, [features?.embededInIframe]);

  return (
    <StudentLayoutViewDataHook>
      <Page title="1Mentor">
        <RootStyled>
          <DashboardNavbar
            onOpenSidebar={() => setOpen( true )}
            openChangePasswordDialog={() => setChangePasswordDialogOpen( true )}
            iframeActive={iframeActive}
          />
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen( false )}
            iframeActive={iframeActive}
          />
          <MainStyled
            isfloatingbutton={isFloatingButton ? 1 : 0}
          >
            <CircularLoaderComponent
              isLoading={isLoading}
              background="white"
              text={t( 'LOADER_TEXT' )}
              textColor="prussianBlue"
            />
            <Outlet />
            {showFooter && (
              <FooterComponent />
            )}
          </MainStyled>
          <ChangePasswordDialog
            open={changePasswordDialogOpen}
            setOpen={setChangePasswordDialogOpen}
          />
        </RootStyled>
      </Page>
    </StudentLayoutViewDataHook>
  );
};

export default DashboardStudentLayout;
