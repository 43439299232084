import {
  QueryTuple,
  useLazyQuery,
  gql,
  QueryResult,
  useQuery,
  ApolloError,
} from '@apollo/client';

import { client } from '../../../../apollo/ApolloConfig';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const WHEN_STUDENT_SKILL_SET_CHANGE_TRIGER = gql`
  query WhenStudentSkillSetChangeTrigger( $studentId: String!, $firsTime: Boolean!, $country: String ) {
    whenStudentSkillSetChangeTrigger( studentId: $studentId,  firsTime: $firsTime, country: $country )
  }
`;

interface IWhenStudentSkillSetChangeTrigerParams {
  studentId: string;
  firsTime: boolean;
  country?: string;
}

interface IWhenStudentSkillSetChangeTrigerResponse {
  whenStudentSkillSetChangeTrigger?: boolean;
}

interface IGetStudentSkilLSetChangeTrigerResponse {
  data: IWhenStudentSkillSetChangeTrigerResponse;
  error: ApolloError | undefined;
}

export const useLazyWhenStudentSkillSetChangeTriger = ( studentId: string, country?: string, fromCache?: boolean ):
QueryTuple<IWhenStudentSkillSetChangeTrigerResponse, IWhenStudentSkillSetChangeTrigerParams> =>
  useLazyQuery<IWhenStudentSkillSetChangeTrigerResponse, IWhenStudentSkillSetChangeTrigerParams>(
    WHEN_STUDENT_SKILL_SET_CHANGE_TRIGER,
    {
      variables: {
        studentId,
        firsTime: false,
        country,
      },
      ...getPolices( fromCache ),
    },
  );

export const useQueryWhenStudentSkillSetChangeTriger = (
  studentId: string,
  firsTime: boolean,
  country?: string,
):
QueryResult<IWhenStudentSkillSetChangeTrigerResponse, IWhenStudentSkillSetChangeTrigerParams> =>
  useQuery<IWhenStudentSkillSetChangeTrigerResponse, IWhenStudentSkillSetChangeTrigerParams>(
    WHEN_STUDENT_SKILL_SET_CHANGE_TRIGER, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        studentId,
        firsTime,
        country,
      },
    },
  );

export const getStudentSkillSetChangeTriger = async (
  studentId: string,
  firstTime: boolean,
  country?: string,
): Promise<IGetStudentSkilLSetChangeTrigerResponse> => {
  const data = await client.query({
    query: WHEN_STUDENT_SKILL_SET_CHANGE_TRIGER,
    variables: {
      studentId,
      firsTime: firstTime,
      country,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data?.error,
  };
};
