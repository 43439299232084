export const borders = {
  none: 'none',
  unset: 'unset',
  extraSmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  border1: 1,
  border2: 2,
  border4: 4,
  border15: 15,
  border16: 16,
  border3px: '3px',
  borderRound: '50%',
  borderDotted: 'dotted',
  borderLine2pxDotted: '2px dotted',
  borderLine1p5dashed: '1.5px dashed',
  borderSolid: 'solid',
  borderLine: '1px solid',
  borderLine1p5px: '1.5px solid',
  borderLine1p6px: '1.6px solid',
  borderLine2px: '2px solid',
  borderLine3px: '3px solid',
  borderLine4px: '4px solid',
  borderLine5px: '5px solid',
  borderLine6px: '6px solid',
  borderLine8px: '8px solid',
  borderSolidXSmall: '2px solid',
  borderSolidSmall: '3px solid',
  borderSolidMedium: '4px solid',
  borderSolidLarge: '8px solid',
  borderSkillInsightsCard: '10px solid ',
  borderSolidTransparent: 'solid transparent',
  borderLine1Gray: '1px solid #C4CDD5',
  width2px: '2px',
  width5px: '5px',
  empty: 0,
  radius2: 2,
  radius3: 3,
  radius4: 4,
  radius5: 5,
  radius6: 6,
  radius8: 8,
  radius10: 10,
  radius12: 12,
  radius16: 16,
  radius24: 24,
  radius50: 50,
  radius100: 100,
  customRadius3: 3,
  skillCardBoxShadows: '0 8px 16px',
  mainCardBoxShadowFirst: '0px 0px 2px rgba(145, 158, 171, 0.24)',
  mainCardBoxShadowSecond: '0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
  textWrapperEmptyCard: '0 0 16px 16px',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  boxShadowButtonAddRemoveSkill: '0px 8px 16px',
  radiusButtonsLeft: '4px 0 0 4px',
  radiusButtonsRight: '0 4px 4px 0',
  borderLineDashed: '1px dashed',
  backDashboardBoxShadow: '0 8px 16px 0',
  boxShadowCardsSkillInsights: '0 4px 8px',
  buttonFloatingPathways: '0 4px 4px',
  pathwaysBannerSkillSet: '0 2px 4px',
  carrouselButtonBoxShadowFirst: '0 24px 48px',
  carrouselButtonBoxShadowSecond: '0 0 4px',
  BannerSkillCard: '0 4px 8px',
};
