import './NotificationComponentConfig';
import { ClickAwayListener } from '@mui/material';
import React, { ReactNode, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import {
  RootStyle, IconButtonStyle, BodyStyle, ContainerIconStyle,
} from './styles/NotificationComponentStyles';
import { notificationsVar } from '../../../cache/cache';
import { NOTIFICATION_TEXT } from '../../../constants/notification';
import { NotificationContext } from '../../../contexts/NotificationsContext';
import palette from '../../../theme/learnerPalette';
import { styleConfigs } from '../../resources/styleConfigs';
import IconCloseWrapper from '../../wrappers/IconCloseWrapper';
import TypographyWrapper from '../../wrappers/TypographyWrapper';

interface INotificationComponentProps {
  title: string;
  description: string | ReactNode;
  leftIcon?: ReactNode;
  autoClose?: boolean;
  autoHideDuration?: number;
  id: string | number;
}

const NotificationComponent: React.FC<INotificationComponentProps> = ( props ) => {
  const {
    title, description, leftIcon, autoClose, id, autoHideDuration = 5000,
  } = props;

  const { t } = useTranslation( 'NotificationComponent' );

  const translateTitle = title === NOTIFICATION_TEXT.errorTitle
    ? t( NOTIFICATION_TEXT.errorTitle ) : title;
  const translateDescription = description === NOTIFICATION_TEXT.errorDescription
    ? t( NOTIFICATION_TEXT.errorDescription ) : description;

  const { showNotification, setShowNotification, deleteNotification } = useContext( NotificationContext );

  const handleChangeStatus = (): void => {
    setShowNotification( !showNotification );
    notificationsVar([]);
  };

  const handleCloseNotification = (): void => {
    setTimeout(() => {
      setShowNotification( false );
      deleteNotification( id );
      notificationsVar([]);
    }, autoHideDuration );
  };

  React.useEffect(() => {
    if ( autoClose ) {
      handleCloseNotification();
    }
  }, [title, leftIcon, autoClose]);

  const onClickAway = (): void => {
    setShowNotification( false );
    deleteNotification( id );
  };

  return (
    <ClickAwayListener
      onClickAway={onClickAway}
    >
      <RootStyle
        data-testid="containerNotification"
        show={showNotification ? 1 : 0}
      >
        {leftIcon}
        <BodyStyle>
          <TypographyWrapper
            variant="h6"
            fontFamily={styleConfigs.fonts.fontFamily}
            color={palette.light.text.ebonyClay}
          >
            {translateTitle}
          </TypographyWrapper>
          <TypographyWrapper
            variant="subtitle2"
            fontFamily={styleConfigs.fonts.fontFamily}
            color={palette.light.text.mistBlue}
          >
            {translateDescription}
          </TypographyWrapper>
        </BodyStyle>
        <ContainerIconStyle>
          <IconButtonStyle
            data-testid="btnClose"
            onClick={handleChangeStatus}
          >
            <IconCloseWrapper />
          </IconButtonStyle>
        </ContainerIconStyle>
      </RootStyle>
    </ClickAwayListener>
  );
};

export default NotificationComponent;
