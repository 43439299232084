import ListItemTextWrapper from '../../wrappers/ListItemTextWrapper';

interface IListItemTextProps {
  primary: string;
}

const ListItemTextComponent: React.FC<IListItemTextProps> = ( props ) => {
  const { primary } = props;

  return <ListItemTextWrapper disableTypography primary={primary} />;
};

export default ListItemTextComponent;
