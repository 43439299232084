import { FeaturesEnum } from '../constants/enums/FeaturesEnum';
import icons from '../constants/icons';

export const getCustomFavicon = ( str?: string ): string => {
  if ( !str ) {
    return icons.FAVICON as string;
  }
  const source = str.split( `${FeaturesEnum.customFavicon}-` )[1];

  return source === 'default' ? icons.FAVICON : source;
};
