import { makeVar, useReactiveVar } from '@apollo/client';

interface IUseLoadersCache {
  loaders: string[];
  addLoader: ( value: string ) => void;
  removeLoader: ( value: string ) => void;
  cleanUpLoader: () => void;
}

export const loadersVar = makeVar<string[]>([]);

export const useLoadersCache = (): IUseLoadersCache => {
  const loaders = useReactiveVar( loadersVar );

  const addLoader = ( loaderId: string ): void => {
    loadersVar([...loaders, loaderId]);
  };

  const removeLoader = ( loaderId: string ): void => {
    const newLoaders = loaders.filter(( loader ) => loader !== loaderId );

    loadersVar( newLoaders );
  };

  const cleanUpLoader = (): void => {
    loadersVar([]);
  };

  return {
    loaders,
    addLoader,
    removeLoader,
    cleanUpLoader,
  };
};
