const parseProviderString = ( str?: string ): string[] | undefined => {
  if ( !str ) {
    return undefined;
  }

  const elements = str.split( ',' );
  const formattedElements = elements.map(( element ) => element.replace( /_/g, ' ' ));

  return formattedElements;
};

export default parseProviderString;
