import { useTheme } from '@mui/material';

import { Palette } from '@mui/material/styles';
import { useContext } from 'react';

import { FeatureFlagsContext } from '../contexts/FeatureFlagsContext';

const useGetPaletteFromWhiteLabel = (): Palette | undefined => {
  const theme = useTheme();
  const { features } = useContext( FeatureFlagsContext );
  const paletteFromTheme = theme.palette;

  return features.whiteLabel ? paletteFromTheme : undefined;
};

export default useGetPaletteFromWhiteLabel;
