import { borders } from './borders';
import { dimensions } from './dimensions';
import { fonts } from './fonts';
import { marginPadding } from './marginPadding';

export const styleConfigs = {
  fonts,
  marginPadding,
  borders,
  dimensions,
};
