import { ComponentType } from 'react';

import { styleConfigs } from '../../components/resources/styleConfigs';
import modalActionTypes from '../actionTypes/modalActionTypes';

export interface IModalContentData {
  component: ComponentType,
  directProps?: any,
  previusProps?: any,
  desktopWidth?: string | number,
  mobileWidth?: string | number,
  desktopHeight?: string,
  mobileHeight?: string,
  mobileRoundedCorner?: boolean,
  mobileMarginInline?: string | number;
  hideCloseButton?: boolean,
  disableCloseOnPressScape?: boolean,
  disableCloseOnClickBackdrop?: boolean,
}

export interface IModalReducer {
  modalOpen: ( modalData: IModalContentData ) => void,
  modalClose: () => void,
  isOpen: boolean,
  contentData: IModalContentData,
}

export const modalReducerState: IModalReducer = {
  modalOpen: () => null,
  modalClose: () => null,
  isOpen: false,
  contentData: {
    component: () => null,
    directProps: null,
    desktopWidth: styleConfigs.dimensions.width900,
    mobileWidth: styleConfigs.dimensions.width100vw,
    desktopHeight: styleConfigs.dimensions.auto,
    mobileHeight: styleConfigs.dimensions.auto,
    mobileRoundedCorner: false,
    mobileMarginInline: styleConfigs.marginPadding.empty,
    hideCloseButton: false,
    disableCloseOnPressScape: true,
    disableCloseOnClickBackdrop: true,
  },
};

const modalReducer = (
  state: IModalReducer,
  action: { type: string, payload?: any },
): IModalReducer => {
  const defaultState = state || modalReducerState;

  switch ( action.type ) {
    case modalActionTypes.MODAL_SET_COMPONENT:
      return {
        ...defaultState,
        isOpen: true,
        contentData: { ...action.payload },
      };

    case modalActionTypes.MODAL_CLOSE:
      return {
        ...defaultState,
        isOpen: false,
      };

    default:
      return defaultState;
  }
};

export default modalReducer;
