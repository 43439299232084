import { Box, BoxProps } from '@mui/material';
import { motion } from 'framer-motion';

import { varSmallClick, varMediumClick } from './variants';

interface IButtonAnimateProps extends BoxProps {
  mediumClick?: boolean;
}

const ButtonAnimate: React.FC<IButtonAnimateProps> = ({
  mediumClick = false, sx, children, ...other
}) => (
  <Box
    variants={mediumClick ? varMediumClick : varSmallClick}
    component={motion.div}
    whileTap="tap"
    whileHover="hover"
    sx={{ display: 'flex', ...sx }}
    {...other}
  >
    {children}
  </Box>
);

export default ButtonAnimate;
