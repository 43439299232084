import { styled } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import BoxWrapper from '../../../wrappers/BoxWrapper';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import StackWrapper from '../../../wrappers/StackWrapper';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';
import IconComponent from '../../IconComponent/IconComponent';

export const EmptyStateWrapperStyled = styled( StackWrapper )((
  { gaptitlesbutton }: { gaptitlesbutton?: number},
) => ({
  width: styleConfigs.dimensions.percent100,
  height: styleConfigs.dimensions.percent100,
  alignItems: 'center',
  placeContent: 'center',
  textAlign: 'center',
  gap: gaptitlesbutton,
}));

export const StackEmptyStateStyled = styled( StackWrapper )((
  {
    margintopstackicon, marginbottomstackicon, directionicontitle, gapicontitle,
  }:
  {margintopstackicon?: number, marginbottomstackicon?: number, directionicontitle?: string, gapicontitle?: number},
) => ({
  flexFlow: `${directionicontitle} ${'wrap'} !important`,
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: margintopstackicon,
  marginBottom: marginbottomstackicon,
  gap: gapicontitle,
}));

export const EmptyStateIconStyled = styled( IconComponent )((
  {
    widthicon, heighticon, iconlinewidth, iconcolor,
  }: {widthicon?: number | string, heighticon?: number | string, iconlinewidth?: number, iconcolor?: string},
) => ({
  width: `${widthicon}px !important`,
  height: `${heighticon}px !important`,
  fontSize: `${iconlinewidth}px !important`,
  color: iconcolor,
}));

export const EmptyStateTitleStyled = styled( TypographyWrapper )((
  { fontsizetitle, colortitle }: {fontsizetitle?: string, colortitle?: string},
) => ({
  fontFamily: `${styleConfigs.fonts.fontFamily} !important`,
  fontWeight: `${styleConfigs.fonts.bold} !important`,
  fontSize: `${fontsizetitle} !important`,
  color: colortitle,
}));

export const EmptyStateSubtitleStyled = styled( TypographyWrapper )((
  {
    fontsizesubtitle, colorsubtitle, paddingtopsubtitle, maxwidthsubtitle,
  }:
  {fontsizesubtitle?: string, colorsubtitle?: string, paddingtopsubtitle?: string | number,
    maxwidthsubtitle?: string | number },
) => ({
  maxWidth: maxwidthsubtitle,
  alignSelf: 'center',
  fontFamily: `${styleConfigs.fonts.fontFamily} !important`,
  fontWeight: `${styleConfigs.fonts.weight400} !important`,
  fontSize: `${fontsizesubtitle} !important`,
  color: colorsubtitle,
  paddingTop: paddingtopsubtitle,
}));

export const BoxEmptyStateButtonStyled = styled( BoxWrapper )((
  { margintopbutton }: {margintopbutton?: number},
) => ({
  marginTop: margintopbutton,
}));

export const EmptyStateButtonStyled = styled( ButtonWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.text?.redViolet ?? palette.light.text.redViolet;

  return {
    fontFamily: `${styleConfigs.fonts.fontFamily} !important`,
    fontWeight: `${styleConfigs.fonts.regular} !important`,
    fontSize: `${styleConfigs.fonts.fontSize14} !important`,
    color: `${colorPalette} !important`,
    padding: `${styleConfigs.marginPadding.custom6}px ${styleConfigs.marginPadding.custom16}px !important`,
    border: `${styleConfigs.borders.borderLine} ${colorPalette} !important`,
    borderRadius: `${styleConfigs.borders.extraSmall} !important`,
  };
});
