import { gql, MutationTuple, useMutation } from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';

export const SIGN_IN_MULTIPLE_ROLES = gql`
    mutation Mutation($token: NonEmptyString!, $role: NonEmptyString!) {
        jwtToken : signInWithMultipleRoles(token: $token, role: $role)
    }
`;

interface ISignInMultipleRolesParams {
  token: string;
  role: string;
}

interface ISignInMultipleRolesResponse {
  jwtToken?: string
}

export const useMutationSignInMultipleRoles = ():
MutationTuple<ISignInMultipleRolesResponse, ISignInMultipleRolesParams> =>
  useMutation<ISignInMultipleRolesResponse, ISignInMultipleRolesParams>(
    SIGN_IN_MULTIPLE_ROLES,
    {
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
