const locale = {
  NOTIFICATION_EMPTY_STATE_MESSAGE: 'No new notifications',
  NOTIFICATION_EMPTY_STATE_TITLE: 'No notifications here',
  NOTIFICATION_EMPTY_STATE_SUBTITLE: `We'll notify you when there is something new!`,
  NOTIFICATION_TEXT_TITLE: 'Notifications',
  NOTIFICATION_TEXT_UNREAD_NOTIFICATION: 'You have {{unread}} unread notifications',
  NOTIFICATION_TEXT_NO_COMPLETED_TEXT: 'is now completed. Your match for {{occupations}} occupations has been updated',
  NOTIFICATION_TEXT_NO_COMPLETED_TEXT_SINGULAR: 'is now completed. Your match for {{occupations}} occupation has been updated',
  NOTIFICATION_TEXT_IN_COLUMN_TEXT: 'is now in column',
  NOTIFICATION_TEXT_IS_COMPLETED_TEXT: 'is now completed, keep it up with the good work!',
  NOTIFICATION_TEXT_EXPLORE_TEXT: 'Explore our courses to stay up to date with the latest trends.',
  NOTIFICATION_TEXT_CHANCE_TEXT: 'Your chances to become',
  NOTIFICATION_TEXT_DECREASE_TEXT: 'has decreased.',
  NOTIFICATION_TEXT_NO_LONGER_PART_TEXT: 'is now in column {{column}}. Your match for {{occupations}} occupations has been updated',
  NOTIFICATION_TEXT_NO_LONGER_PART_TEXT_SINGULAR: 'is now in column {{column}}. Your match for {{occupations}} occupation has been updated',
  NOTIFICATION_TEXT_ADDED_NO_OCCUPATION: 'is now in column {{column}}. Keep up the good work!',
  NOTIFICATION_TEXT_NO_LONGER_PART_NO_JOB_TEXT: 'is no longer part of your skill set. Explore our courses and programs to stay up to date with the latest trends.',
  NOTIFICATION_TEXT_ERROR_TITLE: 'Something went wrong with the server',
  NOTIFICATION_TEXT_ERROR_DESCRIPTION: 'Please try again later',
  backlog: 'to learn',
  learning: 'learning',
  completed: 'completed',
};

export default locale;
