import { AnimatePresence, AnimationProps } from 'framer-motion';

import {
  LoaderContainerStyled, LoaderStyled, TextContainerStyled,
} from './styles/CircularLoaderComponentStyles';
import icons from '../../../constants/icons';

export type ILoaderColor= 'light' | 'dark' | 'small-dark';
export type ILoaderType = 'inner' | 'withoutText';
export type IBackground = 'white' | 'prussianBlue' | 'transparent';
export type ITextColor = 'white' | 'prussianBlue';
export type IAlignText = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

interface ICircularLoaderComponentProps{
  isLoading?: boolean;
  color?: ILoaderColor;
  type?: ILoaderType;
  background?: IBackground;
  text?: string;
  textColor?: ITextColor;
  textWidth?: number;
  textAlign?: IAlignText;
  paddingTopInner?: number;
}

const CircularLoaderComponent: React.FC<ICircularLoaderComponentProps> = ( props ) => {
  const {
    isLoading, color, type, background, text, textColor, textWidth, textAlign, paddingTopInner,
  } = props;

  const animationProps: AnimationProps = {
    animate: { rotate: -360 },
    transition: {
      repeat: Infinity,
      duration: 2,
      ease: 'linear',
    },
  };

  const handleIconType = ( colorType?: ILoaderColor ): string => {
    switch ( colorType ) {
      case 'light':
        return icons.CIRCULAR_LOADER_LIGHT;

      case 'small-dark':
        return icons.CIRCULAR_LOADER_DARK;

      default:
        return icons.CIRCULAR_LOADER_DARK;
    }
  };

  return (
    <AnimatePresence>
      {isLoading && (
        <LoaderContainerStyled
          data-testid="circular_loader"
          loadertype={type}
          textcolortype={textColor}
          backgroundtype={background}
          paddingtopinner={paddingTopInner}
        >
          <LoaderStyled
            src={handleIconType( color )}
            loadercolortype={color}
            {...animationProps}
          />
          {type !== 'withoutText' && (
            <TextContainerStyled
              textwidth={textWidth}
              textalign={textAlign}
            >
              {text }
            </TextContainerStyled>
          )}
        </LoaderContainerStyled>
      )}
    </AnimatePresence>
  );
};

export default CircularLoaderComponent;
