import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';

// ----------------------------------------------------------------------

export default function Skeleton( theme: Theme ): unknown {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.greySkeleton ?? theme.palette.background.neutral;

  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },

      styleOverrides: {
        root: {
          backgroundColor: colorPalette,
        },
      },
    },
  };
}
