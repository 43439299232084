import { emptySkillForCoursesRecommendationCache, SkillForCoursesRecommendationReactiveVar } from '../../cache/learner/skillForCoursesRecommendationReactiveVar';
import { IGetCoursesRecommendations } from '../../operations/query/students/useGetStudentSkillSetMatchByOccupation';
import { decryptor, encryptor } from '../../utils/decryptor';

interface ISkillForCoursesRecommendationHook {
  clearSkillForCoursesRecommendationsError: ()=>void;
  setSkillForCoursesRecommendationsError : ( errorMessage: string ) => void;
  setSkillForCoursesRecommendations : ( skillForCoursesRecommendations: IGetCoursesRecommendations[]) => void;
  setFromLocalSkillForCoursesRecommendations: () => boolean,
}

const SkillForCoursesRecommendationHook = (): ISkillForCoursesRecommendationHook => {
  const clearSkillForCoursesRecommendationsError = ():void => {
    SkillForCoursesRecommendationReactiveVar( emptySkillForCoursesRecommendationCache );
  };
  const setSkillForCoursesRecommendationsError = ( errorMessage: string ):void => {
    SkillForCoursesRecommendationReactiveVar({ ...emptySkillForCoursesRecommendationCache, error: errorMessage });
  };
  const setSkillForCoursesRecommendations = ( skillForCoursesRecommendations: IGetCoursesRecommendations[]): void => {
    SkillForCoursesRecommendationReactiveVar(
      { ...emptySkillForCoursesRecommendationCache, data: skillForCoursesRecommendations },
    );
    const encryptedData = encryptor( JSON.stringify( skillForCoursesRecommendations ));

    if ( !encryptedData ) {
      return;
    }
    sessionStorage.setItem( 'skillForCoursesRecommendations', encryptedData );
  };

  const setFromLocalSkillForCoursesRecommendations = (): boolean => {
    const dataLocal = sessionStorage.getItem( 'skillForCoursesRecommendations' );
    const decryptedData = decryptor( dataLocal ?? '' );

    if ( !decryptedData ) {
      return false;
    }
    SkillForCoursesRecommendationReactiveVar({
      error: '',
      data: JSON.parse( decryptedData ),
    });

    return true;
  };

  return {
    clearSkillForCoursesRecommendationsError,
    setSkillForCoursesRecommendationsError,
    setSkillForCoursesRecommendations,
    setFromLocalSkillForCoursesRecommendations,
  };
};

export default SkillForCoursesRecommendationHook;
