import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Typography( theme: Theme ): unknown {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing( 2 ),
        },
        gutterBottom: {
          marginBottom: theme.spacing( 1 ),
        },
      },
    },
  };
}
