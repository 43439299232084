import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import GridWrapper from '../../../wrappers/GridWrapper';
import IconComponent from '../../IconComponent/IconComponent';

export const ContainerStyled = styled( GridWrapper )(() => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  fontWeight: styleConfigs.fonts.weight600,
  fontSize: styleConfigs.fonts.fontSize16,
  color: palette.light.text.prussianBlue,
  padding: `${styleConfigs.marginPadding.custom5}px ${styleConfigs.marginPadding.custom12}px`,
  gap: styleConfigs.marginPadding.custom6,
  border: `${styleConfigs.borders.borderLine} ${palette.light.text.prussianBlue}`,
  borderRadius: styleConfigs.borders.radius50,
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize12,
  },
}));

export const IconStyled = styled( IconComponent )(() => ({
  fontSize: styleConfigs.dimensions.width20,
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.dimensions.width18,
  },
}));
