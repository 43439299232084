import { BoxProps } from '@mui/material';

import palette from '../../theme/learnerPalette';
import BoxWrapper from '../wrappers/BoxWrapper';

interface ISvgIconStyleProps extends BoxProps {
  isSpan?: boolean;
  width?: number;
  height?: number;
  src: string;
  dataTestId?: string;
}

const SvgIconStyle = ({
  isSpan = true, width = 24, height = 24, src, color = 'inherit', sx, dataTestId = '',
}: ISvgIconStyleProps ): JSX.Element => (
  <BoxWrapper
    data-testid={dataTestId}
    component={isSpan ? 'span' : undefined}
    sx={{
      width,
      height,
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      bgcolor: `${color}.main`,
      ...( color === 'inherit' && { bgcolor: 'currentColor' }),
      ...( color === 'paper' && { bgcolor: 'background.paper' }),
      ...( color === 'action' && { bgcolor: palette.light.icons.mistBlue }),
      ...( color === 'disabled' && { bgcolor: 'action.disabled' }),
      ...sx,
    }}
  />
);

export default SvgIconStyle;
