import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { MainStyled, RootStyled } from './styles/DashboardLayoutStyles';
import ChangePasswordDialog from '../../components/ChangePasswordDialog/ChangePasswordDialog';
import Page from '../../components/template-minimal/Page';
import { getRoutePathname } from '../../utils/getRoutePathname';

const DashboardLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>( false );
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>( false );

  return (
    <Page title={getRoutePathname( pathname )}>
      <RootStyled>
        <DashboardNavbar
          onOpenSidebar={() => setOpen( true )}
          openChangePasswordDialog={() => setChangePasswordDialogOpen( true )}
        />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen( false )}
        />
        <MainStyled>
          <Outlet />
        </MainStyled>
        <ChangePasswordDialog
          open={changePasswordDialogOpen}
          setOpen={setChangePasswordDialogOpen}
        />
      </RootStyled>
    </Page>
  );
};

export default DashboardLayout;
