import { styled, Theme } from '@mui/material';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';

export const FooterStyled = styled( TypographyWrapper )((
  { theme }: {theme: Theme},
) => ({
  fontWeight: styleConfigs.fonts.bold,
  width: '100%',
  marginTop: theme.spacing( 12 ),
  borderTop: `${styleConfigs.borders.borderLine} ${palette.light.borders.cadetGrey}`,
  borderWidth: `${styleConfigs.borders.border3px}`,
  padding: theme.spacing( 8, 0, 0, 0 ),
  fontSize: styleConfigs.fonts.fontSize16,
  textAlign: 'center',
  div: {
    maxWidth: styleConfigs.dimensions.width700,
    margin: `${styleConfigs.marginPadding.auto}`,

  },
}));
