import { useState, useEffect } from 'react';

export default function useLocalStorage<ValueType>( key: string, defaultValue: ValueType ): ValueType | any {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem( key );

    return storedValue === null ? defaultValue : JSON.parse( storedValue );
  });

  useEffect(() => {
    const listener = ( e: StorageEvent ): void => {
      if ( e.storageArea === localStorage && e.key === key ) {
        setValue( e.newValue ? JSON.parse( e.newValue ) : e.newValue );
      }
    };

    window.addEventListener( 'storage', listener );

    return () => {
      window.removeEventListener( 'storage', listener );
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = ( newValue: ValueType ): void => {
    setValue(( currentValue: any ) => {
      const result = typeof newValue === 'function' ? newValue( currentValue ) : newValue;

      localStorage.setItem( key, JSON.stringify( result ));

      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
