import { MutationTuple, gql, useMutation } from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';

interface ILoginCustomSSOParams {
  token: string
  loginMethod: string
}

interface ILoginCustomSSOResponse {
  loginCustomSSO: {
    jwtToken: string
  }

}

export const LOGIN_CUSTOM_SSO = gql`
  mutation LoginCustomSSO($token: String!, $loginMethod: LoginCustomMethods) {
    loginCustomSSO(token: $token, loginMethod: $loginMethod) {
    jwtToken
  }
}
`;

export const useLoginCustomSSO = ():
MutationTuple<ILoginCustomSSOResponse, ILoginCustomSSOParams> =>
  useMutation<ILoginCustomSSOResponse, ILoginCustomSSOParams>(
    LOGIN_CUSTOM_SSO,
    {
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
