import { alpha } from '@mui/material';
import { styled } from '@mui/styles';
import { motion } from 'framer-motion';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import StackWrapper from '../../../wrappers/StackWrapper';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';
import {
  IAlignText, IBackground, ILoaderColor, ILoaderType, ITextColor,
} from '../CircularLoaderComponent';

const handleTextColor = ( textcolortype?: ITextColor ): string => {
  if ( textcolortype === 'white' ) {
    return palette.light.text.white;
  }

  return palette.light.text.prussianBlue;
};

const handleBackground = ( backgroundtype?: IBackground ): string => {
  switch ( backgroundtype ) {
    case 'white':
      return palette.light.background.white;

    case 'transparent':
      return palette.light.background.transparent;

    default:
      return alpha( palette.light.background.prussianBlue, 0.75 );
  }
};

export const LoaderContainerStyled = styled( StackWrapper )((
  {
    loadertype, textcolortype, backgroundtype, paddingtopinner,
  }:
  {loadertype?: ILoaderType, textcolortype?: ITextColor,
    backgroundtype?: IBackground, paddingtopinner?: number},
) => ({
  width: styleConfigs.dimensions.percent100,
  height: styleConfigs.dimensions.percent100,
  alignItems: 'center',
  justifyContent: 'center',
  color: handleTextColor( textcolortype ),
  background: handleBackground( backgroundtype ),
  paddingTop: paddingtopinner,
  gap: styleConfigs.marginPadding.custom20,
  position: (( loadertype === 'inner' ) || ( loadertype === 'withoutText' )) ? 'unset' : 'fixed',
  right: styleConfigs.dimensions.empty,
  bottom: styleConfigs.dimensions.empty,
  zIndex: (( loadertype === 'inner' ) || ( loadertype === 'withoutText' )) ? 1 : 2,
}));

export const LoaderStyled = styled( motion.img )((
  { loadercolortype }: {loadercolortype?: ILoaderColor},
) => ({
  width: loadercolortype === 'small-dark'
    ? styleConfigs.dimensions.width35
    : styleConfigs.dimensions.width45,
  height: loadercolortype === 'small-dark'
    ? styleConfigs.dimensions.width35
    : styleConfigs.dimensions.width45,
}));

export const TextContainerStyled = styled( TypographyWrapper )((
  { textwidth, textalign }: {textwidth?: number, textalign?: IAlignText},
) => ({
  maxWidth: textwidth,
  textAlign: textalign,
  fontSize: styleConfigs.fonts.fontSize16,
}));
