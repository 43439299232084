import Stack, { StackProps } from '@mui/material/Stack';

interface IProps {
  children: React.ReactNode,
  component?: React.ElementType,
}

const StackWrapper: React.FC<IProps & StackProps> = ({ children, ...props }) => (
  <Stack {...props}>
    { children }
  </Stack>
);

export default StackWrapper;
