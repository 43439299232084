export const getRoutePathname = ( pathname: string ): string => {
  const pathnameSet = [
    { name: 'insights', value: 'Skill Insights' },
    { name: 'student-overview', value: 'Student Overview' },
    { name: 'student-advising', value: 'Student Advising' },
    { name: 'career-trends', value: 'Career Trends' },
    { name: 'contact', value: 'Contact Us' },
    { name: 'faq', value: 'Frequently Asked Questions' },
  ];

  let result = '';

  pathnameSet.forEach(( item ) => {
    if ( pathname.includes( item.name )) {
      result = item.value;
    }
  });

  return result;
};
