const locale = {
  CURRENT_LANGUAGE: 'English',
  TITLE_POPOVER: 'Change Language',
  POPOVER_CONTENT: [
    { id: 1, language: 'English', languageId: 'en' },
    { id: 2, language: 'Spanish', languageId: 'es' },
  ],
};

export default locale;
