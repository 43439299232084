import { alpha, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import CardWrapper from '../../../wrappers/CardWrapper';
import GridWrapper from '../../../wrappers/GridWrapper';
import StackWrapper from '../../../wrappers/StackWrapper';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';
import IconComponent from '../../IconComponent/IconComponent';

const customButton = {
  height: styleConfigs.dimensions.height36,
  color: palette.light.text.white,
  fontWeight: styleConfigs.fonts.weight400,
  fontFamily: styleConfigs.fonts.fontFamily,
  fontSize: styleConfigs.fonts.fontSize14,
  borderRadius: styleConfigs.borders.radius8,
  lineHeight: styleConfigs.fonts.lineHeight24,
  '&:hover': {
    background: palette.light.background.redViolet,
  },
};

export const MainContainerStyled = styled( GridWrapper )(({ isMobile }: {isMobile: boolean}) => ({
  paddingInline: isMobile ? styleConfigs.marginPadding.twoRem : styleConfigs.marginPadding.custom88,
}));

export const TitleStyled = styled( TypographyWrapper )(() => ({
  fontFamily: styleConfigs.fonts.fontFamily,
  fontWeight: styleConfigs.fonts.weight400,
  fontSize: styleConfigs.fonts.fontSize32,
  lineHeight: styleConfigs.fonts.lineHeight32,
  color: palette.light.text.ebonyClay,
  margin: `${styleConfigs.marginPadding.empty} ${styleConfigs.marginPadding.empty}
    ${styleConfigs.marginPadding.custom8}px`,
  textAlign: 'center',
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize28,
  },
}));

export const SubtitleStyled = styled( TypographyWrapper )(({ isMobile }: {isMobile: boolean}) => ({
  textAlign: 'center',
  fontSize: styleConfigs.fonts.fontSize14,
  color: palette.light.text.mistBlue,
  lineHeight: styleConfigs.fonts.lineHeight16,
  width: isMobile ? 'auto' : styleConfigs.dimensions.width400,
}));

export const IconsContainer = styled( GridWrapper )(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: styleConfigs.marginPadding.custom10,
  [useTheme().breakpoints.down( 'sm' )]: {
    gap: styleConfigs.marginPadding.custom20,
  },
}));

export const CardContainerStyled = styled( CardWrapper )((
  { isSelected }: { isSelected: boolean },
) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBlock: `${styleConfigs.marginPadding.custom27}px ${styleConfigs.marginPadding.custom16}px`,
  marginInline: styleConfigs.marginPadding.custom12,
  padding: styleConfigs.marginPadding.roleSelectorPadding,
  gap: styleConfigs.marginPadding.custom8,
  boxShadow: styleConfigs.borders.none,
  borderColor: isSelected ? palette.light.text.pictonBlue : palette.light.boxShadows.cadetGrey,
  border: isSelected ? `${styleConfigs.borders.borderLine} ${palette.light.borders.pictonBlue}`
    : styleConfigs.borders.borderLine1Gray,
  backgroundColor: isSelected ? palette.light.background.lightBlueTranslucent : 'initial',
  transition: 'background-color 0.3s, border 0.3s',
  [useTheme().breakpoints.down( 'sm' )]: {
    marginInline: styleConfigs.marginPadding.empty,
  },
}));

export const CardTitleStyled = styled( TypographyWrapper )(() => ({
  textAlign: 'center',
  fontSize: styleConfigs.fonts.fontSize22,
  color: palette.light.text.mistBlue,
  lineHeight: styleConfigs.fonts.lineHeight26p4,
  letterSpacing: styleConfigs.fonts.letterSpacing2,
}));

export const ButtonsContainer = styled( StackWrapper )(() => ({
  width: styleConfigs.dimensions.percent100,
  display: 'flex',
  textAlign: 'center',
  gap: styleConfigs.marginPadding.custom10,
  [useTheme().breakpoints.down( 'sm' )]: {
    gap: styleConfigs.marginPadding.custom25,
  },
}));

export const RoleButtonStyled = styled( ButtonWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;

  return ({
    ...customButton,
    width: styleConfigs.dimensions.percent100,
    marginTop: styleConfigs.marginPadding.custom22,
    background: colorPalette,
    boxShadow: styleConfigs.borders.unset,
    '&:disabled': {
      background: palette.light.background.cadetGrey,
    },
    '&:hover': {
      background: colorPalette,
      boxShadow: styleConfigs.borders.unset,
    },
    '&:focus-visible': {
      outline: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
      borderRadius: styleConfigs.borders.radius4,
    },
    [useTheme().breakpoints.down( 'sm' )]: {
      height: styleConfigs.dimensions.height30,
    },
  });
});

export const CancelLink = styled( GridWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;

  return ({
    width: styleConfigs.dimensions.percent100,
    height: styleConfigs.dimensions.height36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: styleConfigs.fonts.weight400,
    fontSize: styleConfigs.fonts.fontSize14,
    color: colorPalette,
    borderRadius: styleConfigs.borders.radius8,
    cursor: 'pointer',
    '&:hover': {
      color: colorPalette,
      background: alpha( colorPalette, 0.1 ),
    },
    '&:focus-visible': {
      outline: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
      borderRadius: styleConfigs.borders.radius4,
    },
    [useTheme().breakpoints.down( 'sm' )]: {
      height: styleConfigs.dimensions.height30,
    },
  });
});

export const LearnerIconStyled = styled( IconComponent )(() => ({
  width: styleConfigs.dimensions.width70,
  height: styleConfigs.dimensions.height70,
  background: palette.light.text.mistBlue,
}));
