import {
  lazy, useContext, useMemo,
} from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import {
  PATH_AUTH,
  PATH_DASHBOARD,
  PATH_DASHBOARD_STUDENT,
  PATH_WIZARD,
} from './paths';
import { userRoleVar } from '../cache/cache';
import { FeaturesEnum } from '../constants/enums/FeaturesEnum';
import { FeatureFlagsContext } from '../contexts/FeatureFlagsContext';
import LoadableRoutes from '../hooks/useLoadableRoutes/useLoadableRoutes';
import userAuth from '../hooks/userAuth';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import DashboardProfileNavbar from '../layouts/dashboard-student/DashboardProfileNavbar/DashboardProfileNavbar';
import DashboardStudentLayout from '../layouts/dashboard-student/DashboardStudentLayout/DashboardStudentLayout';
import {
  ILoginUserSuccessResponse,
  ROLE_ENUM,
} from '../views/Auth/Login/interfaces/IGoogleUser';

interface IRoute {
  path: string;
  element: JSX.Element;
  children?: IRoute[];
  index?: boolean;
}

export default function Router(): any {
  const { auth } = userAuth();
  const { features } = useContext( FeatureFlagsContext );
  const userRole = userRoleVar();

  // Hide student routes
  const hiddenStudentPaths = useMemo(() => {
    const hiddenPaths = [];

    if ( auth?.onBoardingCompleted ) {
      hiddenPaths.push( 'wizard' );
    }

    if ( auth?.features?.includes( FeaturesEnum.hideLearnerHelp )) {
      hiddenPaths.push( 'help' );
    }

    if ( auth?.features.includes( FeaturesEnum.hideLearningPathWaysScreen )
    ) {
      hiddenPaths.push( 'learning-paths' );
    }

    return hiddenPaths;
  }, [auth?.features, auth?.onBoardingCompleted]);

  const hiddenAdminPaths = useMemo(() => {
    const hiddenPaths = [];

    if ( features.advisor?.hideCareerTrendsScreen ) {
      hiddenPaths.push( 'career-trends' );
    }

    if ( features.advisor?.hideSkillInsights ) {
      hiddenPaths.push( 'insights' );
    }

    if ( features.advisor?.hideStudentAdvising ) {
      hiddenPaths.push( 'student-advising' );
    }

    if ( features.advisor?.hideStudentOverview ) {
      hiddenPaths.push( 'student-overview' );
    }

    if ( features.advisor?.hideContactUs ) {
      hiddenPaths.push( 'contact' );
    }

    if ( features.advisor?.hideFAQ ) {
      hiddenPaths.push( 'faq' );
    }

    return hiddenPaths;
  }, [features.advisor]);

  // Default Advisor Start Page
  const defaultAdvisorStartPage = PATH_DASHBOARD.admin.pageInsights;

  // Default Student Start Page
  const defaultStudentStartPage = useMemo(() => {
    if ( !auth?.onBoardingCompleted ) {
      return PATH_WIZARD.root;
    }

    return PATH_DASHBOARD_STUDENT.admin.pageHome;
  }, [auth?.onBoardingCompleted]);

  const ADVISOR_CHILDREN = [
    { path: 'insights', element: <PageAdminInsights /> },
    { path: 'student-overview', element: <PageAdminStudentOverview /> },
    { path: 'student-advising', element: <PageStudentAdvising /> },
    { path: 'career-trends', element: <PageCareerTrends /> },
    { path: 'contact', element: <PageAdminContact /> },
    { path: 'faq', element: <PageAdminFaq /> },
  ];

  // Set student routes
  const getStudentRoutes = (): IRoute[] => {
    const filterdLearnerRoutes = filterRoutes( LEARNER_ROUTES as IRoute, hiddenStudentPaths );
    const studentRoutes: IRoute[] = [
      filterdLearnerRoutes ?? LEARNER_ROUTES as IRoute, WIZARD_ROUTES, CAREER_DISCOVERY_ROUTES,
    ];
    const filteredStudentRoutes = studentRoutes.filter(({ path }) => !hiddenStudentPaths.includes( path ));

    return filteredStudentRoutes;
  };

  // Set admin routes
  const getAdminRoutes = (): IRoute[] => {
    const filterdAdminRoutes = filterRoutes( ADVISOR_ROUTES as IRoute, hiddenAdminPaths );
    const adminRoutes: IRoute[] = [filterdAdminRoutes ?? ADVISOR_ROUTES as IRoute];
    const filteredAdminRoutes = adminRoutes.filter(({ path }) => !hiddenAdminPaths.includes( path ));

    return filteredAdminRoutes;
  };

  // Get user routes
  const routesByRole = ( role: string ):any => {
    switch ( role ) {
      case ROLE_ENUM.STUDENT:
        return getStudentRoutes();

      case ROLE_ENUM.ADMIN:
        return getAdminRoutes();

      default:
        return [];
    }
  };

  const filterRoutes = ( routes: IRoute, hiddenRoutes: string[]): IRoute | null => {
    const isHiddenPath = ( path?: string ): boolean =>
      hiddenRoutes.some(( hiddenPath ) => path?.includes( hiddenPath ));

    if ( routes.path && isHiddenPath( routes.path )) {
      return null;
    }

    if ( routes.children ) {
      const filteredChildren = routes.children
        .map(( child ) => filterRoutes( child, hiddenRoutes ))
        .filter(( child ) => child !== null ) as IRoute[];

      return { ...routes, children: filteredChildren };
    }

    return routes;
  };

  // Default route
  const getDefaultRoute = ( user: ILoginUserSuccessResponse ): string => {
    if ( !user && !features.embededInIframe ) {
      return PATH_AUTH.login;
    }
    if ( user?.userRole === ROLE_ENUM.STUDENT ) {
      return defaultStudentStartPage;
    }
    if ( !user && features.embededInIframe ) {
      if ( features.iframeAndPlatform ) {
        return PATH_AUTH.login;
      }

      return PATH_AUTH.redirect;
    }

    return defaultAdvisorStartPage;
  };

  const getDefaultAdvisorStartPage = (): string | undefined => {
    const sections = getAdminRoutes();

    if ( !sections || !sections[0]?.children ) {
      return undefined;
    }

    const result = sections[0].children
      .filter(( menu ) => menu.children )
      .flatMap(( menu ) => menu.children
        ?.filter(( sub ) => sub.path ).map(( sub ) => `${sections[0].path}/${menu.path}/${sub.path}` ))
      .find(( path ) => path );

    return result;
  };

  const defaultRedirect = (): string => {
    if ( !auth && features.embededInIframe ) {
      if ( features.iframeAndPlatform ) {
        return PATH_AUTH.login;
      }

      return PATH_AUTH.redirect;
    }

    if ( auth && auth.userRole === ROLE_ENUM.ADMIN ) {
      const defaultAdvisorPage = getDefaultAdvisorStartPage();

      if ( defaultAdvisorPage ) { return defaultAdvisorPage; }

      return PATH_AUTH.login;
    } if ( auth ) {
      return PATH_DASHBOARD_STUDENT.admin.pageHome;
    }

    if ( !auth || features.embededLoginLiderly || features.embededInIframe ) {
      return PATH_AUTH.login;
    }

    return '/404';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const LEARNER_ROUTES = {
    path: 'dashboard',
    element: <DashboardStudentLayout />,
    children: [
      {
        path: 'learner',
        children: [
          { path: 'home', element: <PageStudentHome /> },
          { path: 'skills', element: <PageSkills /> },
          { path: 'occupations', element: <PageOccupations /> },
          { path: 'learning-paths', element: <PageLearningPaths /> },
          { path: 'help', element: <PageStudentHelp /> },
          { path: '*', element: <Navigate to="/dashboard/learner/home" replace /> },
          {
            path: 'profile',
            element: (
              <DashboardProfileNavbar>
                <PageStudentProfile />
              </DashboardProfileNavbar>
            ),
          },
          {
            path: 'profile/skills',
            element: (
              <DashboardProfileNavbar>
                <PageStudentProfileSkills />
              </DashboardProfileNavbar>
            ),
          },
        ],
      },
    ],
  };

  const WIZARD_ROUTES = {
    path: 'wizard',
    element: <Wizard />,
    children: [
      { path: 'information', element: <WizardInformationStep /> },
      { path: 'occupations', element: <WizardOccupationStep /> },
      { path: 'skills', element: <WizardSkillStep /> },
      { path: 'loading', element: <WizardLoadingStep /> },
      { path: '*', index: true, element: <Navigate to="/wizard/information" replace /> },
    ],
  };

  const CAREER_DISCOVERY_ROUTES = {
    path: 'career-discovery',
    element: <CareerDiscovery />,
    children: [
      { path: 'ikigai', element: <CareerIkigaiStep /> },
      { path: 'passion', element: <CareerPassionStep /> },
      { path: 'mission', element: <CareerMissionStep /> },
      { path: 'profession', element: <CareerProfessionStep /> },
      { path: 'vocation', element: <CareerVocationStep /> },
      { path: '*', index: true, element: <Navigate to="/career-discovery/ikigai" replace /> },
    ],
  };

  const ADVISOR_ROUTES = {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: 'advisor',
        children: [
          { element: <Navigate to={defaultAdvisorStartPage} replace /> },
          ...ADVISOR_CHILDREN,
        ],
      },
    ],
  };

  // Auth router
  const authRouter = useMemo(() => {
    const routes = {
      path: 'auth',
      children: [
        {
          path: 'redirect',
          element: <Redirect />,
        },
      ],
    };

    if ( features.iframeAndPlatform || !features.embededInIframe ) {
      routes.children.push({
        path: 'login',
        element: <Login />,
      });
    }

    if ( features.registerUser ) {
      routes.children.push({
        path: 'register',
        element: <Register />,
      },
      {
        path: 'forgot',
        element: <ForgotPassword />,
      });
    }

    return routes;
  }, [features.registerUser, features.embededInIframe]);

  return useRoutes([
    authRouter,
    {
      path: 'unauthorized',
      element: <NotAuthorized />,
    },
    // Main Routes
    {
      path: '/',
      element: <Navigate to={getDefaultRoute( auth )} replace />,
    },
    {
      path: '*',
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to={defaultRedirect()} replace /> },
      ],
    },
  ].concat( routesByRole( userRole )));
}

// IMPORT COMPONENTS
const LoginLazy = lazy(() => import( '../views/Auth/Login/LoginView' ));
const RedirectLazy = lazy(() => import( '../views/Auth/Login/components/RedirectPage/RedirectContent' ));
const PageAdminStudentOverviewLazy = lazy(() => import( '../views/Admin/StudentOverview/StudentOverviewView' ));
const PageAdminInsightsLazy = lazy(() => import( '../views/Admin/Insights/InsightsView' ));
const PageStudentAdvisingLazy = lazy(() => import( '../views/Admin/StudentAdvising/StudentAdvising' ));
const PageCareerTrendsLazy = lazy(() => import( '../views/Admin/CareerTrends/CareerTrends' ));
const PageAdminFaqLazy = lazy(() => import( '../views/Admin/Faq/FaqView' ));
const PageAdminContactLazy = lazy(() => import( '../views/Admin/ContactUs/ContactUsView' ));
const NotFoundLazy = lazy(() => import( '../views/Errors/Page404/Page404' ));
const NotAuthorizedLazy = lazy(() => import( '../views/Errors/Page401/Page401' ));
const RegisterLazy = lazy(() => import( '../views/Auth/Register/RegisterView' ));
const ForgotPasswordLazy = lazy(() => import( '../views/Auth/ForgotPassword/ForgotPasswordView' ));
const WizardLazy = lazy(() => import( '../views/Wizard/Wizard' ));
const WizardLoadingStepLazy = lazy(() => import( '../views/Wizard/components/Steps/WizardLoadingStep/WizardLoadingStep' ));
const WizardSkillStepLazy = lazy(() => import( '../views/Wizard/components/Steps/WizardSkillStep/WizardSkillStep' ));
const WizardOccupationStepLazy = lazy(() => import( '../views/Wizard/components/Steps/WizardOccupationStep/WizardOccupationStep' ));
const WizardInformationStepLazy = lazy(() => import( '../views/Wizard/components/Steps/WizardInformationStep/WizardInformationStep' ));
const PageStudentHomeLazy = lazy(() => import( '../views/Learner/Home/HomeView' ));
const PageSkillsLazy = lazy(() => import( '../views/Learner/Skills/SkillsView' ));
const PageOccupationsLazy = lazy(() => import( '../views/Learner/Occupations/OccupationsView' ));
const PageLearningPathsLazy = lazy(() => import( '../views/Learner/LearningPaths/LearningPathsView' ));
const PageStudentHelpLazy = lazy(() => import( '../views/Learner/Help/HelpView' ));
const PageStudentProfileLazy = lazy(() => import( '../views/Learner/Profile/ProfileView' ));
const PageStudentProfileSkillsLazy = lazy(() => import( '../views/Learner/Profile/ProfileSkills/ProfileSkills' ));
const CareerDiscoveryLazy = lazy(() => import( '../views/CareerDiscovery/CareerDiscovery' ));
const CareerIkigaiStepLazy = lazy(() => import( '../views/CareerDiscovery/components/CareerIkigaiStep/CareerIkigaiStep' ));
const CareerPassionStepLazy = lazy(() => import( '../views/CareerDiscovery/components/CareerPassionStep/CareerPassionStep' ));
const CareerMissionStepLazy = lazy(() => import( '../views/CareerDiscovery/components/CareerMissionStep/CareerMissionStep' ));
const CareerProfessionStepLazy = lazy(() => import( '../views/CareerDiscovery/components/CareerProfessionStep/CareerProfessionStep' ));
const CareerVocationStepLazy = lazy(() => import( '../views/CareerDiscovery/components/CareerVocationStep/CareerVocationStep' ));

// Dashboard
const PageAdminStudentOverview = (): JSX.Element => (
  <LoadableRoutes>
    <PageAdminStudentOverviewLazy />
  </LoadableRoutes>
);
const PageAdminInsights = (): JSX.Element => (
  <LoadableRoutes>
    <PageAdminInsightsLazy />
  </LoadableRoutes>
);
const PageStudentAdvising = (): JSX.Element => (
  <LoadableRoutes>
    <PageStudentAdvisingLazy />
  </LoadableRoutes>
);
const PageCareerTrends = (): JSX.Element => (
  <LoadableRoutes>
    <PageCareerTrendsLazy />
  </LoadableRoutes>
);
const PageAdminFaq = (): JSX.Element => (
  <LoadableRoutes>
    <PageAdminFaqLazy />
  </LoadableRoutes>
);
const PageAdminContact = (): JSX.Element => (
  <LoadableRoutes>
    <PageAdminContactLazy />
  </LoadableRoutes>
);
const NotFound = (): JSX.Element => (
  <LoadableRoutes>
    <NotFoundLazy />
  </LoadableRoutes>
);

const NotAuthorized = (): JSX.Element => (
  <LoadableRoutes>
    <NotAuthorizedLazy />
  </LoadableRoutes>
);

// Main
// Auth
const Register = (): JSX.Element => (
  <LoadableRoutes>
    <RegisterLazy />
  </LoadableRoutes>
);
const Redirect = (): JSX.Element => (
  <LoadableRoutes>
    <RedirectLazy />
  </LoadableRoutes>
);
const ForgotPassword = (): JSX.Element => (
  <LoadableRoutes>
    <ForgotPasswordLazy />
  </LoadableRoutes>
);
const Login = (): JSX.Element => (
  <LoadableRoutes>
    <LoginLazy />
  </LoadableRoutes>
);

// wizard
const Wizard = (): JSX.Element => (
  <LoadableRoutes>
    <WizardLazy />
  </LoadableRoutes>

);
const WizardLoadingStep = (): JSX.Element => (
  <LoadableRoutes>
    <WizardLoadingStepLazy />
  </LoadableRoutes>

);
const WizardSkillStep = (): JSX.Element => (
  <LoadableRoutes>
    <WizardSkillStepLazy />
  </LoadableRoutes>

);
const WizardOccupationStep = (): JSX.Element => (
  <LoadableRoutes>
    <WizardOccupationStepLazy />
  </LoadableRoutes>

);
const WizardInformationStep = (): JSX.Element => (
  <LoadableRoutes>
    <WizardInformationStepLazy />
  </LoadableRoutes>

);

// DashboardStudent
const PageStudentHome = (): JSX.Element => (
  <LoadableRoutes>
    <PageStudentHomeLazy />
  </LoadableRoutes>

);
const PageSkills = (): JSX.Element => (
  <LoadableRoutes>
    <PageSkillsLazy />
  </LoadableRoutes>

);
const PageOccupations = (): JSX.Element => (
  <LoadableRoutes>
    <PageOccupationsLazy />
  </LoadableRoutes>
);

const PageLearningPaths = (): JSX.Element => (
  <LoadableRoutes>
    <PageLearningPathsLazy />
  </LoadableRoutes>
);

const PageStudentHelp = (): JSX.Element => (
  <LoadableRoutes>
    <PageStudentHelpLazy />
  </LoadableRoutes>

);

// profile
const PageStudentProfile = (): JSX.Element => (
  <LoadableRoutes>
    <PageStudentProfileLazy />
  </LoadableRoutes>

);
const PageStudentProfileSkills = (): JSX.Element => (
  <LoadableRoutes>
    <PageStudentProfileSkillsLazy />
  </LoadableRoutes>
);

// Career Discovery
const CareerDiscovery = (): JSX.Element => (
  <LoadableRoutes>
    <CareerDiscoveryLazy />
  </LoadableRoutes>
);

const CareerIkigaiStep = (): JSX.Element => (
  <LoadableRoutes>
    <CareerIkigaiStepLazy />
  </LoadableRoutes>
);

const CareerPassionStep = (): JSX.Element => (
  <LoadableRoutes>
    <CareerPassionStepLazy />
  </LoadableRoutes>
);

const CareerMissionStep = (): JSX.Element => (
  <LoadableRoutes>
    <CareerMissionStepLazy />
  </LoadableRoutes>
);

const CareerProfessionStep = (): JSX.Element => (
  <LoadableRoutes>
    <CareerProfessionStepLazy />
  </LoadableRoutes>
);

const CareerVocationStep = (): JSX.Element => (
  <LoadableRoutes>
    <CareerVocationStepLazy />
  </LoadableRoutes>
);
