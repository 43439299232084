import { ListItemButtonProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';
import ListItemButtonWrapper from '../../../wrappers/ListItemButtonWrapper';
import ListItemIconWrapper from '../../../wrappers/ListItemIconWrapper';

interface IListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

export const NavItemStyle = styled( ListItemButtonWrapper )<IListItemStyleProps>((
  { theme }: {theme: Theme},
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.text?.yaleBlue ?? palette.light.borders.yaleBlue;

  return ({
    ...theme.typography.body2,
    height: styleConfigs.dimensions.height48,
    color: palette.light.text.mistBlue,
    paddingLeft: theme.spacing( 5 ),
    paddingRight: theme.spacing( 2.5 ),
    '&:before': {
      content: '\'\'',
      width: styleConfigs.dimensions.width3,
      top: styleConfigs.marginPadding.empty,
      bottom: styleConfigs.marginPadding.empty,
      right: styleConfigs.marginPadding.empty,
      backgroundColor: theme.palette.secondary.main,
      borderTopLeftRadius: styleConfigs.borders.border4,
      borderBottomLeftRadius: styleConfigs.borders.border4,
      display: 'none',
    },
    '&:focus ': {
      border: `${styleConfigs.borders.borderLine2px} ${colorPalette}`,
    },
  });
});

export const NavItemIconStyle = styled( ListItemIconWrapper )({
  width: styleConfigs.dimensions.width22,
  height: styleConfigs.dimensions.height22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
