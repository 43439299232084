const locale = {
  EXPLORE_SKILL: 'Explorar Habilidad',
  REMOVE_FROM_TRACKER: 'Eliminar del Monitor de Habilidades',
  EXPLORE_SKILL_MOBILE: 'Explorar',
  REMOVE_FROM_TRACKER_MOBILE: 'Eliminar del Monitor',
  RECOMMENDED_NEW_COURSE: 'Recomandar un nuevo Curso',
  CANCEL: 'Cancelar',
  SAVE: 'Guardar',
  LOADER_TEXT: 'Cargando',
};

export default locale;
