export const formatPathName = ( event: string ): string => event.toLowerCase().substring( 1 ).replace( /\//g, '-' );
export const isHtmlString = ( str: string ): boolean => /<\/?[a-z][\s\S]*>/i.test( str );
export const truncateText = ( text: string, maxLength: number ): string =>
  ( text.length > maxLength ? `${text.substring( 0, maxLength - 1 )}...` : text );
export const capitalizeFirstLetter = ( str: string ): string =>
  ( str ? str.charAt( 0 )?.toUpperCase() + str.slice( 1 ).toLowerCase() : '' );
export const capitalizeSentence = ( str: string ) : string =>
  ( str.replace( /\w\S*/g, ( w ) => ( w.replace( /^\w/, ( c ) => c.toUpperCase()))));
export const firstWordInString = ( str: string|null|undefined ): string => {
  if ( !str ) { return ' '; }

  return str.split( ' ' )[0];
};
export const replaceStringValue = ( sentence: string, target: string, value: string ): string =>
  sentence.split( target ).join( value );
export const removeAccents = ( str: string ) : string => str.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' );
