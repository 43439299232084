import {
  ICourseProviders, coursesProvidersReactiveVars, emptyCourseProvidersCache,
} from '../../cache/learner/coursesProvidersReactiveVars';

interface ICourseInstitutionProvidersHook {
  clearCourseInstitutionProvidersError: ()=> void;
  setCourseInstitutionProvidersError : ( errorMessage: string ) => void;
  setCourseInstitutionProviders: ( coursesProviders: ICourseProviders[]) => void;
}

const useCourseInstitutionProvidersHook = (): ICourseInstitutionProvidersHook => {
  const clearCourseInstitutionProvidersError = (): void => {
    coursesProvidersReactiveVars( emptyCourseProvidersCache );
  };
  const setCourseInstitutionProvidersError = ( errorMessage: string ): void => {
    coursesProvidersReactiveVars({ ...emptyCourseProvidersCache, error: errorMessage });
  };

  const setCourseInstitutionProviders = ( courseInstitutionProviders: ICourseProviders[]): void => {
    coursesProvidersReactiveVars(
      {
        error: '',
        data: courseInstitutionProviders,
      },
    );
  };

  return {
    clearCourseInstitutionProvidersError,
    setCourseInstitutionProvidersError,
    setCourseInstitutionProviders,
  };
};

export default useCourseInstitutionProvidersHook;
