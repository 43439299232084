const locale = {
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
  INVALID_USER_PASSWORD: 'Invalid user or password',
  CURRENT_PASSWORD_REQUIRED: 'Current password is required',
  PASSWORD_DONT_MATCH: `Passwords don't match`,
  LABEL_CURRENT_PASSWORD: 'Current password',
  LABEL_NEW_PASSWORD: 'New password',
  LABEL_CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CONSTRAINT_CONTAIN: 'The password must contain:',
  CONSTRAINT_UPPERCASE: 'At least 1 upper case letter',
  CONSTRAINT_NUMBER: 'At least 1 number',
  CONSTRAINT_LENGTH: 'Between 8 and 30 characters',
  UPDATE_PASSWORD: 'Update password',
};

export default locale;
