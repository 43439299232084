import { authErrorMessageVar } from '../../cache/common/authErrorMessageReactiveVar';

interface IAuthErrorMessageHook {
  setAuthErrorMessage: ( message: string | undefined ) => void
}

const useAuthErrorMessageHook = ():IAuthErrorMessageHook => {
  const setAuthErrorMessage = ( message: string | undefined ): void => {
    authErrorMessageVar( message );
  };

  return {
    setAuthErrorMessage,
  };
};

export default useAuthErrorMessageHook;
