import { styled } from '@mui/material/styles';

import { fonts } from '../../../components/resources/fonts';
import palette from '../../../theme/learnerPalette';

export const ProfileWrapper = styled( 'div' )(() => ({
  textAlign: 'left',
  width: '150px',
  paddingLeft: '10px',
}));

export const ProfileName = styled( 'div' )(() => ({
  fontFamily: fonts.fontFamily,
  fontWeight: 600,
  color: 'black',
  fontSize: '0.9rem',
}));

export const ProfileType = styled( 'p' )(() => ({
  fontFamily: fonts.fontFamily,
  fontWeight: 400,
  color: palette.light.text.mistBlue,
  fontSize: '0.9rem',
}));
