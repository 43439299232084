import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../../components/resources/styleConfigs';
import GridWrapper from '../../../../components/wrappers/GridWrapper';
import palette from '../../../../theme/learnerPalette';

export const RootStyled = styled( GridWrapper )(() => ({
  height: styleConfigs.dimensions.height100vh,
  display: 'flex',
  [useTheme().breakpoints.down( 'md' )]: {
    height: styleConfigs.dimensions.height100dvh,
  },
  [useTheme().breakpoints.down( 'sm' )]: {
    height: styleConfigs.dimensions.height100svh,
  },
}));

export const MainStyled = styled( GridWrapper )((
  { isfloatingbutton }: { isfloatingbutton?: number},
) => ({
  flexGrow: 1,
  overflowY: 'scroll',
  backgroundColor: palette.light.background.alabaster,
  paddingTop: styleConfigs.marginPadding.custom88,
  paddingBottom: isfloatingbutton ? styleConfigs.marginPadding.custom121 : styleConfigs.marginPadding.custom88,
  paddingInline: styleConfigs.marginPadding.custom16,
  transition: useTheme().transitions.create( 'padding', {
    duration: useTheme().transitions.duration.complex,
  }),
  [useTheme().breakpoints.up( 'sm' )]: {
    paddingBottom: isfloatingbutton ? styleConfigs.marginPadding.custom131 : styleConfigs.marginPadding.custom88,
    paddingInline: styleConfigs.marginPadding.custom24,
  },
  [useTheme().breakpoints.up( 'lg' )]: {
    paddingTop: styleConfigs.marginPadding.custom116,
    paddingInline: styleConfigs.marginPadding.custom40,
  },
}));
