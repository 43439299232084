import { styleConfigs } from '../../components/resources/styleConfigs';
import modalActionTypes from '../actionTypes/modalActionTypes';
import { IModalContentData } from '../reducer/modalReducer';

export const modalOpenAction = ( modalData: IModalContentData ): { type: string, payload: any } => ({
  type: modalActionTypes.MODAL_SET_COMPONENT,
  payload: {
    component: modalData.component,
    directProps: modalData.directProps ?? null,
    desktopWidth: modalData.desktopWidth ?? styleConfigs.dimensions.width900,
    mobileWidth: modalData.desktopWidth ?? styleConfigs.dimensions.width100vw,
    desktopHeight: modalData.desktopHeight ?? styleConfigs.dimensions.auto,
    mobileHeight: modalData.mobileHeight ?? styleConfigs.dimensions.auto,
    mobileRoundedCorner: modalData.mobileRoundedCorner ?? false,
    mobileMarginInline: modalData.mobileMarginInline ?? styleConfigs.marginPadding.empty,
    hideCloseButton: modalData.hideCloseButton ?? false,
    disableCloseOnPressScape: modalData.disableCloseOnPressScape ?? false,
    disableCloseOnClickBackdrop: modalData.disableCloseOnClickBackdrop ?? false,
  },
});

export const modalCloseAction = (): { type: string } => ({
  type: modalActionTypes.MODAL_CLOSE,
});
