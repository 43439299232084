function path( root: string, sublink: string ): string {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOT_AUTH = '/auth';
const ROOT_WIZARD = '/wizard';
const ROOT_CAREER_DISCOVERY = '/career-discovery';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  admin: {
    pageInsights: path( ROOTS_DASHBOARD, '/advisor/insights' ),
    pageStudentOverview: path( ROOTS_DASHBOARD, '/advisor/student-overview' ),
    pageStudentAdvising: path( ROOTS_DASHBOARD, '/advisor/student-advising' ),
    pageCourseRecommendations: path( ROOTS_DASHBOARD, '/advisor/course-recommendations' ),
    pageCareerTrends: path( ROOTS_DASHBOARD, '/advisor/career-trends' ),
    faq: path( ROOTS_DASHBOARD, '/advisor/faq' ),
    contact: path( ROOTS_DASHBOARD, '/advisor/contact' ),
  },
};

export const PATH_DASHBOARD_STUDENT = {
  root: ROOTS_DASHBOARD,
  admin: {
    pageHome: path( ROOTS_DASHBOARD, '/learner/home' ),
    skills: path( ROOTS_DASHBOARD, '/learner/skills' ),
    occupations: path( ROOTS_DASHBOARD, '/learner/occupations' ),
    LearningPaths: path( ROOTS_DASHBOARD, '/learner/learning-paths' ),
    help: path( ROOTS_DASHBOARD, '/learner/help' ),
  },
  profile: {
    profile: path( ROOTS_DASHBOARD, '/learner/profile' ),
    skills: path( ROOTS_DASHBOARD, '/learner/profile/skills' ),
    experience: path( ROOTS_DASHBOARD, '/learner/profileExperience' ),
  },
};

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path( ROOT_AUTH, '/login' ),
  register: path( ROOT_AUTH, '/register' ),
  forgot: path( ROOT_AUTH, '/forgot' ),
  redirect: path( ROOT_AUTH, '/redirect' ),
};

export const PATH_WIZARD = {
  root: ROOT_WIZARD,
  steps: {
    information: path( ROOT_WIZARD, '/information' ),
    occupations: path( ROOT_WIZARD, '/occupations' ),
    skills: path( ROOT_WIZARD, '/skills' ),
    loading: path( ROOT_WIZARD, '/loading' ),
  },
};

export const CAREER_DISCOVERY = {
  root: ROOT_CAREER_DISCOVERY,
  steps: {
    ikigai: path( ROOT_CAREER_DISCOVERY, '/ikigai' ),
    passion: path( ROOT_CAREER_DISCOVERY, '/passion' ),
    mission: path( ROOT_CAREER_DISCOVERY, '/mission' ),
    profession: path( ROOT_CAREER_DISCOVERY, '/profession' ),
    vocation: path( ROOT_CAREER_DISCOVERY, '/vocation' ),
  },
};
