import { useReactiveVar } from '@apollo/client';
import React, { useContext, useEffect } from 'react';

import { RootStyle } from './ListNotificationComponentStyles';
import { notificationsVar } from '../../../cache/cache';
import { NotificationContext } from '../../../contexts/NotificationsContext';
import NotificationComponent from '../NotificationComponent/NotificationComponent';

const ListNotificationComponent: React.FC = () => {
  const { contentData, setShowNotification } = useContext( NotificationContext );
  const notifications = useReactiveVar( notificationsVar );
  const { listNotifications } = contentData;

  useEffect(() => {
    if ( notifications.length ) {
      setShowNotification( true );
    }
  }, [notifications]);

  return (
    <RootStyle
      data-testid="ListNotification"
      show={( listNotifications?.length || notifications.length ) > 0 ? 1 : 0}
    >
      {
        listNotifications.map(( notification, index: number ) => (
          <NotificationComponent
            key={index.toString()}
            {...notification}
          />
        ))
      }
      {
        notifications.map(( notification, index: number ) => (
          <NotificationComponent
            key={index.toString()}
            {...notification}
          />
        ))
      }
    </RootStyle>
  );
};

export default ListNotificationComponent;
