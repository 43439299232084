export const NOTIFICATION_TEXT = {
  title: 'Notifications',
  unreadNotification: 'You have $unread unread notifications',
  nowCompletedText: 'is now completed. You are closer to becoming a',
  inColumnText: 'is now in column',
  isCompletedText: 'is now completed, keep it up with the good work!',
  exploreText: 'Explore our courses and programs to stay up to date with the latest trends.',
  chanceText: 'Your chances to become',
  decreaseText: 'has decreased',
  noLongerPartText: 'is no longer part of your skill set. Your match with',
  noLongerPartNoJobText: 'is no longer part of your skill set. Explore our courses and programs to stay up to date with the latest trends.',
  errorTitle: 'APOLLO_ERROR_TITLE',
  errorDescription: 'APOLLO_ERROR_DESCRIPTION',
};
