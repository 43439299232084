import './ChangePasswordDialogConfig';
import {
  DialogContent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangePasswordDialogStyle,
} from './ChangePasswordDialogStyles';
import ChangePasswordDialogTitle from './ChangePasswordDialogTitle';
import ChangePasswordForm from '../Forms/ChangePasswordForm/ChangePasswordForm';
import DividerWrapper from '../wrappers/DividerWrapper';

export interface IDialogContentProps {
  open: boolean;
  setOpen: ( x: boolean ) => void,
}

const ChangePasswordDialog: React.FC<IDialogContentProps> = ( props: IDialogContentProps ) => {
  const { open, setOpen } = props;
  const { t } = useTranslation( 'ChangePasswordDialog' );

  /* istanbul ignore next */
  const handleClose = () : void => {
    setOpen( false );
  };

  return (
    <div>
      <ChangePasswordDialogStyle
        /* istanbul ignore next */
        aria-labelledby="changepassword-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <ChangePasswordDialogTitle
          id="changepassword-dialog-title"
          onClose={handleClose}
        >
          {t( 'CHANGE_PASSWORD' )}
        </ChangePasswordDialogTitle>
        <DividerWrapper />
        <DialogContent dividers>
          <ChangePasswordForm closeModal={handleClose} />
        </DialogContent>
      </ChangePasswordDialogStyle>
    </div>
  );
};

export default ChangePasswordDialog;
