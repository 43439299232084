import { createContext, ReactNode, useReducer } from 'react';

import { deleteNotificationAction, notificationOpenAction, showNotificationAction } from './actions/notificationActions';
import notificationReducer, { INotificationReducer, notificationReducerState, INotificationData } from './reducer/notificationReducer';

interface INotificationProviderProps {
  children: ReactNode;
}

const NotificationContext = createContext<INotificationReducer>( notificationReducerState );

const NotificationsProvider = ({ children }: INotificationProviderProps ): JSX.Element => {
  const [state, dispatch] = useReducer( notificationReducer, notificationReducerState );
  const notificationOpen = ( notificationData: INotificationData ): void => {
    dispatch( notificationOpenAction( notificationData ));
  };
  const setShowNotification = ( notification: boolean ): void => {
    dispatch( showNotificationAction( notification ));
  };

  const deleteNotification = ( notificationId: string | number ): void => {
    dispatch( deleteNotificationAction( notificationId ));
  };

  return (
    <NotificationContext.Provider
      value={{
        contentData: state.contentData,
        showNotification: state.showNotification,
        notificationOpen,
        setShowNotification,
        deleteNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationsProvider, NotificationContext };
