import { AvatarContainerStyled, PersonOutlinedIconStyled, AvatarStyled } from './styles/AvatarComponentStyles';
import { PlatformAltTexts } from '../../../constants/PlatformAltTexts';

interface IAvatarProps {
  size: number
  src?: string
  nameInitials?: string
}

const AvatarComponent: React.FC<IAvatarProps> = ({ size, src, nameInitials }) => (
  <AvatarContainerStyled>
    <AvatarStyled
      alt={PlatformAltTexts.profile.title}
      src={src}
      size={size}
    >
      {!src && !nameInitials
        ? ( <PersonOutlinedIconStyled size={size} /> )
        : ( <p>{ nameInitials }</p> )}
    </AvatarStyled>
  </AvatarContainerStyled>
);

export default AvatarComponent;
