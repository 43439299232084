import { decryptor, encryptor } from './utils/decryptor';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    envVariables: IAppVariables;
  }
}

interface IAppVariables {
  reactAppApiGraphql?: string;
  reactAppGraphqlSocket?: string;
  embededInIframe?: string;
  environment?: string;
  contactFormUrlIframeEn?: string;
  contactFormUrlIframeEs?: string;
  proxyMixpanel?: string;
  reactAppChatgpt?: string;
  reactAppProviders?: string;
  mixpanelToken?: string;
  mixpanelStudentToken?: string;
  firebaseProject?: string;
  firebaseApiKey?: string;
  firebaseAuthDomain?: string;
  firebaseStorageBucket?: string;
  firebaseMessagingSenderId?: string;
  firebaseAppId?: string;
  iframeAndPlatform?: string;
  reactAppApiGraphqlDev?: string;
  reactAppGraphqlSocketDev?: string;
  reactAppSamlGriky?: string;
  reactAppSamlUVA?: string;
}

export const initializeVariables = (): void => {
  const variables = window.envVariables;

  if ( variables ) {
    const jsonString = JSON.stringify( variables );
    const encryptedJson = encryptor( jsonString );

    localStorage.setItem( 'keys', encryptedJson ?? '' );
  }
};

initializeVariables();

const loadVariables = (): IAppVariables | undefined => {
  const storedVariables = decryptor( localStorage.getItem( 'keys' ) ?? undefined );
  const envVariables = storedVariables && storedVariables.length > 0
    ? JSON.parse( storedVariables ?? '' ) as IAppVariables : undefined;

  return {
    reactAppApiGraphql: decryptor( envVariables?.reactAppApiGraphql ) ?? process.env.REACT_APP_API_GRAPHQL,
    reactAppGraphqlSocket:
      decryptor( envVariables?.reactAppGraphqlSocket ) ?? process.env.REACT_APP_GRAPHQL_SOCKET,
    embededInIframe: decryptor( envVariables?.embededInIframe ) ?? 'false',
    environment: decryptor( envVariables?.environment ) ?? 'development',
    contactFormUrlIframeEn:
      decryptor( envVariables?.contactFormUrlIframeEn ) ?? process.env.CONTACT_FORM_URL_IFRAME,
    contactFormUrlIframeEs:
      decryptor( envVariables?.contactFormUrlIframeEs ) ?? process.env.CONTACT_FORM_URL_IFRAME,
    proxyMixpanel: decryptor( envVariables?.proxyMixpanel ) ?? process.env.PROXY_MIXPANEL,
    reactAppChatgpt: decryptor( envVariables?.reactAppChatgpt ) ?? process.env.REACT_APP_CHATGPT,
    reactAppProviders: decryptor( envVariables?.reactAppProviders ) ?? process.env.REACT_APP_PROVIDERS,
    mixpanelToken: decryptor( envVariables?.mixpanelToken ) ?? process.env.MIXPANEL_TOKEN,
    mixpanelStudentToken: decryptor( envVariables?.mixpanelStudentToken ) ?? process.env.MIXPANEL_STUDENT_TOKEN,
    firebaseProject: decryptor( envVariables?.firebaseProject ) ?? process.env.FIREBASE_PROJECT,
    firebaseApiKey: decryptor( envVariables?.firebaseApiKey ) ?? process.env.FIREBASE_API_KEY,
    firebaseAuthDomain: decryptor( envVariables?.firebaseAuthDomain ) ?? process.env.FIREBASE_AUTH_DOMAIN,
    firebaseStorageBucket:
      decryptor( envVariables?.firebaseStorageBucket ) ?? process.env.FIREBASE_STORAGE_BUCKET,
    firebaseMessagingSenderId:
      decryptor( envVariables?.firebaseMessagingSenderId ) ?? process.env.FIREBASE_MESSAGING_SENDER_ID,
    firebaseAppId: decryptor( envVariables?.firebaseAppId ) ?? process.env.FIREBASE_APP_ID,
    iframeAndPlatform: decryptor( envVariables?.iframeAndPlatform ) ?? 'false',
    reactAppSamlGriky: decryptor( envVariables?.reactAppSamlGriky ) ?? 'false',
    reactAppSamlUVA: decryptor( envVariables?.reactAppSamlUVA ) ?? 'false',
    reactAppApiGraphqlDev:
      decryptor( envVariables?.reactAppApiGraphqlDev ) ?? process.env.REACT_APP_API_GRAPHQL_DEV,
    reactAppGraphqlSocketDev:
      decryptor( envVariables?.reactAppGraphqlSocketDev ) ?? process.env.REACT_APP_GRAPHQL_SOCKET_DEV,
  };
};

const environmentVariables = loadVariables();

export default environmentVariables;
