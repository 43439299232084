import { ApolloError, makeVar } from '@apollo/client';

export type IColumnInKanban = 'backlog' | 'learning' | 'completed'

interface IStudentSkillStatus {
  TOLEARN: IColumnInKanban,
  LEARNING: IColumnInKanban,
  COMPLETED: IColumnInKanban,
}

export const StudentSkillStatus: IStudentSkillStatus = {
  TOLEARN: 'backlog',
  LEARNING: 'learning',
  COMPLETED: 'completed',
};

export interface ISkillKanban {
  skillId: string;
  status: string;
  name: string;
  type: string;
  translatedType?: string;
  percentageMatch: number;
  validatedSkill: boolean;
}

export interface IKanbanVar {
  id: IColumnInKanban;
  items: ISkillKanban[];
  title: string;
}

export interface IKanbanCache {
  error: ApolloError | undefined;
  data: IKanbanVar[];
}

export const emptyKanbanDataCache: IKanbanCache = {
  error: undefined,
  data: [],
};

export const kanbanDataVar = makeVar<IKanbanCache>( emptyKanbanDataCache );
