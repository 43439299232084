import { alpha, Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';

// ----------------------------------------------------------------------

export default function Lists( theme: Theme ): unknown {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.yaleBlue ?? theme.palette.primary.main;
  const backgroundColorPalette = wlPalette?.background?.yaleBlue ?? theme.palette.action.active;

  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&.active': {
            color: `${colorPalette} !important`,
            backgroundColor: alpha( backgroundColorPalette, 0.1 ),
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing( 2 ),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing( 2 ),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
