import { ReactiveVar } from '@apollo/client';

import { cloneDeep } from 'lodash';

import { ILearningPathsByInstitutionCache, learningPathsByInstitutionVar } from '../../cache/learner/learningPathsByInstitutionReactiveVar';
import { ILearningPathsByInstitution } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';

export interface ILearningPathsByInstitutionHook {
  operations: {
    setLearningPathsByInstitution: ( learningPathsByInstitution: ILearningPathsByInstitution[]) => void;
    deleteLearningPathsByInstitution: ( learningId: string ) => void;
    learningPathsByInstitutionData: ILearningPathsByInstitutionCache;
  }
}

export const useLearningPathsByInstitutionHook = (
  learningPathsByInstitutionVarProp: ReactiveVar<ILearningPathsByInstitutionCache>,
): ILearningPathsByInstitutionHook => {
  const learningPathsByInstitutionData = learningPathsByInstitutionVarProp();

  const setLearningPathsByInstitution = ( learningPathsByInstitution: ILearningPathsByInstitution[]):void => {
    const tempData = cloneDeep( learningPathsByInstitution );

    learningPathsByInstitutionVar(
      {
        error: undefined,
        data: tempData.sort(( a, b ) => b.paths?.length - a.paths?.length ),
      },
    );
  };

  const deleteLearningPathsByInstitution = ( learningId: string ):void => {
    const tempData = learningPathsByInstitutionData.data.map(( learningPath ) => ({
      majorId: learningPath.majorId,
      majorName: learningPath.majorName,
      paths: learningPath.paths.filter(( path ) => path.id !== learningId ),
    }));

    setLearningPathsByInstitution( tempData );
  };

  return {
    operations: {
      learningPathsByInstitutionData,
      setLearningPathsByInstitution,
      deleteLearningPathsByInstitution,
    },
  };
};
