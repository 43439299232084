import type { IWriteLogParams } from '../../operations/mutations/logging/useWriteLog';
import { useWriteLog } from '../../operations/mutations/logging/useWriteLog';

interface IUseLogger {
  operations: {
    writeLogger: ( params: IWriteLogParams ) => Promise<boolean>
  }
}

const useLogger = (): IUseLogger => {
  const [writeLog] = useWriteLog();

  const writeLogger = async ( params:IWriteLogParams ): Promise<boolean> => {
    try {
      const {
        project, source, func, error, level,
      } = params;
      const result = await writeLog({
        variables: {
          project, source, func, error, level,
        },
      });

      return result.data?.writeLog?.success ?? false;
    } catch ( error: unknown ) {
      throw new Error( error as string );
    }
  };

  return {
    operations: {
      writeLogger,
    },
  };
};

export default useLogger;
