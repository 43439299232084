import { alpha, Theme, styled } from '@mui/material/styles';

import { marginPadding } from '../../../components/resources/marginPadding';
import { styleConfigs } from '../../../components/resources/styleConfigs';
import { MIconButton } from '../../../components/template-minimal/@material-extend';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import useGetPaletteFromWhiteLabel from '../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../theme/learnerPalette';

interface IFocusStyle {
  border: string | number,
  borderColor?: string,
}

const focusStyle = ( fromKeyBoard : number ): IFocusStyle => {
  if ( fromKeyBoard !== 0 ) {
    return {
      border: styleConfigs.borders.borderLine2px,
      borderColor: palette.light.borders.yaleBlue,
    };
  }

  return {
    border: styleConfigs.borders.empty,
  };
};

export const MIconButtonStyle = styled( MIconButton )((
  { theme, fromkeyboard }: {theme : Theme, fromkeyboard : number},
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColorPalette = wlPalette?.background?.redViolet ?? palette.light.background.redViolet;

  return ({
    padding: `${styleConfigs.marginPadding.custom8}px ${styleConfigs.marginPadding.custom10}px`,
    ':hover': { backgroundColor: alpha( backgroundColorPalette, 0.1 ) },
    '&:focus': focusStyle( fromkeyboard ),
    [theme.breakpoints.down( 'sm' )]: {
      padding: marginPadding.custom6,
    },
  });
});

export const GridScrollStyled = styled( GridWrapper )(() => ({
  height: styleConfigs.dimensions.height508,
  marginRight: styleConfigs.marginPadding.custom1,
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: styleConfigs.dimensions.width6,
  },
  '::-webkit-scrollbar-thumb': {
    background: palette.light.background.transparent,
    borderRadius: styleConfigs.borders.customRadius3,
  },
  '&:hover::-webkit-scrollbar-thumb': {
    background: palette.light.background.amethyst,
    borderRadius: styleConfigs.borders.customRadius3,
  },
}));

export const NewNotifications = styled( 'span' )(() => ({
  width: styleConfigs.dimensions.width21,
  height: styleConfigs.dimensions.height22,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: styleConfigs.fonts.fontSize12,
  color: palette.light.text.white,
  backgroundColor: palette.light.background.watermelon,
  transform: `translate(${styleConfigs.marginPadding.custom10}px, ${styleConfigs.marginPadding.customNegative10}px)`,
  borderRadius: styleConfigs.borders.borderRound,
  position: 'absolute',
}));
