import { makeVar } from '@apollo/client';

import { IGetCoursesRecommendations } from '../../operations/query/students/useGetStudentSkillSetMatchByOccupation';

export interface ISkillForCoursesRecommendationCache {
  error: string;
  data: IGetCoursesRecommendations[] | undefined;
}

export const emptySkillForCoursesRecommendationCache: ISkillForCoursesRecommendationCache = {
  error: '',
  data: undefined,
};

export const SkillForCoursesRecommendationReactiveVar = makeVar<ISkillForCoursesRecommendationCache>(
  emptySkillForCoursesRecommendationCache,
);
