import { FooterStyled } from './styles/FooterComponentStyles';
import { TypeCustomTexts, useCustomText } from '../../../contexts/InstitutionCustomTextContext';
import CircularLoaderComponent from '../CircularLoaderComponent/CircularLoaderComponent';

const FooterComponent: React.FC = () => {
  const { loading, description } = useCustomText( TypeCustomTexts.FOOTER );

  if ( !description ) {
    return null;
  }

  return (
    <FooterStyled>
      {
        loading ? (
          <CircularLoaderComponent
            isLoading
            type="inner"
            background="transparent"
            textColor="prussianBlue"
          />
        ) : (
          <footer>
            <div>
              {description}
            </div>
          </footer>
        )
      }
    </FooterStyled>
  );
};

export default FooterComponent;
