/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useFormik } from 'formik';

const Formik = ( initialValues: any, validationSchema: any, onSubmit: any ) : any => useFormik({
  initialValues,
  validationSchema,
  onSubmit,
});

export default Formik;
