import './AccountInfoConfig';
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  ProfileContainer,
  ProfileName, ProfileDetails, ProfileMail, LocationHeader, ProfileLocation, BoxAvatar, EmptyMajorsLinkStyled,
} from './AccountInfoStyles';
import { userMajors } from '../../../cache/cache';
import SkeletonComponent from '../../../components/generics/SkeletonComponent/SkeletonComponent';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import TypographyWrapper from '../../../components/wrappers/TypographyWrapper';
import userAuth from '../../../hooks/userAuth';
import { PATH_DASHBOARD_STUDENT } from '../../../routes/paths';
import palette from '../../../theme/learnerPalette';

const AccountInfo: React.FC = () => {
  const { auth } = userAuth();

  const userMajorsStore = useReactiveVar( userMajors );
  const studentInitials = `${auth?.student?.name.replace( /\s/g, '' )[0]}${auth?.student?.lastName.replace( /\s/g, '' )[0]}`;
  const studentFullName = `${auth?.student?.name} ${auth?.student?.lastName}`;
  const navigate = useNavigate();
  const { t } = useTranslation( 'LearnerAccountInfo' );
  const institutionName = auth?.studentInstitution?.institutionName ?? auth?.studentInstitution?.otherInstitutionName;

  return (
    <ProfileContainer>
      <GridWrapper container spacing={0.5}>
        <GridWrapper item xs={5}>
          <BoxAvatar>
            <TypographyWrapper color={palette.light.text.white} variant="h2">
              {studentInitials}
            </TypographyWrapper>
          </BoxAvatar>
        </GridWrapper>
        <GridWrapper item xs={7}>
          <ProfileDetails>
            <ProfileName>
              {studentFullName ?? t( 'GUEST_DISPLAYNAME' )}
            </ProfileName>
            {userMajorsStore ? (
              userMajorsStore.map(( major, index: number ) => (
                <ProfileMail key={index.toString()}>
                  { major.name }
                </ProfileMail>
              ))
            ) : ( <SkeletonComponent sizeSkeleton={1} variant="h3" /> )}
            {!userMajorsStore?.length && (
              <ProfileMail>
                {t( 'NO_MAJORS_LENGTH' )}
              </ProfileMail>
            )}
            {institutionName && (
              <>
                <LocationHeader>
                  {t( 'INFO_POPOVER_STUDENT_LOCATION' )}
                </LocationHeader>
                <ProfileLocation>
                  {institutionName}
                </ProfileLocation>
              </>
            )}
            {!userMajorsStore?.length && (
              <EmptyMajorsLinkStyled
                onClick={() => navigate( PATH_DASHBOARD_STUDENT.profile.profile )}
              >
                {t( 'ADD_MAJOR' )}
              </EmptyMajorsLinkStyled>
            )}
          </ProfileDetails>
        </GridWrapper>
      </GridWrapper>
    </ProfileContainer>
  );
};

export default AccountInfo;
