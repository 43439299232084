import { alpha, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import ButtonWrapper from '../../../components/wrappers/ButtonWrapper';
import DrawerWrapper from '../../../components/wrappers/DrawerWrapper';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import useGetPaletteFromWhiteLabel from '../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../theme/learnerPalette';

export const DrawerContainerStyled = styled( GridWrapper )((
  { iframeactive }: { iframeactive: number},
) => ({
  flexShrink: 0,
  [useTheme().breakpoints.up( 'lg' )]: {
    width: ( iframeactive ) ? styleConfigs.dimensions.empty : styleConfigs.dimensions.width280,
  },
}));

export const DrawerDesktopStyled = styled( DrawerWrapper )((
  { iframeactive }: { iframeactive?: number},
) => ({
  display: 'none',
  [useTheme().breakpoints.up( 'lg' )]: {
    display: iframeactive ? 'none' : 'flex',
  },
}));

export const DrawerMobileStyled = styled( DrawerWrapper )((
  { iframeactive }: { iframeactive?: number},
) => ({
  [useTheme().breakpoints.up( 'lg' )]: {
    display: iframeactive ? 'flex' : 'none',
  },
}));

export const LogoContainerStyled = styled( GridWrapper )({
  display: 'flex',
  justifyContent: 'center',
  paddingBlock: styleConfigs.marginPadding.custom32,
  width: styleConfigs.dimensions.percent95,
});

export const BackButtonStyled = styled( ButtonWrapper )((
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.text?.redViolet ?? palette.light.text.redViolet;

  return {
    marginBlock: styleConfigs.marginPadding.custom32,
    marginInline: styleConfigs.marginPadding.custom16,
    color: colorPalette,
    border: `${styleConfigs.borders.borderLine2px} ${colorPalette}`,
    '&:focus ': {
      border: `${styleConfigs.borders.borderLine2px} ${colorPalette}`,
    },
    '&:hover ': {
      border: `${styleConfigs.borders.borderLine2px} ${colorPalette}`,
      backgroundColor: alpha( colorPalette, 0.1 ),
    },
  };
});
