const locale = {
  PROFILE: 'Profile',
  CHANGE_PASSWORD: 'Change your password',
  LOGOUT: 'Logout',
  HOME: 'Recommendations',
  SKILLS: 'Skills',
  OCCUPATIONS: 'Career Goals',
  LEARNING_PATHS: 'Learning Paths',
  HELP: 'Help',
  PERSONAL: 'Personal Information',
  PREFERENCES: 'Preferences',
  BACK_TO_DASHBOARD: 'Back to the dashboard',
};

export default locale;
