import { ApolloError, makeVar } from '@apollo/client';

import { ILearningPathwaysByObjectives } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';

export interface ILearningPathsByObjectivesCache {
  error: ApolloError | undefined;
  data: ILearningPathwaysByObjectives[];
}

export const emptyLearningPathsByObjectivesCache: ILearningPathsByObjectivesCache = {
  error: undefined,
  data: [],
};
export const learningPathsByObjectivesVar = makeVar<ILearningPathsByObjectivesCache>(
  emptyLearningPathsByObjectivesCache,
);
