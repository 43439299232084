import './StudentLayoutViewDataHookConfig';
import { useReactiveVar } from '@apollo/client';
import {
  FC, useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';

import { useLoadersCache } from '../../cache/common/loadersReactiveVar';
import { jobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import { jobTitlesInstitutionReactiveVar } from '../../cache/learner/jobTitlesInstitutionReactiveVar';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import { learningPathsByInstitutionVar } from '../../cache/learner/learningPathsByInstitutionReactiveVar';
import { learningPathsByObjectivesVar } from '../../cache/learner/learningPathsByObjectivesReactiveVar';
import { RecommendedOccupationsByMajorReactiveVar } from '../../cache/learner/recommendedOccupationsByMajorReactiveVar';
import { RecommendedOccupationsReactiveVar } from '../../cache/learner/recommendedOccupationsReactiveVar';
import { recommendedSkillsVar } from '../../cache/learner/recommendedSkillsReactiveVar';
import { RelatedOccupationsReactiveVar } from '../../cache/learner/relatedOccupationsReactiveVar';
import { SkillForCoursesRecommendationReactiveVar } from '../../cache/learner/skillForCoursesRecommendationReactiveVar';
import { studentLearningPathsVar } from '../../cache/learner/studentLearningPathsReactiveVar';
import { reactAppProviders } from '../../config';
import { useQueryGetInstitutionCourseProviders } from '../../operations/query/institutions/useGetInstitutionsCourseProvider';
import {
  useLazyGetJobTitlesInstitution,
} from '../../operations/query/institutions/useGetJobTitlesInstitution';
import { useLazyGetJobRecommendationsByStudentId } from '../../operations/query/jobs/useGetTopJobsWithMatchSkillSet';
import { useQueryGetLearningPathsInitial } from '../../operations/query/learningPaths/useGetLearningPathsInitial/useGetLearningPathsInitial';
import { IJobPreferences, useLazyGetLearnerEntireProfile } from '../../operations/query/students/useGetLearnerEntireProfile/useGetLearnerEntireProfile';
import { useLazyQueryGetJobRecommendedOccupationsLight } from '../../operations/query/students/useGetRecommendedOccupations/useGetRecommendedOccupations';
import { useLazyGetJobRelatedOccupations } from '../../operations/query/students/useGetRelatedOccupations';
import useCourseInstitutionProvidersHook from '../courseInstitutionProvidersHook/useCourseInstitutionProvidersHook';
import useJobPreferencesDataHook from '../jobPreferencesDataHook/useJobPreferencesDataHook';
import { useLearningPathsByInstitutionHook } from '../learningPathsByInstitutionHook/useLearningPathsByInstitutionHook';
import { useLearningPathsByObjectivesHook } from '../learningPathsByObjectivesHook/useLearningPathsByObjectivesHook';
import useNotificationsHook from '../notificationsHook/useNotificationsHook';
import useRecommendedOccupationsByMajorHook from '../recommendedOccupationsByMajorHook/recommendedOccupationsByMajorHook';
import RecommendedOccupationHook from '../RecommendedOccupationsHook/recommendedOccupationsHook';
import useRecommendedSkillsHook from '../recommendedSkillsHook/useRecommendedSkillsHook';
import SkillForCoursesRecommendationHook from '../skillForCoursesRecommendationHook/skillForCoursesRecommendationHook';
import useStudentLearningPathsHook from '../studentLearningPathsHook/useStudentLearningPathsHook';
import useJobTitlesInstitutionReactiveVarHook from '../useJobTitlesInstitutionReactiveVarHook/useJobTitlesInstitutionReactiveVarHook';
import userAuth from '../userAuth';

export interface IStudentLayoutViewDataHookProps {
  children: JSX.Element;
}

const StudentLayoutViewDataHook: FC<IStudentLayoutViewDataHookProps> = ({
  children,
}: IStudentLayoutViewDataHookProps ) => {
  const { auth } = userAuth();
  const { operations: notificationOps } = useNotificationsHook();
  const { t } = useTranslation( 'StudentLayoutViewDataHook' );
  const studentId: string = auth?.student?.id ?? '';
  const institutionId: string = auth?.studentInstitution?.institutionId ?? '';
  const loaderId = 'StudentLayoutViewDataHookLoaderId';

  const { addLoader, removeLoader, loaders } = useLoadersCache();
  const { operations } = useJobPreferencesDataHook( jobPreferencesVar, kanbanDataVar );
  const { setRecommendedSkills } = useRecommendedSkillsHook( recommendedSkillsVar );
  const { setRecommendedOccupations } = RecommendedOccupationHook( RecommendedOccupationsReactiveVar );
  const { setRecommendedOccupationsByMajor } = useRecommendedOccupationsByMajorHook(
    RecommendedOccupationsByMajorReactiveVar,
  );
  const { setSkillForCoursesRecommendations } = SkillForCoursesRecommendationHook();
  const { setCourseInstitutionProviders } = useCourseInstitutionProvidersHook();
  const {
    operations: jobTitlesInstitutionOperations,
  } = useJobTitlesInstitutionReactiveVarHook( jobTitlesInstitutionReactiveVar );
  const { data: courseProvidresData, error: courseProvidresError } = useQueryGetInstitutionCourseProviders( auth?.studentInstitution?.institutionId ?? '', true );
  const { data: learningPathsData } = useQueryGetLearningPathsInitial( true );
  const { operations: learningPathsOps } = useStudentLearningPathsHook( studentLearningPathsVar );
  const { operations: learningPathsByInstitutionOps } = useLearningPathsByInstitutionHook(
    learningPathsByInstitutionVar,
  );
  const { operations: learningPathsByObjectivesOps } = useLearningPathsByObjectivesHook( learningPathsByObjectivesVar );

  const [
    getJobTitlesInstitution,
    {
      data: jobTitlesInstitutionData,
      error: jobTitlesInstitutionError,
    },
  ] = useLazyGetJobTitlesInstitution( false );

  const [
    getJobRecommendationsByStudentId,
    {
      data: jobRecommendationData,
      loading: jobRecommendationLoading,
      error: jobRecommendationError,
    },
  ] = useLazyGetJobRecommendationsByStudentId( false );
  const [
    getJobRecommendedOccupationsLight,
    {
      data: jobRecommendationByMajor,
      loading: jobRecommendationByMajorLoading,
      error: jobRecommendationByMajorError,
    },
  ] = useLazyQueryGetJobRecommendedOccupationsLight( false );
  const [
    getLearnerEntireProfile,
    {
      data: userEntireProfile,
      loading: userEntireProfileLoading,
      error: userEntireProfileError,
    },
  ] = useLazyGetLearnerEntireProfile( false );

  const [
    getRelatedOccupations,
    { data: relatedOccupationsData, error: relatedOccupationsError },
  ] = useLazyGetJobRelatedOccupations();

  const recommendedSkills = useReactiveVar( recommendedSkillsVar );
  const recommendedOcupations = useReactiveVar( RecommendedOccupationsReactiveVar );
  const recommendedOcupationsByMajor = useReactiveVar( RecommendedOccupationsByMajorReactiveVar );
  const skillsCourses = useReactiveVar( SkillForCoursesRecommendationReactiveVar );
  const jobPreferences = useReactiveVar( jobPreferencesVar );

  const getRecommendations = (): void => {
    getJobRecommendationsByStudentId({
      variables: {
        studentId,
      },
    });
    getJobRecommendedOccupationsLight({
      variables: {
        studentId,
      },
    });

    const jobTitleIds = jobPreferences.data.map(( jobPreference ) => jobPreference.jobTitleId );

    getRelatedOccupations({
      variables: {
        jobTitleIds,
      },
    });
  };

  useEffect(() => {
    try {
      getLearnerEntireProfile({
        variables: {
          studentId,
          defaultProvidersId: reactAppProviders,
        },
        fetchPolicy: 'network-only',
      });

      if (( !recommendedSkills.data?.length
        || !recommendedOcupations.data?.length
        || !recommendedOcupationsByMajor.data?.length
        || !skillsCourses.data?.length
        || !jobPreferences.data?.length
      ) && !loaders.includes( loaderId )) {
        addLoader( loaderId );
      }
      getRecommendations();
    } catch ( error ) {
      notificationOps.addNotification( 'danger', error as string, t( 'SOMETHING_WENT_WRONG' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( institutionId ) {
      getJobTitlesInstitution({
        variables: {
          institutionId,
        },
      });
    }
  }, [institutionId]);

  useEffect(() => {
    if (
      (
        userEntireProfileLoading
        || jobRecommendationLoading
        || jobRecommendationLoading
        || jobRecommendationByMajorLoading
      ) && !loaders.includes( loaderId )) {
      addLoader( loaderId );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntireProfileLoading, jobRecommendationLoading]);

  useEffect(() => {
    if ( !userEntireProfileError ) {
      if ( userEntireProfile?.getLearnerEntireProfile?.jobPreferences.length ) {
        try {
          const jobTitleIds = userEntireProfile
            .getLearnerEntireProfile.jobPreferences.map(({ jobTitleId }) => jobTitleId ) || [];

          operations.setJobPreferences(
            userEntireProfile.getLearnerEntireProfile.jobPreferences.map(( jobPreference ) => ({
              jobTitleId: jobPreference.jobTitleId,
              jobTitleName: jobPreference.jobTitleName,
              match: 0,
              studentId,
              jobTitleSkillSet: jobPreference.jobTitleSkillSet.map(( skill ) => ({
                skill: skill.skill,
                skillName: skill.skillName,
                skillType: skill.skillType,
                jobTitle: skill.jobTitle,
                studentId,
                skillTypeTranslated: skill.skillTypeTranslated,
              })),
            }) as IJobPreferences ),
          );
          getRelatedOccupations({
            variables: {
              jobTitleIds,
            },
          });
        } catch ( error ) {
          notificationOps.addNotification( 'danger', error as string, t( 'SOMETHING_WENT_WRONG' ));
        }
      }
    } else if ( userEntireProfileError ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntireProfile, userEntireProfileError]);

  useEffect(() => {
    if ( !jobRecommendationError ) {
      const jobsWithMatch = jobRecommendationData?.jobsWithMatch;

      if ( jobsWithMatch ) {
        setRecommendedOccupations(
          jobsWithMatch.map(( jobTitle ) => ({
            name: jobTitle.job,
            match: jobTitle.match,
            jobTitleId: jobTitle.id,
          })),
        );
      }
    } else {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRecommendationData, jobRecommendationError]);

  useEffect(() => {
    if ( !jobRecommendationByMajorError ) {
      const majorData = jobRecommendationByMajor?.getRecommendationsByMajor;

      if ( majorData ) {
        setRecommendedOccupationsByMajor(
          majorData.map(( recommendedMajor ) => ({
            majorId: recommendedMajor.studentMajor.tblMajor?.id ?? '',
            majorName: recommendedMajor.studentMajor.tblMajor?.name ?? '',
            match: recommendedMajor.percentageOfMajorMatch.map(( percentage ) => ({
              name: percentage.name,
              match: percentage.match,
              jobTitleId: percentage.id,
            })),
          })),
        );
      }
    } else {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRecommendationByMajor, jobRecommendationByMajorError]);

  useEffect(() => {
    if ( !userEntireProfileError ) {
      const coursesData = userEntireProfile?.getLearnerEntireProfile?.studentSkillForCoursesRecommendation;
      const recommendationsByMajor = userEntireProfile?.getLearnerEntireProfile?.recommendationsByMajor;
      const skillsData = userEntireProfile?.getLearnerEntireProfile?.recommendedSkills;

      if ( skillsData?.length ) {
        setRecommendedSkills( skillsData );
      }

      if ( recommendationsByMajor?.length ) {
        setRecommendedOccupationsByMajor(
          recommendationsByMajor.map(( recommendedMajor ) => ({
            majorId: recommendedMajor.studentMajor.tblMajor?.id ?? '',
            majorName: recommendedMajor.studentMajor.tblMajor?.name ?? '',
            match: recommendedMajor.percentageOfMajorMatch.map(( percentage ) => ({
              name: percentage.name,
              match: percentage.match,
              jobTitleId: percentage.id,
            })),
          })),
        );
      }

      if ( coursesData ) {
        const courses = coursesData.filter(({ course }) => course?.name );

        setSkillForCoursesRecommendations(
          courses.map(( skillCourse, index ) => ({
            skillId: skillCourse.skillId,
            skillName: skillCourse.skillName,
            skillType: skillCourse.skillType,
            course: {
              id: index,
              name: skillCourse.course.name,
              provider: skillCourse.course.provider,
              url: skillCourse.course.url,
              imageUrl: skillCourse.course.imageUrl,
              logoUrl: skillCourse.course.logoUrl,
              hasMagicLink: skillCourse.course.hasMagicLink,
              magicLink: skillCourse.course.magicLink,
            },
          })),
        );
      }
    } else if ( userEntireProfileError ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntireProfile?.getLearnerEntireProfile]);

  useEffect(() => {
    if ( !relatedOccupationsError ) {
      const relatedData = relatedOccupationsData?.relatedOccupations;

      if ( relatedData ) {
        RelatedOccupationsReactiveVar({ error: '', data: relatedData });
      }
    } else {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedOccupationsData, relatedOccupationsError]);

  useEffect(() => {
    if (
      ( recommendedSkills.data?.length || userEntireProfile?.getLearnerEntireProfile?.recommendedSkills )
      && ( recommendedOcupations.data?.length || userEntireProfile?.getLearnerEntireProfile?.recommendedOccupations )
      && (
        recommendedOcupationsByMajor.data?.length
        || userEntireProfile?.getLearnerEntireProfile?.recommendationsByMajor
      )
      && (
        skillsCourses.data?.length
        || userEntireProfile?.getLearnerEntireProfile?.studentSkillForCoursesRecommendation
      )
    ) {
      removeLoader( loaderId );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recommendedSkills,
    recommendedOcupations,
    recommendedOcupationsByMajor,
    skillsCourses,
    userEntireProfileLoading,
    jobRecommendationLoading,
    jobRecommendationByMajorLoading,
  ]);

  useEffect(() => {
    if ( !courseProvidresError ) {
      setCourseInstitutionProviders( courseProvidresData?.getInstitutionCourseProviders ?? []);
    } else {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseProvidresData, courseProvidresError]);

  useEffect(() => {
    const jobTitlesInstitution = jobTitlesInstitutionData?.getJobTitlesInstitution;

    if ( jobTitlesInstitution ) {
      jobTitlesInstitutionOperations.setJobTitlesInstitutionData(
        jobTitlesInstitution,
      );
    }
    if ( jobTitlesInstitutionError ) {
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTitlesInstitutionData, jobTitlesInstitutionError]);

  useEffect(() => {
    if ( learningPathsData?.getStudentPathways ) {
      learningPathsOps.setStudentLearningPaths( learningPathsData.getStudentPathways );
    }
    if ( learningPathsData?.getPathwaysByInstitutionAndOccupations ) {
      learningPathsByInstitutionOps.setLearningPathsByInstitution(
        learningPathsData?.getPathwaysByInstitutionAndOccupations,
      );
    }
    if ( learningPathsData?.getPathwaysByObjectives ) {
      learningPathsByObjectivesOps.setLearningPathsByObjectives( learningPathsData?.getPathwaysByObjectives );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningPathsData]);

  return <>{children}</>;
};

export default StudentLayoutViewDataHook;
