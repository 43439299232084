import {
  Dialog,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import palette from '../../theme/learnerPalette';
import { styleConfigs } from '../resources/styleConfigs';

export const ChangePasswordDialogStyle = styled( Dialog )(() => ({
  '& .MuiDialogContent-root': {
    padding: styleConfigs.marginPadding.custom24,
  },
}));

export const ChangePasswordDialogTitleStyle = styled( DialogTitle )((
  { theme }: {theme: Theme},
) => ({
  minWidth: styleConfigs.dimensions.width435,
  maxWidth: styleConfigs.dimensions.width435,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: styleConfigs.fonts.bold,
  fontSize: `${styleConfigs.fonts.fontSize20} !important`,
  color: palette.light.text.ebonyClay,
  padding: `${styleConfigs.marginPadding.custom38}px ${styleConfigs.marginPadding.custom36}px 
  ${styleConfigs.marginPadding.custom30}px ${styleConfigs.marginPadding.custom24}px`,
  [theme.breakpoints.down( 'sm' )]: {
    minWidth: styleConfigs.dimensions.width368,
    fontSize: `${styleConfigs.fonts.fontSize18} !important`,
    padding: `${styleConfigs.marginPadding.custom18}px ${styleConfigs.marginPadding.custom24}px`,
  },
}));

export const CloseIconButton = styled( IconButton )(() => ({
  color: palette.light.icons.mistBlue,
  padding: styleConfigs.marginPadding.empty,
}));
