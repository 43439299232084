import './NewModalLayoutComponentConfig';
import { useContext, ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultModalHeader from './components/DefaultModalHeader/DefaultModalHeader';
import {
  DialogContentStyled, HeaderContainerStyled, FooterContainerStyled,
} from './styles/NewModalLayoutComponentStyles';
import { ModalContext } from '../../../contexts/ModalContext';
import CircularLoaderComponent from '../CircularLoaderComponent/CircularLoaderComponent';

export type skillStatusType = 'backlog' | 'learning' | 'completed';

interface IModalLayoutProps {
  activeHeader?: boolean;
  titleHeader?: ReactNode | string;
  customHeader?: ReactNode;
  skillStatus?: skillStatusType;
  scrollRef?: RefObject<HTMLDivElement>;
  loading?: boolean;
  children: ReactNode;
  customFooter?: ReactNode;
  paddingInline?: number,
  paddingBlockStart?: number,
  hasDivider?: boolean;
  onBackButton?: VoidFunction;
  customModalClose?: VoidFunction;
}

const NewModalLayoutComponent : React.FC<IModalLayoutProps> = ( props ) => {
  const {
    activeHeader = true,
    titleHeader = '',
    customHeader,
    skillStatus,
    scrollRef,
    loading,
    children,
    customFooter,
    paddingInline,
    paddingBlockStart,
    hasDivider,
    onBackButton,
    customModalClose,
  } = props;
  const { t } = useTranslation( 'NewModalLayoutComponent' );
  const { modalClose } = useContext( ModalContext );

  return (
    <>
      <HeaderContainerStyled
        hasDivider={hasDivider ? 1 : 0}
      >
        { activeHeader && ( customHeader ?? (
          <DefaultModalHeader
            title={titleHeader}
            paddingInline={paddingInline}
            paddingBlockStart={paddingBlockStart}
            onBackButton={onBackButton}
            skillStatus={skillStatus}
            modalClose={customModalClose ?? modalClose}
          />
        ))}
      </HeaderContainerStyled>
      <DialogContentStyled
        ref={scrollRef}
      >
        <CircularLoaderComponent
          isLoading={loading}
          type="inner"
          background="transparent"
          text={t( 'LOADER_TEXT' )}
          textColor="prussianBlue"
        />
        {children}
      </DialogContentStyled>
      { customFooter && (
        <FooterContainerStyled hasDivider={hasDivider ? 1 : 0}>
          {customFooter}
        </FooterContainerStyled>
      )}
    </>
  );
};

export default NewModalLayoutComponent;
