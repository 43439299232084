import { alpha, styled } from '@mui/material/styles';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import SidebarConfiguration from './SidebarConfiguration/SidebarConfiguration';
import { LogoContainerStyled } from './styles/DashboardSidebarStyles';
import { MHidden } from '../../components/template-minimal/@material-extend';
import Logo from '../../components/template-minimal/LogoComponent/Logo';
import NavSection from '../../components/template-minimal/NavSectionComponent/NavSection';
import DrawerWrapper from '../../components/wrappers/DrawerWrapper';
import { COLLAPSE_WIDTH, DRAWER_WIDTH } from '../../constants/dashboard/breakpoints';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

const RootStyle = styled( 'div' )(({ theme }) => ({
  [theme.breakpoints.up( 'lg' )]: {
    flexShrink: 0,
    transition: theme.transitions.create( 'width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

interface IDashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }: IDashboardSidebarProps ): JSX.Element => {
  const { pathname } = useLocation();
  const {
    isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave,
  } = useCollapseDrawer();
  const { features } = useContext( FeatureFlagsContext );
  const { t } = useTranslation( 'SidebarConfiguration' );

  useEffect(() => {
    if ( isOpenSidebar ) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <LogoContainerStyled>
        <Logo />
      </LogoContainerStyled>
      <NavSection navConfig={SidebarConfiguration( features, t )} isShow={!isCollapse} />
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...( collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <DrawerWrapper
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </DrawerWrapper>
      </MHidden>

      <MHidden width="lgDown">
        <DrawerWrapper
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...( isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...( collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: ( theme ) => theme.customShadows.z20,
                bgcolor: ( theme ) => alpha( theme.palette.background.default, 0.88 ),
              }),
            },
          }}
        >
          {renderContent}
        </DrawerWrapper>
      </MHidden>
    </RootStyle>
  );
};

export default DashboardSidebar;
