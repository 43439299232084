import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../learnerPalette';

// ----------------------------------------------------------------------

export default function Autocomplete( theme: Theme ): unknown {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPalette = wlPalette?.background?.redViolet ?? theme.palette.primary.main;

  return {
    MuiAutocomplete: {
      styleOverrides: {
        loading: {
          color: colorPalette,
          fontSize: '1rem',
        },
        paper: {
          boxShadow: theme.customShadows.z20,
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: `${palette.light.background.listSelected} !important`,
          },
          '& .MuiAutocomplete-noOptions': {
            color: colorPalette,
            fontSize: '1rem',
          },
        },
        inputRoot: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
        },
      },
    },
  };
}
