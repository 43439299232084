import '../../dashboard-student/AccountPopOverComponents/AccountInfoConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileWrapper, ProfileName, ProfileType } from './AccountBriefStyles';
import userAuth from '../../../hooks/userAuth';

const AccountBrief: React.FC = () => {
  const { auth } = userAuth();
  const { t } = useTranslation( 'LearnerAccountInfo' );

  return (
    <ProfileWrapper>
      <ProfileName>
        { auth?.displayName || t( 'GUEST_DISPLAYNAME' ) }
      </ProfileName>
      <ProfileType>{t( 'ADMIN_PROFILE' )}</ProfileType>
    </ProfileWrapper>
  );
};

export default AccountBrief;
