import { ApolloError, makeVar } from '@apollo/client';

export interface IDataSelectionVar {
  id: string;
  name: string;
  type?: string;
}

export interface ISelectedOptionsSkillVarCache {
  error: ApolloError | undefined;
  data: IDataSelectionVar[]
}

export const emptySelectedOptionsSkillCache: ISelectedOptionsSkillVarCache = {
  error: undefined,
  data: [],
};

export const selectedOptionsSkillVar = makeVar<ISelectedOptionsSkillVarCache>( emptySelectedOptionsSkillCache );
