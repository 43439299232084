import { IconProps } from '@mui/material';

import IconWrapper from '../../wrappers/IconWrapper';

interface IIconComponentProps {
  id?: string
  alt?: string,
  src: string;
}

const IconComponent: React.FC<IconProps & IIconComponentProps> = ({
  id = 'icon_component', alt = 'icon component', src, ...props
}) => (
  <IconWrapper {...props}>
    <img data-testid={id} alt={alt} src={src} />
  </IconWrapper>
);

export default IconComponent;
