import './AccountInfoAdvisorConfig';
import { useTranslation } from 'react-i18next';

import {
  ProfileContainer, ProfileName, ProfileDetails, ProfileMail, LocationHeader, ProfileLocation, AvatarStyled,
} from './AccountInfoStyles';
import icons from '../../../constants/icons';
import userAuth from '../../../hooks/userAuth';

const AccountInfo: React.FC = () => {
  const { auth } = userAuth();
  const locationName = auth?.userRole === 'admin' ? auth?.advisor?.institutionName : auth?.studentInstitution?.institutionName;
  const { t } = useTranslation( 'AdvisorAccountInfo' );

  return (
    <ProfileContainer>
      <AvatarStyled
        alt="User"
        src={icons.DEFAULT_UNIVERSITY_AVATAR}
      />
      <ProfileDetails>
        <ProfileName>
          { auth?.displayName ?? t( 'GUEST_DISPLAYNAME' ) }
        </ProfileName>
        <ProfileMail>
          { auth?.username ?? t( 'GUEST_DISPLAYNAME' ) }
        </ProfileMail>
        <LocationHeader>
          {t( 'LOCATION' )}
        </LocationHeader>
        <ProfileLocation>
          {locationName ?? t( 'DEFAULT_LOCATION' )}
        </ProfileLocation>
      </ProfileDetails>
    </ProfileContainer>
  );
};

export default AccountInfo;
