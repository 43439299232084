export enum MixpanelEventsEnum {
  ADD_SKILL = 'Add Skill',
  DELETE_SKILL = 'Delete Skill',
  MODIFY_SKILL = 'Modify Skill',
  EXPLORE_SKILL_MODAL = 'Explore Skill Modal',
  EXPLORE_SKILL_SCREEN = 'Explore Skill Screen',
  ADD_OCCUPATION = 'Add Occupation',
  COMPLETED_PERSONAL_INFORMATION_STEP = 'Completed Personal Information Step',
  ADD_MAJOR = 'Add Major',
  COMPLETED_OCCUPATIONS_PREFERENCES_STEP = 'Completed Occupations Preferences Step',
  DELETE_MAJOR = 'Delete Major',
  COMPLETED_SKILLS_STEP = 'Completed Skills Step',
  UPLOAD_CV = 'Upload CV',
  REPLACE_CV = 'Replace CV',
  HELP_US_IMPROVE = 'Help us improve',
  VIEW_NOTIFICATIONS = 'View Notifications',
  CLEAR_NOTIFICATIONS = 'Clear Notifications',
  PROFILE_POPOVER = 'Profile Popover',
  GO_TO_PROFILE = 'Go to Profile',
  CHANGE_PASSWORD = 'Change Password',
  LOGOUT = 'Logout',
  EDIT_PERSONAL_INFO = 'Edit Personal Info',
  UPDATE_PERSONAL_INFO = 'Update Personal Info',
  PROFILE_UPDATED_SKILLS = 'Profile Updated Skills',
  PROFILE_UPDATED_PREFERENCES = 'Profile Updated Preferences',
  VIEW_MORE = 'View More',
  SKILL_TRACKER_FILTER = 'Skill Tracker Filter',
  OPTIONS_KANBAN_SKILL = 'Options Kanban Skill',
  EXPAND_PROFICIENCY_LEVELS = 'Expand Proficiency Levels',
  MOVE_ROULETTE = 'Move Roulette',
  TAB_EVENT = 'Tab Event',
  FAQ_QUESTION = 'FAQ Question',
  VIEW_PRIVACY_POLICY = 'View Privacy Policy',
  VIEW_TERMS_OF_SERVICE = 'View Terms of Service',
  SIGN_UP = 'Sign up',
  LOG_IN = 'Log in',
  RESET_PASSWORD = 'Reset Password',
  FORGOT_PASSWORD = 'Forgot Password',
  CLICK_HERE_SIGN_UP_CODE = 'Click here signup code',
  VERIFY_CODE = 'Verify Code',
  PRIVACY_POLICY_MODAL_OPEN = 'Privacy Policy Modal Open',
  ACCEPT_PRIVACY_POLICY = 'Accept Privacy Policy',
  DECLINE_PRIVACY_POLICY = 'Decline Privacy Policy',
  BROWSE_MORE_COURSES= 'Browse More Courses',
  FILTER_COURSES_PROVIDERS = 'Filter Course Provider',
  DELETE_OCCUPATION = 'Delete Occupation',
  EXPLORE_OCCUPATION_MODAL = 'Explore Occupation Modal',
  EXPLORE_OCCUPATION_SCREEN = 'Explore Occupation Screen',
  CLICK_COURSE = 'Click Course',
  CLICK_PROVIDER = 'Click Provider',
  SCREEN_TIME = 'Screen Time',
  WIZARD_COMPLETED = 'Wizard Completed',
  MAJOR_FILTER_SUGGESTIONS = 'Major Filter Suggestions',
  SELECT_SKILL_INSIGHTS_SCREEN = 'Select Skill Insights Screen',
  EXPLORE_OCCUPATION_OVERVIEW_MODAL = 'Explore Occupation Overview Modal',
  CARD_TOP_SKILL_SCROLL = 'Card Top Skill Scroll',
  SEARCH_BY_STUDENT_NAME = 'Search By Student Name',
  ADVISOR_PROFILE_POPOVER = 'Advisor Profile Popover',
  ADD_HOBBY = 'Add Hobby',
  IKIGAI_HOME = 'Ikigai Home',
  IKIGAI_STEP_PASSION = 'Ikigai Step Passion',
  IKIGAI_STEP_MISSION = 'Ikigai Step Mission',
  IKIGAI_STEP_PROFESSION = 'Ikigai Step Profession',
  IKIGAI_STEP_VOCATION = 'Ikigai Step Vocation',
  IKIGAI_RECOMMENDATIONS = 'Ikigai Recommendations',
}

// Generic Props (generic props apply to all events)
interface IGenericPropsForEvents {
  screen: string,
  component?: string,
  action?: string,
}

// #region SKILL EVENTS
interface IGenericSkillEvent extends IGenericPropsForEvents{
  skillName: string,
  skillStatus: string,
  skillType: string
}

interface IAddSkillEventData extends IGenericSkillEvent{
  recommended: boolean,
}

interface IAddSkillEvent {
  action: MixpanelEventsEnum.ADD_SKILL,
  data: IAddSkillEventData,
}

interface IDeleteSkillEvent {
  action: MixpanelEventsEnum.DELETE_SKILL,
  data : IGenericSkillEvent,
}

interface IModifySkillEventData extends IGenericSkillEvent{
  fromStatus: string,
}

interface IModifySkillEvent {
  action: MixpanelEventsEnum.MODIFY_SKILL,
  data: IModifySkillEventData,
}

interface IExploreSkillModalData extends IGenericPropsForEvents{
  skillName: string,
  skillType: string,
  skillStatus?: string,
  recommended?: boolean,
}

interface IExploreSkillModalEvent {
  action: MixpanelEventsEnum.EXPLORE_SKILL_MODAL,
  data: IExploreSkillModalData,
}

interface IExploreSkillEventData extends IGenericPropsForEvents {
  recommended: boolean,
  skillName: string,
}

interface IExploreSkillScreenEvent {
  action: MixpanelEventsEnum.EXPLORE_SKILL_SCREEN,
  data: IExploreSkillEventData,
}
// #endregion

// #region OCCUPATION EVENTS
interface IGenericOccupationEvent extends IGenericPropsForEvents{
  occupationName: string,
}
interface IDataAddOccupationEvent extends IGenericOccupationEvent{
  replaceAnOccupation: string,
  recommendedBy: string,
}
interface IDataExploreOccupationModalEvent extends IGenericOccupationEvent{
  percentageOfMatch: string | number,
  recommendedBy: string,
}
interface IAddOccupationEvent {
  action: MixpanelEventsEnum.ADD_OCCUPATION,
  data: IDataAddOccupationEvent,
}

interface IDeleteOccupationEvent {
  action: MixpanelEventsEnum.DELETE_OCCUPATION,
  data: IGenericOccupationEvent,
}

interface IExploreOccupationModalEvent {
  action: MixpanelEventsEnum.EXPLORE_OCCUPATION_MODAL,
  data: IDataExploreOccupationModalEvent,
}
interface IExploreOccupationScreen {
  action: MixpanelEventsEnum.EXPLORE_OCCUPATION_SCREEN,
  data: IGenericOccupationEvent,
}
// #endregion

// #region COURSES EVENTS
interface IGenericCourseEvent extends IGenericPropsForEvents {
  courseProvider?: string,
}

interface IBrowseMoreCoursesEvent {
  action: MixpanelEventsEnum.BROWSE_MORE_COURSES,
  data: IGenericCourseEvent,
}

interface IFilterCourseProvider {
  action: MixpanelEventsEnum.FILTER_COURSES_PROVIDERS,
  data: IGenericCourseEvent,
}

interface IDataGenericCourseEvent extends IGenericCourseEvent {
  skillName?: string,
  courseName?: string,
}

interface IClickCourseEvent {
  action: MixpanelEventsEnum.CLICK_COURSE,
  data: IDataGenericCourseEvent,
}

interface IClickProviderEvent {
  action: MixpanelEventsEnum.CLICK_PROVIDER,
  data: IDataGenericCourseEvent,
}
// #endregion

// #region GENERAL EVENTS
interface ISignUpEvent {
  action: MixpanelEventsEnum.SIGN_UP,
  data: IGenericPropsForEvents,
}

interface ILogInEvent {
  action: MixpanelEventsEnum.LOG_IN,
  data: IGenericPropsForEvents,
}

interface IResetPasswordEvent {
  action: MixpanelEventsEnum.RESET_PASSWORD,
  data: IGenericPropsForEvents,
}

interface IForgotPasswordEvent {
  action: MixpanelEventsEnum.FORGOT_PASSWORD,
  data: IGenericPropsForEvents,
}

interface IClickHereSignUpCodeEvent {
  action: MixpanelEventsEnum.CLICK_HERE_SIGN_UP_CODE,
  data: IGenericPropsForEvents,
}

interface IVerifyCodeEvent {
  action: MixpanelEventsEnum.VERIFY_CODE,
  data: IGenericPropsForEvents,
}

interface IPrivacyPolicyModalOpenEvent {
  action: MixpanelEventsEnum.PRIVACY_POLICY_MODAL_OPEN,
  data: IGenericPropsForEvents,
}

interface IDeclinePrivacyPolicyEvent {
  action: MixpanelEventsEnum.DECLINE_PRIVACY_POLICY,
  data: IGenericPropsForEvents,
}

interface IAcceptPrivacyPolicyEvent {
  action: MixpanelEventsEnum.ACCEPT_PRIVACY_POLICY,
  data: IGenericPropsForEvents,
}
// #endregion

// #region WIZARD EVENTS
interface IDataCompletedPersonalInformationStepEvent extends IGenericPropsForEvents{
  userType: string,
  majorsNumber: number,
  hobbiesNumber: number,
}

interface ICompletedPersonalInformationStepEvent {
  action: MixpanelEventsEnum.COMPLETED_PERSONAL_INFORMATION_STEP,
  data: IDataCompletedPersonalInformationStepEvent
}

interface IDataCompletedOccupationsPreferencesStepEvent extends IGenericPropsForEvents{
  occupationsNumber: number,
}

interface ICompletedOccupationsPreferencesStepEvent {
  action: MixpanelEventsEnum.COMPLETED_OCCUPATIONS_PREFERENCES_STEP,
  data: IDataCompletedOccupationsPreferencesStepEvent
}

interface IDataCompletedSkillsStepEvent extends IGenericPropsForEvents{
  skillsAdded: number,
  numberOfSpecialized: number,
  numberOfHuman: number,
}

interface ICompletedSkillsStepEvent {
  action: MixpanelEventsEnum.COMPLETED_SKILLS_STEP,
  data: IDataCompletedSkillsStepEvent
}

interface IUploadCVEvent {
  action: MixpanelEventsEnum.UPLOAD_CV,
  data: IGenericPropsForEvents,
}

interface IReplaceCVEvent {
  action: MixpanelEventsEnum.REPLACE_CV,
  data: IGenericPropsForEvents,
}

interface IWizardCompletedEvent {
  action: MixpanelEventsEnum.WIZARD_COMPLETED,
  data: IGenericPropsForEvents,
}
// #endregion

// #region MAJOR EVENTS
interface IGenericAddMajorEvent extends IGenericPropsForEvents{
  majorName: string,
}
interface IAddMajorEvent {
  action: MixpanelEventsEnum.ADD_MAJOR,
  data: IGenericAddMajorEvent,
}

interface IDeleteMajorEvent{
  action: MixpanelEventsEnum.DELETE_MAJOR,
  data: IGenericAddMajorEvent,
}
// #endregion

// #region PAGES EVENTS
interface IHelpUsImproveEvent {
  action: MixpanelEventsEnum.HELP_US_IMPROVE,
  data: IGenericPropsForEvents,
}

interface IViewNotificationsEvent {
  action: MixpanelEventsEnum.VIEW_NOTIFICATIONS,
  data: IGenericPropsForEvents,
}

interface IClearNotificationsEvent {
  action: MixpanelEventsEnum.CLEAR_NOTIFICATIONS,
  data: IGenericPropsForEvents,
}

interface IProfilePopoverEvent {
  action: MixpanelEventsEnum.PROFILE_POPOVER,
  data: IGenericPropsForEvents,
}

interface IGoToProfileEvent {
  action: MixpanelEventsEnum.GO_TO_PROFILE,
  data: IGenericPropsForEvents,
}

interface IChangePasswordEvent {
  action: MixpanelEventsEnum.CHANGE_PASSWORD,
  data: IGenericPropsForEvents,
}
interface ILogoutEvent {
  action: MixpanelEventsEnum.LOGOUT,
  data: IGenericPropsForEvents,
}

interface IEditPersonalInfoEvent {
  action: MixpanelEventsEnum.EDIT_PERSONAL_INFO,
  data: IGenericPropsForEvents,
}

interface IUpdatePersonalInfoEvent {
  action: MixpanelEventsEnum.UPDATE_PERSONAL_INFO,
  data: IGenericPropsForEvents,
}

interface IProfileUpdatedSkillsEvent {
  action: MixpanelEventsEnum.PROFILE_UPDATED_SKILLS,
  data: IGenericPropsForEvents,
}

interface IProfileUpdatedPreferencesEvent {
  action: MixpanelEventsEnum.PROFILE_UPDATED_PREFERENCES,
  data: IGenericPropsForEvents,
}

interface IViewMoreEventData extends IGenericPropsForEvents{
  name: string,
}
interface IViewMoreEvent {
  action: MixpanelEventsEnum.VIEW_MORE,
  data: IViewMoreEventData,
}

interface ISkillTrackerFilterEventData extends IGenericPropsForEvents{
  occupation?: string,
  skillType?: string,
}

interface ISkillTrackerFilterEvent {
  action: MixpanelEventsEnum.SKILL_TRACKER_FILTER,
  data: ISkillTrackerFilterEventData,
}

interface IOptionsKanabnSkillEvent {
  action: MixpanelEventsEnum.OPTIONS_KANBAN_SKILL,
  data: IGenericPropsForEvents,
}
interface IExpandProficiencyLevelsEvent {
  action: MixpanelEventsEnum.EXPAND_PROFICIENCY_LEVELS,
  data: IGenericPropsForEvents,
}

interface IDataMoveRouletteEvent extends IGenericPropsForEvents{
  name: string,
}
interface IMoveRouletteEvent {
  action: MixpanelEventsEnum.MOVE_ROULETTE,
  data: IDataMoveRouletteEvent,
}

interface ITabEventData extends IGenericPropsForEvents{
  tabName: string,
}

interface ITabEvent {
  action: MixpanelEventsEnum.TAB_EVENT,
  data: ITabEventData,
}

interface IFAQQuestionEventData extends IGenericPropsForEvents{
  questionNumber: string,
}

interface IFAQQuestionEvent {
  action: MixpanelEventsEnum.FAQ_QUESTION,
  data: IFAQQuestionEventData,
}

interface IViewPrivacyPolicyEvent {
  action: MixpanelEventsEnum.VIEW_PRIVACY_POLICY,
  data: IGenericPropsForEvents,
}

interface IViewTermsOfServiceEvent {
  action: MixpanelEventsEnum.VIEW_TERMS_OF_SERVICE,
  data: IGenericPropsForEvents,
}
// #endregion

// #region SCREEN TIME
interface IDataScreenTimeEvent extends IGenericPropsForEvents{
  timeScreen: string,
}

interface IScreenTimeEvent {
  action: MixpanelEventsEnum.SCREEN_TIME,
  data: IDataScreenTimeEvent,
}
// #endregion

// #region ADVISOR
interface IMajorFilterSuggestionsEvent {
  action: MixpanelEventsEnum.MAJOR_FILTER_SUGGESTIONS,
  data: IGenericOccupationEvent,
}

interface ISelectSkillInsightsScreenEvent {
  action: MixpanelEventsEnum.SELECT_SKILL_INSIGHTS_SCREEN,
  data: IGenericOccupationEvent,
}

interface IExploreOccupationOverviewModalEvent {
  action: MixpanelEventsEnum.EXPLORE_OCCUPATION_OVERVIEW_MODAL,
  data: IGenericOccupationEvent,
}

interface ICardTopSkillScrollEvent {
  action: MixpanelEventsEnum.CARD_TOP_SKILL_SCROLL,
  data: IGenericPropsForEvents,
}

interface IDataSearchByStudentNameEvent extends IGenericPropsForEvents {
  studentName: string,
}

interface ISearchByStudentNameEvent {
  action: MixpanelEventsEnum.SEARCH_BY_STUDENT_NAME,
  data: IDataSearchByStudentNameEvent,
}

interface IAdvisorProfilePopoverEvent {
  action: MixpanelEventsEnum.ADVISOR_PROFILE_POPOVER,
  data: IGenericPropsForEvents,
}
// #endregion

// #region HOBBY

interface IGenericHobbyEvent extends IGenericPropsForEvents{
  hobbyName: string,
  hobbyId: string,
}
interface IAddHobbyEvent {
  action: MixpanelEventsEnum.ADD_HOBBY,
  data: IGenericHobbyEvent,
}
// #endregion

// #region IKIGAI
interface IIkigaiHome {
  action: MixpanelEventsEnum.IKIGAI_HOME,
  data: IGenericPropsForEvents,
}

interface IIkigaiPassion {
  action: MixpanelEventsEnum.IKIGAI_STEP_PASSION,
  data: IGenericPropsForEvents,
}

interface IIkigaiMission {
  action: MixpanelEventsEnum.IKIGAI_STEP_MISSION,
  data: IGenericPropsForEvents,
}

interface IIkigaiProfession {
  action: MixpanelEventsEnum.IKIGAI_STEP_PROFESSION,
  data: IGenericPropsForEvents,
}

interface IIkigaiVocation {
  action: MixpanelEventsEnum.IKIGAI_STEP_VOCATION,
  data: IGenericPropsForEvents,
}

interface IIkigaiRecommendationsEvent extends IGenericPropsForEvents {
  recommendedJobs: string[],
}

interface IIkigaiRecommendations {
  action: MixpanelEventsEnum.IKIGAI_RECOMMENDATIONS,
  data: IIkigaiRecommendationsEvent,
}
// #endregion

export type IMixpanelEvents =
IAddSkillEvent
| ICompletedPersonalInformationStepEvent
| IAddMajorEvent
| ICompletedOccupationsPreferencesStepEvent
| IDeleteMajorEvent
| ICompletedSkillsStepEvent
| IUploadCVEvent
| IReplaceCVEvent
| IHelpUsImproveEvent
| IViewNotificationsEvent
| IClearNotificationsEvent
| IProfilePopoverEvent
| IGoToProfileEvent
| IChangePasswordEvent
| ILogoutEvent
| IEditPersonalInfoEvent
| IUpdatePersonalInfoEvent
| IProfileUpdatedSkillsEvent
| IProfileUpdatedPreferencesEvent
| IDeleteSkillEvent
| IModifySkillEvent
| IExploreSkillModalEvent
| IExploreSkillScreenEvent
| IViewMoreEvent
| ISkillTrackerFilterEvent
| IOptionsKanabnSkillEvent
| IExpandProficiencyLevelsEvent
| IMoveRouletteEvent
| ITabEvent
| IFAQQuestionEvent
| IViewPrivacyPolicyEvent
| IViewTermsOfServiceEvent
| IAddOccupationEvent
| IExploreOccupationModalEvent
| IClickCourseEvent
| ISignUpEvent
| ILogInEvent
| IResetPasswordEvent
| IForgotPasswordEvent
| IClickHereSignUpCodeEvent
| IVerifyCodeEvent
| IPrivacyPolicyModalOpenEvent
| IDeclinePrivacyPolicyEvent
| IAcceptPrivacyPolicyEvent
| IBrowseMoreCoursesEvent
| IFilterCourseProvider
| IDeleteOccupationEvent
| IExploreOccupationScreen
| IScreenTimeEvent
| IWizardCompletedEvent
| IMajorFilterSuggestionsEvent
| ISelectSkillInsightsScreenEvent
| IExploreOccupationOverviewModalEvent
| ICardTopSkillScrollEvent
| ISearchByStudentNameEvent
| IAdvisorProfilePopoverEvent
| IClickProviderEvent
| IAddHobbyEvent
| IIkigaiHome
| IIkigaiPassion
| IIkigaiMission
| IIkigaiProfession
| IIkigaiVocation
| IIkigaiRecommendations
