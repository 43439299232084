import { GridProps } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LogoContainerStyled } from './styles/LogoStyles';
import icons from '../../../constants/icons';
import { PlatformAltTexts } from '../../../constants/PlatformAltTexts';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { PATH_DASHBOARD_STUDENT, PATH_DASHBOARD } from '../../../routes/paths';
import { getLogoFromFeatures } from '../../../utils/getLogoFrom';

interface IDashboardLogoProps {
  isLearner?: boolean;
}

const Logo: React.FC<GridProps & IDashboardLogoProps> = ({ sx, isLearner }) => {
  const { features } = useContext( FeatureFlagsContext );

  const getLogo = (): string | undefined => {
    if ( !isLearner ) {
      return icons.ADVISOR_LOGO;
    }
    if ( features.customLogo ) {
      return getLogoFromFeatures( features.customLogo ).url;
    }

    return icons.LEARNER_LOGO;
  };

  const getRedirectUrl = (): string => {
    const { customRedirectUrl } = features;

    if ( customRedirectUrl ) {
      return customRedirectUrl;
    }

    return isLearner ? PATH_DASHBOARD_STUDENT.admin.pageHome : PATH_DASHBOARD.admin.pageInsights;
  };

  return (
    <LogoContainerStyled sx={{ ...sx }}>
      <Link to={getRedirectUrl()}>
        <img
          aria-label={PlatformAltTexts.sidebar.description}
          alt={PlatformAltTexts.sidebar.title}
          src={getLogo()}
        />
      </Link>
    </LogoContainerStyled>
  );
};

export default Logo;
