import {
  ApolloClient,
  ApolloProvider,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import React from 'react';

import environmentVariables from '../appVariables';
import { cache, notificationsVar } from '../cache/cache';
import { webSocketURL as url } from '../config';
import { HTTP_STATUS_CODE } from '../constants/apolloConfig';
import { NOTIFICATION_TEXT } from '../constants/notification';
import SubscribeConnectionHook from '../hooks/subscribeConnectionHook/subscribeConnectionHook';
import { Mixpanel } from '../mixpanel/mixpanel';
import { refreshAuthTokenService } from '../services/auth';
import { getJwtToken, removeCookie } from '../utils/cookiesUtil';
import { tokenDecode } from '../utils/jwtUtils';

const { setSubscribeConnection } = SubscribeConnectionHook();

const authLink = setContext((
  _, { headers },
) => {
  const token = getJwtToken();

  return {
    headers: {
      ...headers,
      ...( token
        && { Authorization: `Bearer ${token}` }
      ),
    },
  };
});

const httpLink = createUploadLink({
  uri: environmentVariables?.environment !== 'development'
    ? `${environmentVariables?.reactAppApiGraphql}/graphql`
    : `${environmentVariables?.reactAppApiGraphqlDev}/graphql`,
  headers: {
    'Apollo-Require-Preflight': 'true',
    'x-timeout': ( 1000 * 60 * 5 ).toString(),
  },
});

const logout = async (): Promise<void> => {
  const token = getJwtToken();

  if ( !token ) {
    return;
  }
  const tokenContent = tokenDecode( token );
  const redirectPath = tokenContent.redirectLogoutUrl ?? '/';

  Mixpanel.reset();
  removeCookie( 'token' );
  removeCookie( 'preferredLanguage' );
  sessionStorage.clear();
  window.location.href = redirectPath;
  await client.clearStore();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleForbiddenError = async ({ operation, forward }: any ): Promise<any> => {
  const token = getJwtToken();

  if ( !token ) {
    await logout();

    return;
  }

  const currentNumericDate = Math.round( Date.now() / 1000 );
  const tokenContent = tokenDecode( token );
  const tokenExp = tokenContent.expiresIn;

  if ( currentNumericDate <= tokenExp && !tokenContent.remember ) {
    await logout();

    return;
  }

  try {
    const accessToken = await refreshAuthTokenService( token, tokenContent.refreshToken );
    const oldHeaders = operation.getContext().headers;

    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `Bearer ${accessToken}`,
      },
    });

    // eslint-disable-next-line consistent-return
    return forward( operation );
  } catch ( refreshError ) {
    notificationsVar([
      {
        id: operation?.operationName,
        title: refreshError as string,
        description: NOTIFICATION_TEXT.errorDescription,
        autoClose: true,
        leftIcon: <WarningAmberRoundedIcon color="error" />,
      },
    ]);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logoutLink = onError(({ networkError, operation, forward }: any ) => {
  if ( !networkError ) {
    return;
  }

  switch ( networkError?.result?.errors[0]?.extensions?.code?.toString()?.toUpperCase() || '' ) {
    case 'FORBIDDEN':
      handleForbiddenError({ operation, forward });
      break;

    case 'TIME_OUT':
      forward( operation );
      break;

    case HTTP_STATUS_CODE.BadGateway:
      forward( operation );
      break;

    case HTTP_STATUS_CODE.BadRequest:
      forward( operation );
      break;

    case HTTP_STATUS_CODE.Unauthorized:
      forward( operation );
      break;

    default:
      notificationsVar([
        {
          id: operation?.operationName,
          title: NOTIFICATION_TEXT.errorTitle,
          description: NOTIFICATION_TEXT.errorDescription,
          autoClose: true,
          leftIcon: <WarningAmberRoundedIcon color="error" />,
        },
      ]);
      break;
  }
});

const wsLink = new GraphQLWsLink( createClient({
  url,
  on: {
    closed: () => {
      setSubscribeConnection( false );
    },
    connected: () => {
      setSubscribeConnection( true );
    },
  },
}));
const timeoutLink = new ApolloLinkTimeout( 1000 * 60 * 5 );
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition( query );

    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const link = authLink.concat( splitLink ).concat( timeoutLink );

// initialize apollo client
export const client = new ApolloClient({
  cache,
  link: from([logoutLink.concat( link )]),
  credentials: '*',
});

const ApolloConfig: React.FC = ({ children }) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
);

export default ApolloConfig;
