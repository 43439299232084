export type LanguagesEnum = 'en' | 'es';

export const getDefaultLanguageFromFeature = (
  initialValue: string[],
): LanguagesEnum => {
  const activeLanguages: string[] = initialValue.filter(( availableFeature ) => availableFeature.includes(
    'DEFAULT_LANGUAGE_',
  ));

  if ( !activeLanguages.length ) {
    return 'en';
  }

  const language = activeLanguages[0].split( '_' )[2]?.toLowerCase() ?? 'en';

  return ( language === 'en' || language === 'es' ) ? language : 'en';
};
