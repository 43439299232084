import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import sidebarConfig from './SidebarConfig';
import sidebarProfileConfig from './SidebarProfileConfiguration/SidebarProfileConfiguration';
import {
  DrawerDesktopStyled, DrawerMobileStyled, DrawerContainerStyled, BackButtonStyled, LogoContainerStyled,
} from './styles/DashboardSidebarStyles';
import AvatarComponent from '../../components/generics/AvatarComponent/AvatarComponent';
import { styleConfigs } from '../../components/resources/styleConfigs';
import Logo from '../../components/template-minimal/LogoComponent/Logo';
import NavSectionStudent from '../../components/template-minimal/NavSectionStudent/NavSectionStudent';
import StackWrapper from '../../components/wrappers/StackWrapper';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';
import userAuth from '../../hooks/userAuth';
import { PATH_DASHBOARD_STUDENT } from '../../routes/paths';

interface IDashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  iframeActive: boolean;
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar, iframeActive }: IDashboardSidebarProps ): JSX.Element => {
  const { auth } = userAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation( 'LearnerSidebar' );
  const { t: tSidebarProfile } = useTranslation( 'SidebarProfileConfiguration' );
  const isProfile = pathname.includes( PATH_DASHBOARD_STUDENT.profile.profile );
  const studentInitials = `${auth?.student?.name.replace( /\s/g, '' )[0]}${auth?.student?.lastName.replace( /\s/g, '' )[0]}`;
  const { features } = useContext( FeatureFlagsContext );

  useEffect(() => {
    if ( isOpenSidebar ) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <LogoContainerStyled>
        <Logo
          isLearner
        />
      </LogoContainerStyled>
      {isProfile && (
        <StackWrapper
          pt={styleConfigs.marginPadding.custom4}
        >
          <AvatarComponent
            nameInitials={auth?.student?.name && studentInitials}
            size={styleConfigs.dimensions.width170}
          />
          <BackButtonStyled
            variant="outlined"
            size="large"
            startIcon={<ArrowBackOutlinedIcon />}
            onClick={() => {
              navigate( PATH_DASHBOARD_STUDENT.admin.pageHome );
            }}
          >
            {t( 'BACK_TO_DASHBOARD' )}
          </BackButtonStyled>
        </StackWrapper>
      )}
      <NavSectionStudent
        navConfig={
          isProfile
            ? sidebarProfileConfig( tSidebarProfile )
            : sidebarConfig( features )
        }
      />
    </>
  );

  return (
    <DrawerContainerStyled
      iframeactive={iframeActive ? 1 : 0}
    >
      <DrawerDesktopStyled
        open
        variant="persistent"
        iframeactive={iframeActive ? 1 : 0}
        PaperProps={{
          sx: {
            width: styleConfigs.dimensions.width280,
          },
        }}
      >
        {renderContent}
      </DrawerDesktopStyled>
      <DrawerMobileStyled
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        iframeactive={iframeActive ? 1 : 0}
        PaperProps={{
          sx: { width: styleConfigs.dimensions.width280 },
        }}
      >
        {renderContent}
      </DrawerMobileStyled>
    </DrawerContainerStyled>
  );
};

export default DashboardSidebar;
