const locale = {
  GUEST_DISPLAYNAME: 'Invitado',
  LOCATION: 'Institución',
  DEFAULT_LOCATION: 'Ontario, Canadá',
  INFO_POPOVER_STUDENT_LOCATION: 'Universidad',
  INFO_POPOVER_PROFILE: 'Purdue University',
  ADMIN_PROFILE: 'Admin',
  NO_MAJORS_LENGTH: 'No hay información de áreas de interés',
  ADD_MAJOR: 'Agrega tu primer área de interés',
};

export default locale;
