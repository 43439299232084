const locale = {
  SUCCESS_NOTIFICATION: 'Éxito',
  DANGER_NOTIFICATION: 'Peligro',
  CONGRATULATION_NOTIFICATION: 'Felicidades!',
  WARNING_NOTIFICATION: 'Advertencia!',
  LOKOUT_NOTIFICATION: 'Atención!',
  LOOKOUT_NOTIFICATION_DESCRIPTION_1_OCCUPATION: '¡Esta acción ha disminuido tu compatibilidad con {{numberOccupations}} ocupación!',
  LOOKOUT_NOTIFICATION_DESCRIPTION: '¡Esta acción ha disminuido tu compatibilidad con {{numberOccupations}} ocupaciones!',
  REMOVED_NOTIFICATION: 'Se eliminó!',
  CONGRATULATIONS_1_OCCUPATION: '¡Esta actualización de habilidades ha aumentado tu compatibilidad con {{numberOccupations}} ocupación!',
  CONGRATULATIONS: '¡Esta actualización de habilidades ha aumentado tu compatibilidad con {{numberOccupations}} ocupaciones!',
  YOU_ADDED: 'Añadiste',
  YOU_MOVED: 'Moviste',
  TO_LEARNING_SKILL_TRACKER: 'a la columna "Objetivos de Aprendizaje" en tu monitor de habilidades',
  TO_LEARN_SKILL_TRACKER: 'a la columna "Brechas" en tu monitor de habilidades',
  ADD_SKILL_NOTIFICATION: 'Se ha añadido {{skill}}',
  DRAG_SKILL_NOTIFICATION: 'Se ha movido {{skill}}',
  REMOVED_SKILL_NOTIFICATION: 'Se ha eliminado {{skill}}',
  FROM_LEARNING_SKILL_TRACKER: 'de la columna Objetivos de Aprendizaje en tu monitor de habilidades',
  YOU_DELETED: 'Eliminaste',
  FROM_LEARN_SKILL_TRACKER: 'de la columna "Brechas" en tu monitor de habilidades',
  CV_UPLOAD_ERROR: 'Error al subir el archvo',
  CV_VALID_FORMAT: 'Solo formato PDF aceptado',
  CV_ERROR_452: 'El bucket no existe',
  CV_ERROR_512: 'La operación de limpieza de texto falló. Por favor, inténtalo de nuevo más tarde',
  CV_ERROR_513: 'La operación de traducción de texto falló. Por favor, inténtalo de nuevo más tarde',
  CV_ERROR_454: 'La extracción de habilidades falló. Por favor, inténtalo de nuevo más tarde',
  CV_MAX_SIZE: 'Se excedió el tamaño máximo de PDF de {{size}} MB',
  CV_ERROR_002: 'Error al subir el archvo a google',
  COMPLETE_LEARNING_PATH_DESCRIPTION: '¡Encontrarás tus logros en la sección de',
  LP_COMPLETE_STATUS: 'Completados!',
  COMPLETE_LP_TITLE: '¡Excelente trabajo!',
  REACTIVATE_LP_DESCRIPTION: 'Esta ruta ha vuelto a tus rutas activas. ¡Sigamos progresando y logrando juntos!',
  REACTIVATE_LP_TITLE: 'Ruta de aprendizaje reactivada',
  OCCUPATION_SELECTED: 'Ocupación seleccionada',

};

export default locale;
