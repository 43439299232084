import { IFeatures } from '../contexts/reducer/featureFlagsReducer';
import { ILoginUserSuccessResponse } from '../views/Auth/Login/interfaces/IGoogleUser';

type ICaseHandleAuth = 'jwtTokenAndTokenAndNotExpiredAndnotEmbebedInIframe' | 'embebedInIframe' | 'tokenAndExpiredTokenAndredirectLogoutUrl' | 'embebedInIframeAndPlatform' | 'default' ;
const expiryValidation = ( expireIn: number ) : boolean => {
  const now = Date.now() / 1000;

  return expireIn < now;
};

export const handleCaseAuth = ( args : {
  token: ILoginUserSuccessResponse,
  jwToken: string|null,
  features: IFeatures
}): ICaseHandleAuth => {
  const { token, jwToken, features } = args;

  if ( jwToken && token && !expiryValidation( token.exp || 0 ) && !features.embededInIframe ) {
    return 'jwtTokenAndTokenAndNotExpiredAndnotEmbebedInIframe';
  }
  if ( features.embededInIframe ) {
    if ( features.iframeAndPlatform ) {
      return 'embebedInIframeAndPlatform';
    }

    return 'embebedInIframe';
  }
  if ( token && expiryValidation( token.exp || 0 ) && token.redirectLogoutUrl ) {
    return 'tokenAndExpiredTokenAndredirectLogoutUrl';
  }

  return 'default';
};
