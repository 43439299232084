import { alpha } from '@mui/material/styles';

function createGradient( color1: string, color2: string ): string {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface IGradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  chatGPT?: string;
}

interface IChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
  frenchRose?: string;
  yaleBlue?: string;
  pictonBlue?: string;
  deepPink?: string;
  purpleHeart?: string;
  blazeOrange?: string;
  curiousBlue?: string;
  freshGreen?: string;
  coralRed?: string;
}

interface ICustomStyles {
  [key: string]: string;
}

type IBordersPaletteOptions = ICustomStyles

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypeText {
    redViolet?: string;
    coralRed?: string;
    amethyst?: string;
    yaleBlue?: string;
    frenchRose?: string;
    mistBlue?: string;
    moonBlue?: string;
    prussianBlue?: string;
    tooltipRankingList?: string;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypeBackground {
    neutral: string;
    redViolet?: string;
    moonBlueA29?: string;
    frenchRoseA29?: string;
    greySkeleton?: string;
    yaleBlue?: string;
    prussianBlue?: string;
    pictonBlue?: string;
    frenchRose?: string;
    cadetGrey?: string;
    coralRed?: string;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    gradients: IGradientsPaletteOptions;
    chart: IChartPaletteOptions;
    borders: IBordersPaletteOptions;
    light?: Palette;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions {
    gradients: IGradientsPaletteOptions;
    chart: IChartPaletteOptions;
    borders?: IBordersPaletteOptions;
    light?: PaletteOptions;
  }
}

declare module '@mui/material' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#5FB2E029',
  light: '#5FB2E0',
  main: '#104799',
  dark: '#406CAD',
  darker: '#7091C2',
  backgroundLightBlue: '#5FB2E029',
  darkBlue: '#172738',
  frenchRose: '#F25A8C',
  frenchRoseA29: '#f25a8c29',
  redViolet: '#D82B9A',
  purplishPink: '#E055AE',
  prussianBlue: '#172638',
  royalFuchsia: '#d82b9a7a',
  cadetGrey: '#919eab3d',
  moonBlueA29: '#10479929',
  lightBlueTranslucent: '#dff0f94d',
  lightBlue: '#5fb2e03d',
  lightBlue05: '#DFF0F9',
  daySkyBlueA80: '#94cbff80',
  moonBlue: '#104799b3',
  coolGrey: '#919eabcc',
  whiteSmoke: '#f4f6f8',
  vividTangerine: '#FFA48D',
  antiqueWhite: '#FFE7D9',
  brightCeruleanA14: '#18a2dc14',
  cascade: '#919eab52',
  transparent: 'transparent',
  curiousBlue: '#18A2DC',
  morningGlory: '#A2D9F1',
  ocean: '#10719A',
  brightCeruleanA29: '#18A2DC29',
  black: '#000000',
  astronautBlue: '#094058',
  ballBlue: '#18a2dc1f',
  whiteA80: '#ffffff80',
  aquaGreen: '#34D399',
  pinkLemonade: '#e02881',
  blueDianne: '#164656',
  deepPink: '#FC248D',
  purpleHeart: '#6D28D9',
  greenPeas: '#68d75b',
  pumpkinOrange: '#f96d21',
  mistBlueA40: '#63738140',
  gableGreen: '#172638bf',
  goldenYellow: '#FFE700',
  balticSea: '#212b363d',
  watermelon: '#fa4447',
  bluishCyan: '#17A2DC',
  none: 'none',

  // News
  chartSkillTrackerSelect: '#F0F2F4',
  topListColorPrevious: '#406CAD',
  tooltipRankingList: '#454F5B',
  blackSkeleton: '#D9D9D9',
  floatingButton: '#00000040',
  frenchRoseVariant: '#F57BA3',
  greenPathCourses: '#45963B',
  carrouselButton: '#7b7b7b3d',
  orangeUva: '#E47404',
  blueUva: '#232D4B',
  skinUva: '#F9DCBF',
};
const SECONDARY = {
  lighter: '#F57BA3',
  light: '#F25A8C',
  main: '#D82B9A',
  dark: '#E055AE',
  darker: '#E880C2',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#B1EBAA',
  light: '#81DE77',
  main: '#62D655',
  dark: '#45963b',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FED4B5',
  light: '#FE9A52',
  main: '#FD6F08',
  dark: '#CA5906',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFA4A1',
  light: '#FF6D68',
  main: '#FF4842',
  dark: '#CC3A35',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha( '#919EAB', 0.08 ),
  500_12: alpha( '#919EAB', 0.12 ),
  500_16: alpha( '#919EAB', 0.16 ),
  500_24: alpha( '#919EAB', 0.24 ),
  500_32: alpha( '#919EAB', 0.32 ),
  500_48: alpha( '#919EAB', 0.48 ),
  500_56: alpha( '#919EAB', 0.56 ),
  500_80: alpha( '#919EAB', 0.8 ),
};

const GRADIENTS = {
  primary: createGradient( PRIMARY.light, PRIMARY.main ),
  info: createGradient( INFO.light, INFO.main ),
  success: createGradient( SUCCESS.light, SUCCESS.main ),
  warning: createGradient( WARNING.light, WARNING.main ),
  error: createGradient( ERROR.light, ERROR.main ),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      ...PRIMARY,
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      ...PRIMARY,
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
    },
    borders: {
      ...PRIMARY,
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
