import { ReactiveVar } from '@apollo/client';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as _ from 'lodash';

import { notificationsVar } from '../../cache/cache';
import { IJobPreferencesVar } from '../../cache/learner/jobPreferencesReactiveVar';
import {
  emptyRecommendedOccupationsCache, IRecommendedSkills, IRecommendedSKillsCache, recommendedSkillsVar,
} from '../../cache/learner/recommendedSkillsReactiveVar';
import { decryptor, encryptor } from '../../utils/decryptor';

interface IRecommendedOccupationHook {
  setRecommendedSkillsError : ( errorMessage: string ) => void;
  setRecommendedSkills: ( recommendedOccupations: IRecommendedSkills[]) => void;
  setFromLocalRecommendedSkills: () => boolean;
  updateRecommendedSkills: ( id: string ) => void;
  recomendetSkillsMatchCalculate: ( skills: IRecommendedSkills[], jobPreferences:IJobPreferencesVar[])
  => IRecommendedSkills[];

}

const useRecommendedSkillsHook = (
  recommendedSkillsVarProp: ReactiveVar<IRecommendedSKillsCache>,
): IRecommendedOccupationHook => {
  const {
    data: recommendedSkillsData,
    error: recommendedSkillError,
  } = recommendedSkillsVarProp();

  const setRecommendedSkillsError = ( errorMessage: string ): void => {
    recommendedSkillsVar({ ...emptyRecommendedOccupationsCache, error: errorMessage });
  };
  const setRecommendedSkills = ( recommendedOccupations: IRecommendedSkills[]): void => {
    recommendedSkillsVar(
      {
        error: '',
        data: recommendedOccupations,
      },
    );

    const encryptedData = encryptor( JSON.stringify( recommendedOccupations ));

    if ( !encryptedData ) {
      return;
    }

    sessionStorage.setItem( 'recommendedSkills', encryptedData );
  };

  const setFromLocalRecommendedSkills = (): boolean => {
    const dataLocal = sessionStorage.getItem( 'recommendedSkills' );
    const decryptedData = decryptor( dataLocal ?? '' );

    if ( !decryptedData ) {
      return false;
    }
    recommendedSkillsVar(
      {
        error: '',
        data: JSON.parse( decryptedData ),
      },
    );

    return true;
  };

  const updateRecommendedSkills = ( id: string ): void => {
    if ( recommendedSkillError ) {
      notificationsVar([
        {
          id: 'updateRecommendedSkills',
          title: recommendedSkillError,
          description: recommendedSkillError,
          autoClose: true,
          leftIcon: <WarningAmberRoundedIcon color="error" />,
        },
      ]);
    } else if ( recommendedSkillsData ) {
      setRecommendedSkills( _.cloneDeep( recommendedSkillsData.filter(( skill ) => skill.id !== id )));
    }
  };

  const recomendetSkillsMatchCalculate = ( skills: IRecommendedSkills[],
    jobPreferences:IJobPreferencesVar[]):IRecommendedSkills[] =>
    skills.map(( skill ) => {
      const skillUniverse = jobPreferences.length;
      const skillSample = jobPreferences.reduce(( acc, current ) =>
        acc + current.jobTitleSkillSet.filter(( sk ) => sk.skillId === skill.id ).length,
      0 );

      return {
        ...skill,
        percentage: skillUniverse > 0 ? Math.round(( skillSample * 100 ) / skillUniverse ) : 0,
      };
    });

  return {
    setRecommendedSkillsError,
    setRecommendedSkills,
    setFromLocalRecommendedSkills,
    updateRecommendedSkills,
    recomendetSkillsMatchCalculate,
  };
};

export default useRecommendedSkillsHook;
