const locale = {
  CURRENT_LANGUAGE: 'Español',
  TITLE_POPOVER: 'Seleccione Idioma',
  POPOVER_CONTENT: [
    { id: 1, language: 'Inglés', languageId: 'en' },
    { id: 2, language: 'Español', languageId: 'es' },
  ],
};

export default locale;
