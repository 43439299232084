const locale = {
  NOTIFICATION_EMPTY_STATE_MESSAGE: 'No hay nuevas notificaciones',
  NOTIFICATION_EMPTY_STATE_TITLE: 'No hay notificaciones',
  NOTIFICATION_EMPTY_STATE_SUBTITLE: `¡Te avisaremos cuando haya algo nuevo!`,
  NOTIFICATION_TEXT_TITLE: 'Notificaciones',
  NOTIFICATION_TEXT_UNREAD_NOTIFICATION: 'Tienes {{unread}} notificaciones sin leer',
  NOTIFICATION_TEXT_NO_COMPLETED_TEXT: 'ahora está completado. Tu compatibilidad con {{occupations}} ocupaciones ha sido actualizada!',
  NOTIFICATION_TEXT_NO_COMPLETED_TEXT_SINGULAR: 'ahora está completado. Tu compatibilidad con {{occupations}} ocupación ha sido actualizada!',
  NOTIFICATION_TEXT_IN_COLUMN_TEXT: 'ahora está en la columna ',
  NOTIFICATION_TEXT_IS_COMPLETED_TEXT: 'ahora está completado, ¡Sigue así con tu excelente trabajo!',
  NOTIFICATION_TEXT_EXPLORE_TEXT: 'Explora nuestros cursos y programas para mantenerte al día con las últimas tendencias.',
  NOTIFICATION_TEXT_CHANCE_TEXT: 'Tus posibilidades de convertirte en',
  NOTIFICATION_TEXT_DECREASE_TEXT: 'ha disminuido.',
  NOTIFICATION_TEXT_NO_LONGER_PART_TEXT: 'ahora está en la columna {{column}}. Tu compatibilidad con {{occupations}} ocupaciones ha sido actualizada.',
  NOTIFICATION_TEXT_NO_LONGER_PART_TEXT_SINGULAR: 'ahora está en la columna {{column}}. Tu compatibilidad con {{occupations}} ocupación ha sido actualizada.',
  NOTIFICATION_TEXT_ADDED_NO_OCCUPATION: 'ahora está en la columna {{column}} ¡Sigue así con tu excelente trabajo!',
  NOTIFICATION_TEXT_NO_LONGER_PART_NO_JOB_TEXT: 'ya no es parte de tu conjunto de habilidades. Explora nuestros cursos y programas para mantenerte al día con las últimas tendencias.',
  NOTIFICATION_TEXT_ERROR_TITLE: 'Algo salió mal con el servidor',
  NOTIFICATION_TEXT_ERROR_DESCRIPTION: 'Por favor, inténtelo de nuevo más tarde',
  backlog: 'por aprender',
  learning: 'aprendiendo',
  completed: 'completado',
};

export default locale;
