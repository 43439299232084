import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { useTheme } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../theme/learnerPalette';
import AvatarWrapper from '../../../wrappers/AvatarWrapper';
import GridWrapper from '../../../wrappers/GridWrapper';

export const AvatarContainerStyled = styled( GridWrapper )(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const AvatarStyled = styled( AvatarWrapper )((
  { size }:{size: number},
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColorPalette = wlPalette?.background.redViolet ?? palette.light.background.redViolet;
  const colorPalette = wlPalette
    ? wlPalette.text.redViolet : palette.light.text.redViolet;

  return {
    width: size,
    height: size,
    fontSize: size * 0.4,
    color: colorPalette,
    background: alpha( backgroundColorPalette, 0.24 ),
    [useTheme().breakpoints.down( 'sm' )]: {
      width: size * 0.75,
      height: size * 0.75,
      fontSize: size * 0.28,
    },
  };
});

export const PersonOutlinedIconStyled = styled( PersonOutlined )((
  { size }:{size: number},
) => ({
  fontSize: size * 0.7,
  color: palette.light.icons.curiousBlue,
}));
