import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { eventTime, pathnameEvent } from '../../cache/cache';
import userAuth from '../../hooks/userAuth';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { useMixpanelTimeFunction } from '../../utils/mixpanelTimeFunction';

const MixpanelOnRouteChange: React.FC = () => {
  const { pathname, search } = useLocation();
  const currentPath = useReactiveVar( pathnameEvent );
  const { auth } = userAuth();
  const { changeScreen } = useMixpanelTimeFunction( eventTime, pathnameEvent, auth );

  useEffect(() => {
    if (( currentPath !== getMixpanelName( `${pathname}${search}` ))) {
      changeScreen( pathname + search );
    }
  }, [pathname, search]);

  return null;
};

export default MixpanelOnRouteChange;
