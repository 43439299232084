const locale = {
  SKILL_INSIGHTS: 'Skill Insights',
  STUDENT_OVERVIEW: 'Student Overview',
  STUDENT_ADVISING: 'Student Advising',
  COURSE_RECOMMENDATIONS: 'Course Recommendations',
  CAREER_TRENDS: 'Career Trends',
  CONTACT_US: 'Contact Us',
  FAQ: 'FAQ',
  GENERAL: 'General',
  SUPPORT: 'Support',
};

export default locale;
